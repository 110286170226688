import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Drawer } from '@material-ui/core'
import { Cancel, Help } from '@material-ui/icons'
import { useAuth } from 'context/auth/AuthContext'
import { useUi } from 'context/ui/UIContext'
import Menu from 'pages/private/privateLayout/menu/Menu'
import { ISubscribedPlan } from 'pages/public/schedule/interfaces/plansAndSubscription/ISubscribedPlan'
import FaqPage from 'pages/public/schedule/pages/tour/presentation/pages/faq/FaqPage'
import React, { FC, useCallback, useEffect, useState } from 'react'
import HeaderLayout from './headerLayout/HeaderLayout'
import styles from './Layout.module.scss'
import ImageGramado from 'assets/image/serraGaucha.png'
import FacebookImg from 'assets/icons/facebook.svg'
import InstagramImg from 'assets/icons/instagram.svg'
import YoutubeImg from 'assets/icons/youtube.svg'

export interface ILayoutProps {
    //propertys
    plan?: ISubscribedPlan,
    confirmBack?: boolean,
    onClickBack?: () => void,
    enableDescriptionImage?: boolean,
    banner?: string,
    isTopLayout?: boolean;
    children: React.ReactNode;
}

const Layout: FC<ILayoutProps> = ({ children, plan, confirmBack, onClickBack, enableDescriptionImage, banner, isTopLayout }) => {
    const { logout, isAuth, user, openModalLogin } = useAuth();
    const { isIframe, helpFixed, helpHeader, setHelpFixed, setHelpHeader, openTooltip, setOpenTooltip } = useUi();
    const [openMenu, setopenMenu] = useState(false);

    const [dialogLogoutOpen, setdialogLogoutOpen] = useState(false);
    const [dialogSairOpen, setdialogSairOpen] = useState(false);

    const [openFaq, setOpenFaq] = useState(false)

    const { isMobile } = useUi();

    const onClickLogout = () => {
        logout();
        setdialogLogoutOpen(false);
    }

    useEffect(() => {
        if (!helpFixed) {
            const timer = setTimeout(() => {
                setOpenTooltip(false)
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [helpFixed, setOpenTooltip])


    const onClickConfirmBack = useCallback(() => {
        if (isIframe) {
            window.parent.postMessage({ command: "CLOSE" }, "*")
        } else {
            onClickBack && onClickBack();
        }
    }, [isIframe, onClickBack])

    const onClickBackHandle = useCallback(
        () => {
            if (confirmBack) {
                setdialogSairOpen(true);
            } else {
                onClickConfirmBack();
            }
        },
        [confirmBack, onClickConfirmBack],
    )


    const onClickLogin = useCallback(() => {
        openModalLogin();
    }, [openModalLogin])

    const onClickFaq = useCallback(() => {
        setOpenFaq(prev => !prev);
    }, [])

    const onClickMenu = useCallback(() => {
        setopenMenu(prev => !prev);
    }, [])

    const onClickCloseHelpFixed = useCallback(() => {
        setHelpFixed(false);

    }, [setHelpFixed]);

    useEffect(() => {
        if (!helpFixed) {
            setHelpHeader(true)
        } else {
            setHelpHeader(false)
        }
    }, [helpFixed, setHelpHeader])

    return (
        <>
            <div id={styles.Layout}>
                <div className={styles.top}>
                    <HeaderLayout
                        name={user?.name} description={plan ? plan.planName : ""}
                        onClickBack={onClickBack ? onClickBackHandle : undefined}
                        onClickMenu={onClickMenu}
                        helpHeader={helpHeader}
                        onClickFaq={onClickFaq}
                        openTooltip={openTooltip}
                    />

                    {
                        !isTopLayout ?

                            <div className={styles.imageGramado} ><img src={ImageGramado} alt="Experimente Serra Gaucha" /></div>
                            :
                            ''
                    }

                </div>
                <div className={styles.content}>
                    {children}
                </div>
                <div className={styles.footer}>

                    <div className={styles.footerContainer}>
                        <div className={styles.header}>
                            <h3 className={styles.title}>Endereço - Gramado Receptivo</h3>
                            <p>Rua João Fisch Sobrinho, nº 115 | Centro, Gramado Cep: 95670-000</p>

                        </div>
                        <div className={styles.main}>
                            <h3 className={styles.title}>Siga-nos nas redes sociais</h3>
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/GramadoReceptivo/" target="_blank" rel="noopener noreferrer" title="facebook">
                                        <img src={FacebookImg} className={styles.iconFacebook} alt="" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/c/GramadoReceptivo" target="_blank" rel="noopener noreferrer" title="youtube">
                                        <img src={YoutubeImg} className={styles.iconYoutube} alt="" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/gramadoreceptivo/" target="_blank" rel="noopener noreferrer" title="instagram">
                                        <img src={InstagramImg} className={styles.iconInstagram} alt="" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

                {
                    helpFixed &&
                    <div className={styles.containerHelp}>
                        <div className={styles.cancelBox}>
                            <Cancel onClick={onClickCloseHelpFixed} />
                        </div>
                        <div className={styles.helpBox} onClick={onClickFaq}>
                            <div>
                                <Help />
                                <p>Ajuda</p>
                            </div>
                        </div>
                    </div>
                }

                {
                    isMobile ?
                        <Dialog fullScreen open={openFaq} maxWidth={false} onClose={() => { setOpenFaq(false) }} >
                            <FaqPage onClickFaq={onClickFaq} />
                        </Dialog>
                        :
                        openFaq &&
                        <div className={styles.containerFaqHelpFixed}>
                            <div className={styles.faqHelpFixed}>
                                <FaqPage onClickFaq={onClickFaq} />
                            </div>
                        </div>
                }



            </div>
            <Drawer anchor="right" open={openMenu} style={{ minWidth: 250 }} onClose={() => setopenMenu(false)}>
                <Menu
                    onClickOut={isAuth ? () => setdialogLogoutOpen(true) : undefined}
                    onClickLogin={!isAuth ? () => onClickLogin() : undefined} />
            </Drawer>

            <Dialog open={dialogLogoutOpen} onClose={() => { setdialogLogoutOpen(false) }} >
                <DialogTitle>Sair?</DialogTitle>
                <DialogContent>
                    Você tem certeza que deseja sair?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setdialogLogoutOpen(false) }} variant="outlined" >Cancelar</Button>
                    <Button onClick={onClickLogout} variant="outlined" >Sair</Button>
                </DialogActions>
            </Dialog>
        </>

    )
};
export default Layout