import { ICoupon } from 'models/coupon/ICoupon';
import { useCallback, useEffect, useState } from 'react'
import CouponApi from 'services/Api/coupon/CouponApi'
const UseCoupon = () => {

    const [coupons, setCoupons] = useState<ICoupon[]>();
    const [selectedCoupon, setSelectedCoupon] = useState<ICoupon>();
    const [isLoadingCoupon, setIsLoadingCoupon] = useState(false)

    const activateCoupon = useCallback(async (code: string) => {

        try {
            setIsLoadingCoupon(true)
            const couponSerivce = CouponApi();
            const responseCoupon = await couponSerivce.activate(code);
            // setSelectedCoupon(responseCoupon);
            setCoupons(prev => ([...prev ?? [], responseCoupon]))
        } catch (error) {

        } finally {
            setIsLoadingCoupon(false)
        }

    }, [])

    const getAllCoupons = useCallback(async () => {

        try {
            setIsLoadingCoupon(true)
            const couponSerivce = CouponApi();
            const responseCoupon = await couponSerivce.getAll();
            setCoupons(responseCoupon);
        } catch (error) {

        } finally {
            setIsLoadingCoupon(false)
        }

    }, [])

    useEffect(() => {
        getAllCoupons();
    }, [getAllCoupons])


    return ({
        selectedCoupon,
        isLoadingCoupon,
        activateCoupon,
        getAllCoupons,
        coupons,
        setSelectedCoupon
    })
}

export default UseCoupon