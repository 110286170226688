import { IOrderTracking } from "models/order/aggregates/tracking/IOrderTracking";
import api from "../Api";

export const OrderTrackingApi = () => {

    const getStatusOrders = async (orderId: string) => {
        const response = await api.get<IOrderTracking>(`MeepFood/Order/${orderId}`);
        return response
    };

    return {
        getStatusOrders
    }
}