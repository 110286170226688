import React, { useState } from 'react'
import styles from './PaymentResume.module.scss';
import { Checkbox, Dialog, Drawer, IconButton } from '@material-ui/core';
import Button from 'pages/public/schedule/components/Button/Button';
import UseScheduleTourPayment from './UseScheduleTourPayment';
import { PaymentSelect } from 'pages/payment/PaymentSelect/PaymentSelect';
import { Close, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { IFormScheduleTour } from '../../pages/formTour/interface/IFormScheduleTour';
import { IServiceScheduleForm } from '../../pages/formTour/interface/IServiceScheduleForm';
import { IPlaceScheduleFormSchedule } from '../../pages/formTour/interface/IPlaceScheduleFormSchedule';
import { UsePaymentResume } from './UsePaymentResume';
import { addHours, format } from 'date-fns';

interface IPaymentResume {
    open: boolean;
    onClose: () => void;
    formScheduleTour: IFormScheduleTour;
    service: IServiceScheduleForm;
    placeSchedule: IPlaceScheduleFormSchedule
}

export const PaymentCartResume: React.FC<IPaymentResume> = ({ open, onClose, formScheduleTour, service, placeSchedule }) => {

    const [openCustomers, setOpenCustomers] = useState<boolean>(false)

    const {
        paymentType,
        setpaymentType,
        localTour,
        storeConfig,
        card,
        setCard,
        sendScheduleTour,
        totalValue,
    } = UseScheduleTourPayment(formScheduleTour, service, placeSchedule)

    const { handleChecked, contractTerms, toggleContractTerms, handleModalContractTerms } = UsePaymentResume();

    return (
        <Drawer anchor="bottom" open={open} onClose={onClose}>
            <div id={styles.PaymentResumePage}>
                <div className={styles.headerInfoResumePayment}>
                    <div className={styles.headerTitle}>
                        <IconButton className={styles.resumeDown} onClick={onClose}>
                            <h2>Confira seus dados</h2>
                            <KeyboardArrowDown />
                        </IconButton>
                    </div>
                    <div className={styles.containerInfoResumePayment}>
                        <h2>Confira os dados da sua reserva</h2>
                        <div>
                            <h3>Local do passeio:</h3>
                            <p>{localTour?.name}</p>
                        </div>
                        <div>
                            <h3>Data do passeio:</h3>
                            <p>{formScheduleTour.date && new Date(formScheduleTour.date).toLocaleDateString()}</p>
                            <h4>O horário exato da sua reserva será avisado na véspera até as 18 horas por mensagem de Whatsapp. Em caso de dúvida (54) 99204-6916</h4>
                        </div>
                    </div>
                </div>
                <div className={styles.showCustomersResume}>
                    <p>Reservas para {formScheduleTour?.customers.length} pessoas</p>

                    <IconButton onClick={() => setOpenCustomers((prev) => !prev)}>
                        {openCustomers ? (
                            <KeyboardArrowUp />
                        ) : (
                            <KeyboardArrowDown />
                        )}
                    </IconButton>

                </div>

                <div
                    className={
                        openCustomers ? styles.showCustomers : styles.hideCustomers
                    }
                >
                    <div className={styles.mainInfoResumePayment}>
                        {
                            formScheduleTour && formScheduleTour?.customers.map(item => (
                                <div>
                                    <p>CPF: {item.document !== "" ? item.document : 'Não preenchido'}</p>
                                    <p>Nome: {item.name}</p>
                                    <p>Nascimento: {format(addHours(new Date(item.birthDate), 3), 'dd-MM-yyyy')}</p>
                                    <p>RG: {item.rg !== "" ? item.rg : 'Não preenchido'}</p>
                                    <p>Telefone: {item.phone}</p>
                                    <p>Hotel: {item.note}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className={styles.footerInfoResumePayment}>
                    <div>
                        <p>{formScheduleTour?.customers.length} Reservas</p>
                        <h3>Valor Total: <span className={styles.totalValue}>{new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                        }).format(totalValue)}</span></h3>
                    </div>
                    <div className={styles.contractTerms}>
                        <Checkbox color="primary" required onChange={handleChecked}></Checkbox>
                        <p><u><a onClick={handleModalContractTerms}>Declaro que li o Termo de Cancelamento, e estou ciente que tenho o prazo de 48 horas antes do passeio para cancelar sem cobrança de taxas.</a></u></p>
                    </div>
                    {/* <InstallmentSelect min={2} max={6} onChange={setInstallments} parcelaMinima={100} description={"Selecione o numero de parcelas"} total={totalValue}/> */}
                    <PaymentSelect
                        paymentType={paymentType}
                        setPaymentType={setpaymentType}
                        storeConfig={storeConfig}
                        setCard={setCard}
                        card={card}
                        disabledTitle
                    />
                    <Button disabled={!card || !contractTerms} onClick={() => sendScheduleTour("")}>Realizar pagamento</Button>
                </div>

                <Dialog fullWidth open={toggleContractTerms} onClose={handleModalContractTerms}>
                    <div className={styles.containerModal}>
                        <div className={styles.containerTitle}>
                            <h1>Termos da reserva</h1>
                            <Close onClick={handleModalContractTerms} />
                        </div>
                        <p>
                            <p>LEIA COM ATENÇÃO:</p>
                            <p>- Plantão 08h30 às 24h</p>
                            <p>Operações - (54) 99204-6916</p>
                            <p>- Os dias de realização dos passeios serão agendados de acordo com a programação interna da agência.</p>
                            <p>- Os horários dos passeios serão informados pela agência sempre no dia que "antecede" o passeio e através de mensagens de WhatsApp.</p>
                            <p>- Traslados de chegada pode haver a necessidade de espera de saída do transfer em cerca de 1:20 horas, de acordo com os horários dos voos.</p>
                            <p>- O traslado de saída do hotel é marcado cerca de até 5 horas antes do horário de saída do vôo, de acordo com a programação interna da agência.</p>
                            <p>- Não nos responsabilizamos por objetos deixados nos veículos utilizados nos passeios e transfers.</p>
                            <p>- No caso de desistência dos serviços, serão cobradas taxas administrativas de 50% do valor total. A devolução dos 50% do valor, será de acordo a</p>
                            <p>- forma de pagamento. Caso a desistência ocorra há 48 horas antes da saída do passeio/translado/serviço, não haverá devolução.</p>
                            <p>- Observe o horário do início dos passeios/transfers no seu diário de viagem, no recibo de compras ou pela mensagem recebida via WhatsApp no dia</p>
                            anterior ao serviço. Pois, não haverá tolerância no horário marcado para saída dos passeios/transfers.
                            <p>- Por favor, aguarde na recepção do hotel, conforme o horário programado. Caso contrário, entenderemos que não irá ao passeio/transfer, configurando NO SHOW. Em consideração aos demais clientes, daremos continuidade ao passeio/transfer. Guias e Motoristas não são autorizados a circular pelas dependências dos Hotéis/Pousadas</p>
                            <p>- Os passeios opcionais poderão ser cancelados ou remarcados devido a condições climáticas, formação mínima de pessoas, cancelamento do</p>
                            <p>- fornecedor, entre outros. No caso de cancelamento desta natureza, será restituído ao passageiro o valor pago integral.</p>
                        </p>
                    </div>
                </Dialog>
                {/* <Drawer anchor="bottom" open={securityCodeOpen} onClose={() => setSecurityCodeOpen(false)}>
                    <SecurityCodeInput onSubmit={(cvv) => { sendScheduleTour(cvv); setSecurityCodeOpen(false) }} />
                </Drawer> */}
            </div>
        </Drawer>
    )
}
