import { Icon, IconButton } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useUi } from '../../../context/ui/UIContext'
import { IOrderTracking } from '../../../models/order/aggregates/tracking/IOrderTracking'
import { OrderTrackingApi } from '../../../services/Api/order/OrderTrackingApi'
import { OrderTracking } from './OrderTracking'

export const OrderTrackingPage = () => {


    const { orderId } = useParams<{ orderId: string }>()
    const { showLoading, hideLoading } = useUi();
    const orderTrackingApi = OrderTrackingApi()
    const [orderTracking, setorderTraking] = useState<IOrderTracking>()
    const { isIframe } = useUi()

    const getOrderTracking = useCallback(
        async (orderId: string) => {
            showLoading()
            const response = await orderTrackingApi.getStatusOrders(orderId)
            if (response.data) {
                setorderTraking(response.data)
            }
            hideLoading()
        },
        [],
    )

    useEffect(() => {
        getOrderTracking(orderId)
        return () => {

        }
    }, [orderId])

    const onClickConfirmBack = () => {
        window.parent.postMessage({ command: "CLOSE" }, "*")

    }

    return (
        <>
            {isIframe &&
                <IconButton onClick={onClickConfirmBack}><Icon className="icon">keyboard_backspace</Icon></IconButton>
            }
            {
                orderTracking ?
                    <OrderTracking orderTracking={orderTracking} />
                    : null
            }
        </>)
}
