import { useEffect, useState } from 'react'

export const UseDetectPlatform = () => {
    const [platform, setPlatform] = useState<"windows" | "android" | "ios" | null>(null)

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();

        if (userAgent.includes("iphone")) {
            setPlatform("ios");
        }

        if (userAgent.includes("android")) {
            setPlatform("android")
        }
        if (userAgent.includes("windows")) {
            setPlatform("windows");
        }
        return () => {

        }
    }, [])


    return (
        { platform }
    )
}
