import { validateCPF } from "utils/mask/cpf";
import { ITicketFormValues } from "./TicketForm";
import { IPaymentLinkResponse, PaymentTypeEnum, PaymentTypeForm } from "models/paymentLink/IPaymentLinkResponse";

export interface IPayerErrors {
  PayerFirstName?: string;
  PayerLastName?: string;
  PayerDocumentNumber?: string;
  PayerEmail?: string;
  PayerPhone?: string;
}

const isFieldRequired = (paymentLink: IPaymentLinkResponse, fieldType: PaymentTypeForm): boolean => {
  const ticketPaymentType = paymentLink.paymentTypes.find(
    (paymentType) => paymentType.type === PaymentTypeEnum.TICKET
  );
  return ticketPaymentType?.fields?.some((field) => field.type === fieldType) ?? false;
};

export const validate = (
  paymentLink: IPaymentLinkResponse,
  payer: ITicketFormValues,
  holder: string,
  setErrors: React.Dispatch<React.SetStateAction<IPayerErrors>>
) => {
  setErrors({
    PayerFirstName: "",
    PayerLastName: "",
    PayerDocumentNumber: "",
    PayerEmail: "",
  });

  let validForm = true;

  if (isFieldRequired(paymentLink, PaymentTypeForm.NAME)) {
    if (!payer.PayerFirstName || payer.PayerFirstName.length < 3) {
      setErrors((prev) => ({ ...prev, PayerFirstName: "Nome obrigatório" }));
      validForm = false;
    } else if (holder === "fisica" && (!payer.PayerFirstName.includes(" ") || payer.PayerFirstName.trim().split(" ").length < 2)) {
      setErrors((prev) => ({ ...prev, PayerFirstName: "Nome completo obrigatório (nome e sobrenome)" }));
      validForm = false;
    } else if (holder === "juridica" && !payer.PayerFirstName.includes(" ")) {
      payer.PayerFirstName = `${payer.PayerFirstName}.`;
    }
  }

  if (isFieldRequired(paymentLink, PaymentTypeForm.DOCUMENT)) {
    if (!payer.PayerDocumentNumber || payer.PayerDocumentNumber.length < 14) {
      setErrors((prev) => ({ ...prev, PayerDocumentNumber: "CPF ou CNPJ obrigatório" }));
      validForm = false;
    }
  }

  const validEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (isFieldRequired(paymentLink, PaymentTypeForm.EMAIL)) {
    if (!payer.PayerEmail) {
      setErrors((prev) => ({ ...prev, PayerEmail: "Email obrigatório" }));
      validForm = false;
    } else if (!validEmail.test(payer.PayerEmail)) {
      setErrors((prev) => ({ ...prev, PayerEmail: "Email inválido" }));
      validForm = false;
    }
  }

  return validForm;
};
