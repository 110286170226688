import { Api } from 'services/Api/Api';

export const ProfileApi = () => {
    const api = Api();
    // /api/superApp/User/Profile
    const getProfile = (id: string) => {
        const response = api.get("/superApp/User/Profile/" + id)
        return response
    }
    return { getProfile }
}
