import { useAuth } from 'context/auth/AuthContext'
import { useLocal } from 'context/local/LocalContext'
import { useParamsConfig } from 'context/paramsConfig/ParamsConfigContext'
import { UseMercadoPago } from 'context/payment/UseMercadoPago'
import { useUi } from 'context/ui/UIContext'
import { ICardItem } from 'models/payment/ICard'
import { PaymentType } from 'models/payment/IPay'
import { useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { PostReserveTourScheduleUseCase } from '../../../application/useCases/PostReserveTourScheduleUseCase'
import { ICartTour } from '../../interfaces/ICartTour'
import CartTourToAddDemandWithPaymentDTOUseCase from '../../../application/useCases/CartTourToAddDemandWithPaymentDTOUseCase'
import { UseNavigationTour } from '../../hooks/UseNavigationTour'
import { Parcela } from '../installmentSelect/InstallmentSelect'

const UsePaymentCartTourResume = (cartTour: ICartTour, onSucess: () => void) => {
    // const [localTour, setlocalTour] = useState<IMoreDetails>();
    const [paymentType, setpaymentType] = useState<PaymentType>(0);
    const [card, setCard] = useState<ICardItem | null>(null);
    const [totalValue] = useState(0);

    const [installments, setInstallments] = useState<Parcela>({ label: `A vista por R$ ${cartTour.totalPrice.toFixed(2)}`, parcela: 1, value: cartTour.totalPrice });

    const { url } = useParams<{ url: string }>();


    const { goToTourReserves } = UseNavigationTour()

    // const { serviceId } = useParams<{ serviceId: string; }>();

    const { localId, storeConfig } = useLocal();
    const { user } = useAuth()
    const { queryParams } = useParamsConfig()
    const { toastFullScreen, showLoading, hideLoading } = useUi();
    const { deviceIdMercadoPago } = UseMercadoPago();
    const { push } = useHistory();

    // useEffect(() => {
    //     if (serviceId) {
    //         showLoading();
    //         GetServiceDetailsByServiceIdUseCase(serviceId)
    //             .then((response) => {
    //                 setlocalTour(response);
    //             })
    //             .finally(() => {
    //                 hideLoading();
    //             })
    //     }
    // }, [serviceId])

    const onClickItemEdit = useCallback(
        (cartId: string) => {
            console.clear()
            const cartItem = cartTour.cartItems.find(item => item.id === cartId)

            push(`/${url}/schedule/tour/${cartItem?.storeId}/form/${cartItem?.serviceId}/${cartId}`);
        },
        [cartTour, push, url],
    )


    const sendScheduleTour = useCallback(() => {
        if (localId && card) {
            const request = CartTourToAddDemandWithPaymentDTOUseCase(localId, card.id, deviceIdMercadoPago, user.id, cartTour.cartItems, installments?.parcela ?? 1, queryParams?.operatorId)
            showLoading();
            PostReserveTourScheduleUseCase(request).then(() => {
                toastFullScreen("Reserva Realizada com Sucesso", "check", "success", 3000, () => { goToTourReserves() });
                onSucess();
            }).finally(() => {
                hideLoading()
            })
        }
    }
        , [localId, card, deviceIdMercadoPago, user.id, cartTour.cartItems, installments, queryParams, showLoading, toastFullScreen, onSucess, goToTourReserves, hideLoading])

    return ({
        paymentType,
        setpaymentType,
        // localTour,
        storeConfig: storeConfig ? {
            ...storeConfig,
            allowOnlinePayment: true,
            allowPaymentOnDelivery: false,
            disableAutenticateCard: true,
        } : null,
        card,
        setCard,
        sendScheduleTour,
        totalValue,
        installments,
        setInstallments,
        onClickItemEdit
    })
}

export default UsePaymentCartTourResume