export interface IDDI {
    ddi: string,
    country: string,
    continent: string
}
export const ddis: IDDI[] = [
    { ddi: "55", country: "Brasil", continent: "América do Sul" },
    { ddi: "93", country: "Afeganistão", continent: "Ásia" },
    { ddi: "27", country: "África do Sul", continent: "África" },
    { ddi: "355", country: "Albânia", continent: "Europa" },
    { ddi: "49", country: "Alemanha", continent: "Europa" },
    { ddi: "376", country: "Andorra", continent: "Europa" },
    { ddi: "244", country: "Angola", continent: "África" },
    { ddi: "1", country: "Anguilla", continent: "América Central" },
    { ddi: "1", country: "Antígua e Barbuda", continent: "América Central" },
    { ddi: "599", country: "Antilhas Holandesas", continent: "América Central" },
    { ddi: "966", country: "Arábia Saudita", continent: "Ásia" },
    { ddi: "213", country: "Argélia", continent: "África" },
    { ddi: "54", country: "Argentina", continent: "América do Sul" },
    { ddi: "374", country: "Armênia", continent: "Ásia" },
    { ddi: "297", country: "Aruba", continent: "América Central" },
    { ddi: "247", country: "Ascensão", continent: "África" },
    { ddi: "61", country: "Austrália", continent: "Oceania" },
    { ddi: "43", country: "Áustria", continent: "Europa" },
    { ddi: "994", country: "Azerbaijão", continent: "Ásia" },
    { ddi: "1", country: "Bahamas", continent: "América Central" },
    { ddi: "880", country: "Bangladesh", continent: "Ásia" },
    { ddi: "1", country: "Barbados", continent: "América Central" },
    { ddi: "973", country: "Bahrein", continent: "Ásia" },
    { ddi: "32", country: "Bélgica", continent: "Europa" },
    { ddi: "501", country: "Belize", continent: "América Central" },
    { ddi: "229", country: "Benim", continent: "África" },
    { ddi: "1", country: "Bermudas", continent: "América Central" },
    { ddi: "375", country: "Bielorrússia", continent: "Europa" },
    { ddi: "591", country: "Bolívia", continent: "América do Sul" },
    { ddi: "387", country: "Bósnia e Herzegovina", continent: "Europa" },
    { ddi: "267", country: "Botswana", continent: "África" },
    { ddi: "673", country: "Brunei", continent: "Ásia" },
    { ddi: "359", country: "Bulgária", continent: "Europa" },
    { ddi: "226", country: "Burkina Faso", continent: "África" },
    { ddi: "257", country: "Burundi", continent: "África" },
    { ddi: "975", country: "Butão", continent: "Ásia" },
    { ddi: "238", country: "Cabo Verde", continent: "África" },
    { ddi: "237", country: "Camarões", continent: "África" },
    { ddi: "855", country: "Camboja", continent: "Ásia" },
    { ddi: "1", country: "Canadá", continent: "América do Norte" },
    { ddi: "7", country: "Cazaquistão", continent: "Ásia" },
    { ddi: "237", country: "Chade", continent: "África" },
    { ddi: "56", country: "Chile", continent: "América do Sul" },
    { ddi: "86", country: "República Popular da China", continent: "Ásia" },
    { ddi: "357", country: "Chipre", continent: "Europa" },
    { ddi: "57", country: "Colômbia", continent: "América do Sul" },
    { ddi: "269", country: "Comores", continent: "África" },
    { ddi: "242", country: "Congo-Brazzaville", continent: "África" },
    { ddi: "243", country: "Congo-Kinshasa", continent: "África" },
    { ddi: "850", country: "Coreia do Norte", continent: "Ásia" },
    { ddi: "82", country: "Coreia do Sul", continent: "Ásia" },
    { ddi: "225", country: "Costa do Marfim", continent: "África" },
    { ddi: "506", country: "Costa Rica", continent: "América Central" },
    { ddi: "385", country: "Croácia", continent: "Europa" },
    { ddi: "53", country: "Cuba", continent: "América Central" },
    { ddi: "45", country: "Dinamarca", continent: "Europa" },
    { ddi: "253", country: "Djibuti", continent: "África" },
    { ddi: "1", country: "Dominica", continent: "América Central" },
    { ddi: "20", country: "Egipto", continent: "África/Ásia" },
    { ddi: "503", country: "El Salvador", continent: "América Central" },
    { ddi: "971", country: "Emirados Árabes Unidos", continent: "Ásia" },
    { ddi: "593", country: "Equador", continent: "América do Sul" },
    { ddi: "291", country: "Eritreia", continent: "África" },
    { ddi: "421", country: "Eslováquia", continent: "Europa" },
    { ddi: "386", country: "Eslovénia", continent: "Europa" },
    { ddi: "34", country: "Espanha", continent: "Europa" },
    { ddi: "1", country: "Estados Unidos", continent: "América do Norte" },
    { ddi: "372", country: "Estónia", continent: "Europa" },
    { ddi: "251", country: "Etiópia", continent: "África" },
    { ddi: "679", country: "Fiji", continent: "Oceania" },
    { ddi: "63", country: "Filipinas", continent: "Ásia" },
    { ddi: "358", country: "Finlândia", continent: "Europa" },
    { ddi: "33", country: "França", continent: "Europa" },
    { ddi: "241", country: "Gabão", continent: "África" },
    { ddi: "220", country: "Gâmbia", continent: "África" },
    { ddi: "233", country: "Gana", continent: "África" },
    { ddi: "995", country: "Geórgia", continent: "Ásia" },
    { ddi: "350", country: "Gibraltar", continent: "Europa" },
    { ddi: "1", country: "Granada", continent: "América Central" },
    { ddi: "30", country: "Grécia", continent: "Europa" },
    { ddi: "299", country: "Groenlândia", continent: "América do Norte" },
    { ddi: "590", country: "Guadalupe", continent: "América Central" },
    { ddi: "671", country: "Guam", continent: "Oceania" },
    { ddi: "502", country: "Guatemala", continent: "América Central" },
    { ddi: "592", country: "Guiana", continent: "América do Sul" },
    { ddi: "594", country: "Guiana Francesa", continent: "América do Sul" },
    { ddi: "224", country: "Guiné", continent: "África" },
    { ddi: "245", country: "Guiné-Bissau", continent: "África" },
    { ddi: "240", country: "Guiné Equatorial", continent: "África" },
    { ddi: "509", country: "Haiti", continent: "América Central" },
    { ddi: "504", country: "Honduras", continent: "América Central" },
    { ddi: "852", country: "Hong Kong", continent: "Ásia" },
    { ddi: "36", country: "Hungria", continent: "Europa" },
    { ddi: "967", country: "Iêmen", continent: "Ásia" },
    { ddi: "1", country: "Ilhas Cayman", continent: "América Central" },
    { ddi: "672", country: "Ilha Christmas", continent: "Oceania" },
    { ddi: "672", country: "Ilhas Cocos", continent: "Oceania" },
    { ddi: "682", country: "Ilhas Cook", continent: "Oceania" },
    { ddi: "298", country: "Ilhas Féroe", continent: "Europa" },
    { ddi: "672", country: "Ilha Heard e Ilhas McDonald", continent: "Oceania" },
    { ddi: "960", country: "Maldivas", continent: "Ásia" },
    { ddi: "500", country: "Ilhas Malvinas", continent: "América do Sul" },
    { ddi: "1", country: "Ilhas Marianas do Norte", continent: "Oceania" },
    { ddi: "692", country: "Ilhas Marshall", continent: "Oceania" },
    { ddi: "672", country: "Ilha Norfolk", continent: "Oceania" },
    { ddi: "677", country: "Ilhas Salomão", continent: "Oceania" },
    { ddi: "1", country: "Ilhas Virgens Americanas", continent: "América Central" },
    { ddi: "1", country: "Ilhas Virgens Britânicas", continent: "América Central" },
    { ddi: "91", country: "Índia", continent: "Ásia" },
    { ddi: "62", country: "Indonésia", continent: "Ásia/Oceania" },
    { ddi: "98", country: "Irã", continent: "Ásia" },
    { ddi: "964", country: "Iraque", continent: "Ásia" },
    { ddi: "353", country: "Irlanda", continent: "Europa" },
    { ddi: "354", country: "Islândia", continent: "Europa" },
    { ddi: "972", country: " Israel", continent: "Ásia" },
    { ddi: "39", country: "Itália", continent: "Europa" },
    { ddi: "1", country: "Jamaica", continent: "América Central" },
    { ddi: "81", country: "Japão", continent: "Ásia" },
    { ddi: "962", country: "Jordânia", continent: "Ásia" },
    { ddi: "686", country: "Kiribati", continent: "Oceania" },
    { ddi: "383", country: "Kosovo", continent: "Europa" },
    { ddi: "965", country: "Kuwait", continent: "Ásia" },
    { ddi: "856", country: "Laos", continent: "Ásia" },
    { ddi: "266", country: "Lesoto", continent: "África" },
    { ddi: "371", country: "Letônia", continent: "Europa" },
    { ddi: "961", country: "Líbano", continent: "Ásia" },
    { ddi: "231", country: "Libéria", continent: "África" },
    { ddi: "218", country: "Líbia", continent: "África" },
    { ddi: "423", country: "Liechtenstein", continent: "Europa" },
    { ddi: "370", country: "Lituânia", continent: "Europa" },
    { ddi: "352", country: "Luxemburgo", continent: "Europa" },
    { ddi: "853", country: "Macau", continent: "Ásia" },
    { ddi: "389", country: "República da Macedônia", continent: "Europa" },
    { ddi: "261", country: "Madagascar", continent: "África" },
    { ddi: "60", country: "Malásia", continent: "Ásia" },
    { ddi: "265", country: "Malawi", continent: "África" },
    { ddi: "223", country: "Mali", continent: "África" },
    { ddi: "356", country: "Malta", continent: "Europa" },
    { ddi: "212", country: "Marrocos", continent: "África" },
    { ddi: "596", country: "Martinica", continent: "América Central" },
    { ddi: "230", country: "Maurícia", continent: "África" },
    { ddi: "222", country: "Mauritânia", continent: "África" },
    { ddi: "269", country: "Mayotte", continent: "África" },
    { ddi: "52", country: "México", continent: "América do Norte" },
    { ddi: "691", country: "Estados Federados da Micronésia", continent: "Oceania" },
    { ddi: "258", country: "Moçambique", continent: "África" },
    { ddi: "373", country: "Moldávia", continent: "Europa" },
    { ddi: "377", country: "Mônaco", continent: "Europa" },
    { ddi: "976", country: "Mongólia", continent: "Ásia" },
    { ddi: "382", country: "Montenegro", continent: "Europa" },
    { ddi: "1", country: "Montserrat", continent: "América Central" },
    { ddi: "95", country: "Myanmar", continent: "Ásia" },
    { ddi: "264", country: "Namíbia", continent: "África" },
    { ddi: "674", country: "Nauru", continent: "Oceania" },
    { ddi: "977", country: "Nepal", continent: "Ásia" },
    { ddi: "505", country: "Nicarágua", continent: "América Central" },
    { ddi: "227", country: "Níger", continent: "África" },
    { ddi: "234", country: "Nigéria", continent: "África" },
    { ddi: "683", country: "Niue", continent: "Oceania" },
    { ddi: "47", country: "Noruega", continent: "Europa" },
    { ddi: "687", country: "Nova Caledônia", continent: "Oceania" },
    { ddi: "64", country: "Nova Zelândia", continent: "Oceania" },
    { ddi: "968", country: "Omã", continent: "Ásia" },
    { ddi: "31", country: "Países Baixos", continent: "Europa" },
    { ddi: "680", country: "Palau", continent: "Oceania" },
    { ddi: "970", country: "Palestina", continent: "Ásia" },
    { ddi: "507", country: "Panamá", continent: "América Central" },
    { ddi: "675", country: "Papua-Nova Guiné", continent: "Oceania" },
    { ddi: "92", country: "Paquistão", continent: "Ásia" },
    { ddi: "595", country: "Paraguai", continent: "América do Sul" },
    { ddi: "51", country: "Peru", continent: "América do Sul" },
    { ddi: "689", country: "Polinésia Francesa", continent: "Oceania" },
    { ddi: "48", country: "Polônia", continent: "Europa" },
    { ddi: "1", country: "Porto Rico", continent: "América Central" },
    { ddi: "351", country: "Portugal", continent: "Europa" },
    { ddi: "974", country: "Qatar", continent: "Ásia" },
    { ddi: "254", country: "Quênia", continent: "África" },
    { ddi: "996", country: "Quirguistão", continent: "Ásia" },
    { ddi: "44", country: "Reino Unido", continent: "Europa" },
    { ddi: "236", country: "República Centro-Africana", continent: "África" },
    { ddi: "1", country: "República Dominicana", continent: "América Central" },
    { ddi: "420", country: "República Tcheca", continent: "Europa" },
    { ddi: "262", country: "Reunião", continent: "África" },
    { ddi: "40", country: "Romênia", continent: "Europa" },
    { ddi: "250", country: "Ruanda", continent: "África" },
    { ddi: "7", country: "Rússia", continent: "Europa/Ásia" },
    { ddi: "212", country: "Saara Ocidental", continent: "África" },
    { ddi: "685", country: "Samoa", continent: "Oceania" },
    { ddi: "1", country: "Samoa Americana", continent: "Oceania" },
    { ddi: "290", country: "Santa Helena (território)", continent: "África" },
    { ddi: "1", country: "Santa Lúcia", continent: "América Central" },
    { ddi: "1", country: "São Cristóvão e Nevis", continent: "América Central" },
    { ddi: "378", country: "São Marinho", continent: "Europa" },
    { ddi: "508", country: "Saint-Pierre e Miquelon", continent: "América do Norte" },
    { ddi: "239", country: "São Tomé e Príncipe", continent: "África" },
    { ddi: "1", country: "São Vicente e Granadinas", continent: "América Central" },
    { ddi: "248", country: "Seicheles", continent: "África" },
    { ddi: "221", country: "Senegal", continent: "África" },
    { ddi: "232", country: "Serra Leoa", continent: "África" },
    { ddi: "381", country: "Sérvia", continent: "Europa" },
    { ddi: "65", country: "Singapura", continent: "Ásia" },
    { ddi: "963", country: "Síria", continent: "Ásia" },
    { ddi: "252", country: "Somália", continent: "África" },
    { ddi: "94", country: "Sri Lanka", continent: "Ásia" },
    { ddi: "268", country: "Suazilândia", continent: "África" },
    { ddi: "249", country: "Sudão", continent: "África" },
    { ddi: "211", country: "Sudão do Sul", continent: "África" },
    { ddi: "46", country: "Suécia", continent: "Europa" },
    { ddi: "41", country: "Suíça", continent: "Europa" },
    { ddi: "597", country: "Suriname", continent: "América do Sul" },
    { ddi: "992", country: "Tadjiquistão", continent: "Ásia" },
    { ddi: "66", country: "Tailândia", continent: "Ásia" },
    { ddi: "886", country: "República da China", continent: "Ásia" },
    { ddi: "255", country: "Tanzânia", continent: "África" },
    { ddi: "246", country: "Território Britânico do Oceano Índico", continent: "África" },
    { ddi: "670", country: "Timor-Leste", continent: "Ásia" },
    { ddi: "228", country: "Togo", continent: "África" },
    { ddi: "690", country: "Tokelau", continent: "Oceania" },
    { ddi: "676", country: "Tonga", continent: "Oceania" },
    { ddi: "1", country: "Trinidad e Tobago", continent: "América Central" },
    { ddi: "216", country: "Tunísia", continent: "África" },
    { ddi: "1", country: "Turcas e Caicos", continent: "América Central" },
    { ddi: "993", country: "Turquemenistão", continent: "Ásia" },
    { ddi: "90", country: "Turquia", continent: "Ásia//Europa" },
    { ddi: "688", country: "Tuvalu", continent: "Oceania" },
    { ddi: "380", country: "Ucrânia", continent: "Europa" },
    { ddi: "256", country: "Uganda", continent: "África" },
    { ddi: "598", country: "Uruguai", continent: "América do Sul" },
    { ddi: "998", country: "Uzbequistão", continent: "Ásia" },
    { ddi: "678", country: "Vanuatu", continent: "Oceania" },
    { ddi: "379", country: "Vaticano", continent: "Europa" },
    { ddi: "58", country: "Venezuela", continent: "América do Sul" },
    { ddi: "84", country: "Vietnã", continent: "Ásia" },
    { ddi: "681", country: "Wallis e Futuna", continent: "Oceania" },
    { ddi: "260", country: "Zâmbia", continent: "África" },
    { ddi: "263", country: "Zimbábue", continent: "África" }]