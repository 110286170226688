import { ICardPaymentGuestRequest } from "models/paymentGuest/IPaymentGuestForm";

export const validate = (
  card: ICardPaymentGuestRequest,
  setErrors: React.Dispatch<
    React.SetStateAction<{
      number: string;
      securityCode: string;
      ownerName: string;
      month: string;
      year: string;
      owner: {
        name: string;
        document: string;
        email: string;
        phoneNumber: string;
        address: string;
      };
    }>
  >
) => {
  setErrors({
    number: "",
    securityCode: "",
    ownerName: "",
    month: "",
    year: "",
    owner: {
      name: "",
      document: "",
      email: "",
      phoneNumber: "",
      address: "",
    },
  });
  let validForm = true
  if (!card.number || card.number.length < 14) {
    setErrors((prev) => ({ ...prev, number: "Número inválido" }));
    validForm = false;
  }
  if (!card.month) {
    setErrors((prev) => ({ ...prev, month: "Mês inválido" }));
    validForm = false;
  }
  if (!card.year || !card.year.length) {
    setErrors((prev) => ({ ...prev, year: "Ano inválido" }));
    validForm = false;
  }
  if (!card.ownerName || card.ownerName.length < 3) {
    setErrors((prev) => ({ ...prev, ownerName: "Nome inválido" }));
    validForm = false;
  }
  if (!card.securityCode || card.securityCode.length < 3) {
    setErrors((prev) => ({ ...prev, securityCode: "Código inválido" }));
    validForm = false;
  }
  if (!card.owner.name || card.owner.name.length < 3) {
    setErrors((prev) => ({
      ...prev,
      owner: { ...prev.owner, name: "Nome inválido" },
    }));
    validForm = false;
  }
  if (!card.owner.document || card.owner.document.length < 11) {
    setErrors((prev) => ({
      ...prev,
      owner: { ...prev.owner, document: "Documento inválido" },
    }));
    validForm = false;
  }
  if (!card.owner.birthDay || card.owner.birthDay.length < 10) {
    setErrors((prev) => ({
      ...prev,
      owner: { ...prev.owner, birthDay: "Data de nascimento obrigatório" },
    }));
    validForm = false;
  }
  if (!card.owner.email || card.owner.email.length < 11) {
    setErrors((prev) => ({
      ...prev,
      owner: { ...prev.owner, email: "Email inválido" },
    }));
    validForm = false;
  }
  if (!card.owner.phoneNumber || card.owner.phoneNumber.length < 11) {
    setErrors((prev) => ({
      ...prev,
      owner: { ...prev.owner, phoneNumber: "Telefone inválido" },
    }));
    validForm = false;
  }

  if (
    !card.owner?.address ||
    !card.owner.address.street ||
    !card.owner.address.number ||
    !card.owner.address.city ||
    !card.owner.address.state
  ) {
    setErrors((prev) => ({
      ...prev,
      owner: { ...prev.owner, address: "Endereço inválido" },
    }));
    validForm = false;
  }

  return validForm;
};
