import { IAddress } from "../../models/address/IAddress";
import { IDetails } from "./interfaces/IDetailMaps";


// id: string;
// CEP?: string;
// description: string;
// street: string;
// number: string;
// complement: string;
// neighborhood: string;
// city: string;
// state: string;
// default: boolean;

export const ConvertDetailToAddress = (details: google.maps.GeocoderResult): IAddress => {


    let returnAddress: IAddress = {} as IAddress;

    details.address_components.map((address_components) => {


        if (address_components.types.includes("street_number")) {
            //numero
            returnAddress = { ...returnAddress, number: address_components.short_name }
        }
        if (address_components.types.includes("route")) {
            //logradouro
            returnAddress = { ...returnAddress, street: address_components.short_name }
        }
        if (address_components.types.includes("sublocality_level_1")) {
            //bairro
            returnAddress = { ...returnAddress, neighborhood: address_components.short_name }
        }
        if (address_components.types.includes("postal_code")) {
            //cep
            returnAddress = { ...returnAddress, CEP: address_components.short_name }
        }
        if (address_components.types.includes("administrative_area_level_1")) {
            //state
            returnAddress = { ...returnAddress, state: address_components.short_name }
        }
        if (address_components.types.includes("administrative_area_level_2")) {
            //cidade
            returnAddress = { ...returnAddress, city: address_components.short_name }
        }
    })

    return returnAddress
}
export const _ConvertDetailToAddress = (details: IDetails): IAddress => {


    let returnAddress: IAddress = {} as IAddress;

    details.address_components.map((address_components) => {


        if (address_components.types.includes("street_number")) {
            //numero
            returnAddress = { ...returnAddress, number: address_components.short_name }
        }
        if (address_components.types.includes("route")) {
            //logradouro
            returnAddress = { ...returnAddress, street: address_components.short_name }
        }
        if (address_components.types.includes("sublocality_level_1")) {
            //bairro
            returnAddress = { ...returnAddress, neighborhood: address_components.short_name }
        }
        if (address_components.types.includes("postal_code")) {
            //cep
            returnAddress = { ...returnAddress, CEP: address_components.short_name }
        }
        if (address_components.types.includes("administrative_area_level_1")) {
            //state
            returnAddress = { ...returnAddress, state: address_components.short_name }
        }
        if (address_components.types.includes("administrative_area_level_2")) {
            //cidade
            returnAddress = { ...returnAddress, city: address_components.short_name }
        }
    })

    return returnAddress
}