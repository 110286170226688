import React from "react";
import { Routes } from "./routes/Routes";
import { UIProvider } from "./context/ui/UIContext";
import { BrowserRouter } from "react-router-dom";
import { ParamsProvider } from "./context/paramsConfig/ParamsConfigContext";
import { ThemeProvider } from "context/theme/ThemeContext";
import "./styles/globals.scss";

declare global {
  interface Window {
    fbq: (...args: any[]) => void;
  }
}

function App() {
  return (
    <ThemeProvider>
      <UIProvider>
        <BrowserRouter>
          <ParamsProvider>
            <Routes />
          </ParamsProvider>
        </BrowserRouter>
      </UIProvider>
    </ThemeProvider>
  );
}

export default App;
