import React, { ReactNode, useCallback, useState } from 'react'
import { Drawer, Icon, IconButton } from '@material-ui/core'

import styles from './Cart.module.scss'
import { useCart } from './CartContext'
import { ICartItem } from './ICartItem'
import { ICart } from './ICart'

export interface ITourCartProps<T extends ICartItem> {
    onClickConfirm(cart: ICart<T>): void,
    renderDescription?(cartItem: T): ReactNode
    confirmLabel?: string
}

const Cart = <T extends ICartItem,>({ onClickConfirm, renderDescription }: ITourCartProps<T>) => {

    const { cartTourItems, totals, removeCart: removeTourCart, clearCart: clearTourCart } = useCart<T>()

    const [openCart, setOpenCart] = useState(false);

    const sendTourCartToPayment = useCallback(() => {
        onClickConfirm({ cartItems: cartTourItems, totalPrice: totals.price })
    }, [cartTourItems, onClickConfirm, totals])

    return (
        <div id={styles.cart}>
            <div
                onClick={() => setOpenCart(true)}
                className={!!totals.quantity ? styles.barVisible : styles.barHide}
            // className={styles.barVisible}
            >
                <div className={styles.quantity}>
                    {totals.quantity} Reservas
                </div>
                <div className={styles.title}>
                    Carrinho
                </div>
                <div className={styles.price}>
                    R${totals.price.toFixed(2)}
                </div>
            </div>
            <Drawer  anchor="bottom" open={openCart && !!totals.quantity} onClose={() => setOpenCart(false)}>
                <div className={styles.drawerContainer}>
                    <div className={styles.header}>
                        <IconButton onClick={() => setOpenCart(false)}><Icon >keyboard_arrow_down</Icon></IconButton> <h2>Carrinho</h2> <div onClick={clearTourCart}>Limpar</div>
                    </div>
                    <div className={styles.content}>
                        {cartTourItems.map((cartItem) => (
                            <div className={styles.cartTourItem}>
                                <div className={styles.removeButton}>
                                    <IconButton onClick={() => removeTourCart(cartItem.id)}><Icon >close</Icon></IconButton>
                                </div>
                                <div className={styles.image}>
                                    <img src={cartItem.imageUrl} alt={"Imagem do" + cartItem.name} />
                                </div>
                                <div className={styles.info}>
                                    <div className={styles.tourName}>
                                        {cartItem.name}
                                    </div>
                                    <div>
                                        {renderDescription && renderDescription(cartItem)}
                                    </div>
                                    {/* <div className={styles.dateTime}>
                                        {cartItem.date} - {cartItem.reserveTour.time}
                                    </div>
                                    <div className={styles.persons}>
                                        {cartItem.reserveTour.customers.length} pessoas
                                    </div>
                                    <div className={styles.price}>
                                        R${cartItem.reserveTour.totalPrice.toFixed(2)}
                                    </div> */}
                                </div>
                            </div>
                        )
                        )}
                    </div>
                    <div className={styles.footer}>
                        <div className={styles.totals}>
                            <div>SubTotal:</div><div>R${totals.price.toFixed(2)}</div>
                        </div>
                        <div className={styles.buttons} onClick={sendTourCartToPayment}>
                            <div className={styles.sendButton} >Realizar Pagamento</div>
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

export default Cart
