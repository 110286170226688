import { ScheduleTourApi } from 'services/Api/schedule/ScheduleTourApi';
import { IServiceScheduleForm } from '../../presentation/pages/formTour/interface/IServiceScheduleForm';

export const GetServiceScheduleByIdUseCase = async (serviceId: string, localId: string): Promise<IServiceScheduleForm> => {
    const scheduleTourApi = ScheduleTourApi();
    const response = await scheduleTourApi.getServiceCategoryByLocalId(localId);
    const service = response.items.flatMap(item => item.services).find(item => item.id === serviceId)
    if (service) {

        return {
            id: service.id,
            name: service.name,
            duration: service.duration,
            imageUrl: service.imageUrl,
            prices: service.prices,
            description: service.description,
            productId: service.productId,
            productName: service.productName,
        }
    } else {
        throw (Error("not found service"))
    }
}
