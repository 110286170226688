import { Drawer } from '@material-ui/core';
import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { IStoreConfig } from '../../models/local/ILocalConfig';
import { IMeepCardData } from '../../models/meepCard/ILoginMeepCard'
import { IOrderDetail } from '../../models/order/aggregates/datail/IOrderDetail';
import { PaymentType } from '../../models/payment/IPay';
import { IConfirmarPagamento } from '../../models/payment/IPaymentApi';
import { MeepCardApi } from '../../services/Api/meepCard/MeepCardApi';
import { OrderApi } from '../../services/Api/order/OrderApi';
import { PaymentApi } from '../../services/Api/payment/PaymentApi';
import { useParamsConfig } from '../paramsConfig/ParamsConfigContext';
import { useUi } from '../ui/UIContext';
import { ConfirmPasswordInput } from './confirmPasswordInput/ConfirmPasswordInput';

interface IMeepCardPaymentContext {
    meepCardData: IMeepCardData | undefined,
    setMeepCardData: React.Dispatch<React.SetStateAction<IMeepCardData | undefined>>,
    login: (apelido: string, senha: string) => void,
    orderDetail: IOrderDetail | null,
    storeConfig: IStoreConfig | null,
    setCustomDeliveryDescription: (text: string) => void,
    customDeliveryDescription: string,
    onClickEnviarPagamento: () => void
}

const MeepCardPaymentContext = createContext<IMeepCardPaymentContext>({} as IMeepCardPaymentContext)

export const MeepCardPaymentProvider: FC<{ children: React.ReactNode }> = ({ children }) => {

    const [meepCardData, setMeepCardData] = useState<IMeepCardData>();
    const { showLoading, hideLoading, toastFullScreen, toast } = useUi();


    const { queryParams } = useParamsConfig();
    //dados do pedido e da configuração do pagamento
    const [orderDetail, setOrderDetail] = useState<IOrderDetail | null>(null);
    const [storeConfig, setStoreConfig] = useState<IStoreConfig | null>(null);
    const [customDeliveryDescription, setCustomDeliveryDescription] = useState("");

    const [confirmPasswordOpen, setConfirmPasswordOpen] = useState(false)

    const history = useHistory();

    const [inPayment, setInPayment] = useState(false);

    const getOrderAndConfig = useCallback(
        async (orderId: string) => {
            showLoading()
            try {
                const orderApi = OrderApi();
                const response = await orderApi.getOrderAndConfigs(orderId);
                if (response) {
                    setStoreConfig(response.data.store);
                    setOrderDetail(response.data.order);
                }
            } catch (error: any) {
                if (error?.response?.data?.message) {
                    toastFullScreen(error?.response?.data?.message, "error", "error", 3000, () => { history.replace("/") });
                } else {
                    toastFullScreen("Pedido Não Encontrado", "error", "error", 3000, () => { history.replace("/") });
                }
            } finally {
                hideLoading()
            }
        },
        [hideLoading, history, showLoading, toastFullScreen],
    )


    const login = useCallback(async (apelido: string, senha: string) => {
        if (queryParams?.localId) {
            try {
                showLoading();
                const meepCard = MeepCardApi()
                const response = await meepCard.login(apelido, senha, queryParams.localId);
                // toast("login realizado com sucesso", "success");
                setMeepCardData(response.data)
            } catch (error:any) {
                if (error?.response?.data?.message) {
                    toast(error?.response?.data?.message, "error");
                } else {
                    toast("Não foi possivel efetuar", "error");
                }
            } finally {
                hideLoading();
            }
        }


    }, [hideLoading, showLoading, toast, queryParams]);

    const enviarMensagemDeConfirmacao = useCallback((orderId: string) => {
        let mensagem = `Pedido realizado com sucesso`
        let actionButton = `Concluir`
        if (queryParams?.queryTypeDelivery === "3") {
            // mensagem = `Pedido realizado com sucesso\n ${queryParams?.queryDescription ?? ""} ${queryParams?.queryPlace ?? ""}\n Sera um gerado um QrCode para se apresentado referente ao seu pedido.`
            mensagem = `PARABÉNS PELA SUA COMPRA!\n\n\nÉ só fazer o seguinte:\n\n\n1-Aguarde seu pedido ficar pronto.\n\n2-${queryParams?.queryDescription ?? ""} ${queryParams?.queryPlace ?? ""}\n\n3-No aplicativo entre no menu pedidos e clique no ícone do QRCode.\n\n4-Um QRCode será gerado. É só apresentar no ponto de venda!\n\n`
            actionButton = "Entendi"
        }
        toastFullScreen(
            mensagem,
            "check",
            "success",
            15000,
            () => {
                window.open("/tracking/" + orderId);
                // window.open("/tracking/" + orderId);
                // history.replace(`/payment/confirm-payment/${storeConfig?.storeId}`);
                window.parent.postMessage({ command: "SUCESS_PAYMENT" }, "*")
            },
            actionButton
        );
    }, [queryParams, toastFullScreen])



    const enviarPagamentoPedido = useCallback(async (paymentRequest: IConfirmarPagamento) => {
        try {
            showLoading();
            setInPayment(true)

            const paymentApi = PaymentApi();
            const response = await paymentApi.confirmarPagamentoPedidoMeepCard(paymentRequest);
            if (response) {
                enviarMensagemDeConfirmacao(response.data.pedidoId);
            }
        } catch (error:any) {
            if (error?.response?.data?.message) {
                toast(error?.response?.data?.message, "error")
            }
        } finally {
            hideLoading();
            setInPayment(false)
        }
    }, [enviarMensagemDeConfirmacao, hideLoading, showLoading, toast])

    const validacao = useCallback(
        () => {
            if (customDeliveryDescription) {
                return true
            } else {
                toast("Preencha todos os campos", "warning")
                return false
            }
        },
        [customDeliveryDescription, toast],
    )


    const onClickEnviarPagamento = useCallback(() => {
        if (validacao()) {
            setConfirmPasswordOpen(true)
        }
    }, [validacao])
    const onPasswordConfirmado = useCallback((password: string) => {

        if (meepCardData && orderDetail && queryParams?.localId) {
            const paymentRequest: IConfirmarPagamento = {
                localClienteId: queryParams.localId,
                enderecoEntregaId: "",
                customDeliveryDescription: customDeliveryDescription,
                tipoPagamentoId: PaymentType.CREDITOLOJA,
                resumoPedido: {
                    pedidoId: queryParams?.orderId,
                    valorPedido: orderDetail?.amount,
                    taxaEntrega: 0
                },
                dadosCartaoNominal: {
                    historicoAssociacaoId: meepCardData.associationId,
                    Senha: password,
                }
            }
            enviarPagamentoPedido(paymentRequest)
        }


    }, [customDeliveryDescription, enviarPagamentoPedido, meepCardData, orderDetail, queryParams])




    useEffect(() => {
        if (meepCardData && queryParams?.orderId) {
            getOrderAndConfig(queryParams?.orderId);
        }
        return () => {
        }
    }, [getOrderAndConfig, meepCardData, queryParams])

    useEffect(() => {
        if (orderDetail && storeConfig) {
        }

    }, [orderDetail, storeConfig])

    return (
        <MeepCardPaymentContext.Provider value={{
            meepCardData,
            setMeepCardData,
            login,
            orderDetail,
            storeConfig,
            setCustomDeliveryDescription,
            customDeliveryDescription,
            onClickEnviarPagamento
        }}>
            {children}
            <Drawer anchor="bottom" open={confirmPasswordOpen} onClose={() => setConfirmPasswordOpen(false)}>
                <ConfirmPasswordInput onSubmit={onPasswordConfirmado} loading={inPayment} ></ConfirmPasswordInput>
            </Drawer>
        </MeepCardPaymentContext.Provider>
    )
}

export const MeepCardPaymentConsumer = MeepCardPaymentContext.Consumer

export const useMeepCardPayment = () => {
    const context = useContext(MeepCardPaymentContext)
    return context
}
