import React, { FC, useCallback, useEffect, useState } from "react";
import styles from "./HomePage.module.scss";
import { PrivateHeader } from "pages/private/Components/privateHeader/PrivateHeader";
import useCatalogContext from "context/catalog/UseCatalogContext";
import ProductsPage from "../products/ProductsPage";
import Home from "./components/home/Home";
import StoresPage from "../stores/StoresPage";

export interface IHomePage {}

const HomePage: FC<IHomePage> = ({}) => {
  const { catalog, onLoading } = useCatalogContext();
  const [showHomePage, setShowHomePage] = useState(false);
  const [showCatalog, setShowCatalog] = useState(false);

  useEffect(() => {
    if (catalog) {
      if (catalog.showCustomHome) {
        setShowHomePage(true);
        setShowCatalog(false);
      } else {
        setShowHomePage(false);
        setShowCatalog(true);
      }
    }
  }, [catalog]);

  const goToProducts = useCallback(() => {
    setShowHomePage(false);
    setShowCatalog(true);
  }, []);

  return (
    <div id={styles.HomePage}>
      <PrivateHeader title={catalog?.name} />
      <div className={styles.container}>
        {showHomePage && catalog && <Home goToCatalog={goToProducts} catalog={catalog} />}
        {!onLoading && showCatalog && <StoresPage />}
      </div>
    </div>
  );
};

export default HomePage;
