export const cpfMask = (cpf: string) => {
  return cpf
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
};

export const hideCPF = (cpf: string): string => {
  const cleanCPF = cpf.replace(/\D/g, '');

  if (cleanCPF.length !== 11) {
    return '';
  }

  return `***.***.*${cleanCPF.slice(7, 9)}-${cleanCPF.slice(9, 11)}`;
}

export const validateCPF = (strCPF: string) => {
  var Soma;
  var Resto;
  Soma = 0;

  if (strCPF.length !== 11) return false;
  if (strCPF === "00000000000") return false;
  if (strCPF === "11111111111") return false;
  if (strCPF === "22222222222") return false;
  if (strCPF === "33333333333") return false;
  if (strCPF === "44444444444") return false;
  if (strCPF === "55555555555") return false;
  if (strCPF === "66666666666") return false;
  if (strCPF === "77777777777") return false;
  if (strCPF === "88888888888") return false;
  if (strCPF === "99999999999") return false;

  for (let i = 1; i <= 9; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
};