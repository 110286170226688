import React, { createContext, FC, useContext, useEffect } from 'react';
import { IProductCompositionContext as ICompositionContext } from '../interfaces/IProductCompositionContext';
import { ICompositionsProviderProps } from '../interfaces/ICompositionsProviderProps';
import useCaseCompositions from './UseCompositionContext';

const CompositionContext = createContext<ICompositionContext>({} as ICompositionContext);

export const CompositionsProvider: FC<ICompositionsProviderProps> = ({ children, onChangeOrderCompositions, productCompositions, disabled }) => {

    const {
        orderCompositions,
        totals,
        addCompositionItem,
        removerCompositionItem,
        getTotalsCompositionItem,
        unValidatedCompositions,
        getTotalsComposition
    } = useCaseCompositions(productCompositions);

    useEffect(() => {
        if (onChangeOrderCompositions) {
            onChangeOrderCompositions(orderCompositions, totals, unValidatedCompositions);
        }
    }, [onChangeOrderCompositions, orderCompositions, totals, unValidatedCompositions])

    return (
        <CompositionContext.Provider value={
            {
                orderCompositions,
                totals,
                addCompositionItem,
                removerCompositionItem,
                getTotalsCompositionItem,
                getTotalsComposition,
                unValidatedCompositions, 
                disabled
            }
        }>
            <>{children}</>
        </CompositionContext.Provider>
    );
};
export const ProductCompositionsConsumer = CompositionContext.Consumer

export const useComposition = () => {
    const context = useContext(CompositionContext);
    return context;
};