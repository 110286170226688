import React, { createContext, FC, useContext, useState, useEffect, useCallback } from 'react'
import LocalApi from '../../services/Api/local/LocalApi'
import { IStoreConfig } from '../../models/local/ILocalConfig'
import { useRouteMatch, useParams } from 'react-router-dom'
import { useParamsConfig } from '../paramsConfig/ParamsConfigContext'
import { useUi } from '../ui/UIContext'
import { CatalogApi } from 'services/Api/catalog/CatalogApi'

interface ILocalContext {
    localId?: string,
    localLoading: boolean,
    storeConfig: IStoreConfig | null
}

const LocalContext = createContext<ILocalContext>({} as ILocalContext)



export const LocalProvider: FC<{ children: React.ReactNode }> = ({ children }) => {

    const [storeConfig, setStoreConfig] = useState<IStoreConfig | null>(null);
    const { queryParams } = useParamsConfig();
    const [localLoading, setlocalLoading] = useState(true);
    const { showLoading, hideLoading, toast } = useUi();
    const { url } = useParams<{ url: string }>()
    const [localId, setlocalId] = useState<string>();
    const { path } = useRouteMatch()

    const getLocalConfig = useCallback(
        (localId: string) => {

            setlocalId(localId)
            setlocalLoading(true);
            showLoading();
            const localApi = LocalApi();
            localApi.getConfig(localId).then(responseLocalConfig => {
                setStoreConfig(responseLocalConfig);
                setlocalLoading(false);
            }).catch((error: any) => {
                toast("localInvalido", "error")
                console.error(Error);
            }).finally(() => {
                hideLoading();
            })

        }, [hideLoading, path, showLoading, toast])

    useEffect(() => {
        if (!url && queryParams) {
            if (queryParams?.localId) {
                getLocalConfig(queryParams?.localId)
            } else {
                toast("Informe um local", "error")
            }
        }
    }, [getLocalConfig, queryParams, toast, url])


    useEffect(() => {
        if (url) {
            showLoading();
            const catalogApi = CatalogApi();
            catalogApi.getCatalogByUrl(url).then((response) => {
                setlocalId(response.data.id);
                getLocalConfig(response.data.id);
            }).finally(() =>
                hideLoading()
            )
        }
    }, [hideLoading, showLoading, url])

    return (
        <LocalContext.Provider value={{ localId: localId, localLoading, storeConfig }}>
            {children}
        </LocalContext.Provider>
    )
}

export const LocalConsumer = LocalContext.Consumer;

export const useLocal = () => {
    return useContext(LocalContext)
}
