import React, { ChangeEvent, useState } from 'react'
import styles from './PaymentCartTourResume.module.scss';
import { Checkbox, Dialog, Drawer, IconButton } from '@material-ui/core';
import Button from 'pages/public/schedule/components/Button/Button';
import UseScheduleTourPayment from './UsePaymentCartTourResume';
import { PaymentSelect } from 'pages/payment/PaymentSelect/PaymentSelect';
import { Close, Edit, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { addHours, format } from 'date-fns';
import { ICartTour } from '../../interfaces/ICartTour';
import InstallmentSelect from '../installmentSelect/InstallmentSelect';

interface PaymentCartTourResumeProps {
    open: boolean;
    onClose: () => void;
    cartTour: ICartTour;
    onSucess: () => void;
}

export const PaymentCartTourResume: React.FC<PaymentCartTourResumeProps> = ({ open, onClose, cartTour, onSucess }) => {
    const [openCustomers, setOpenCustomers] = useState<boolean>(false)
    const {
        paymentType,
        setpaymentType,
        storeConfig,
        card,
        setCard,
        sendScheduleTour,
        installments,
        setInstallments,
        onClickItemEdit
    } = UseScheduleTourPayment(cartTour, onSucess);



    const [contractTerms, setContractTerms] = useState(false)
    const [toggleContractTerms, setToggleContractTerms] = useState(false)

    const handleModalContractTerms = () => {
        setToggleContractTerms(!toggleContractTerms)
    }

    const handleChecked = (event: ChangeEvent<HTMLInputElement>) => {
        setContractTerms(event.target.checked);
    }


    return (
        <Drawer anchor="bottom" open={open} onClose={onClose}>
            <div id={styles.PaymentResumePage}>
                <div className={styles.resumeDown}>
                    <h2>Confira seus dados</h2>
                    <IconButton onClick={onClose}>
                        <KeyboardArrowDown />
                    </IconButton>
                </div>
                <div className={styles.infoHour}>
                    <p>O horário exato da sua reserva será avisado na véspera até as 18 horas por mensagem de Whatsapp. </p>

                    <p>Em caso de dúvida: <b>(54) 99204-6916</b></p>
                </div>
                {cartTour.cartItems.map((cartItem) => <div className={styles.cartItemResume}>
                    <div className={styles.headerInfoResumePayment}>
                        <div className={styles.containerInfoResumePayment}>
                            <div>
                                <h3>Local do passeio:</h3>
                                <p>{cartItem.name}</p>
                            </div>
                            <div>
                                <h3>Data do passeio:</h3>
                                <p>{cartItem.startDate && new Date(cartItem.startDate).toLocaleDateString()}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.showCustomersResume}>
                        <h3>Participantes:</h3>
                        <div>
                            <p><u>Reservas para {cartItem.customers.length} pessoas</u></p>

                            <IconButton onClick={() => setOpenCustomers((prev) => !prev)}>
                                {openCustomers ? (
                                    <KeyboardArrowUp />
                                ) : (
                                    <KeyboardArrowDown />
                                )}
                            </IconButton>
                        </div>

                    </div>

                    <div
                        className={
                            openCustomers ? styles.showCustomers : styles.hideCustomers
                        }
                    >
                        <div className={styles.mainInfoResumePayment}>
                            {
                                cartItem?.customers.map(item => (
                                    <div>
                                        <div className={styles.editBox}>
                                            <p>Nome: {item.name}</p>
                                            <Edit onClick={() => onClickItemEdit(cartItem.id)} />
                                        </div>
                                        <p>CPF: {item.document !== "" ? item.document : 'Não preenchido'}</p>
                                        <p>Nascimento: {format(addHours(new Date(item.birthDate), 3), 'dd-MM-yyyy')}</p>
                                        <p>RG: {item.rg !== "" ? item.rg : 'Não preenchido'}</p>
                                        <p>Telefone: {item.phone}</p>
                                        <p>Hotel: {item.note}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>)
                }


                <div className={styles.footerInfoResumePayment}>
                    <div className={styles.total}>
                        {/* <p>{formScheduleTour?.customers.length} Reservas</p> */}
                        <h3>Valor Total: <span className={styles.totalValue}>{new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                        }).format(cartTour.totalPrice)}</span></h3>
                    </div>

                    <PaymentSelect
                        paymentType={paymentType}
                        setPaymentType={setpaymentType}
                        storeConfig={storeConfig}
                        setCard={setCard}
                        card={card}
                        disabledTitle
                    />
                    <InstallmentSelect
                        min={2} max={6}
                        value={installments}
                        variant={"outlined"}
                        onChange={(value) => setInstallments(value)}
                        parcelaMinima={100}
                        description={"Selecione o numero de parcelas"}
                        total={cartTour.totalPrice}
                    />

                    <div className={styles.contractTerms}>
                        <Checkbox color="primary" required onChange={handleChecked}></Checkbox>
                        <p><u><div onClick={handleModalContractTerms}>Declaro que li o Termo de Cancelamento, e estou ciente que tenho o prazo de 48 horas antes do passeio para cancelar sem cobrança de taxas.</div></u></p>
                    </div>
                    <Button disabled={!card || !contractTerms} onClick={() => sendScheduleTour()}>Realizar pagamento</Button>
                </div>
            </div>
            <Dialog fullWidth open={toggleContractTerms} onClose={handleModalContractTerms}>
                <div className={styles.containerModal}>
                    <div className={styles.containerTitle}>
                        <h1>Termos da reserva</h1>
                        <Close onClick={handleModalContractTerms} />
                    </div>
                    <p>
                        <p>LEIA COM ATENÇÃO:</p>
                        <p>- Plantão 08h30 às 24h</p>
                        <p>Operações - (54) 99204-6916</p>
                        <p>- Os dias de realização dos passeios serão agendados de acordo com a programação interna da agência.</p>
                        <p>- Os horários dos passeios serão informados pela agência sempre no dia que "antecede" o passeio e através de mensagens de WhatsApp.</p>
                        <p>- Traslados de chegada pode haver a necessidade de espera de saída do transfer em cerca de 1:20 horas, de acordo com os horários dos voos.</p>
                        <p>- O traslado de saída do hotel é marcado cerca de até 5 horas antes do horário de saída do vôo, de acordo com a programação interna da agência.</p>
                        <p>- Não nos responsabilizamos por objetos deixados nos veículos utilizados nos passeios e transfers.</p>
                        <p>- No caso de desistência dos serviços, serão cobradas taxas administrativas de 50% do valor total. A devolução dos 50% do valor, será de acordo a</p>
                        <p>- forma de pagamento. Caso a desistência ocorra há 48 horas antes da saída do passeio/translado/serviço, não haverá devolução.</p>
                        <p>- Observe o horário do início dos passeios/transfers no seu diário de viagem, no recibo de compras ou pela mensagem recebida via WhatsApp no dia</p>
                        anterior ao serviço. Pois, não haverá tolerância no horário marcado para saída dos passeios/transfers.
                        <p>- Por favor, aguarde na recepção do hotel, conforme o horário programado. Caso contrário, entenderemos que não irá ao passeio/transfer, configurando NO SHOW. Em consideração aos demais clientes, daremos continuidade ao passeio/transfer. Guias e Motoristas não são autorizados a circular pelas dependências dos Hotéis/Pousadas</p>
                        <p>- Os passeios opcionais poderão ser cancelados ou remarcados devido a condições climáticas, formação mínima de pessoas, cancelamento do</p>
                        <p>- fornecedor, entre outros. No caso de cancelamento desta natureza, será restituído ao passageiro o valor pago integral.</p>
                    </p>
                </div>
            </Dialog>

        </Drawer>
    )
}
