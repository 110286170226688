import { useUi } from 'context/ui/UIContext'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ProfileApi } from 'services/Api/social/profile/ProfileApi'
import { IProfile } from './interfaces/IProfile'
// const mock={
//     name: "Thiago José",
//     photoUrl: "https://scontent.fplu3-1.fna.fbcdn.net/v/t1.6435-9/30531539_1650813321640868_1665317893362941952_n.jpg?_nc_cat=111&ccb=1-3&_nc_sid=09cbfe&_nc_eui2=AeEUPwFk0Rk2ir4VP8OqLk6gPeMQUNygcRA94xBQ3KBxECNUx-6p_uFAPrDIU07Hekuhp1krHi24ImmXzS4fr3tw&_nc_ohc=aSUU20apvnYAX8IO4ww&_nc_oc=AQlJ3nunhsPKxX_S3E_J0v_6SxoHTjbOKFx9s8t2ZAIMIaas4XVROckLx3WaGzH6M8sD3dplXDtRGbUGQbp0Ay1j&_nc_ht=scontent.fplu3-1.fna&oh=de6d232ec2f99b3237e1f832d2bdf2cd&oe=60E15156",
//     age: 29,
//     from: "Belo horizonte-MG",
//     social: {
//         whatsapp: "5531994770583"
//     }
// }
export interface IUseProfilePageProps {
    //propertys
}
const UseProfilePage = () => {

    const { profileId } = useParams<{ profileId: string }>();

    const [profile, setProfile] = useState<IProfile | null>(null);

    const { showLoading, hideLoading, toast } = useUi();

    useEffect(() => {
        const profileApi = ProfileApi();
        if (profileId) {
            showLoading();
            profileApi.getProfile(profileId).then((response) => {
                setProfile(response.data);
            }).catch((error) => {
                if (error?.response?.data?.message) {
                    //toast(error?.response?.data?.message, "error");
                } else {
                    toast("Perfil não encontrado", "error");
                }
                setProfile(null);
            }).finally(() => {
                hideLoading()
            })
        }
    }, [profileId])

    return (
        { profile }
    )
}
export default UseProfilePage