import { useUi } from 'context/ui/UIContext'
import React, { FC, useEffect, useState } from 'react'
import ScheduledApi from 'services/Api/schedule/ScheduledApi'
import { IAppointments } from '../../interfaces/IScheduled'
import styles from './ScheduledPage.module.scss'
import ScheduledItem from './_scheduledItem/ScheduledItem'
export interface IScheduledPageProps {
    //propertys
}
const ScheduledPage: FC<IScheduledPageProps> = () => {
    const [appointments, setAppointments] = useState<IAppointments[]>()
    const { showLoading, hideLoading } = useUi()

    useEffect(() => {
        const { getDemandedSchedulesByUserService } = ScheduledApi();
        showLoading()
        getDemandedSchedulesByUserService().then((response) => {
            setAppointments(response.data.items)
        }).finally(() => {
            hideLoading()
        })
        return () => {
        }
    }, [])

    return (
        <div id={styles.ScheduledPage} >
            <h2>Minhas reservas</h2>
            <div className={styles.container} >
                <div className={styles.list} >
                    {appointments?.map((appointment) => (
                        <div>
                            <h2>{appointment.store.name}</h2>
                            <ScheduledItem appointment={appointment}></ScheduledItem>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default ScheduledPage
