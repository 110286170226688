import { Catalog } from "../../../models/catalog/aggregates/Catalog";
import { Api } from "../Api";

export const CatalogApi = () => {
  const api = Api();
  // const criarPrePedidoMeep = async (resumoPedido: ResumoPedido, localClienteId: string) => {
  //   const response = await api.post<{ pedidoId: string }>("/vendas/criarPrePedido", {
  //     resumoPedido,
  //     localClienteId,
  //   });
  //   return response;
  // };

  // async function ObterProdutosMeep(localClienteId: string) {
  //   const response = await api.get<Catalog>("/meepFoodWeb/productcatalog/" + localClienteId);

  //   return response;
  // }

  async function getCatalogByUrl(identificador: string) {
    const response = await api.get<Catalog>("/meepFoodWeb/productCatalogByUrl/" + identificador + "/");

    return response;
  }

  async function GetCatalogByListId(identificador: string, listId: string) {
    const response = await api.get<Catalog>("/meepFoodWeb/productCatalogByList/" + identificador + "/" + listId);

    return response;
  }

  return {
    getCatalogByUrl,
    GetCatalogByListId,
  };
};
