import { IUser } from 'models/user/IUser'
import React, { FC } from 'react'
import styles from './AuthPage.module.scss'
import useAuthPage from './AuthPageUseCase'
import { EtapasAuth } from './EtapasAuth'
import { FormCpf } from './login/formCpf/FormCpf'
import { Login } from './login/formLoginEmail/Login'
import { FormLoginPhoneNumber } from './login/formLoginPhoneNumber/FormLoginPhoneNumber'
import { FormLoginValitationCode } from './login/formLoginValidation/FormLoginValidationCode'
import { FormNewUser } from './login/formNewUser/FormNewUser'
import { LoginPage } from './login/LoginPage'

export interface IAuthPageProps {
    //propertys
    onLogin?: (authValues?: { token?: string, user?: IUser }) => void,
    onPressCancel?: () => void
    enableDddi?: boolean
}


const AuthPage: FC<IAuthPageProps> = ({ onLogin, onPressCancel, enableDddi }) => {

    const {
        loginWithUsername,
        validateSmsCode,
        requestValidationCode,
        registerNewUser,
        requestEmailByCpf,
        user,
        smsValidationId,
        etapa,
        setEtapa
    } = useAuthPage(onLogin);

    return (
        <div id={styles.AuthPage}>
            <div className={styles.container}>
                <LoginPage>

                    {
                        etapa === EtapasAuth.Cpf &&
                        <FormCpf onSubmit={requestEmailByCpf} />
                    }
                    {
                        etapa === EtapasAuth.Username &&
                        <Login emailSugestion={user.email} onSubmit={loginWithUsername} />
                    }
                    {
                        etapa === EtapasAuth.NewUser &&
                        <FormNewUser onSubmit={registerNewUser} smsValidationId={smsValidationId} defaultValues={user} />
                    }
                    {
                        etapa === EtapasAuth.PhoneNumber &&
                        <FormLoginPhoneNumber enableDdi={enableDddi} onPressCancel={onPressCancel} onSubmit={requestValidationCode} />
                    }
                    {
                        etapa === EtapasAuth.SMSCode &&
                        <FormLoginValitationCode onSubmit={validateSmsCode} onClickBack={() => setEtapa(EtapasAuth.PhoneNumber)} defaultValues={user} onResend={requestValidationCode}/>
                    }
                </LoginPage>
            </div>
        </div>
    )
}
export default AuthPage

