import React, { FC, useState } from 'react'
import styles from './ProductItem.module.scss'
import Image from '../../../../../../../components/ui/_image/Image'
import { IProductItem } from '../interfaces/IProductItem'
import { ChevronRight } from '@material-ui/icons';
import { Icon } from '@material-ui/core';

export interface IProductItemProps {
    product: IProductItem,
    onClick: () => void,
    prefixPrice?: string
}

const ProductItem: FC<IProductItemProps> = ({ product, onClick }) => {
    const [hasImageError, setHasImageError] = useState(false);

    return (
        <div onClick={onClick} id={styles.ProductItem}>
            <div className={styles.container}>
                <div className={styles.image}>
                    {
                        !hasImageError ?
                        <Image src={product.thumbnailUrl} className={styles.img} onError={() => setHasImageError(true)} /> :
                        <div className={styles.imageFallback}>
                            <Icon className={styles.icon}>no_photography</Icon>
                        </div>
                    }
                </div>

                <div className={styles.content}>
                    <div className={styles.containerCard}>
                        <div className={styles.name}>
                            <h3>{product.name}</h3>
                        </div>
                        <div className={styles.description}>
                            <h3>{product.description}</h3>
                        </div>
                        <div className={styles.mobilePrice}>
                            <span>R$</span>
                            <h3>
                                {product.price.toFixed(2).replace(".", ",")}
                            </h3>
                        </div>
                    </div>
                </div>

                <div className={styles.price}>
                    <span>R$</span>
                    <h3>
                        {product.price.toFixed(2).replace(".", ",")}
                    </h3>
                </div>

                <ChevronRight style={{ color: '#A2A2A2', fontSize: 22 }} />
            </div>
        </div>
    )
}
export default ProductItem