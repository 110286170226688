import { IconButton } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import React, { useState } from 'react'
import { IQuestion } from '../IFaq';
import styles from './FaqCard.module.scss'

interface IFaqCardProps {
    value: IQuestion;
}

export const FaqCard: React.FC<IFaqCardProps> = ({ value }) => {

    const [openAnswers, setOpenAnswers] = useState(false);


    return (
        <div id={styles.FaqCard}>
            <div className={styles.containerCategory}>
                {
                    // value.map(item => (
                    <>

                        {/* <p>{item.title}</p> */}
                        <p>
                            <div className={styles.containerQuestion}>
                                <div className={styles.containerDescription} onClick={() => setOpenAnswers((prev) => !prev)}>
                                    <p className={styles.description}>{value.description}</p>
                                    <IconButton>
                                        {!openAnswers ? (
                                            <KeyboardArrowDown />
                                        ) : (
                                            <KeyboardArrowUp />
                                        )}
                                    </IconButton>
                                </div>
                                <div
                                // className={
                                //     openAnswers ? styles.showCard : styles.hideCard
                                // }
                                >

                                    {openAnswers && (
                                        <p className={styles.answers}>{value.answers}</p>
                                    )}
                                </div>
                            </div>
                        </p>
                    </>
                    // ))
                }
            </div>
        </div >
    )
}
