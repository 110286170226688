import React, { FC } from 'react'
import './Header.css'

interface IHearder {
    start?: JSX.Element
    center?: JSX.Element
    end?: JSX.Element
    title?: string;
    children?: React.ReactNode;
}

export const Header: FC<IHearder> = ({ children, start, center, end, title }) => {
    return (
        <div id="header">
            {children ??
                <div className={"container"} >
                    <div className={"start"} >
                        {start}
                    </div>
                    <div className={"center"} >
                        {center ??
                            <div className={"center"}>{title}</div>
                        }
                    </div>
                    <div className={"end"} >
                        {end}
                    </div>
                </div>
            }
        </div>
    )
}
