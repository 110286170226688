import { Drawer } from '@material-ui/core'
import React, { FC, useCallback, useEffect, useState } from 'react'
import './PickerDate.scss'
import Calendar, { CalendarTileProperties } from 'react-calendar';
import { format, getDay } from 'date-fns'
import { AvailableDay } from './interfaces/IAvailableDays';
import ButtonPickerAcordion from '../buttonPickerAcordion/ButtonPickerAcordion';
import { DateInfo } from './interfaces/PickerDate.type';
// import 'react-calendar/dist/Calendar.css'

export interface IPickerDateProps {
    //propertys
    disabledClick?: boolean
    everOpen?: boolean,
    availableDays?: AvailableDay[];
    value: Date | null;
    onChange: (Date: Date) => void;
    dateInfo?: DateInfo[]
}
const PickerDate: FC<IPickerDateProps> = ({
    disabledClick,
    value,
    onChange,
    dateInfo
}) => {
    const [openList, setopenList] = useState(false)


    const closeListHandle = () => {
        setopenList(false)
    }

    const openListHandle = useCallback(() => {
        !disabledClick && setopenList(prev => !prev)
    }, [disabledClick])

    const onClickItem = (Date: Date) => {
        onChange(Date);
        closeListHandle();
    }

    // const disabledDayOfWeek = useCallback(
    //     ((availableDays: AvailableDay[], date: Date) => {
    //         return !availableDays.find((availableDay) => availableDay.dayOfWeek === getDay(date))
    //     }),
    //     [],
    // )

    useEffect(() => {
        if (!disabledClick) {
            setopenList(true)
        }
        return () => {

        }
    }, [disabledClick])



    const tileClassName = useCallback(
        ({ activeStartDate, date, view }: CalendarTileProperties) => {
            if (view === "month") {
                if (dateInfo?.find(item => item.day === format(date, "dd/MM/yyyy"))) {
                    return "availabeDay"
                } else {
                    return "busyDay"
                }
            } else {
                return "availabeDay"
            }
        },[dateInfo]
    )
    const isDisabledTile = useCallback(
        ({ activeStartDate, date, view }: CalendarTileProperties): boolean => {
            if (view === "month") {
                if (dateInfo?.find(item => item.day === format(date, "dd/MM/yyyy"))) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        },
        [dateInfo],
    )


    return (
        <>
            <div id={"PickerDate"} >
                <div className={"botao"} >
                    <ButtonPickerAcordion
                        icon="event"
                        open={openList}
                        onClick={openListHandle}
                        label={value ? "Data" : "Selecionar Data"}
                        value={value?.toLocaleDateString('pt-BR')}
                        selected={!!value}
                    >


                        <Calendar
                            minDate={new Date()}
                            locale="pt-BR"
                            value={value}
                            className={"react-calendar"}
                            onChange={(date: Date | Date[]) => onClickItem(date as Date)}
                            tileClassName={tileClassName}
                            tileDisabled={isDisabledTile}
                            // tileDisabled={({ activeStartDate, date, view }) => !!availableDays ? disabledDayOfWeek(availableDays, date) : false}
                        />




                    </ButtonPickerAcordion>
                </div>

            </div>
            {
                false && <div style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}>

                    <Drawer anchor={"bottom"} open={!disabledClick && openList} onClose={closeListHandle}>
                        <div id={"drawerDate"}>
                            <h2>Selecione uma Data</h2>
                            <div className={"calendar"}>
                                {
                                    <Calendar
                                        minDate={new Date()}
                                        locale="pt-BR"
                                        className={"react-calendar"}
                                        onChange={(date: Date | Date[]) => onClickItem(date as Date)}
                                        // tileDisabled={({ activeStartDate, date, view }) => !!availableDays ? disabledDayOfWeek(availableDays, date) : false}
                                    // tileContent={
                                    //     ({ view, activeStartDate, date }) => <div><Icon>close</Icon></div>
                                    // }
                                    // tileClassName={
                                    //     ({ view, activeStartDate, date }) => "busyDay"
                                    // }

                                    />
                                }
                            </div>
                        </div>
                    </Drawer>

                </div>
            }
        </>
    )
}
export default PickerDate