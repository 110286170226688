import { Store } from 'models/catalog/aggregates/Store'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useCatalog } from '../../../../context/catalog/CatalogContext'
import Header from './components/Header/Header'
import styles from './ProductsPage.module.scss'
import { PrivateHeader } from 'pages/private/Components/privateHeader/PrivateHeader'
import { IProductItem } from './components/productList/interfaces/IProductItem'
import ProductListCategory from './components/productListCategory/ProductListCategory'
import { useParamsConfig } from 'context/paramsConfig/ParamsConfigContext'
import { SearchOutlined } from '@material-ui/icons'
import { SeccionedProduct } from 'models/catalog/aggregates/SeccionedProduct'
import { Modal } from '@material-ui/core'
import ProductDetail from '../productDetail/ProductDetail'
import CartBar from 'components/cart/cartBar/CartBar'
import FacebookPixel from 'components/tracking/facebookPixel/FacebookPixel'
import StoreApi from "../../../../services/Api/local/LocalApi";

const ProductsPage = () => {
    const { catalog, onLoading } = useCatalog();
    const { storeId, url, productId } = useParams<{ storeId: string, url: string, productId?: string }>()
    const { setParamsLocalId } = useParamsConfig()
    const { replace } = useHistory();
    const [store, setStore] = useState<Store>();
    const [filtered, setFiltered] = useState<SeccionedProduct[]>([]);
    const [filterText, setFilterText] = useState('');

    useEffect(() => {
        if (!filterText.trim()) {
            setFiltered(store?.seccionedProduct || []);
        } else {
            const normalizedFilterText = filterText
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase();

            setFiltered(store?.seccionedProduct.map(x => ({
                ...x,
                products: x.products.filter(y => {
                    const normalizedProductName = y.name
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .toLowerCase();

                    return normalizedProductName.toLowerCase().includes(normalizedFilterText)
                })
            })) || [])
        }
    }, [filterText, store]);

    useEffect(() => {
        if (catalog && storeId) {
            const _local = catalog.stores.find(_store => _store.id === storeId)
            setStore(_local);
            setParamsLocalId(_local?.id);

        }
    }, [catalog, setParamsLocalId, storeId]);

    useEffect(() => {
        if (storeId && !window.fbq) {
            getFacebookPixel(storeId);
        }
    }, [storeId]);

    const onClickBack = useCallback(() => {
        replace(`/${url}`)
    }, [url, replace]);

    const onClickProductItemHandle = useCallback((product: IProductItem) => {
        if (window.fbq) {
            window.fbq("trackCustom", "ProductClicked", {
                content_ids: product.id,
                content_name: product.name,
                value: product.price,
                content_category: product.category || ''
            });
        }
        replace(`/${url}/${storeId}/product/${product.id}`)
    }, [replace, url, storeId]);

    const getFacebookPixel = async (storeId: string) => {
        const pixelId = await StoreApi().getFacebookPixelId(storeId);
        if (pixelId) {
            FacebookPixel.init(pixelId);
        }
    }

    return (
        <div id={styles.ProductsPage} >
            <PrivateHeader title={store?.name} onClickBack={catalog && catalog.stores.length > 1 ? onClickBack : undefined}></PrivateHeader>
            {
                onLoading ?
                    <div>Carregando</div>
                    :
                    (
                        store &&
                        <div className={styles.container} >
                            <div className={styles.headerContainer}>
                                <Header
                                    defaultBackgroundImage={catalog?.logoUrl}
                                    backgroundImage={store?.imageUrl ? store?.imageUrl : (catalog?.logoUrl ?? "")}
                                    store={store}
                                />
                            </div>

                            <div className={styles.filterContainer}>
                                <div className={styles.filter}>
                                    <input placeholder='Buscar produtos' value={filterText} onChange={(e) => setFilterText(e.target.value)} />
                                    <SearchOutlined className={styles.icon} />
                                </div>
                            </div>

                            <div className={styles.productsContainer}>
                                <div className={styles.content}>
                                    <ProductListCategory
                                        seccionedProduct={filtered.filter(item => !!item.products.length)}
                                        title={store.name}
                                        onClickBack={onClickBack}
                                        onClickProduct={onClickProductItemHandle}
                                    />
                                </div>
                            </div>
                        </div>
                    )
            }

            <CartBar />

            <Modal open={!!productId} className={styles.modalContainer} onClose={onClickBack}>
                <>
                    {
                        !!productId && <ProductDetail productId={productId} />
                    }
                </>
            </Modal>
        </div >
    )
}
export default ProductsPage