import { useLocal } from 'context/local/LocalContext'
import { useUi } from 'context/ui/UIContext';
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { GetStoreToursByLocaldUseCase } from '../../application/useCases/GetStoreToursByLocaldUseCase';
import { IStoreTour } from '../../_domain/_models/IStoreTour';
import bannerAboutProject from '../../../../../../../assets/image/projeto.png'


const UseTourPage = () => {
    const [storesTour, setStoresTour] = useState<IStoreTour[]>()
    const { localId } = useLocal();
    const { url } = useParams<{ url: string }>();
    const { showLoading, hideLoading } = useUi();
    const { push } = useHistory();


    const onClickBackHandle = useCallback(() => {
        push(`/${url}/schedule/tour/`)
    }, [])

    const aboutProject: IStoreTour = {
        id: v4(),
        name: 'Conheça o projeto',
        imageUrl: bannerAboutProject,
        logoUrl: '',
        productTourCategory: [],
    }


    useEffect(() => {
        if (localId) {
            showLoading();
            GetStoreToursByLocaldUseCase(localId)
                .then((response) => {
                    if (response.items.length === 1) {
                        // replace(`/${url}/schedule/tour/${response.items[0].id}`);
                    }
                    setStoresTour(response.items);
                })
                .finally(() => {
                    hideLoading();
                })
        }

        return () => {

        }

    }, [localId, url])


    const onClickStoreTourHandle = useCallback(
        (storeId: string) => {
            push(`/${url}/schedule/tour/${storeId}`)
        },
        [url],
    )
    const onClickAboutProject = useCallback(
        () => {
            push(`/${url}/schedule/tour/aboutProject`)
        },
        [url],
    )



    return ({ storesTour, onClickStoreTourHandle, onClickBackHandle, aboutProject, onClickAboutProject })
}

export default UseTourPage