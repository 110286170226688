import api from "../Api"
import { IAddress } from "../../../models/address/IAddress"

const AddressApi = () => {
    const getUserAdresses = async () => {
        const response = await api.get<IAddress[]>("/superapp/address/GetUserAddresses")
        return response;
    }

    const setDefaultAddress = async (id: string) => {
        return await api.post<IAddress>('/superapp/Address/SetMainAddress', { id });
    }

    const saveAddress = async (address: IAddress) => {
        return await api.post<IAddress>('/superapp/Address/Save', address);
    }

    const deleteAddress = async (addressId: string) => {
        return await api.post<void>('/superapp/address/Delete/' + addressId, {});
    }

    return {
        getUserAdresses,
        setDefaultAddress,
        saveAddress,
        deleteAddress
    }
}




///api/superapp/Address/Save'

export default AddressApi();