import React, { FC, FormEvent, useCallback, useRef, useState } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { useUi } from "../../../../../context/ui/UIContext";
import { ddis, IDDI } from "./ddis";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styles from './FormLoginPhoneNumber.module.scss';

interface ILoginPostObject {
  ddd: string;
  ddi?: string;
  phoneNumber: string;
  codeVerification: string;
  isSecondAttempt: boolean;
}

interface FormLoginPhoneNumberProps {
  onSubmit: (values: ILoginPostObject) => void
  onClickBack?: () => void,
  smsCode?: boolean,
  onPressCancel?: () => void
  enableDdi?: boolean
}

export const FormLoginPhoneNumber: FC<FormLoginPhoneNumberProps> = ({
  onSubmit,
  smsCode,
  onClickBack,
  onPressCancel,
  enableDdi
}) => {

  const { toast } = useUi();
  const { isIframe } = useUi();

  var [disabledDDD, setDisabledDDD] = useState(false)


  const refDdd = useRef<HTMLInputElement>(null);
  const refTel = useRef<HTMLInputElement>(null);

  const [values, setValues] = useState<ILoginPostObject>({
    ddd: "",
    ddi: "55",
    phoneNumber: "",
    codeVerification: "",
    isSecondAttempt: false,
  });

  const [selectedDDI, setSelectedDDI] = useState<IDDI | undefined>({ ddi: "55", country: "Brasil", continent: "América do Sul" })

  const onSubmitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();

      if (values.ddi === "55") {
        if (values.phoneNumber.length !== 9 || values.ddd.length !== 2) {
          toast("Insira um numero valido", "warning");
          return;
        }
      } else {
        if (values.phoneNumber.length < 6 || values.phoneNumber.length > 15) {
          toast("Insira um numero valido.", "warning");
          return;
        }
      }

      if (!smsCode) {
        onSubmit(values);
      } else {
        if (values.codeVerification.length !== 6) {
          toast("Insira um codigo valido", "warning");
          return;
        }
        onSubmit({ ...values });
      }
    },
    [values, smsCode, toast, onSubmit]
  );

  const numberMask = useCallback((value: string) => {
    let newValue = value.replace(/[^0-9]/g, "");
    if (values.ddi === "55") {
      newValue.substring(0, 9);
    }
    return newValue;
  }, [values.ddi]);

  const dddMask = useCallback((value: string) => {
    var max = 2
    // var max = 2
    const newValue = value.replace(/[^0-9]/g, "").substring(0, max);
    if (newValue.length === max) {
      if (refTel) {
        refTel.current?.focus();
      }
    }
    return newValue;
  }, []);

  const changeDDDHandle = useCallback((value: string) => {
    setValues((prev) => ({ ...prev, ddd: value }));
  }, []);

  const changeDDIHandle = useCallback((value?: IDDI | null) => {
    setSelectedDDI(value ?? undefined);
    setValues(prev => ({ ...prev, ddi: value?.ddi ?? undefined, ddd: "" }))
    if (value?.ddi === "55") {
      setDisabledDDD(false)
      refDdd.current?.focus();

    } else {
      setDisabledDDD(true)
      refTel.current?.focus();
    }

  }, []);

  const changePhoneNumberHandle = useCallback((value: string) => {
    setValues((prev) => ({ ...prev, phoneNumber: value }));
  }, []);
  const changecodeVerificationHandle = useCallback((value: string) => {
    setValues((prev) => ({ ...prev, codeVerification: value }));
  }, []);

  return (
    <form onSubmit={onSubmitHandle}>
      <Grid spacing={2} container>
        {!smsCode && (
          <>
            <Grid item xs={12}>
              <div style={{ fontSize: 24, color: 'var(--text)', fontWeight: 700, textAlign: 'left' }}>
                Insira o número do seu <span style={{ color: 'var(--primary)' }}>telefone</span>
              </div>
            </Grid>
            {enableDdi &&
              <Grid item xs={4} style={{ textAlign: 'left'}}>
                <label htmlFor="ddi" className={`${styles.label} ${styles.required}`}>DDI</label>
                <Autocomplete
                  fullWidth
                  disabled={smsCode}
                  // // helperText={touched.ddd && errors.ddd}
                  // error={touched.ddd && Boolean(errors.ddd)}
                  onChange={(e, value) => changeDDIHandle(value)}
                  value={selectedDDI}
                  // renderOption={(option) => option.country + " (+" + option.ddi + ")"}
                  getOptionLabel={(option) => option.country + " (+" + option.ddi + ")"}
                  renderInput={(inputParams) => <TextField variant="outlined" {...inputParams} />}
                  options={ddis}
                  size="small"
                />
              </Grid>
            }
            {!disabledDDD &&
              <Grid item xs={enableDdi ? 2 : 3} style={{ textAlign: 'left'}}>
                <label htmlFor="ddd" className={`${styles.label} ${styles.required}`}>DDD</label>
                <TextField
                  // variant="outlined"
                  inputRef={refDdd}
                  fullWidth
                  disabled={smsCode}
                  // // helperText={touched.ddd && errors.ddd}
                  // error={touched.ddd && Boolean(errors.ddd)}
                  onChange={(e) => changeDDDHandle(dddMask(e.target.value))}
                  value={values.ddd}
                  name="ddd"
                  type="phone"
                  placeholder="XXX"
                  inputProps={{
                    // maxLength: 2,
                    type: "tel",
                  }}
                  variant="outlined"
                  size="small"
                />
              </Grid>
            }
            <Grid item xs={disabledDDD ? 8 : enableDdi ? 6 : 9} style={{ textAlign: 'left'}}>
              <label htmlFor="phoneNumber" className={`${styles.label} ${styles.required}`}>Telefone</label>
              <TextField
                // variant="outlined"
                disabled={smsCode}
                fullWidth
                // helperText={(touched.phoneNumber && errors.phoneNumber) || (touched.ddd && errors.ddd)}
                // error={(touched.phoneNumber && Boolean(errors.phoneNumber)) || (touched.ddd && Boolean(errors.ddd))}
                onChange={(e) => changePhoneNumberHandle(numberMask(e.target.value))}
                value={values.phoneNumber}
                name="phoneNumber"
                type="tel"
                inputRef={refTel}
                size="small"
                variant="outlined"
                placeholder="XXXXX-XXXX"
              />
            </Grid>
          </>
        )}
        {smsCode && (
          <>
            <Grid item xs={12}>
              <div style={{ fontSize: 20, color: 'var(--text)', fontWeight: 700, textAlign: 'left' }}>
                Digite o código que foi enviado para seu telefone
              </div>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'left' }}>
              <label htmlFor="codeVerification" className={`${styles.label} ${styles.required}`}>Código</label>
              <TextField
                // variant="outlined"
                fullWidth
                // helperText={(touched.codeVerification && errors.codeVerification) || (touched.ddd && errors.ddd)}
                // error={(touched.codeVerification && Boolean(errors.codeVerification)) || (touched.ddd && Boolean(errors.ddd))}
                onChange={(e) => changecodeVerificationHandle(e.target.value)}
                value={values.codeVerification}
                name="codeVerification"
                type="number"
                variant="outlined"
                placeholder="XXXXXX"
                size="small"
              />
            </Grid>
          </>
        )}

        {(isIframe || onPressCancel) && (
          <Grid item xs={4}>
            <Button
              onClick={() => {
                onPressCancel ? onPressCancel() : window.parent.postMessage({ command: "CLOSE" }, "*");
              }}
              fullWidth
              size="large"
              variant="contained"
              style={{ height: 49, boxShadow: 'none', borderRadius: 8 }}
            >
              {"VOLTAR"}
            </Button>
          </Grid>
        )}
        <Grid item xs={(isIframe || onPressCancel) ? 8 : 12}>
          <Button fullWidth size="large" variant="contained" color="primary" type="submit" style={{ height: 49, boxShadow: 'none', borderRadius: 8 }}>
            {smsCode ? "Confirmar" : "Enviar código"}
          </Button>
        </Grid>
        {smsCode && (
          <Grid item xs={12}>
            <Button
              onClick={
                onClickBack
              }
              size="small"
              variant="text"
              color="primary"
              type="submit"
            >
              Voltar
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  );
};
