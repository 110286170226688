import { Button, ButtonBase, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon, IconButton, Modal, TextField } from '@material-ui/core'
import { useUi } from 'context/ui/UIContext'
import { ICoupon } from 'models/coupon/ICoupon'
import { PaymentType } from 'models/payment/IPay'
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react'

import CalcCoupon from './CalcCoupon'
import styles from './CouponInput.module.scss'
import { format } from 'date-fns'

export interface ICouponInputProps {
    //propertys
    selectedCoupon?: ICoupon
    onSelectCoupon?: (coupon: ICoupon | undefined) => void
    onSubmit: (code: string) => void
    coupons?: ICoupon[]
    isLoading?: boolean;
    total: number;
    paymentType: PaymentType;
    onlinePayment: boolean;
}

const CouponInput: FC<ICouponInputProps> = ({ onSubmit, isLoading, selectedCoupon, coupons, onSelectCoupon, total, paymentType, onlinePayment }) => {

    const [values, setValues] = useState({ code: "" });
    const [openCoupons, setOpenCoupons] = useState(false);
    const { toast } = useUi();
    const [rules, setRules] = useState<string[]>()

    const changeHandle = useCallback((value: string) => {
        setValues((prev) => ({ ...prev, code: value }));
    }, []);

    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        onSubmit(values.code)
    }, [onSubmit, values.code])

    useEffect(() => {

        if (selectedCoupon && (paymentType !== PaymentType.CREDITO || !onlinePayment)) {
            onSelectCoupon?.(undefined);
            toast("Cupom disponivel apenas para pagamento no aplicativo", "warning")
        }
    }, [onSelectCoupon, onlinePayment, paymentType, selectedCoupon, toast])

    return (
        <div id={styles.CouponInput} >
            <h4 className={styles.coupon}>Cupom</h4>
            {
                selectedCoupon ?
                    <ButtonBase className={styles.couponButton} >
                        <div onClick={() => setOpenCoupons(true)} className={styles.item}>
                            <div className={styles.code}>{selectedCoupon.code}</div>
                            <div className={styles.name}>{selectedCoupon.name}</div>
                            <div className={styles.description}>{selectedCoupon.description}</div>
                        </div>
                        <IconButton onClick={() => onSelectCoupon?.(undefined)} ><Icon>close</Icon></IconButton>
                    </ButtonBase>
                    :
                    <>
                        <ButtonBase onClick={() => setOpenCoupons(true)} className={styles.couponButton} >
                            <Icon>add</Icon>Adicionar Cupom
                        </ButtonBase>

                    </>
            }
            <Modal open={openCoupons} onClose={() => setOpenCoupons(false)} className={styles.modalContainer}>
                <div className={styles.drawerContainer}>
                    {
                        isLoading && <div className={styles.loadingContainer}>
                            <CircularProgress size={14} />
                        </div>
                    }
                    <h4>Cupom</h4>
                    <form onSubmit={submitHandle}>
                        <TextField
                            label="CODIGO"
                            name='code'
                            variant='outlined'
                            onChange={(ev) => changeHandle(ev.target.value)}
                            disabled={isLoading}
                            size='small'
                            fullWidth
                        />
                        <Button
                            color='primary'
                            variant='contained'
                            disabled={isLoading}
                            type='submit'
                        >
                            <Icon>check</Icon>
                        </Button>
                    </form>
                    {coupons?.map((couponItem) => (
                        <ButtonBase
                            disabled={!CalcCoupon(total, couponItem).ableTo}
                            style={{ opacity: CalcCoupon(total, couponItem).ableTo ? 1 : 0.2 }}
                            className={styles.couponButton}
                        >
                            <div
                                className={styles.item}
                                onClick={() => { onSelectCoupon?.(couponItem); setOpenCoupons?.(false) }}
                            >
                                <div className={styles.code}>{couponItem.code}</div>
                                <div className={styles.name}>{couponItem.name}</div>
                                <div className={styles.description}>{couponItem.description}</div>
                                <div className={styles.finishAt}>{couponItem.finishAt ? format(new Date(couponItem.finishAt), 'dd/MM/aaaa HH:mm') : ''}</div>
                                <div style={{ color: 'red' }}> {CalcCoupon(total, couponItem).ableTo}</div>
                            </div>
                            <div onClick={() => setRules(couponItem.rules)}>Ver regras</div>
                        </ButtonBase>
                    ))
                    }
                </div>
            </Modal>
            <Dialog open={!!rules} onClose={() => setRules(undefined)}>
                <DialogTitle>
                    Termos
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {rules?.map((item) => <div>{item}</div>)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions><Button onClick={() => setRules(undefined)}>Concordar</Button></DialogActions>
            </Dialog>

        </div >)
}
export default CouponInput