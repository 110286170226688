import { useEffect, useState } from 'react'

declare global {
    interface Window {
        deviceId: string;
        onGetFingerPrint: (value: any) => void
    }
}

export const UseMercadoPago = () => {
    const [deviceIdMercadoPago, setDeviceIdMercadoPago] = useState("")

    useEffect(() => {
        // window.onGetFingerPrint = onGetFingerPrint
        const script = document.createElement('script');
        script.src = "https://www.mercadopago.com/v2/security.js";
        script.setAttribute("output", "deviceId");
        script.setAttribute("view", "checkout");
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            if (window.deviceId) {
                clearTimeout(timer)
                setDeviceIdMercadoPago(window.deviceId)
            }

        }, 1000);

        return () => {
            clearTimeout(timer)
        }

    }, [])

    return ({deviceIdMercadoPago})
}
