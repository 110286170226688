import { useAuth } from 'context/auth/AuthContext';
import { useLocal } from 'context/local/LocalContext';
import { useUi } from 'context/ui/UIContext';
import { add } from 'date-fns';
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { ScheduleTourApi } from 'services/Api/schedule/ScheduleTourApi';
import { GetReserverListRequest } from '../../../_domain/dto/ReservePlaceSchedule/GetReserverListRequest';
import { IGetReserveListByUseResponse } from '../../../_domain/dto/ReservePlaceSchedule/GetReserversByUserResponse';

const UseReservesPage = () => {
    const date = new Date(new Date().setDate(new Date().getDate() - 7));
    const { localId } = useLocal();
    const [values, setValues] = useState<IGetReserveListByUseResponse>()
    // } as IReserveFilter);
    const { goBack } = useHistory();
    const { openModalLogin, isAuth } = useAuth()
    const { showLoading, hideLoading } = useUi()

    useEffect(() => {
        if (isAuth) {
            showLoading();
            const scheduleTour = ScheduleTourApi();
            const request: GetReserverListRequest = {
                localClienteId: localId,
                page: 0,
                pageSize: 200,
                start: add(new Date(), { days: -7 }).toISOString(),
                keyword: ""
            }
            scheduleTour.getReserveByUser(request).then((response) => {
                setValues(response)
            }).catch((error) => {
                if (error.response.status) {
                    openModalLogin()
                }
            }).finally(() => {
                hideLoading()
            })
        }
    }, [hideLoading, showLoading, localId, isAuth, openModalLogin])


    const onClickBackHandle = useCallback(() => {
        goBack();
    }, [])

    return (
        {
            values,
            onClickBackHandle
        }
    )
}

export default UseReservesPage
