import { IOrderDetailWithStoreConfig } from "models/order/aggregates/datail/IOrderDetailWithStoreConfig"
import api from "../Api"

export const OrderApi = () => {
    // api/SuperApp/Order/GetOrderAndConfigs/{orderId}

    const getOrderAndConfigs = async (orderId: string) => {
        const response = await api.get<IOrderDetailWithStoreConfig>(`/SuperApp/Order/GetOrderAndConfigs/${orderId}`)
        return response
    }




    return { getOrderAndConfigs }
}

