import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { EnumPrinterStatus, IOrderInProgress } from "models/order/aggregates/progress/IOrderInProgress";
import "./OrderInProgressItem.css";
import QRCode from "qrcode.react";
import { format, addHours, differenceInMinutes } from "date-fns";
import { ProductItemDetail } from "./productItemDetails/ProductItemDetail";
import { Button, CircularProgress, Drawer, Icon } from "@material-ui/core";
import { OrderTracking } from "../../../orderTracking/OrderTracking";
import { OrderTrackingApi } from "services/Api/order/OrderTrackingApi";
import { IOrderTracking } from "models/order/aggregates/tracking/IOrderTracking";

interface IOrderInProgressItemProps {
  orderInProgress: IOrderInProgress;
}
export const OrderInProgressItem: FC<IOrderInProgressItemProps> = ({ orderInProgress }) => {
  const [openQrCode, setOpenQrCode] = useState(false);
  const [openTracking, setOpenTracking] = useState(false);

  const orderTrackingApi = useMemo(OrderTrackingApi, []);
  const [orderTracking, setorderTraking] = useState<IOrderTracking>();

  const getOrderTracking = useCallback(async () => {
    const response = await orderTrackingApi.getStatusOrders(orderInProgress.id);
    if (response.data) {
      setorderTraking(response.data);
    }
  }, [orderInProgress.id, orderTrackingApi]);

  useEffect(() => {
    const time = setInterval(() => {
      getOrderTracking();
    }, 30000);
    return () => {
      clearInterval(time);
    };
  }, [getOrderTracking]);

  useEffect(() => {
    getOrderTracking();
    return () => { };
  }, [getOrderTracking]);

  const calcTime = (time: string) => {
    const difference = differenceInMinutes(new Date(), addHours(new Date(time), -3))
    return difference
  }

  return (
    <>
      <div id={"orderInProgressItem"}>
        <div className="buttonBase">
          <div className={"content"}>
            <div className={"storeName"}>{orderInProgress.storeName}</div>
            {
              orderInProgress.printerStatus?.length
                && orderInProgress.printerStatus[0].status !== EnumPrinterStatus.Printed ? (
                calcTime(orderInProgress.printerStatus[0].createdAt) < 5 ?
                  <div className={"status"} style={{ backgroundColor: 'orange' }}>
                    <CircularProgress size={12} style={{ color: '#fff' }} />
                    Enviando pedido aguarde
                  </div> :
                  <div className={"status"} style={{ backgroundColor: 'red' }}>
                    {"Pedido esta demorando mais do que o esperado \n Solicite um garçom"}
                  </div>
              )
                :
                orderInProgress.status && <div className={"status"}>{orderTracking?.CurrentState ?? orderInProgress.status}</div>
            }
            <div className={"createAt"}>Realizado em: {format(addHours(new Date(orderInProgress.created), -3), "HH:mm dd/MM/yyyy")}</div>
            Pedido:
            <div className={"pedido"}>
              <div>
                {orderInProgress.productsDetails.map((productsDetail, index) => (
                  <ProductItemDetail key={index} productDetail={productsDetail} />
                ))}
              </div>
            </div>
            <div>
              <div>Total:</div>
              <div className={"total"}>R${orderInProgress.total.toFixed(2)}</div>
            </div>
            {/* <div className={"qrCodeIcon"}>
                            <Icon>qr_code_2</Icon>
                        </div> */}
            <div className="actionsBuntons">
              <div className="button" style={{ marginRight: 2 }}>
                <Button onClick={() => setOpenTracking(true)} disabled={(!orderTracking || !!(orderInProgress.printerStatus?.length
                  && orderInProgress.printerStatus[0].status !== EnumPrinterStatus.Printed))} variant="contained" fullWidth color="primary">
                  <Icon>open_in_new</Icon> Acompanhar
                </Button>
              </div>
              <div className="button" style={{ marginLeft: 2 }}>
                <Button onClick={() => setOpenQrCode(true)} variant="contained" fullWidth color="primary">
                  {" "}
                  <Icon>qr_code_2</Icon> QRCode
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer anchor={"bottom"} open={openQrCode} onClose={() => setOpenQrCode(false)}>
        <div id={"qrCodeContainer"}>
          <QRCode size={200} style={{ margin: 16 }} value={orderInProgress.id.replace(/[^0-9a-zA-Zs]/g, "")} />
          <div className={"storeName"}>{orderInProgress.storeName}</div>
          <div className={"createAt"}>{format(new Date(orderInProgress.created), "HH:mm dd/MM/yyyy").toLocaleLowerCase("pt-BR")}</div>
          <div className={"total"}>R${orderInProgress.total.toFixed(2)}</div>
        </div>
      </Drawer>
      {orderTracking && (
        <Drawer anchor={"bottom"} open={openTracking} onClose={() => setOpenTracking(false)}>
          <OrderTracking orderTracking={orderTracking} />
        </Drawer>
      )}
    </>
  );
};
