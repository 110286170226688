import { ButtonBase, Icon, IconButton, Tooltip } from '@material-ui/core'
import React, { FC, memo } from 'react'
import './CardItem.css'

interface CardItemProps {
    textTop?: string
    textBottom?: string
    imageUrl?: string
    iconName?: string
    imageSrc?: any
    onClickEdit?: () => void
    onClickRemove?: () => void
    onClick?: () => void
    onClickAdd?: () => void
    select?: boolean
    disabled?: boolean
    autenticated?: boolean
    autenticateIcon?: boolean
}

export const CardItem: FC<CardItemProps> = memo(({ onClickRemove, onClickEdit, onClick, onClickAdd, autenticated, autenticateIcon, textTop, textBottom, imageUrl, imageSrc, iconName, disabled }) => {
    return (
        <>
            <ButtonBase
                onClick={onClick}
                disabled={!onClick}
                style={{ opacity: disabled ? 0.3 : 1 }}
                className={"container-card-item"}  >
                {
                    iconName &&
                    <div className="icon-grid-card-item" >
                        <Icon className={"icon-card-item"}>{iconName}</Icon>
                    </div>
                }

                <div className={"content-card-item"}  >
                    <div className={"text-content-card-item"} >
                        <div className={"top-content-card-item"} >
                            {textTop}
                        </div>
                        <div className={"bottom-content-card-item"} >
                            {textBottom}
                        </div>
                    </div>

                    {
                        imageSrc &&
                        <div className={"brand-image-content-card-item"} >
                            <img alt="" style={{ width: 50, height: 50, objectFit: "contain" }} src={imageSrc}></img>

                        </div>
                    }

                    {
                        autenticateIcon && autenticated === true &&
                        <Tooltip title="Cartão Verificado">

                            <div className={"autenticatedIcon"} >
                                <Icon style={{ color: "green" }}>verified</Icon>
                            </div>
                        </Tooltip>
                    }
                    {
                        autenticateIcon && autenticated === false &&
                        <Tooltip title="Cartão não verificado">

                            <div className={"autenticatedIcon"} >
                                <Icon style={{ color: "#f55" }}>privacy_tip</Icon>
                            </div>
                        </Tooltip>
                    }
                </div>

                <div className={"actions-card-item"} >
                    {onClickRemove && <IconButton onClick={onClickRemove}><Icon>close</Icon></IconButton>}
                    {onClickEdit && <IconButton onClick={onClickEdit}><Icon>edit</Icon></IconButton>}
                    {onClickAdd && <IconButton onClick={onClickAdd}><Icon>add</Icon></IconButton>}
                </div>
            </ButtonBase>
        </>
    )
})
