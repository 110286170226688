import { CalcTotalsAllCompositions } from 'components/productComposition/useCases/CalcTotalsAllCompositions';
import { ICartItem } from '../models/ICart';

// export const RemoveProductCartUseCase = (): IAddCartItemUseCase => {
export const AddCartItemByIdUseCase = (prevCartItems: ICartItem[], cartItemId: string, quantity: number): ICartItem[] => {

    const AddCartItem = (_prevCartItems: ICartItem[], _cartItemId: string, _quantity: number) => {
        const newCartItemsRemovedQuantitys = _prevCartItems.map((prevCartItem) => {
            if (prevCartItem.id === _cartItemId) {
                return {
                    ...prevCartItem,
                    quantity: prevCartItem.quantity + _quantity,
                    totalPrice: (prevCartItem.quantity + _quantity) * (prevCartItem.price + (prevCartItem.orderComposition ? CalcTotalsAllCompositions(prevCartItem.orderComposition).price : 0)),
                };
            } else {
                return { ...prevCartItem };
            }
        });
        return newCartItemsRemovedQuantitys;
    };


    return AddCartItem(prevCartItems, cartItemId, quantity);
};

