import { useCart } from 'context/cart/CartContext';
import React, { FC } from 'react';
import CartItem from './cartItem/CartItem';
import styles from './CartPage.module.scss';
import UseCartPage from './UseCartPage';
import { Close, DeleteOutline } from '@material-ui/icons';
import NumberToMoneyString from 'utils/mask/NumberToMoney';

export interface ICartViewProps {
    onClickClose?: () => void
}

const CartPage: FC<ICartViewProps> = ({ onClickClose }) => {
    const { cartItems, clearCart, totals } = useCart();
    const { sendPreOrder } = UseCartPage();

    // const totalCart = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0)

    return (
        <div id={styles.CartView}>
            <div className={styles.container}>
                <header>
                    <h1>Carrinho</h1>
                    <button onClick={onClickClose}>
                        <Close />
                    </button>
                </header>
                <div className={styles.content} >
                    <div className={styles.clearCartContainer}>
                        <button onClick={clearCart}>
                            <DeleteOutline />
                            <p>Limpar</p>
                        </button>
                    </div>
                    {cartItems.map((cartItem) => <CartItem key={cartItem.id} cartItem={cartItem} totals={totals.totalPrice} />)}
                </div>
                <footer>
                    <span>Total: {NumberToMoneyString(totals.totalPrice)}</span>
                    <button onClick={sendPreOrder} className={styles.submitButton}>
                        Confirmar
                    </button>

                    <button onClick={onClickClose} className={styles.backButton}>
                        Continuar comprando
                    </button>
                </footer>
            </div>
        </div>
    )
}
export default CartPage