import { IconButton } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { addHours, format } from 'date-fns';
import React, { useState } from 'react'
import { IReserveItem } from '../../../_domain/dto/ReservePlaceSchedule/GetReserversByUserResponse';
import styles from './ReserveListCard.module.scss';
import QRCode from "qrcode.react";

export interface IReserveListCardProps {
    value: IReserveItem;
    onDelete?: () => void;
}

export const ReserveListCard: React.FC<IReserveListCardProps> = ({ value, onDelete }) => {

    const [openCustomersDetails, setOpenCustomersDetails] = useState(false);

    return (
        <div id={styles.containerDetailsCustomer} >
            <div className={styles.customerContainer}>
                <div className={styles.customer}>
                    <div className={styles.name}>Nome: {value.responsable.name}</div>
                    <div className={styles.service}>Serviço: {value.service.name}</div>
                    <div className={styles.participantsQuantity}>Qtd: {value.participantsQuantity}</div>
                    <div className={styles.dateTour}>
                        <div>
                            Data do passeio: {new Date(value.startAt).toLocaleDateString()}
                        </div>
                    </div>
                    <div className={styles.netValue}> Valor: {Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                    }).format(value.netValue)}</div>
                    {
                        value.currentState.name === 'confirmed'
                            ?
                            <div className={styles.document}>Status: Confirmado</div>
                            :
                            <div className={styles.document}>Status: Não Confirmado</div>


                    }
                    <div className={styles.formPayment}> Pagamento: {value.formOfPayment.name }</div>

                    <div className={styles.buttonOptions}>
                        <IconButton onClick={() => setOpenCustomersDetails((prev) => !prev)}>
                            {!openCustomersDetails ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowDown />
                            )}
                        </IconButton>
                    </div>
                </div>
                <div
                    className={
                        !openCustomersDetails ? styles.customersDetailsOpen : styles.customersDetailsClose
                    }
                >
                    <div className={styles.containerDetailsCustomers}>
                        {/* <div className={styles.headerTitle}>
                            <div className={styles.titlePeople}><p>Pessoas na reserva</p></div>
                            <div className={styles.titleDocument}><p>Documento</p></div>
                            <div className={styles.titleAge}><p>Idade</p></div>
                        </div> */}

                        {value.participants?.map((participant) => (
                            <div className={styles.containerCustomersList}>
                                <div className={styles.nameParticipant}> {participant.name}</div>
                                <div className={styles.document}> {participant.document}</div>
                                <div className={styles.birthDate}>
                                    <div>{participant.age} anos</div>
                                    <div>{`(${format(addHours(new Date(participant.birthday), 3), 'dd/MM/yyyy')})`}</div>
                                </div>
                                <div className={styles.qrCode}>
                                <QRCode size={150} style={{ margin: 16 }} value={participant.id.replace(/[^0-9a-zA-Zs]/g, "")} />
                                </div>
                            </div>
                        
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
