export interface IComposition {
  id: string;
  description: string;
  productId: string;
  productValue: number;
  quantity: number;
}

export interface ICompositionSection {
  id: string;
  description: string;
  options: IComposition[];
}

export interface IRegisteredCreditCard {
  id: string;
  cvv: string;
  token: string;
}

export interface IPaymentOrderItem {
  id: string;
  name: string;
  quantity: number;
  value: number;
  composition: string;
}

export interface IPaymentCoupon {
  id: string;
  value: number;
}

export interface IPaymentOrder {
  id: string;
  value: number;
  deliveryFee: number;
  items: IPaymentOrderItem[];
  discount?: number;
  coupon?: IPaymentCoupon;
}

export interface IMeepWalletModel {
  id: string;
}

export enum PaymentType {
  INDEFINIDO = -1,
  NAO_INFORMADO = 0,
  CREDITO = 1,
  DEBITO = 2,
  DINHEIRO = 3,
  CREDITOLOJA = 4,
  MEEPCOIN = 5,
  MISTO = 6,
  CHEQUE = 7,
  PENDENTE = 8,
  BOLETO = 9,
  REEMBOLSO = 10,
  CASHBACK = 11,
  PRODUCAO = 12,
  AJUSTE = 13,
  VOUCHER = 14,
  DELIVERY = 15,
  PERDA = 16,
  PIX = 18,
  WALLET = 22
}


export const PaymentTypeString = (paymentType: PaymentType) => {
  switch (paymentType) {
    case (-1):
      return "INDEFINIDO"
    case (0):
      return "NAO INFORMADO"
    case (1):
      return "Crédito"
    case (2):
      return "Débito"
    case (3):
      return "Dinheiro"
    case (4):
      return "CREDITOLOJA"
    case (5):
      return "MEEPCOIN"
    case (6):
      return "MISTO"
    case (7):
      return "CHEQUE"
    case (8):
      return "PENDENTE"
    case (9):
      return "BOLETO"
    case (10):
      return "REEMBOLSO"
    case (11):
      return "CASHBACK"
    case (12):
      return "PRODUCAO"
    case (13):
      return "AJUSTE"
    case (14):
      return "VOUCHER"
    case (15):
      return "DELIVERY"
    case (16):
      return "PERDA"
    case (18):
      return "PIX"
    case (22):
      return "WALLET"
  }
}

export interface IExternalAuthentication {
  cavv: string;
  xid: string;
  eci: string;
  version: string;
  referenceID: string;
}

export interface IPay {
  localClientId: string;
  deliveryAddressId?: string;
  customDeliveryDescription?: string;
  paymentType: PaymentType;
  change?: number;
  installments: number;
  registeredCreditCard?: IRegisteredCreditCard;
  order: IPaymentOrder;
  meepWalletModel?: IMeepWalletModel;
  externalAuthentication?: IExternalAuthentication;
}
