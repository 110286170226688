import { GetPlacesScheduleByServiceIdResponseDTO } from "pages/public/schedule/pages/tour/_domain/dto/ReservePlaceSchedule/GetPlacesScheduleByServiceIdResponse";
import { IGetMainServicesCategoryByLocalIdResponse, IGetServicesCategoryByLocalIdResponse } from "pages/public/schedule/pages/tour/_domain/dto/ReservePlaceSchedule/GetServicesByLocalIdResponseDTO";
import { AddPlaceDemandScheduleRequestDTO } from "pages/public/schedule/pages/tour/_domain/dto/ReservePlaceSchedule/IAddReseveScheduleWithouPaymentRequest";
import { GetProductValuesByServiceIdResponseDTO } from "pages/public/schedule/pages/tour/_domain/dto/ReservePlaceSchedule/IGetProductValuesByServiceIdResponse";
import api from "../Api";
import { AddPlaceDemandScheduleWithPaymentRequest } from "pages/public/schedule/pages/tour/_domain/dto/ReservePlaceSchedule/IAddPlaceDemandScheduleWithPaymentRequest";
import { IGetDataByDocumentResponse } from "pages/public/schedule/pages/tour/_domain/dto/ReservePlaceSchedule/IGetDataByDocumentResponse";
import { IGetServiceDetailsByServiceIdResponse } from "pages/public/schedule/pages/tour/_domain/dto/ReservePlaceSchedule/IGetServiceDetailsByServiceIdResponse";
import { GetVancaciesAndDiponibilityResponseDto } from "pages/public/schedule/pages/tour/_domain/dto/ReservePlaceSchedule/GetVacanciesAndDisponibilityByServiceIdResponseDTO";
import { IGetReserveListByUseResponse } from "pages/public/schedule/pages/tour/_domain/dto/ReservePlaceSchedule/GetReserversByUserResponse";
import { GetReserverListRequest } from "pages/public/schedule/pages/tour/_domain/dto/ReservePlaceSchedule/GetReserverListRequest";
/*
ByLocalId/{localId}
Principals/ByLocalId/{localId}
Subcategories/ByLocalId/{localId}
 */
export const ScheduleTourApi = () => {

    const postReserveTourWithPayment = async (request: AddPlaceDemandScheduleWithPaymentRequest): Promise<void> => {
        const response = await api.post(`DemandSchedule/Place/WithPaymentAndMultipleReservation`, request);
        return
    }

    const getServiceCategoryByLocalId = async (localId: string): Promise<IGetServicesCategoryByLocalIdResponse> => {
        const response = await api.get<IGetServicesCategoryByLocalIdResponse>(`/Schedule/ServiceCategory/ByLocalId/${localId}`, { params: { removeSubcategories: false, removePrincipalCategories: true } });
        return (response.data);
    }

    const getServiceCategoryByCategoryId = async (categoryId: string): Promise<IGetServicesCategoryByLocalIdResponse> => {
        const response = await api.get<IGetServicesCategoryByLocalIdResponse>(`/Schedule/ServiceCategory/ByCategoryId/${categoryId}`);
        return (response.data);
    }

    const getMainServiceCategoryByLocalId = async (localId: string): Promise<IGetMainServicesCategoryByLocalIdResponse> => {
        const response = await api.get<IGetMainServicesCategoryByLocalIdResponse>(`/Schedule/ServiceCategory/ByLocalId/${localId}`, { params: { removeSubcategories: true, removePrincipalCategories: false } });
        return (response.data);
    }//obter 


    const getDataByDocument = async (localId: string, cpf: string): Promise<IGetDataByDocumentResponse> => {
        const response = await api.get<IGetDataByDocumentResponse>(`/Schedule/${localId}/Client/Cpf/${cpf}`);
        return (response.data);
    }

    const getPlacesByServiceId = async (serviceId: string): Promise<GetPlacesScheduleByServiceIdResponseDTO> => {
        const response = await api.get<GetPlacesScheduleByServiceIdResponseDTO>(`/PlaceSchedule/GetPlacesScheduleByServiceId/${serviceId}`);
        return (response.data);
    }

    const getServicesValuesByServiceId = async (serviceId: string): Promise<GetProductValuesByServiceIdResponseDTO> => {
        const response = await api.get<GetProductValuesByServiceIdResponseDTO>(`/ServiceSchedule/GetProductValuesByServiceId/${serviceId}`);
        return (response.data);
    }

    const getServiceDetailsByServiceId = async (serviceId: string): Promise<IGetServiceDetailsByServiceIdResponse> => {
        const response = await api.get<IGetServiceDetailsByServiceIdResponse>(`/ServiceSchedule/GetServiceDetails/${serviceId}`);
        return (response.data);
    }

    const postAddResevePlaceSchedule = async (request: AddPlaceDemandScheduleRequestDTO): Promise<void> => {
        const response = await api.post(`DemandSchedule/Place/WithoutPayment`, request);
        return
    }


    const getVacanciesAndDisponibilityService = async (serviceScheduledId: string, start: string, end: string): Promise<GetVancaciesAndDiponibilityResponseDto[]> => {
        const response = await api.get<GetVancaciesAndDiponibilityResponseDto[]>(`/PlaceSchedule/Vacancies`,
            {
                params: {
                    serviceScheduledId,
                    start,
                    end,
                }
            });
        return response.data;
    }

    const getReserveByUser = async (request: GetReserverListRequest): Promise<IGetReserveListByUseResponse> => {
        const response = await api.get<IGetReserveListByUseResponse>(`/DemandSchedule/ReservationListByUser`, { params: request });
        return (response.data);
    }

    return {
        postReserveTourWithPayment,
        getServiceCategoryByLocalId,
        getPlacesByServiceId,
        getServicesValuesByServiceId,
        postAddResevePlaceSchedule,
        getServiceCategoryByCategoryId,
        getMainServiceCategoryByLocalId,
        getDataByDocument,
        getServiceDetailsByServiceId,
        getVacanciesAndDisponibilityService,
        getReserveByUser
    }
}

// const storeTourMock: IStoreTour[] = [
//     {
//         id: '001',
//         name: 'Passeios',
//         imageUrl: 'https://malaprontagramado.com.br/wp-content/uploads/2020/02/cidade-de-gramado.jpg',
//         logoUrl: '',
//         productTourCategory: [
//             {
//                 id: '001',
//                 title: 'Tour nova Petrópolis',
//                 imageUrl: '',
//                 products: [],
//             },
//             {
//                 id: '002',
//                 title: 'Tour uva e vinho + Trem',
//                 imageUrl: '',
//                 products: [],
//             },
//             {
//                 id: '003',
//                 title: 'Tour vinhedos Especiais',
//                 imageUrl: '',
//                 products: [],
//             },
//             {
//                 id: '004',
//                 title: 'Passeio com cerveja',
//                 imageUrl: '',
//                 products: [],
//             }
//         ]
//     },
//     {
//         id: '002',
//         name: 'Jantares',
//         imageUrl: 'https://malaprontagramado.com.br/wp-content/uploads/2020/02/cidade-de-gramado.jpg',
//         logoUrl: '',
//         productTourCategory: []
//     },
//     {
//         id: '003',
//         name: 'Transfers',
//         imageUrl: 'https://malaprontagramado.com.br/wp-content/uploads/2020/02/cidade-de-gramado.jpg',
//         logoUrl: '',
//         productTourCategory: []
//     },

// ];


// const productTourMock: IProductTourCategory[] = [
//     {
//         id: '001',
//         title: 'Passeio com almoço',
//         imageUrl: 'https://malaprontagramado.com.br/wp-content/uploads/2020/02/cidade-de-gramado.jpg',
//         products: [
//             {
//                 id: "000-001",
//                 name: "Tour nova Petrópolis",
//                 description: "Lindo passeio pelas montanhas de gramado, além de um excelente almoço!",
//                 thumbnailUrl: 'https://malaprontagramado.com.br/wp-content/uploads/2020/02/cidade-de-gramado.jpg',
//                 imageUrl: 'https://malaprontagramado.com.br/wp-content/uploads/2020/02/cidade-de-gramado.jpg',
//                 price: 50
//             },
//             {
//                 id: "000-002",
//                 name: "Tour uva e vinho",
//                 description: "Lindo passeio pelas montanhas de gramado, além de um excelente almoço!",
//                 thumbnailUrl: 'https://malaprontagramado.com.br/wp-content/uploads/2020/02/cidade-de-gramado.jpg',
//                 imageUrl: 'https://malaprontagramado.com.br/wp-content/uploads/2020/02/cidade-de-gramado.jpg',
//                 price: 50
//             },
//             {
//                 id: "000-003",
//                 name: "Tour vinhedos especiais",
//                 description: "Lindo passeio pelas montanhas de gramado, além de um excelente almoço!",
//                 thumbnailUrl: 'https://malaprontagramado.com.br/wp-content/uploads/2020/02/cidade-de-gramado.jpg',
//                 imageUrl: 'https://malaprontagramado.com.br/wp-content/uploads/2020/02/cidade-de-gramado.jpg',
//                 price: 50
//             },
//         ],
//     },
//     {
//         id: '004',
//         title: 'Passeio com cerveja',
//         imageUrl: 'https://malaprontagramado.com.br/wp-content/uploads/2020/02/cidade-de-gramado.jpg',
//         products: [
//             {
//                 id: "000-004",
//                 name: "Tour vinhedos especiais",
//                 description: "Lindo passeio pelas montanhas de gramado, além de um excelente almoço!",
//                 thumbnailUrl: 'https://malaprontagramado.com.br/wp-content/uploads/2020/02/cidade-de-gramado.jpg',
//                 imageUrl: 'https://malaprontagramado.com.br/wp-content/uploads/2020/02/cidade-de-gramado.jpg',
//                 price: 50
//             },
//             {
//                 id: "000-005",
//                 name: "Tour vinhedos especiais",
//                 description: "Lindo passeio pelas montanhas de gramado, além de um excelente almoço!",
//                 thumbnailUrl: 'https://malaprontagramado.com.br/wp-content/uploads/2020/02/cidade-de-gramado.jpg',
//                 imageUrl: 'https://malaprontagramado.com.br/wp-content/uploads/2020/02/cidade-de-gramado.jpg',
//                 price: 50
//             }
//         ],
//     },


// ];
