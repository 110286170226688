import React from "react";
import { TextField } from "@material-ui/core";
import InputMask from "react-input-mask";
import styles from './InputMasked.module.scss';

export interface ParametersInputProps {
    mask: string | (string | RegExp)[] /* "999.999.999-99" | "(31)3333-4444"*/;
    disabled?: boolean;
    placeholder?: string;
    value: string | number | undefined;
    onChange?: any;
    name: string;
    label: string;
    variant?: "standard" | "filled" | "outlined" | undefined;
    fullWidth?: boolean;
    className?: string | undefined;
}

const MeepMaskedInput: React.FC<ParametersInputProps> = ({
    mask,
    disabled,
    placeholder,
    value,
    onChange,
    name,
    label,
    variant,
    fullWidth = true,
    className,
}) => {
    return (       
            <InputMask
                mask={mask}
                value={value}
                disabled={disabled}
                onChange={onChange}
            >
                <TextField
                    fullWidth={fullWidth}
                    label={label}
                    name={name}
                    variant={variant}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    placeholder={placeholder}
                    className={`${className} ${styles.textField}`}
                />
            </InputMask>
      
    );
};

export default MeepMaskedInput;
