import React, { createContext, FC, useContext } from 'react';
import { IUseCaseCart } from './interfaces/IUseCaseCart';
import useCaseCart from './useCaseCart';

const CartContext = createContext<IUseCaseCart>({} as IUseCaseCart);

export const CartProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const caseCart = useCaseCart();
  return (
    <CartContext.Provider value={caseCart}>
      {children}
    </CartContext.Provider>
  );
};

export const CartConsumer = CartContext.Consumer;

export const useCart = () => {
  const context = useContext(CartContext);
  return context;
};
