import { useUi } from 'context/ui/UIContext';
import { ProductType } from 'models/catalog/enums/ProductType';
import { IOrder } from 'models/order/IOrder';
import { ICardBrandsInstallments, IPaymentLinkResponse } from 'models/paymentLink/IPaymentLinkResponse';
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import SendOrderApi from 'services/Api/order/SendOrderApi';
import PaymentLinkApi from 'services/Api/paymentLink/PaymentLinkApi';
import { v4 } from 'uuid';

const paymentLinkApi = PaymentLinkApi();

export const UsePaymentLink = (
  valueRef?: React.RefObject<HTMLInputElement>
) => {
  const [paymentLink, setPaymentiLink] = useState<IPaymentLinkResponse>();
  const [paymentLinkValue, setPaymentLinkValue] = useState("");

  const { paymentLinkId } = useParams<{ paymentLinkId: string }>();
  
  const { showLoading, hideLoading } = useUi();
  const { push } = useHistory();

  const getInstallments = useCallback(async (value: number) => {
    var installments: ICardBrandsInstallments[] = [];
    if (paymentLinkId) {
      const installmentsResponse = await paymentLinkApi.getInstallments(paymentLinkId, value);
      return installmentsResponse.data;
    }

    return installments;
  }, [paymentLinkId]);

  const cleanValue = useCallback((value: string) => {
    value = value.replace(/[^\d]/g, "");
    value = value.slice(0, -2) + "," + value.slice(-2);
    value = value.split(",")[0].length === 0 ? "0" + value : value;

    return parseFloat(value.replace(",", "."));
  }, []);

  const handleBlurValue = useCallback(
    async (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value = ev.target.value;
      const price = cleanValue(value);

      const installments = await getInstallments(price);
      setPaymentiLink((prev) => {
        if (prev) {
          return {
            ...prev,
            cardBrandsInstallments: installments
          };
        }
      });
    }, [cleanValue, getInstallments]);

  const handleChangeValue = useCallback(
    (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value = ev.target.value;
      const price = cleanValue(value);

      setPaymentLinkValue(price === 0 ? "" : value);
      setPaymentiLink((prev) => {
        if (prev) {
          return {
            ...prev,
            price,
          };
        }
      });
    }, [cleanValue]);

  const generateOrderAndRedirect = useCallback(() => {
    const sendOrderApi = SendOrderApi();
    if (paymentLink) {
      const newOrder: IOrder = {
        id: v4(),
        total: paymentLink.price,
        items: [
          {
            id: paymentLink.productId,
            name: paymentLink.product.nome,
            category: "",
            price: paymentLink.price,
            totalPrice: paymentLink.price,
            description: "",
            productPrice: paymentLink.price,
            productId: paymentLink.productId,
            productType: ProductType.Produto,
            quantity: 1,
            imageUrl: "",
          },
        ],
      };

      showLoading();
      sendOrderApi
        .sendPreOrder(newOrder, paymentLink.localId)
        .then((response) => {
          push(`/payment?orderId=${response.data.pedidoId}`);
        })
        .finally(() => {
          hideLoading();
        });
    }
  }, [hideLoading, paymentLink, push, showLoading]);

  const goToPayment = useCallback(() => {
    if (paymentLink) {
      push(`/paymentguest?paymentLinkId=${paymentLink.id}`);
    }
  }, [paymentLink, push]);

  const inputMoneyMask = useCallback(
    (value?: string) => {
      if (!value) {
        return undefined;
      }
      value = value.replace(/\D/g, "");
      while (value.length < 3) {
        value = "0" + value;
      }
      value = value.replace(/^0+(?=\d{3,})/, "");

      let maskedValue = "R$ " + value.slice(0, -2) + "," + value.slice(-2);
      if (value === "000") {
        if (valueRef && valueRef.current) {
          valueRef.current.value = "";
        }
        return undefined;
      }
      return maskedValue;
    },
    [valueRef]
  );

  useEffect(() => {
    if (paymentLinkId) {
      showLoading();
      paymentLinkApi
        .getPaymentProduct(paymentLinkId)
        .then((response) => {
          setPaymentiLink(response.data);
          setPaymentLinkValue(
            response.data.price.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
              minimumFractionDigits: 2,
            })
          );
        })
        .finally(() => {
          hideLoading();
        });
    }
    return () => {};
  }, [hideLoading, inputMoneyMask, paymentLinkId, showLoading]);

  return {
    paymentLink,
    generateOrderAndRedirect,
    goToPayment,
    handleChangeValue,
    inputMoneyMask,
    paymentLinkValue,
    handleBlurValue,
  };
};
