import { Icon } from '@material-ui/core';
import React, { FC, useCallback } from 'react'
import styles from './ButtonPickerAcordion.module.scss'

export interface IButtonPickerAcordionProps {
    //propertys
    onClick: () => void
    label: string,
    value?: string,
    icon?: string,
    selected?: boolean,
    open?: boolean,
    disabledAnimation?: boolean;
    children?: React.ReactNode;
}
const ButtonPickerAcordion: FC<IButtonPickerAcordionProps> = ({ onClick, children, selected, icon, label, open, value, disabledAnimation }) => {

    const onClickHandle = useCallback(
        () => {
            onClick();
        },
        [onClick],
    )
    return (
        <div id={styles.ButtonPickerAcordion} >
            <div className={selected && !disabledAnimation ? styles.containerSelected : styles.containerUnselected} >
                <div className={styles.content}>
                    <div onClick={onClickHandle} className={styles.botaoContainer}>
                        <div style={{ display: "flex", alignItems: 'center' }}>
                            {icon &&
                                <div className={styles.iconContainer}>
                                    <Icon className={styles.icon} fontSize={"small"}>{icon}</Icon>
                                </div>
                            }
                            <div className={styles.botaoValue}>
                                {value &&
                                    <div className={styles.botaoLabel}>
                                        {label}
                                    </div>
                                }
                                <div className={styles.botaoValue}>
                                    {value ?? label}
                                </div>
                            </div>
                        </div>
                        <Icon fontSize={"small"} className={styles.icon}>{open ? "expand_more" : "chevron_right"}</Icon>
                    </div>
                    <div className={(open) ? styles.acordionOpen : styles.acordionClose}>
                        {children}
                    </div>

                </div>
            </div>
        </div>
    )
}
export default ButtonPickerAcordion