import { createContext, FC, useContext, useEffect, useState } from "react";
import React from 'react'
import { useLocation } from "react-router-dom";

interface IQueryParams {
    queryPlace?: string
    queryDescription?: string
    queryTypeDelivery?: string
    orderId?: string,
    localId?: string,
    accountId?: string,
    operatorId?: string
}
interface IParamsConfigContext {
    queryParams: IQueryParams | undefined
    setParamsLocalId: React.Dispatch<React.SetStateAction<string | undefined>>
}
const ParamsConfigContext = createContext<IParamsConfigContext>({} as IParamsConfigContext)

export const ParamsProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    
    const [queryParams, setQueryParams] = useState<IQueryParams>();
    const location = useLocation();
    
    const [localId, setParamsLocalId] = useState<string>()

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const queryPlace = query.get("pl");
        const queryDescription = query.get("desc");
        const queryTypeDelivery = query.get("td");
        const orderId = query.get("orderId");
        const accoutnId = query.get("accountId");
        const localId = query.get("localId");
        const operatorId = query.get("operatorId");

        if (localId) {
            setParamsLocalId(localId);
        }
        setQueryParams(prev => ({
            queryPlace: queryPlace ?? prev?.queryPlace,
            queryDescription: queryDescription ?? prev?.queryDescription,
            queryTypeDelivery: queryTypeDelivery ?? prev?.queryTypeDelivery,
            orderId: orderId ?? prev?.orderId,
            accountId: accoutnId ?? prev?.accountId,
            localId: localId ?? prev?.localId,
            operatorId: operatorId ?? prev?.operatorId,
        }))

        return () => {

        }
    }, [location]);


    useEffect(() => {
        if (localId && queryParams?.queryPlace) {
            localStorage.setItem('@storagePlace-' + localId, queryParams?.queryPlace)
        }
    }, [localId, queryParams])


    return (
        <ParamsConfigContext.Provider value={{ queryParams, setParamsLocalId }}>
            {children}
        </ParamsConfigContext.Provider >
    )
}



export const useParamsConfig = () => {
    const context = useContext(ParamsConfigContext)
    return context
}