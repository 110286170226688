
import React, { forwardRef } from 'react'
import { IProductItem } from './interfaces/IProductItem'
import { ISeccionedProduct } from './interfaces/ISeccionedProduct'
import ProductTourItem from './productItem/ProductTourItem'
import styles from './ProductList.module.scss'

export interface IProductListTourProps {
    seccionedProduct: ISeccionedProduct[];
    simpleTitle?: boolean,
    onClickProduct: (product: IProductItem) => void
    prefixPrice?:string
}
export interface IProductListRef {
    goToCagetory: (index: number) => void;
}

const ProductListTour = forwardRef<IProductListRef, IProductListTourProps>(({ seccionedProduct,  onClickProduct, prefixPrice, simpleTitle }, ref) => {
    return (
        <div id={styles.ProductList} >
            <div className={styles.container} >
                {
                    seccionedProduct.map((section, index) => (
                        !!section.products.length && <div className={styles.section}>
                            <div className={simpleTitle ? styles.simpletitle : styles.title} style={{ backgroundImage: simpleTitle ? "none" : `url(${section.products[0].imageUrl})` ?? "" }} >{section.title}</div>

                            <div className={styles.list}>
                                {section.products.map((product) =>
                                    // <Link to={`${url}/product/${product.id}`}>
                                    <ProductTourItem prefixPrice={prefixPrice} onClick={() => onClickProduct(product)} product={product}></ProductTourItem>
                                    // </Link>
                                )}
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
})
export default ProductListTour