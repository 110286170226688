import { CircularProgress } from '@material-ui/core'
import { useAuth } from 'context/auth/AuthContext'
import { useTheme } from 'context/theme/ThemeContext'
import { useUi } from 'context/ui/UIContext'
import { IUser } from 'models/user/IUser'
import { NewCard } from 'pages/newCard/NewCard'
import React, { FC, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import UserApi from 'services/Api/user/UserApi'
import styles from './ExternalNewCardPage.module.scss'
export interface IExternalNewCardPageProps {
    //propertys
}
const ExternalNewCardPage: FC<IExternalNewCardPageProps> = () => {
    const { token, url } = useParams<{ token: string, url?: string, userId?: string }>();
    const { updateToken, isAuth, updateUser } = useAuth()
    const { toastFullScreen } = useUi();
    const { showLoading, hideLoading } = useUi()

    const { detectThemeByUrl } = useTheme();

    // useEffect(() => {
    //     window.addEventListener('message', (event: MessageEvent<{ user: IUser }>) => {
    //         updateUser(event.data.user);
    //     })
    // }, [updateUser])


    useEffect(() => {
        if (token) {
            updateToken(token)
        }
    }, [token, updateToken])


    const getUserData = useCallback(async () => {
        try {
            showLoading()
            const response = await UserApi().getUserData()
            updateUser(response as IUser)
        } finally {
            hideLoading()
        }
    }, [hideLoading, showLoading, updateUser])

    useEffect(() => {
        getUserData()
    }, [getUserData])

    const closeHandle = useCallback(() => {
        window.parent.postMessage({ command: "ADD_CARD_CANCEL" }, "*");
    }, [])

    const onConfirmHandle = useCallback(() => {
        window.parent.postMessage({ command: "ADD_CARD_SUCCESS" }, "*");
        toastFullScreen("Cartao adicionado com sucesso", "check", "success", 10000);
    }, [toastFullScreen])

    useEffect(() => {
        detectThemeByUrl(url)

        return () => {

        }
    }, [detectThemeByUrl, url])


    return (
        isAuth ?
            <div id={styles.ExternalNewCardPage} >
                <div className={styles.container} >
                    <NewCard onCancel={closeHandle} onConfirm={onConfirmHandle}></NewCard>
                </div>
            </div>
            :
            <div className={styles.container} >
                <CircularProgress></CircularProgress>
            </div>
    )
}
export default ExternalNewCardPage