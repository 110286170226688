import { ITrackSurveyWidget } from "models/surveys/ITrackSurveyWidget"
import api from "../Api"

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
const baseUrl = "https://127.0.0.1:7236"

const SurveyApi = () => {

    const getWdigetByLocalId = async (localId: string): Promise<ITrackSurveyWidget> => {
        // const token = localStorage.getItem(`@token`)
        const response = await api.get<ITrackSurveyWidget>(`${baseUrl}/api/customerSurveys/getMeepWidget/${localId}`)
        return response.data;
    }

    return ({ getWdigetByLocalId })
}
export default SurveyApi