import { Drawer, Icon } from '@material-ui/core';
import ButtonPickerAcordion from 'pages/public/schedule/components/buttonPickerAcordion/ButtonPickerAcordion';
import React, { FC, useState, memo, useEffect } from 'react'
import styles from "./TimeSelect.module.scss"

interface TimeSelectProps {
    times: { start: string, end: string, vacancies: number }[]
    value?: { start: string, end: string, vacancies: number }
    onChange?: (values: { start: string, end: string, vacancies: number }) => void
    localVacancies: number
}

const TimeSelect: FC<TimeSelectProps> = memo(({ times, value, onChange, localVacancies }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (times.length === 1) {
            onChange && onChange(times[0])
        }
    }, [times, onChange])
    return (
        <div id={styles.TimeSelect}>
            <ButtonPickerAcordion
                label={"Hora"} icon={"schedule"} selected={!!value} onClick={() => setOpen(true)} /*className={styles.container}*/
                value={
                    value ? `A partir de ${value.start.substr(0, 5)} até às ${(value.end.substr(0, 5))}`
                        :
                        "Selecione um horário"
                }
            />
            {
                value &&
                (<div className={styles.lastVacancies} ><Icon>error_outline</Icon> {localVacancies === 1 ? "Ultimas vagas disponíveis" : localVacancies === 0 ? "Não há vagas disponíveis" : "Ultimas vagas restantes"}</div>)
            }
            <Drawer anchor="bottom" open={open && times.length > 1} onClose={() => setOpen(false)}>
                <div className={styles.drawer}>
                    <h2> Selecione um horário </h2>
                    {times.map((item, index) => (
                        <div key={index} onClick={() => { onChange && onChange(item); setOpen(false) }} className={styles.timeItem}>{item.start}</div>
                    ))}
                </div>
            </Drawer>
        </div>
    )
})

export default TimeSelect
