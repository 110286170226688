export enum CouponValueType {
  percentage = 1,
  currency = 2,
}

export enum CoupomType {
  discount = 1,
  cashback = 2,
}

export enum CouponAppliedInType {
  order = 1,
  delivery = 2,
}

export interface ICoupon {
  id: string;
  couponId: string;
  name: string;
  description: string;
  code: string;
  value: number;
  locals: string[];
  finishAt?: Date;
  valueType: CouponValueType;
  type: CoupomType;
  maxValue?: number;
  minOrderValue?: number;
  appliedIn: CouponAppliedInType;
  createdAt?: string;
  imageUrl: string;
  startAt?: string;
  quantity?: number;
  rules: string[];
}

export interface IDiscount {
  id: string;
  name: string;
  value: number;
  valueType: CouponValueType;
}
