export enum StatusOrder {
    pendente = "Pendente",
    recusado = "Recusado",
    aceito = "Aceito",
    aceitoENaoImpresso = "aceitoSemImpressora",
    atraso = "Atraso",
    preparo = "Em preparo",
    transporte = "Em transporte",
    retirar = "Retirar no estabelecimento",
    entregue = "Entregue",
    devolvido = "Devolvido"
}
