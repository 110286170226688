import { Product } from "models/catalog/aggregates/Product";
import React, { createContext, FC, useContext } from "react";
import { Catalog } from "../../models/catalog/aggregates/Catalog";
import useCatalogContext from "./UseCatalogContext";

interface ICatalogContext {
  catalog: Catalog | undefined;
  onLoading?: boolean;
  getProductFromCatalog: (produtoId: string) => Product | undefined
  storesLengthRedirect: () => void
}

const CatalogContext = createContext<ICatalogContext>({} as ICatalogContext);

export const CatalogProvider: FC<{ children: React.ReactNode }> = ({ children }) => {

  const catalog = useCatalogContext();

  return (
    <CatalogContext.Provider value={catalog}>
      {children}
    </CatalogContext.Provider>
  );
};

export const useCatalog = () => {
  const context = useContext(CatalogContext);
  return context;
};
