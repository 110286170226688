import { IPaymentLinkResponse, PaymentTypeEnum, PaymentTypeForm } from "models/paymentLink/IPaymentLinkResponse";
import { IPixFormValues } from "./PixForm";

export interface IPayerErrors {
  PayerFirstName?: string;
  PayerLastName?: string;
  PayerDocument?: string;
  PayerEmail?: string;
  PayerPhone?: string;
}

const isFieldRequired = (paymentLink: IPaymentLinkResponse, fieldType: PaymentTypeForm): boolean => {
  const pixPaymentType = paymentLink.paymentTypes.find(
    (paymentType) => paymentType.type === PaymentTypeEnum.PIX
  );
  return pixPaymentType?.fields?.some((field) => field.type === fieldType) ?? false;
};

export const validate = (
  paymentLink: IPaymentLinkResponse,
  payer: IPixFormValues,
  setErrors: React.Dispatch<React.SetStateAction<IPayerErrors>>
) => {
  setErrors({
    PayerFirstName: "",
    PayerEmail: "",
    PayerDocument: "",
  });

  let validForm = true;

  if (isFieldRequired(paymentLink, PaymentTypeForm.NAME)) {
    if (!payer.PayerFirstName || payer.PayerFirstName.length < 3) {
      setErrors((prev) => ({ ...prev, PayerFirstName: "Nome obrigatório" }));
      validForm = false;
    }
  }

  if (isFieldRequired(paymentLink, PaymentTypeForm.DOCUMENT)) {
    if (!payer.PayerDocument || payer.PayerDocument.length < 14) {
      setErrors((prev) => ({ ...prev, PayerDocument: "CPF obrigatório" }));
      validForm = false;
    }
  }

  const validEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (isFieldRequired(paymentLink, PaymentTypeForm.EMAIL)) {
    if (!payer.PayerEmail) {
      setErrors((prev) => ({ ...prev, PayerEmail: "Email obrigatório" }));
      validForm = false;
    } else if (!validEmail.test(payer.PayerEmail)) {
      setErrors((prev) => ({ ...prev, PayerEmail: "Email inválido" }));
      validForm = false;
    }
  }

  return validForm;
};
