import { Button } from '@material-ui/core'
import React, { FC } from 'react'
import styles from './ConfirmPlace.module.scss'
export interface IConfirmPlaceProps {
    //propertys
    onClickConfirmar: () => void,
    onClickVoltar: () => void,
    table?: string;
}
const ConfirmPlace: FC<IConfirmPlaceProps> = ({ onClickConfirmar, onClickVoltar, table }) => {
    return (
        <div id={styles.ConfirmPlace} >
            <h2 >
                Confirmação de local
            </h2>
            <span  >
                Você confirma que o pedido realizado é referente a <b>{table}</b>
            </span>
            <Button color='primary' variant='contained' onClick={onClickConfirmar}>Confirmar</Button>
            <Button color='primary' variant='outlined' onClick={onClickVoltar}>Voltar</Button>
        </div>
    )
}
export default ConfirmPlace