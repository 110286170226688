import { Button, CircularProgress, TextField } from '@material-ui/core'
import React, { FC, useState } from 'react'
import './SecurityCodeInput.css'


interface ISecurityCodeInput {
    onSubmit: (securityCode: string) => void
    loading?: boolean
    isPassword?: boolean
}
export const SecurityCodeInput: FC<ISecurityCodeInput> = ({ onSubmit, loading, isPassword }) => {
    const [securityCode, setsecurityCode] = useState("");
    const onsubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!loading) {
            onSubmit(securityCode);
        }
    }


    return (
        <div id="SecurityCodeInput">
            <form onSubmit={onsubmit}>
                <div className="container">
                    <div className="content">
                        <div className="description">
                            {
                                isPassword 
                                    ? "Inserir senha Wallet" 
                                    : "Insira o código de segurança do cartão selecionado"
                            }
                    </div>
                        <div className="inputContainer">
                            <TextField
                                autoFocus
                                disabled={loading}
                                type={isPassword ? "password" : "text"}
                                inputProps={{ style: { fontSize: 38, textAlign: "center" }, inputMode: 'numeric' }}
                                value={securityCode}
                                onChange={(e) => setsecurityCode(e.target.value.substr(0, isPassword ? 4 : 3).replace("-", "").replace(".", ""))}
                            ></TextField>
                        </div>
                    </div>
                    <div className="buttonContainer">
                        <Button disabled={securityCode.length < (isPassword ? 4 : 3)} size="large" color="primary" variant="contained" fullWidth type="submit">
                            {!loading ?
                                "Confirmar Pagamento" :
                                <CircularProgress size={26} style={{ color: "#fff" }} />
                            }   </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}
