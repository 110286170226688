import Layout from 'components/layout/Layout'
import React, { FC } from 'react'
import styles from './ProductsTourPage.module.scss'
import UseProductsTourPage from './UseProductsTourPage'
import CartTour from '../../components/cartTour/CartTour'
import ProductListTour from 'pages/public/catalog/products/components/productListTour/_ProductTourList'

export interface IProductsTourPageProps {
    //propertys
}

const ProductsTourPage: FC<IProductsTourPageProps> = () => {
    const { productsTour, onClickProductHandle, onClickBackHandle } = UseProductsTourPage();

    return (
        <Layout enableDescriptionImage onClickBack={onClickBackHandle}>
            <div id={styles.ProductsTourPage}>
                <div className={styles.container}>
                    <ProductListTour
                        seccionedProduct={productsTour.filter(item => !!item.products.length)}
                        simpleTitle
                        onClickProduct={onClickProductHandle}
                    />
                </div>
            </div>
            <CartTour />
        </Layout>
    )
}
export default ProductsTourPage