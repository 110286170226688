import React, { FC } from 'react'
import { Box } from '../../../components/box/Box'
import { IMeepCardData } from '../../../models/meepCard/ILoginMeepCard'
import styles from './MeepCardResume.module.scss'

interface MeepCardResumeProps {
    cardData?: IMeepCardData
}

const MeepCardResume: FC<MeepCardResumeProps> = ({ cardData }) => {
    return (
        <Box loading={!cardData}>
            {
                cardData &&
                <div className={styles.container}>
                    <div className={styles.item}>
                        <div className={styles.label}>
                            Nome
                    </div>
                        <div className={styles.value}>
                            {cardData.holderName}
                        </div>
                    </div>
                    {cardData.dailyLimit < cardData.balance &&
                        <div className={styles.item}>
                            <div className={styles.label}>
                                Saldo Diario
                        </div>
                            <div className={styles.value}>
                                {cardData.dailyLimit}
                            </div>
                        </div>}
                    <div className={styles.item}>
                        <div className={styles.label}>
                            Saldo
                    </div>
                        <div className={styles.value}>
                            R${cardData.balance.toFixed(2).replace(".",",")}
                        </div>
                    </div>

                </div>
            }
        </Box>
    )
}

export default MeepCardResume
