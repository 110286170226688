import React, { FC } from "react";
import styles from "./Button.module.scss";
import { Button as ButtomMaterial } from "@material-ui/core";
export interface IButtonProps {
  //propertys
  id?: string;
  fullWidth?: boolean;
  variant?: "outlined" | "text" | "contained";
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  className?: string;
  onClick?: () => void;
  autoFocus?: boolean;
  href?: string;
  color?: "primary" | "secondary";
  children?: React.ReactNode;
}
const Button: FC<IButtonProps> = ({
  children,
  fullWidth = true,
  variant = "contained",
  disabled,
  type,
  className,
  onClick,
  autoFocus,
  id,
  href,
  color = "primary"
}) => {
  return (
    <ButtomMaterial
      id={id}
      type={type}
      disabled={disabled}
      variant={variant}
      fullWidth={fullWidth}
      onClick={onClick}
      autoFocus={autoFocus}
      href={href}
      classes={{
        root: styles.container,
        contained: styles.botao,
        outlined: styles.botaoOutlined,
        text: styles.botaoText,
        disabled: styles.botaoDisabled,
      }}
      className={className}
      color={color}
    >
      {children}
    </ButtomMaterial>
  );
};
export default Button;
