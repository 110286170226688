import React, { FC } from 'react'
import { IOrderInProgressProduct } from '../../../../../../models/order/aggregates/progress/IInProgressOrder'
import "./ProductItemDetail.css"
interface IProductDetailsProps {
    productDetail: IOrderInProgressProduct
}
export const ProductItemDetail: FC<IProductDetailsProps> = ({ productDetail }) => {
    return (
        <div id="productDetails">
            <div className="container">
                <div className="quantidade">
                    {productDetail.quantity}
                </div>
                <div className="name">
                    {productDetail.name}
                </div>
            </div>
            <div className="total">
                R${productDetail.total.toFixed(2)}
            </div>
        </div>
    )
}
