import { useAuth } from 'context/auth/AuthContext'
import { useLocal } from 'context/local/LocalContext'
import { useParamsConfig } from 'context/paramsConfig/ParamsConfigContext'
import { UseMercadoPago } from 'context/payment/UseMercadoPago'
import { useUi } from 'context/ui/UIContext'
import { ICardItem } from 'models/payment/ICard'
import { PaymentType } from 'models/payment/IPay'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { GetServiceDetailsByServiceIdUseCase } from '../../../application/useCases/GetServiceDetailsByServiceIdUseCase'
import { PostReserveTourScheduleUseCase } from '../../../application/useCases/PostReserveTourScheduleUseCase'
import { AddPlaceDemandScheduleWithPaymentRequest } from '../../../_domain/dto/ReservePlaceSchedule/IAddPlaceDemandScheduleWithPaymentRequest'
import { IFormScheduleTour } from '../../pages/formTour/interface/IFormScheduleTour'
import { IPlaceScheduleFormSchedule } from '../../pages/formTour/interface/IPlaceScheduleFormSchedule'
import { IServiceScheduleForm } from '../../pages/formTour/interface/IServiceScheduleForm'
import { IMoreDetails } from '../../../application/interfaces/IMoreDetails'
import { Parcela } from '../installmentSelect/InstallmentSelect'

const UseScheduleTourPayment = (values: IFormScheduleTour, service: IServiceScheduleForm, placeSchedule: IPlaceScheduleFormSchedule) => {
    const [localTour, setlocalTour] = useState<IMoreDetails>()
    const [paymentType, setpaymentType] = useState<PaymentType>(0);
    const [card, setCard] = useState<ICardItem | null>(null);
    const [totalValue, setTotalValue] = useState(0);

    const { serviceId } = useParams<{ serviceId: string; }>();

    const { localId, storeConfig } = useLocal();

    const { user } = useAuth()

    const { queryParams } = useParamsConfig()

    const { toastFullScreen, showLoading, hideLoading } = useUi();

    const { deviceIdMercadoPago } = UseMercadoPago();

    const { url } = useParams<{ url: string }>()

    const { replace } = useHistory();

    const [installments, setInstallments] = useState<Parcela>();


    useEffect(() => {
        const total = values.customers.map(item => item.value?.value ?? 0).reduce((prev, current) => (prev + current), 0)
        setTotalValue(total)

    }, [values])

    useEffect(() => {
        if (serviceId) {
            showLoading();
            GetServiceDetailsByServiceIdUseCase(serviceId)
                .then((response) => {
                    setlocalTour(response);
                })
                .finally(() => {
                    hideLoading();
                })
        }
    }, [serviceId])

    const sendScheduleTour = useCallback((
        cvv: string,
    ) => {
        //

        if (localId && card) {

            const request: AddPlaceDemandScheduleWithPaymentRequest = {
                localId: localId,
                creditCardId: card?.id,
                quantity: 1,
                cvv: cvv,
                operatorId: queryParams?.operatorId ?? "",
                fingerprint: deviceIdMercadoPago,
                userId: user.id,
                installments: installments?.parcela ?? 1,
                services: values.customers.map((customerItem) => ({
                    serviceScheduledId: placeSchedule.serviceScheduledId,
                    start: values.date ?? "",
                    listProductId: customerItem.value?.listId ?? "",//TODO: REVER
                    client: {
                        name: customerItem.name,
                        document: customerItem.document,
                        rg: customerItem.rg,
                        note: customerItem.note,
                        phone: customerItem.phone,
                        birthDate: customerItem.birthDate,
                    }
                }))
            }
            showLoading();
            PostReserveTourScheduleUseCase(request).then(() => {
                toastFullScreen("Reserva Realizada com Sucesso", "check", "success", 3000, () => { replace(`/${url}/schedule/reserves`) });
                replace("")
            }).finally(() => {
                hideLoading()
            })
        }
    },
        [localId, card, queryParams, deviceIdMercadoPago, user, values, placeSchedule, url],
    )

    return ({
        paymentType,
        setpaymentType,
        localTour,
        storeConfig: storeConfig ? {
            ...storeConfig,
            allowOnlinePayment: true,
            allowPaymentOnDelivery: false,
            disableAutenticateCard: true,
        } : null,
        card,
        setCard,
        sendScheduleTour,
        totalValue,
        installments,
        setInstallments
    })
}

export default UseScheduleTourPayment