import { ICartItem } from '../models/ICart';

export const CalcTotalsCartUseCase = (prevCartItems: ICartItem[]) => {

    const calcTotals = (_prevCartItems: ICartItem[]) => {
        const totalPrice = _prevCartItems.map(item =>  item.totalPrice).reduce((acumulado, atual) => atual + acumulado, 0);
        const totalQuantity = _prevCartItems.map(item => item.quantity).reduce((acumulado, atual) => atual + acumulado, 0);
        return { totalQuantity: totalQuantity, totalPrice: totalPrice };
    };

    return calcTotals(prevCartItems);
}
