import { debounce } from '@material-ui/core'
import React, { createRef, forwardRef, RefObject, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { IProductItem } from './interfaces/IProductItem'
import { ISeccionedProduct } from './interfaces/ISeccionedProduct'
import ProductItem from './productItem/ProductItem'
import styles from './ProductList.module.scss'

export interface IProductListProps {
    sectionedProducts: ISeccionedProduct[];
    onChangeCategory?: (index: number) => void;
    offset?: number;
    onClickProduct: (product: IProductItem) => void
    simpleTitle?: boolean,
    prefixPrice?: string
}

export interface IProductListRef {
    goToCagetory: (index: number) => void;
}

const ProductList = forwardRef<IProductListRef, IProductListProps>(({ sectionedProducts, onChangeCategory, offset = 50, onClickProduct, simpleTitle, prefixPrice }, ref) => {
    const [refDiv, setRefDiv] = useState<RefObject<HTMLDivElement>[]>([]);

    useEffect(() => {
        let refs = sectionedProducts.map((_, index) => { return createRef<HTMLDivElement>() });
        setRefDiv(refs);
    }, [sectionedProducts])

    const verificarPosicoes = useCallback(() => {
        window.addEventListener("scroll", debounce(() => {
            refDiv.map((_ref, index) => {
                let last = refDiv[index]?.current?.offsetTop ?? 0
                let next = refDiv[index + 1]?.current?.offsetTop ?? 0
                if (window.pageYOffset >= last && window.pageYOffset < next) {
                    onChangeCategory && onChangeCategory(index)
                }
            })
        }, 100))
    }, [onChangeCategory, refDiv])

    useEffect(() => {
        if (!!refDiv.length) {
            verificarPosicoes();
        }
        return () => {
        }
    }, [refDiv, verificarPosicoes]);

    const goToCagetory = useCallback(
        (index: number) => {
            if (refDiv.length > 0) {
                let _ref = refDiv[index].current
                if (_ref) {
                    window.scrollTo(0, _ref.offsetTop + offset)
                }
            }
        },
        [refDiv, offset],
    )

    useImperativeHandle(ref, () => {
        return {
            goToCagetory
        }
    }, [goToCagetory])

    return (
        <div id={styles.ProductList} >
            <div className={styles.container} >
                {
                    sectionedProducts.map((section, index) => (
                        !!section.products.length && <div ref={refDiv[index]} className={styles.section} key={section.id}>
                            <div className={styles.title}>
                                {section.title.toLowerCase()}
                            </div>

                            <div className={styles.list}>
                                {section.products.map((product) =>
                                    <ProductItem key={product.id} prefixPrice={prefixPrice} onClick={() => onClickProduct(product)} product={product}></ProductItem>
                                )}
                            </div>
                        </div>
                    ))
                }

                {sectionedProducts.length === 0 && <div className={styles.emptyListFallback}>Não encontramos resultados para sua busca. Que tal tentar outro produto?</div>}
            </div>
        </div>
    )
})

export default ProductList