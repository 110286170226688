import { Icon } from '@material-ui/core'
import React, { FC } from 'react'
import './StatusItem.css'

export enum StatusState {
    disabled = 1,
    enabled,
    concluded,
    hidden,
    canceled,
}


interface StatusItemProps {
    state: StatusState,
    index: number;
    children?: React.ReactNode;
}
export const StatusItem: FC<StatusItemProps> = ({ state, children, index }) => {
    return (
        <div id="statusItem" style={{ display: state === StatusState.hidden ? "none" : "flex" }}>

            {state === StatusState.disabled &&
                <div className={"stateIconStatusItem"} style={{ backgroundColor: "#aaa" }}>
                    {index}
                </div>
            }
            {state === StatusState.enabled &&
                <div className={"stateIconStatusItem"} style={{ backgroundColor: "#0a7" }}>
                    <Icon>
                        check
                    </Icon>
                </div>
            }
            {state === StatusState.concluded &&
                <div className={"stateIconStatusItem"} style={{ backgroundColor: "#0a7a" }}>
                    <Icon>
                        check
                    </Icon>
                </div>
            }
            {state === StatusState.canceled &&
                <div className={"stateIconStatusItem"} style={{ backgroundColor: "#f55" }}>
                    <Icon>
                        close
                    </Icon>
                </div>
            }
            {children}
        </div>
    )
}
