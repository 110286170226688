import { PaymentStatus } from "models/payment/PaymentStatus"
import { IConfirmarPagamento, IConfirmarPagamentoResponse } from "../../../models/payment/IPaymentApi"
import { IPayOrRecharge } from "../../../models/payment/IPayOrRecharge"
import api from "../Api"
import { IGetAddressResponse } from "models/paymentLink/IGetAddressResponse"

export const PaymentApi = () => {
    const confirmarPagamentoPedido = async (paymentRequest: IConfirmarPagamento) => {
        const response = await api.post<IConfirmarPagamentoResponse>("/vendas/confirmarPagamento", paymentRequest)
        return response
    }
    const confirmarPagamentoPedidoMeepCard = async (paymentRequest: IConfirmarPagamento) => {
        const response = await api.post<IConfirmarPagamentoResponse>("/vendas/confirmarPagamento", paymentRequest, { headers: { Authorization: "" } })
        return response
    }
    const payOrRecharge = async (payOrRecharge: IPayOrRecharge) => {
        const response = await api.post("/SuperApp/LiveUser/PayOrRecharge", payOrRecharge)
        return response
    }
    const getPaymentStatus = async (orderId: string): Promise<{ id: PaymentStatus }> => {
        const response = await api.get<{ Id: PaymentStatus }>("/PedidoPOS/PaymentStatus/" + orderId)
        return { id: response.data.Id }
    }
    const getAddress = async (postalAddressCode: string): Promise<IGetAddressResponse> => {
        const response = await api.get<IGetAddressResponse>("/address/postal-address-code/" + postalAddressCode)
        return response.data
    }


    return { confirmarPagamentoPedido, payOrRecharge, confirmarPagamentoPedidoMeepCard, getPaymentStatus, getAddress }
}