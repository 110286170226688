import React, { FC } from 'react'
import styles from './ProductTourItem.module.scss'
import Image from '../../../../../../../components/ui/_image/Image'
import { IProductItem } from '../interfaces/IProductItem'
import { Favorite, KeyboardArrowRight, Star } from '@material-ui/icons'
import Medal from 'assets/icons/medal.svg'

export interface IProductItemProps {
    product: IProductItem,
    onClick: () => void,
    prefixPrice?: string
}
const ProductTourItem: FC<IProductItemProps> = ({ product, onClick, prefixPrice }) => {

    return (
        <div onClick={onClick} id={styles.ProductItem} >
            <div className={styles.container} >
                <div className={styles.image} >
                    <Image src={product.thumbnailUrl} className={styles.img}></Image>
                </div>
                <div className={styles.content} >
                    <div className={styles.containerCard}>
                        <div className={styles.headerCard}>
                            <div className={styles.name} >
                                <h3>{product.name}</h3>
                            </div>
                            <div className={styles.price} >
                                <span>a partir de</span>
                                <h4>
                                    {prefixPrice}
                                    R${product.price.toFixed(2).replace(".", ",")}</h4>
                            </div>
                        </div>
                        <div className={styles.description}>
                            <p>{product.description}</p>
                        </div>
                    </div>
                    {
                        product.id === "72c36fe2-358e-4f73-9ce9-ea185d25ad1e" ?
                            <div className={styles.footerCard}>
                                <div className={styles.favorite}>
                                    <img src={Medal} alt="Medalha de mais vendido" />
                                    <p>Mais vendido</p>
                                </div>
                                <div className={styles.viewMore} >
                                    <p>Ver mais</p>
                                    <KeyboardArrowRight />
                                </div>
                            </div>
                            :
                            product.id === '8a17f4d2-3aef-4e11-8530-c057abe5f551' ?
                                <div className={styles.footerCard}>
                                    <div className={styles.favorite}>
                                        <Favorite />
                                        <p>Mais procurado</p>
                                    </div>
                                    <div className={styles.viewMore} >
                                        <p>Ver mais</p>
                                        <KeyboardArrowRight />
                                    </div>
                                </div>
                                :
                                product.id === '1debb996-0c1c-4942-851f-f990900dc2ae' ?
                                    <div className={styles.footerCard}>
                                        <div className={styles.favorite}>
                                            <Star />
                                            <p>Destaque</p>
                                        </div>
                                        <div className={styles.viewMore} >
                                            <p>Ver mais</p>
                                            <KeyboardArrowRight />
                                        </div>
                                    </div>
                                    :
                                    <div className={styles.viewMoreOnly} >
                                        <p>Ver mais</p>
                                        <KeyboardArrowRight />
                                    </div>
                    }

                </div>

            </div>
        </div>
    )
}
export default ProductTourItem