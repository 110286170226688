import { CardBrandType } from "models/payment/ICard";

export interface IPaymentLinkResponse {
  id: string;
  orderId?: string;
  productId: string;
  localId: string;
  description: string;
  successMessage: string;
  price: number;
  expirationDate: string;
  maximumInstallments: number;
  allowPaymentSlip: boolean;
  isVariable?: boolean;
  product: IPaymentLinkProduct;
  name: string;
  cardBrandsInstallments: ICardBrandsInstallments[];
  interestPerInstallment: number;
  paymentTypes: IPaymentType[];
  paymentDay: string;
  isSinglePayment: boolean;
  billingStartsInCurrentMonth: boolean;
  isRecurrenceLimit: boolean;
  amountOfCharges: number;
}
export interface IPaymentLinkProduct {
  id: string;
  nome: string;
  valor: number;
}

export interface ICardBrandsInstallments {
  brandType: number;
  installments: IPaymentLinkInstallments[];
}

export interface IPaymentLinkInstallments {
  installment: number;
  description: string;
  price: number;
}

export interface IPaymentType {
  type: PaymentTypeEnum;
  name: string;
  fields: IFields[];
}

export interface IFields {
  type?: PaymentTypeForm;  // Agora usa o enum
  required?: boolean;
}
export enum PaymentTypeEnum {
  CREDIT = 1,
  TICKET = 9,
  PIX = 18,
}

export enum PaymentTypeForm {
  DOCUMENT = 1,
  NAME = 2,
  PHONE = 3,
  EMAIL = 4,
  ADDRESS = 5,
}

export interface PixPaymentResponse {
  paymentId: string;
  qrCodeBase64: string;
  qrCodeLink: string;
  ownerId: string;
  status: PixStatus;
  value: number;
  createdAt?: string;
  approvedAt?: Date;
  acquirerType?: number;
}

export type PixStatus = "approved" | "cancelled" | "pending";

export interface PixPaymentRequest {
  Value: number;
  PixPaymentOrderId: string;
  LocalClientId: string;
}

export interface TicketRequest {
  OwnerId: string;
  ProductId: string;
  TransactionAmount: number;
  Description: string;
  PayerFirstName: string;
  PayerLastName: string;
  PayerDocumentNumber: string;
  PayerEmail: string;
  PayerAddress: {
    postalCode?: string;
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: string;
    state: string;
    description: string;
  }

}

export type TicketStatus = PixStatus;

export interface TicketPostResponse {
  BoletoId: string;
  MeepPedidoPOSId: string;
  Url: string;
  Valor: number;
  Erro: any;
  DataExpiracao: string;
  TransactionGateway: any;
}

export interface TicketGetResponse {
  Id: string;
  Amount: number;
  Expiration: string;
  Status: TicketStatus;
  BarCode: string;
  Url: string;
}
