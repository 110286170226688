import React, { useCallback, useEffect, useMemo } from "react";
import { Container, Dialog, Drawer, Grid } from "@material-ui/core";
import { InfoOutlined } from '@material-ui/icons'
import { ResumeView } from "./resume/ResumeView";
import { DeliverySelect } from "./deliverySelect/DeliverySelect";
import { PaymentSelect } from "./PaymentSelect/PaymentSelect";
import { usePayment } from "../../context/payment/PaymentContext";
import { DeliveryAddressItem } from "./deliverySelect/deliveryAddressItem/DeliveryAddressItem";
import { useParamsConfig } from "../../context/paramsConfig/ParamsConfigContext";
import { PrivatePage } from "pages/private/privateLayout/PrivatePage";
import { useHistory, useParams } from "react-router-dom";
import CouponInput from "./coupon/CouponInput";
import PixPage from "./pixPage/PixPage";
import ConfirmPlace from "./confirmPlace/ConfirmPlace";
import { PaymentType } from "models/payment/IPay";
import styles from './PaymentPage.module.scss';

export const PaymentPage = () => {
  const {
    confirmarPagamento,
    orderDetail,
    setPaymentType,
    card,
    deliveryType,
    deliveryFee,
    paymentType,
    setChange,
    change,
    setInstallment,
    installment,
    setCard,
    storeConfig,
    setAddress,
    address,
    setDeliveryType,
    setCustomDeliveryDescription,
    customDeliveryDescription,
    orderPads,
    setSelectedOrderPad,
    selectedOrderPad,
    selectedCoupon,
    isLoadingCoupon,
    activateCoupon,
    setSelectedCoupon,
    coupons,
    serviceFee,
    setAcceptServiceFee,
    pixValues,
    enviarMensagemDeConfirmacao,
    confimPlaceModal,
    onClickConfirmarAndOpenPlaceAlert,
    closePlaceAlert,
    setWallet,
  } = usePayment();

  useEffect(() => {
    window.scrollTo({ top: Number.MAX_SAFE_INTEGER, behavior: "smooth" });
    return () => { };
  }, [setPaymentType, card, deliveryType]);

  const { queryParams } = useParamsConfig();
  const { url } = useParams<{ url: string }>();
  const { replace } = useHistory();
  const onClickBack = useCallback(() => {
    replace(`/${url}`);
  }, [replace, url]);

  const localStore = useMemo(() => localStorage.getItem('@storagePlace-' + storeConfig?.storeId), [storeConfig])
  return (
    <PrivatePage
      title={"Pagamento"}
      onClickBack={!!url ? onClickBack : undefined}
      style={{ height: '100%' }}
    >
      <Container style={{ backgroundColor: "var(--background)", flex: 1 }}>
        {orderDetail ? (
          <Grid
            container
            style={{ textAlign: "center", margin: "auto", paddingBottom: 80 }}
          >
            <Grid item xs={12}>
              <ResumeView orderDetail={orderDetail} deliveryFee={deliveryFee} setAcceptServiceFee={setAcceptServiceFee} coupon={selectedCoupon} serviceFee={serviceFee} />
            </Grid>

            <Grid item xs={12}>
              {queryParams?.queryPlace || localStore ? (
                <>
                  <div>Local de Entrega</div>
                  <DeliveryAddressItem>
                    <div>{queryParams?.queryDescription && queryParams?.queryDescription !== "" ? queryParams?.queryDescription : storeConfig?.customDeliveryDescription}</div>
                    <div style={{ fontSize: "large", fontWeight: 550 }}>
                      {queryParams?.queryPlace || localStore}
                    </div>
                  </DeliveryAddressItem>
                </>
              ) : (
                <DeliverySelect
                  title={"Como deseja receber seu pedido?"}
                  setAddress={setAddress}
                  address={address}
                  storeConfig={storeConfig}
                  setDeliveryType={setDeliveryType}
                  deliveryType={deliveryType}
                  setCustomDeliveryDescription={setCustomDeliveryDescription}
                  customDeliveryDescription={customDeliveryDescription}
                  localOrderPads={orderPads}
                  selectedOrderPad={selectedOrderPad}
                  onChangeSelectedOrderPads={setSelectedOrderPad}
                  // defaultOrderPadNumber={queryParams?.queryPlace}
                  fixedPlace={!!queryParams?.queryPlace}
                />
              )}
            </Grid>

            {orderDetail.amount > 0 && (
              <>
                <Grid item xs={12}>
                  <PaymentSelect
                    orderDetail={orderDetail}
                    setPaymentType={setPaymentType}
                    paymentType={paymentType}
                    setChange={setChange}
                    change={change}
                    setInstallment={setInstallment}
                    installment={installment}
                    card={card}
                    setCard={setCard}
                    storeConfig={storeConfig}
                    setWallet={setWallet}
                  />

                </Grid>
                {
                  (paymentType === PaymentType.CREDITO || paymentType === PaymentType.DEBITO || paymentType === PaymentType.WALLET) &&
                  <Grid item xs={12}>
                    <CouponInput
                      onlinePayment={!!card}
                      paymentType={paymentType}
                      total={orderDetail.amount}
                      selectedCoupon={selectedCoupon}
                      coupons={coupons}
                      onSubmit={activateCoupon}
                      isLoading={isLoadingCoupon}
                      onSelectCoupon={setSelectedCoupon}
                    />
                  </Grid>
                }

                <p className={styles.info}><InfoOutlined /> Para realizar o cancelamento do consumo entre em contato com o estabelecimento.</p>

                <button
                  onClick={onClickConfirmarAndOpenPlaceAlert}
                  className={styles.submitButton}
                >
                  Finalizar Pagamento
                </button>
              </>
            )}
          </Grid>
        ) : (
          <div />
        )}
      </Container>
      <Drawer anchor="bottom" open={!!pixValues} >
        {pixValues && orderDetail?.id &&
          <PixPage
            data={pixValues}
            orderId={orderDetail?.id}
            total={orderDetail.amount}
            onPaymentSuccess={enviarMensagemDeConfirmacao}
          />}
      </Drawer>
      <Dialog open={confimPlaceModal}>
        <ConfirmPlace
          onClickConfirmar={confirmarPagamento}
          onClickVoltar={closePlaceAlert}
          table={selectedOrderPad?.number} />
      </Dialog>
    </PrivatePage>
  );
};
