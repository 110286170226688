import { Icon } from '@material-ui/core'
import { IAppointments } from 'pages/public/schedule/interfaces/IScheduled'
import React, { FC } from 'react'
import styles from './ScheduledItem.module.scss'
import QRCode from "qrcode.react";
export interface IScheduledItemProps {
    //propertys
    appointment: IAppointments
}
const ScheduledItem: FC<IScheduledItemProps> = ({ appointment }) => {
    return (
        <div id={styles.ScheduledItem} >
            <div className={styles.container} >
                <div className={styles.top} >
                    <Icon className={styles.icon}>event</Icon>
                    <div className={styles.date} >
                        {new Date(appointment.schedule.date).toLocaleDateString("pt-BR")}
                    </div>
                </div>
                <div className={styles.content} >
                    {appointment.store.name && <div>{appointment.store.name}</div>}
                    {appointment.schedule.environment && <div>{appointment.schedule.environment}</div>}
                    {appointment.schedule.peopleAmount && <div>{appointment.schedule.peopleAmount}</div>}
                    {appointment.schedule.table && <div> {appointment.schedule.table}</div>}
                    {/* {appointment.schedule.professional && <div>{appointment.schedule.professional}</div>} */}
                    {<div><b>Nome: </b>{appointment.client.name}</div>}
                    {<div><b>Documento: </b>{appointment.client.document}</div>}
                    {<div><b>Criado por: </b>{appointment.createdBy.name}</div>}
                    {appointment.schedule.procedure && <div>{appointment.schedule.procedure}</div>}
                    {appointment.schedule.unity && <div>{appointment.schedule.unity}</div>}
                    {appointment.schedule.value && <div>{appointment.schedule.value?.toFixed(2).replace(".", ",")}</div>}
                    <div className={styles.qrCodeContainer} >
                        <QRCode size={120} style={{ margin: 16 }} value={appointment.schedule.id.replace(/[^0-9a-zA-Zs]/g, "")} />
                    </div>
                </div>
            </div>
        </div >
    )
}
export default ScheduledItem