import { ICartItemTour } from "../../presentation/interfaces/ICartTour";
import { AddPlaceDemandScheduleWithPaymentRequest } from "../../_domain/dto/ReservePlaceSchedule/IAddPlaceDemandScheduleWithPaymentRequest";

const CartTourToAddDemandWithPaymentDTOUseCase = (localId: string, cardId: string, deviceIdMercadoPago: string, userId: string, cartItems: ICartItemTour[], installments?: number, operatorId?: string) => {
    const request: AddPlaceDemandScheduleWithPaymentRequest = {
        localId: localId,
        creditCardId: cardId,
        quantity: 1,
        cvv: "",
        operatorId: operatorId ?? "",
        fingerprint: deviceIdMercadoPago,
        userId: userId,
        installments: installments ?? 1,
        services: cartItems.map((cartItem) => (cartItem.customers.map((customerItem) => ({
            serviceScheduledId: cartItem.serviceScheduledId,
            start: cartItem.startDate ?? "",
            listProductId: customerItem.value?.listId ?? "",
            client: {
                name: customerItem.name,
                document: customerItem.document,
                rg: customerItem.rg,
                note: customerItem.note,
                phone: customerItem.phone,
                birthDate: customerItem.birthDate,
            }
        })))).flatMap(item => item)
    };
    return (request);
};
export default CartTourToAddDemandWithPaymentDTOUseCase;
