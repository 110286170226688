import { ITheme } from "../interfaces/ITheme";

export const MeepTheme: ITheme = {
  backgroundColor: "#FFF",
  secondaryBackground: "#F0F0F0",
  text: "#222",
  primaryDark: "#001537",
  primaryColor: "#32008E",
  secondaryColor: "#955CFF",
  accentColor: "#FF1744",
};

export const PalmeirasTheme: ITheme = {
  backgroundColor: "#fff",
  text: "#222",
  primaryColor: "#000",
  secondaryColor: "rgb(0,100,55)",
  accentColor: "rgb(0,100,55)",
  topImage: "https://meepblob.blob.core.windows.net/clientes-profile/f96c1dbc-46dd-77ee-fe76-2e0040392c1f.png",
};

export const FoodHallDadoBierTheme: ITheme = {
  backgroundColor: "#222",
  text: "#ddd",
  primaryColor: "#f00",
  secondaryColor: "#f00",
  accentColor: "#f00",
  topImage: "/img/dado-bg.png",
  type: `dark`
};

export const ArenaMRVTheme: ITheme = {
  backgroundColor: "#242424",
  text: "#888",
  primaryDark: "#F8DA13",
  primaryColor: "#F8DA13",
  secondaryColor: "#000",
  accentColor: "#F8DA13",
  type: `dark`
  // backgroundImageUrl: '/img/arena-mrv-bg.jpeg'
};

export const MineiraoTheme: ITheme = {
  backgroundColor: "#fff",
  text: "#222",
  primaryColor: "#471e6b",
  secondaryColor: "#471e6b",
  accentColor: "#471e6b",
  backgroundImageUrl: '/img/purple-bg.png'
};

export const OutLandTheme: ITheme = {
  backgroundColor: "#fff",
  text: "#222",
  primaryColor: "#083847",
  secondaryColor: "#083847",
  accentColor: "#083847",
};

export const Minastenisclube: ITheme = {
  backgroundColor: "#fff",
  text: "#222",
  primaryColor: "#2252A6",
  secondaryColor: "#2252A6",
  accentColor: "#2252A6",
};


/**
 *
 * background: #242424

título: #F5F5F5

label: #E0E0E0

button bg: #F8DA13

button txt: #000000
 */

// colors: {
//   primary: "",
//   secondary: "#f00",
//   background: "#222",
//   text: "#ddd",
//   toolbar: "#f00",
// },
// topBackgroundUrl: "/imgs/dado/top-bg.png",
// hideLogo: true,
// type: "dark",