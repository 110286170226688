import React, { FC } from 'react'
import localImg from "../img/Estabelecimento.svg"
import clientImg from "../img/Casa.svg"
import currentImg from "../img/Moto.svg"
import "./Marker.scss"

interface IMarkerProps {
    icon?: string
    lat: number,
    lng: number,
    text: string,
    type: "current" | "client" | "local"
}

export const Marker: FC<IMarkerProps> = ({ icon, lat, lng, text, type }) => {
    return (
        <div className="marker">
            {
                type === 'current' &&
                <img alt="" src={currentImg} />
            }
            {
                type === 'client' &&
                <img alt="" src={clientImg} />
            }
            {
                type === 'local' &&
                <img alt="" src={localImg} />
            }
            {/* <Icon fontSize={"large"} color={"primary"}>{icon ?? "place"}</Icon> */}
            <div>{text}</div>
        </div>
    )
}
