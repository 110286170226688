
import { Button, Icon } from '@material-ui/core'
import AlertApp from 'models/alertApp/AlertApp'
import React, { FC } from 'react'
import ConquistasProfile from '../components/_conquistasProfile/ConquistasProfile'
import InteressesProfile from '../components/_interessesProfile/InteressesProfile'
import PreviewPhotosProfile from '../components/previewPhotosProfile/PreviewPhotosProfile'
import ProfileHeader from '../components/ProfileHeader/ProfileHeader'
import styles from './ProfilePage.module.scss'
import { UsePhoto } from './UsePhoto'
import UseProfilePage from './UseProfilePage'
export interface IProfilePageProps {
    //propertys
}
const ProfilePage: FC<IProfilePageProps> = () => {

    const { profile } = UseProfilePage();
    const { photos } = UsePhoto();

    return (
        <div id={styles.ProfilePage} >
            <AlertApp></AlertApp>
            {profile &&
                <div className={styles.container} >
                    <ProfileHeader profile={profile} />
                    <ConquistasProfile />
                    <PreviewPhotosProfile photos={photos} />
                    <InteressesProfile />

                    <div className={styles.buttonContainer} >
                        <Button color="primary" variant="contained" size="large" className={styles.button} fullWidth ><Icon>send</Icon>Envie um presente!</Button>
                    </div>
                </div>
            }
        </div>
    )
}
export default ProfilePage