import React, { createContext, useContext, FC, useState, useCallback, useEffect } from 'react'
import { ToastContainer, toast as toastify } from 'react-toastify';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import 'react-toastify/dist/ReactToastify.css';
import { Drawer } from '@material-ui/core';
import { ToastFullScreen, ToastFullScreenProps } from './toastFullScreen/ToastFullScreen';

interface IUIcontext {
    toast: (message: string, type: 'info' | 'success' | 'warning' | 'error' | 'default' | 'dark', position?: "top-right" | "top-center" | "top-left" | "bottom-right" | "bottom-center" | "bottom-left") => void,
    showLoading: () => void,
    hideLoading: () => void,
    toastFullScreen: (message: string, icon: string, type: 'info' | 'success' | 'warning' | 'error' | 'default' | 'dark' | 'payment', timer?: number, callback?: () => void, actionButton?: string, iconSize?: number, fontSize?: number) => void
    isIframe: boolean,
    isMobile: boolean,
    dimensions: { width: number, heigth: number },
    helpFixed: boolean,
    helpHeader: boolean,
    setHelpFixed: React.Dispatch<React.SetStateAction<boolean>>,
    setHelpHeader: React.Dispatch<React.SetStateAction<boolean>>,
    setOpenTooltip: React.Dispatch<React.SetStateAction<boolean>>,
    openTooltip: boolean,
}
const mobileSize = 600;

const UIContext = createContext<IUIcontext>({} as IUIcontext)

export const UIProvider: FC<{ children: React.ReactNode }> = ({ children }) => {

    const [loading, setloading] = useState(false);
    const [toastFullScreenProps, settoastFullScreenProps] = useState<ToastFullScreenProps>({} as ToastFullScreenProps)
    const [openToastFullScreen, setopenToastFullScreen] = useState(false)
    const [isIframe, setIsIframe] = useState(false)
    const [countLoad, setCountLoad] = useState(0)
    const [dimensions, setDimensions] = useState({ width: window.innerWidth, heigth: window.innerHeight });
    const [isMobile, setIsMobile] = useState(
        window.innerWidth <= mobileSize
    )
    const [helpFixed, setHelpFixed] = useState(true);
    const [helpHeader, setHelpHeader] = useState(false);
    const [openTooltip, setOpenTooltip] = useState(true)


    useEffect(() => {
        if (!helpFixed) {
            setHelpHeader(true)
        } else {
            setHelpHeader(false)
        }
    }, [helpFixed])

    const getDimensions = useCallback(() => {
        const { innerWidth } = window;

        setDimensions({ width: window.innerWidth, heigth: window.innerHeight });

        if (innerWidth <= mobileSize) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }

    }, []);

    useEffect(() => {
        window.addEventListener("resize", getDimensions)
        return () => window.removeEventListener("resize", getDimensions)
    }, [getDimensions])

    useEffect(() => {
        function isIframe() {
            try {
                return window.self !== window.top;
            } catch (e) {
                return true;
            }
        }

        setIsIframe(isIframe)

        return () => {

        }
    }, [])

    const toast = (message: string, type: 'info' | 'success' | 'warning' | 'error' | 'default' | 'dark', position?: "top-right" | "top-center" | "top-left" | "bottom-right" | "bottom-center" | "bottom-left") => {
        toastify(message, {
            type: type,
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const showLoading = useCallback(() => {
        setloading(true)
        setCountLoad(prev => prev + 1)
    }, [])
    const hideLoading = useCallback(() => {
        setCountLoad(prev => !!prev ? prev - 1 : 0)
        setloading(false)
    }, [])

    useEffect(() => {
        return () => {

        }
    }, [countLoad])

    const toastFullScreen = useCallback(
        (message: string, icon: string, type: 'info' | 'success' | 'warning' | 'error' | 'default' | 'dark' | "payment", time: number = 3000, callback?: () => void, actionButton?: string, iconSize?: number, fontSize?: number) => {
            settoastFullScreenProps({ message, type, icon, actionButton, onClickActionButton: () => { setopenToastFullScreen(false); callback && callback() }, iconSize, fontSize })
            setopenToastFullScreen(true);
            if (time > 0) {
                setTimeout(() => {
                    callback && callback();
                    setopenToastFullScreen(false);
                }, time);
            }
        },
        [],
    )

    return (
        <UIContext.Provider value={{ toast, showLoading, hideLoading: hideLoading, toastFullScreen, isIframe, isMobile, dimensions, helpFixed, helpHeader, setHelpFixed, setHelpHeader, setOpenTooltip, openTooltip }}>
            {children}
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Backdrop style={{ zIndex: 10000 }} open={!!countLoad}>
                <CircularProgress color="primary" style={{ color: "#fff" }} />
            </Backdrop>
            <Drawer open={openToastFullScreen} anchor={"bottom"}>
                <ToastFullScreen
                    {...toastFullScreenProps}
                ></ToastFullScreen>
            </Drawer>
        </UIContext.Provider>
    )
}

export const useUi = () => {
    const context = useContext(UIContext);
    return context
}