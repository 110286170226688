import React, { FC } from 'react'
import { ButtonBase, CircularProgress, Icon, IconButton } from '@material-ui/core'
import { IAddress } from '../../../../models/address/IAddress'
import './DeliveryAddressItem.css'

interface IDeliveryAddressItemProps {
    address?: IAddress | null
    onClickEdit?: () => void
    onClickRemove?: () => void
    onClick?: () => void
    onClickMain?: () => void
    select?: boolean,
    loading?: boolean,
    catchOnStore?: boolean,
    other?: boolean;
    children?: React.ReactNode;
}

export const DeliveryAddressItem: FC<IDeliveryAddressItemProps> = ({ children, address, onClickRemove: removeButton, catchOnStore, loading, onClick, onClickMain, onClickEdit }) => {
    return (
        <ButtonBase
            onClick={onClick}
            disabled={!onClick}
            className={"container-item-address"}  >
            <div className="icon-grid-item-address" >
                {
                    catchOnStore ?
                        <Icon fontSize={"large"} className={"icon-item-address"}>store</Icon>
                        :
                        <Icon fontSize={"large"} className={"icon-item-address"}>place</Icon>
                }
            </div>
            <div className={"content-item-address"}  >
                {
                    children ??
                    <>
                        {
                            loading ?
                                <div className={"top-content-item-address"}  >
                                    <CircularProgress size={18} color="primary"></CircularProgress>
                                </div>
                                :
                                address ?
                                    <>
                                        <div className={"top-content-item-address"}  >
                                            {address.street} {address.number} - {address.neighborhood}
                                        </div>
                                        <div className={"bottom-content-item-address"} >
                                            {address.city} - {address.state}
                                        </div>
                                    </> :
                                    <>
                                        <div className={"top-content-item-address"}  >
                                            Nenhum endereço selecionado
                                </div>
                                        <div className={"bottom-content-item-address"} >

                                        </div>
                                    </>
                        }
                    </>
                }

            </div>
            <div className={"actions-item-address"} >
                {/* {onClickMain && <IconButton onClick={onClickMain}><Icon style={{ color: address.default ? "#fa0" : "#999" }}>start</Icon></IconButton>} */}
                {removeButton && <IconButton onClick={removeButton}><Icon>close</Icon></IconButton>}
                {onClickEdit && <IconButton onClick={onClickEdit}><Icon>edit</Icon></IconButton>}
            </div>
        </ButtonBase>
    )
}
