import { useCart } from "context/cart/CartContext";
import { useCatalog } from "context/catalog/CatalogContext";
import { useUi } from "context/ui/UIContext";
import { useCallback, } from "react"
import { useHistory, useParams } from "react-router";
import SendOrderApi from "services/Api/order/SendOrderApi"
import { v4 } from "uuid";

const UseCartPage = () => {
    const { cartItems, totals, updateCartLocalStorage } = useCart();
    const { showLoading, hideLoading } = useUi();
    
    const { catalog } = useCatalog();
    const { push } = useHistory();
    const { url } = useParams<{ url: string }>()


    const sendPreOrder = useCallback(() => {        
            const storageCart = sessionStorage.getItem(`@Meep:cart`);
            const newOrderId = v4();
            const newOrder = {
                id: newOrderId,
                items: cartItems,
                total: totals.totalPrice,
            }
            const sendOrder = SendOrderApi();
            if (catalog) {
                showLoading();
                updateCartLocalStorage(cartItems)
                sendOrder.sendPreOrder(newOrder, catalog?.id).then((response) => {
                    if (window.fbq) {
                        window.fbq("track", "InitiateCheckout", {
                            content_ids: newOrderId,
                            num_items: cartItems.reduce((acc, item) => acc + item.quantity, 0),
                            value: totals.totalPrice,
                            contents: [cartItems.map(item => ({ id: item.productId, quantity: item.quantity, name: item.name }))]
                        });
                    }

                    if (catalog.isMeepCard) {
                        push(`/${url}/selecionarPagamento?localId=${catalog.id}&orderId=${response.data.pedidoId}`);
                    } else {
                        push(`/${url}/payment?orderId=${response.data.pedidoId}`);
                    }
                }).finally(() => {
                    hideLoading();
                })

            }
        },
        [cartItems, totals.totalPrice, catalog, showLoading, updateCartLocalStorage, push, url, hideLoading],
    )

    return {
        sendPreOrder
    }
}
export default UseCartPage