import React, { FC, useState } from 'react'
import styles from './AlertApp.module.scss'
import iconApp from '../../res/images/icon-meep-app.svg'
import { Drawer, Icon } from '@material-ui/core'
import { UseDetectPlatform } from './UseDetectPlatform'

export interface IAlertAppProps {
    //propertys
}
const AlertApp: FC<IAlertAppProps> = () => {
    const [open, setOpen] = useState(true);

    const { platform } = UseDetectPlatform();

    return (
        <Drawer anchor={"top"} PaperProps={{ className: styles.paper }} open={open} onClose={() => { setOpen(false) }} >
            <div id={styles.AlertApp} >
                <div className={styles.container} >
                    <div className={styles.iconApp} >
                        <img src={iconApp} alt="Ícone do App" />
                    </div>
                    <div className={styles.content} >
                        <h2>Meep App</h2>
                        {platform === "ios" &&
                            <a
                                href='https://apps.apple.com/br/app/meep-food/id1519547197'
                            >
                                <h3>Obter na Apple Store</h3>
                            </a>
                        }
                        {platform === "android" &&
                            <a
                                href='https://play.google.com/store/apps/details?id=com.meep.superapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                            ><h3>Obter na Play Store</h3>
                            </a>
                        }
                        {platform === "windows" &&
                            <>
                                <a
                                    href='https://apps.apple.com/br/app/meep-food/id1519547197'
                                >
                                    <h3>Obter na Apple Store</h3>
                                </a>
                                <a
                                    href='https://play.google.com/store/apps/details?id=com.meep.superapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                                >
                                    <h3>Obter na Play Store</h3>
                                </a>
                            </>
                        }
                    </div>
                    <div className={styles.closeIconContainer} onClick={() => { setOpen(false) }}>
                        <Icon className={styles.closeIcon} fontSize={"large"}>close</Icon>
                    </div>
                </div>
            </div>
        </Drawer>
    )
}
export default AlertApp