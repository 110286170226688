import { useState } from 'react'
import { IPhoto } from './interfaces/IPhotos'

export const UsePhoto = () => {

    const [photos] = useState<IPhoto[]>([
        {
            thumbnailUrl: "/demo/social/1.png",
            photoUrl: "/demo/social/1.png",
        },
        {
            thumbnailUrl: "/demo/social/2.png",
            photoUrl: "/demo/social/2.png",
        },
        {
            thumbnailUrl: "/demo/social/3.png",
            photoUrl: "/demo/social/3.png",
        },
        {
            thumbnailUrl: "/demo/social/4.png",
            photoUrl: "/demo/social/4.png",
        },
    ])


    return (
        {
            photos
        }
    )
}
