import { Button, Grid, TextField } from '@material-ui/core'
import { Form, Formik } from 'formik'
import React, { FC } from 'react'
import { IUser, IUserRegisterRequest } from '../../../../../models/user/IUser'
import "./FormNewUser.css"

interface FormNewUserProps {
    smsValidationId: string,
    onSubmit: (values: IUserRegisterRequest) => void,
    defaultValues?: IUser,
}
export const FormNewUser: FC<FormNewUserProps> = ({ smsValidationId, onSubmit, defaultValues }) => {


    const onsubmit = (newUser: IUser) => {
        const userToRegister: IUserRegisterRequest = {
            name: newUser.name,
            email: newUser.email,
            phoneNumber: newUser.phoneNumber,
            ddd: newUser.ddd,
            cpf: newUser.cpf,
            smsValidationId: smsValidationId,
        }
        onSubmit(userToRegister);
    }

    return (
        <Formik
            initialValues={defaultValues ?? {} as IUser}
            onSubmit={onsubmit}
        >{
                ({
                    handleSubmit,
                    handleChange,
                    values,
                    isValid,
                    errors,
                    touched
                }) =>
                    <Form onSubmit={handleSubmit}>
                        {
                            <Grid container spacing={2}>

                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        value={values.ddd}
                                        name="ddd"
                                        label="DDD"
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        value={values.phoneNumber}
                                        name="phoneNumber"
                                        label="Celular"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        value={values.cpf}

                                        name="cpf"
                                        label="CPF"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        fullWidth
                                        value={values.name}
                                        onChange={handleChange}
                                        name="name"
                                        label="Nome"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        fullWidth
                                        value={values.email}
                                        onChange={handleChange}
                                        name="email"
                                        label="Email"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button

                                        fullWidth size="large"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        Cadastrar
                                        </Button>
                                </Grid>

                            </Grid>
                        }
                    </Form>

            }
        </Formik>
    )
}
