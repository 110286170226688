import { IOrderComposition } from 'models/order/IOrderComposition'

export const CalcTotalsAllCompositions = (prevCompositions: IOrderComposition[]): { quantity: number, price: number } => {

    const calcTotalsAllCompositions = (_prevCompositions: IOrderComposition[]) => {
        const price = _prevCompositions
            .flatMap((_composition) => _composition.compositionItems)
            .map(_item => (_item.totalPrice))
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

        const quantity = _prevCompositions
            .flatMap((_composition) => _composition.compositionItems)
            .map(_item => (_item.quantity))
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
        return { quantity, price }
    }

    return calcTotalsAllCompositions(prevCompositions)

}
