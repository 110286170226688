import {
  IPaymentLinkResponse,
  PaymentTypeEnum,
} from "models/paymentLink/IPaymentLinkResponse";
import React, { FC, useMemo } from "react";
import Pix from "../pix/Pix";
import Ticket from "../ticket/Ticket";
import styles from "./Payment.module.scss";
import Credit from "../credit/Credit";
import { useUi } from "context/ui/UIContext";

export interface IPayment {
  selectedPaymentType: PaymentTypeEnum | null;
  paymentLink: IPaymentLinkResponse;
}

const Payment: FC<IPayment> = ({ selectedPaymentType, paymentLink }) => {
  const { isMobile } = useUi();
  const renderContent = useMemo(() => {
    switch (selectedPaymentType) {
      case PaymentTypeEnum.CREDIT:
        return <Credit paymentLink={paymentLink} />;
      case PaymentTypeEnum.PIX:
        return <Pix paymentLink={paymentLink} />;
      case PaymentTypeEnum.TICKET:
        return <Ticket paymentLink={paymentLink} />;
      default:
        return (
          <div className={styles.title}>Selecione a forma de pagameto</div>
        );
    }
  }, [paymentLink, selectedPaymentType]);

  return (
    <div
      id={styles.Payment}
      style={{
        width: isMobile ? 350 : undefined,
        height: isMobile ? "auto" : undefined,
        marginTop: isMobile ? "30px" : undefined,
        overflow: isMobile ? "unset" : undefined,
      }}
    >
      {renderContent}
    </div>
  );
};

export default Payment;
