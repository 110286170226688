import React, { createContext, useContext, useState, FC, useCallback, useEffect } from "react";
import { useUi } from "../ui/UIContext";
import api from "../../services/Api/Api";
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { ILocal } from "../../models/local/ILocal";
import { IUser } from "../../models/user/IUser";
import { useLocal } from "../local/LocalContext";
import { Dialog } from "@material-ui/core";
import AuthPage from "pages/public/auth/AuthPage";
import { UseOnErroApi } from "context/ui/UseOnErroApi";

interface IAuthContext {
  isAuth: boolean;
  logout: () => void;
  locals: ILocal[] | null;
  currentLocal: ILocal | null;
  user: IUser;
  loadToken: boolean;
  updateToken: (token: string) => void
  updateUser: (user: IUser) => void
  goToLogin: () => void
  openModalLogin: () => void;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);
export const AuthProvider: FC<{ disableRedirect?: boolean, children: React.ReactNode }> = ({ children, disableRedirect }) => {
  const { toast } = useUi();
  const [locals, setLocals] = useState<ILocal[] | null>(null);
  const [currentLocal, setCurrentLocal] = useState<ILocal | null>(null);
  const [token, setToken] = useState("");
  const history = useHistory();
  const [loadToken, setloadToken] = useState(false);
  const { path } = useRouteMatch();
  const { url } = useParams<{ url: string }>();
  const [user, setUser] = useState<IUser>({} as IUser);

  const { localId } = useLocal();

  const [isAuth, setIsAuth] = useState(false);

  const [modalLogin, setModalLogin] = useState(false);

  let query = new URLSearchParams(useLocation().search);

  const updateUser = useCallback((userValue: IUser) => {
    localStorage.setItem(`@Meep:user`, JSON.stringify(userValue));
    setUser(userValue);
  }, []);

  const logout = useCallback(async () => {
    setLocals(null);
    setCurrentLocal(null);
    updateUser({} as IUser);
    setToken("");
    localStorage.clear();
    setIsAuth(false);

    if (!disableRedirect) {
      if (!path.includes("/login")) {
        const address = (`/login?goto=${path}`).replace(":url", url).replace(":token", "");
        history.push(address);
      }
    }

  }, [disableRedirect, history, path, updateUser, url]);

  useEffect(() => {
    const storageToken = localStorage.getItem(`@Meep:token`);
    const storageUser = localStorage.getItem(`@Meep:user`);
    try {

      if (storageToken) {
        setToken(storageToken);
        api.defaults.headers = { Authorization: `bearer ${storageToken}` };
        setIsAuth(true);
      } else {
        logout();
      }
      if (storageUser) {
        updateUser(JSON.parse(storageUser) as IUser);
      }
    } catch (error) {
      logout();
    } finally {
      setloadToken(true);
    }
  }, [history, localId, logout, updateUser]);


  const updateToken = useCallback(
    (tokenValue: string) => {
      api.defaults.headers = { Authorization: `bearer ${tokenValue}` };
      localStorage.setItem(`@Meep:token`, tokenValue);
      setToken(tokenValue);
    },
    []
  );

  const redirectLogin = useCallback(() => {
    if (!path.includes("/login") && !disableRedirect) {
      const address = (`/login?goto=${path}`).replace(":url", url).replace(":token", "");
      history.push(address);
    }
  }, [path, disableRedirect, history, url])

  const goToLogin = useCallback(() => {
    if (!path.includes("/login")) {
      const address = (`/login?goto=${path}`).replace(":url", url).replace(":token", "");
      history.push(address);
    }
  }, [path, history, url])

  useEffect(() => {
    if (!isAuth && loadToken) {
      redirectLogin();
    }
    if (isAuth && loadToken) {
      const goto = query.get("goto");
      if (goto) {
        history.replace(`${goto}`);
      }
    }
    return () => {

    }
  }, [history, isAuth, loadToken, path, query, redirectLogin, url])


  const openModalLogin = useCallback(() => {
    setModalLogin(true);
  }, [])

  const onLoginModal = useCallback(() => {
    setModalLogin(false);
    toast("Autenticado com sucesso", "success");
  }, [toast])

  return (
    <AuthContext.Provider
      value={{
        isAuth: !!token,
        logout,
        locals,
        currentLocal,
        user,
        loadToken,
        updateUser,
        updateToken,
        goToLogin,
        openModalLogin
      }}
    >
      {children}
      <UseOnErroApi />
      <Dialog open={modalLogin} onClose={() => setModalLogin(false)}>
        <AuthPage onLogin={onLoginModal}></AuthPage>
      </Dialog>
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};
