import React, { FC } from 'react'
import styles from './ProfilePicture.module.scss'
export interface IProfilePictureProps {
    //propertys
    photoUrl: string;
    onPress?: () => void;
}
const ProfilePicture: FC<IProfilePictureProps> = ({ photoUrl }) => {
    return (
        <div id={styles.ProfilePicture} >
            <div className={styles.container} >
                <img
                    alt={"profile"}
                    src={photoUrl}
                    className={styles.img}
                ></img>
            </div>
        </div>
    )
}
export default ProfilePicture