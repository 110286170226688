import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router';


export const UseNavigationTour = () => {

    const { url, storeId } = useParams<{ url?: string, storeId?: string, serviceId?: string, }>();
    const { replace } = useHistory();

    const goToCurrentTourStore = useCallback(() => {
        replace(`/${url}/schedule/tour/${storeId}`);
    }, [replace, storeId, url])

    const goToTourStores = useCallback(() => {
        replace(`/${url}/schedule/tour/`);
    }, [replace, url])

    const goToTourReserves = useCallback(() => {
        replace(`/${url}/schedule/tour/reserves`);
    }, [replace, url])


    return (
        { goToCurrentTourStore, goToTourStores, goToTourReserves }
    );
};
