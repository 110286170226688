import { ChangeEvent, useState } from 'react'

export const UsePaymentResume = () => {

    const [contractTerms, setContractTerms] = useState(false)
    const [toggleContractTerms, setToggleContractTerms] = useState(false)

    const handleModalContractTerms = () => {
        setToggleContractTerms(!toggleContractTerms)
    }

    function handleChecked(event: ChangeEvent<HTMLInputElement>) {
        setContractTerms(event.target.checked);
    }

    return (
        {
            handleChecked,
            contractTerms,
            handleModalContractTerms,
            toggleContractTerms
        }
    )
}
