import { Icon, IconButton } from '@material-ui/core'
import React, { FC } from 'react'
import styles from './BackButton.module.scss'

export interface IBackButtonProps {
    //propertys
    onClick?: () => void,
    className?: string
}

const BackButton: FC<IBackButtonProps> = ({ onClick, className }) => {
    return (
        <IconButton onClick={onClick} id={styles.BackButton} className={className} >
            <Icon>arrow_back_ios_new</Icon>
        </IconButton>
    )
}
export default BackButton