/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { useAuth } from '../../../context/auth/AuthContext'
import './OrderHistoryPage.css'
import { IOrderInProgress } from "../../../models/order/aggregates/progress/IOrderInProgress";
import { IHistorySectionOrder } from '../../../models/order/aggregates/history/IOrderHistory'
import { OrderHistoryApi } from '../../../services/Api/order/OrderHistoryApi';
import { OrdersInProgressList } from './ordersInProgressList/OrdersInProgressList';
import { useUi } from '../../../context/ui/UIContext';
import OldOrderList from './oldOrdersList/OldOrderList';

export const OrderHistory = () => {

    const orderHistoryApi = OrderHistoryApi();
    const [orderInProgress, setOrderInProgress] = useState<IOrderInProgress[]>()
    const [sectionOrders, setSectionOrders] = useState<IHistorySectionOrder[]>([])
    const { user } = useAuth()
    const { showLoading, hideLoading } = useUi()
    

    const getInProgressOrders = useCallback(
        async () => {
            try {
                showLoading()
                const response = await orderHistoryApi.getInProgressOrders();
                if (response) {
                    setOrderInProgress(response.data)
                }
            } finally {
                hideLoading()
            }
        },
        [],
    )
    const getOrderHistory = useCallback(
        async (userId: string) => {
            const response = await orderHistoryApi.getByUser(userId);
            if (response) {
                setSectionOrders(response.data)
            }
        },
        [],
    )

    useEffect(() => {

        getOrderHistory(user.id);
        getInProgressOrders();
        return () => {

        }
    }, [user])


    return (
        <div id="orderHistoryPage">
            <div className="title-orderHistoryPage">Pedidos em Andamento</div>
            {

                orderInProgress &&
                <OrdersInProgressList ordersInProgressList={orderInProgress} ></OrdersInProgressList>

            }



            <div className="title-orderHistoryPage">Pedidos finalizados</div>

            {sectionOrders.map((orderHistory) => (
                <OldOrderList orders={orderHistory} />
            ))}

        </div>
    )
}
