import { Container } from '@material-ui/core'
import React, { FC, HTMLProps, useEffect, useState } from 'react'
import { PrivateHeader } from '../Components/privateHeader/PrivateHeader'
import Menu from './menu/Menu'
import styles from './PrivatePage.module.scss'

interface PrivatePageProps extends HTMLProps<HTMLDivElement> {
    onClickBack?: () => void;
    title?:string;
    children: React.ReactNode;
}

export const PrivatePage: FC<PrivatePageProps> = ({ children, onClickBack, title, ...props }) => {
    const [isSmall, setisSmall] = useState(false);

    useEffect(() => {
        const verificarTela = () => {
            if (window.screen.width > 1000) {
                setisSmall(false);
            } else {
                setisSmall(true)
            }
        }
        verificarTela()
        window.addEventListener("resize", () => verificarTela())
    }, [])

    return (
        <div id={styles.private}>
            <PrivateHeader title={title} menuDisabled={!isSmall} onClickBack={onClickBack} />
            <Container maxWidth={"lg"} >
                <div className={styles.container}>
                    <div className={styles.menu}>
                        <Menu />
                    </div>
                    <div>
                        <div className={styles.content} {...props}>
                            {children}
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}
