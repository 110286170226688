import { Button, CircularProgress, TextField } from '@material-ui/core'
import React, { FC, useState } from 'react'
import './ConfirmPasswordInput.css'


interface IConfirmPasswordInput {
    onSubmit: (password: string) => void
    loading?: boolean
}
export const ConfirmPasswordInput: FC<IConfirmPasswordInput> = ({ onSubmit, loading }) => {
    const [password, setPassword] = useState("");

    const onsubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!loading) {
            onSubmit(password);
        }
    }

    return (
        <div id="ConfirmPasswordInput">
            <form onSubmit={onsubmit}>
                <div className="container">
                    <div className="content">
                        <div className="description">
                            Confirme sua senha
                    </div>
                        <div className="inputContainer">
                            <TextField
                                autoFocus
                                disabled={loading}
                                inputProps={{ style: { fontSize: 38, textAlign: "center" }, inputMode: 'numeric' }}
                                value={password}
                                type="password"
                                onChange={(e) => setPassword(e.target.value.substr(0, 6).replace("-", "").replace(".", ""))}
                            ></TextField>
                        </div>
                    </div>
                    <div className="buttonContainer">
                        <Button disabled={password.length < 3} size="large" color="primary" variant="contained" fullWidth type="submit">
                            {!loading ?
                                "Confirmar Pedido" :
                                <CircularProgress size={26} style={{ color: "#fff" }} />
                            }   </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}
