import { ICoupon } from "models/coupon/ICoupon";
import api from "../Api";

const CouponApi = () => {
    const activate = async (code: string): Promise<ICoupon> => {
        const response = await api.post<ICoupon>('/CouponUser/AddCouponToUser', { code });
        return response.data
    };

    const getAll = async (): Promise<ICoupon[]> => {
        const response = await api.get<ICoupon[]>('/CouponUser/AvailableCoupons');
        return response.data
    };

    return { activate, getAll };

}
export default CouponApi