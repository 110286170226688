import React from "react";
import { Container } from "@material-ui/core";
import styles from './LoginPage.module.scss';

export const LoginPage = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={styles.container}>
      <Container maxWidth="sm" className={styles.content}>
        <div style={{ maxWidth: 462 }}>
          {children}
        </div>
        <div style={{ color: "#fff", position: "fixed", bottom: 0, right: 4 }}>0.1.8</div>
      </Container>
    </div>
  );
};
