import { InputLabelProps, TextField } from "@material-ui/core";
import React, { FC, InputHTMLAttributes } from "react";
import styles from "./Input.module.scss";

interface InputProps {
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  InputLabelProps?: Partial<InputLabelProps>;
  multiline?: boolean;
  endAdornmentText?: string;
  value?: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  type?: string;
  className?: string;
  fullwidth?: boolean;
  name?: string;
  required?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  variant?: "standard" | "outlined" | "filled";
  select?: boolean;
  disabled?: boolean;
  testId?: string;
  label?: string;
  rows?: string | number;
  rootStyle?: string;
  children?: React.ReactNode;
}

const Input: FC<InputProps> = ({
  inputProps,
  InputLabelProps,
  children,
  multiline,
  onChange,
  value,
  type,
  className,
  variant,
  fullWidth = true,
  name,
  required,
  placeholder,
  select,
  disabled,
  testId,
  label,
  rows,
  rootStyle
}) => {
  return (
    <TextField
      size="small"
      select={select}
      name={name}
      disabled={disabled}
      className={`${className} ${styles.textField}`}
      multiline={multiline}
      rows={rows}
      value={value}
      variant={variant}
      placeholder={placeholder}      
      type={type}
      fullWidth={fullWidth}
      onChange={onChange}
      required={required}
      label={label}
      classes={{
        root: rootStyle,        
      }}
      SelectProps={{
        classes: { select: styles.MuiSelect },
      }}
      inputProps={{
        ...inputProps,
        "data-testid": testId,
        // endAdornment: <InputAdornment position="end">{endAdornmentText}</InputAdornment>
      }}
      InputLabelProps={InputLabelProps}
    >
      { children}
    </TextField >
  );
};

export default Input;
