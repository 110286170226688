import { Icon, IconButton, Theme, Tooltip, withStyles } from '@material-ui/core'
import { HelpOutline, KeyboardArrowLeft } from '@material-ui/icons'
import React, { FC } from 'react'
import styles from './HeaderLayout.module.scss'
export interface IHeaderLayoutProps {
    //propertys
    name?: string,
    description?: string,
    onClickBack?: () => void,
    onClickMenu?: () => void,
    helpHeader?: boolean,
    onClickFaq?: () => void;
    openTooltip?: boolean
}

const TooltipStyle = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: '#32008E',
        color: '#FFF',
        maxWidth: 221,
        maxHeight: 40,
        fontSize: theme.typography.pxToRem(16),
        borderRadius: 8,
        border: '1px solid #dadde9',
        padding: 8,
    },
    arrow: {
        color: '#32008E',

    }
}))(Tooltip);

const HeaderLayout: FC<IHeaderLayoutProps> = ({
    name,
    description,
    onClickBack,
    onClickMenu,
    helpHeader,
    onClickFaq,
    openTooltip
}) => {
    return (
        <div id={styles.HeaderLayout} >
            {onClickBack && <IconButton onClick={onClickBack}><KeyboardArrowLeft/></IconButton>}
            <div className={styles.container} >
                <h3>{name}</h3>
                <span>{description}</span>
            </div>
            {
                helpHeader &&
                <TooltipStyle open={openTooltip} arrow title="Se precisar estarei aqui!">
                    <HelpOutline className={styles.buttonHelp} onClick={onClickFaq} />
                </TooltipStyle>
            }
            <IconButton onClick={onClickMenu}><Icon>menu</Icon></IconButton>
        </div>
    )
}
export default HeaderLayout