import { useLocal } from 'context/local/LocalContext';
import { useUi } from 'context/ui/UIContext';
import { IProductItem } from 'pages/public/catalog/products/components/productList/interfaces/IProductItem';
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { GetServicesTourCategoryByIdUseCase } from '../../../application/useCases/GetServicesTourCategoryByIdUseCase';
import { IProductTourCategory } from '../../../_domain/_models/IProductTourCategory';

const UseProductsTourPage = () => {
    const { url, storeId } = useParams<{ url: string, storeId: string }>();
    const { localId } = useLocal()
    const { push } = useHistory();
    const { showLoading, hideLoading } = useUi();
    const [productsTour, setProductsTour] = useState<IProductTourCategory[]>([]);
    const [storeName, setStoreName] = useState<string>("");

    useEffect(() => {
        if (storeId && localId) {
            showLoading();
            GetServicesTourCategoryByIdUseCase(storeId, localId)
                .then((response) => {
                    setProductsTour(response.items);
                    setStoreName(response.storeName)

                })
                .finally(() => {
                    hideLoading();
                })
        }

    }, [storeId, localId, showLoading, hideLoading])

    const onClickBackHandle = useCallback(() => {
        push(`/${url}/schedule/tour`)
    }, [])



    const onClickProductHandle = useCallback(
        (product: IProductItem) => {
            push(`/${url}/schedule/tour/${storeId}/moreDetails/${product.id}`)
        },
        [url, storeId, push],
    )

    return ({
        productsTour,
        storeName,
        onClickProductHandle,
        onClickBackHandle,

    })
}
export default UseProductsTourPage