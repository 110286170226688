import { IOrderInProgressProduct } from "./IInProgressOrder";


export enum EnumPrinterStatus {
  InQueue = 1,
  Printed = 2
};

export interface printerStatus {
  status: EnumPrinterStatus;
  createdAt: string;
  id: string;
  message?: string;
  pedidoId: string;
  printOrder: string;
  updatedAt: string;
}

export interface IOrderInProgress {
  total: number;
  created: Date;
  productsDetails: IOrderInProgressProduct[];
  storeId: string;
  storeName: string;
  deliveryTime: number;
  estimatedTime: Date;
  id: string;
  status: string;
  printerStatus?: printerStatus[]
}
