import { Catalog } from "models/catalog/aggregates/Catalog"

export const GetProductFromCatalogById = (catalog: Catalog, productId: string) => {

    const getProductFromCatalogById = (catalog: Catalog, productId: string) => {
        return catalog.stores.flatMap(store => store.seccionedProduct.flatMap(section => section.products)).find(product => product.id === productId)
    }

    return (
        getProductFromCatalogById(catalog, productId)
    )
}
