import React, { FC, FormEvent, useCallback, useState } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import * as yup from "yup"; // for everything

interface ILoginPostObject {
  email: string;
  password: string;
}

interface ILoginProps {
  emailSugestion?: string;
  onSubmit: (username: string, password: string) => void
}

export const Login: FC<ILoginProps> = ({ emailSugestion, onSubmit }) => {
  const [values, setValues] = useState({ email: "", password: "" });

  const cardSchema = yup.object<ILoginPostObject>().shape({
    email: yup.string().email("Insira um email válido").required("Email é obrigatório"),
    password: yup.string().min(4, "A senha deve possui pelo menos 4 digitos").required("Senha é obrigatório"),
  });

  const changeNameHandle = useCallback((value: string) => {
    setValues((prev) => ({ ...prev, email: value }));
  }, []);
  const changePasswordHandle = useCallback((value: string) => {
    setValues((prev) => ({ ...prev, password: value }));
  }, []);

  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      onSubmit(values.email, values.password);
    },
    [values, onSubmit]
  );

  return (
    <form onSubmit={submitHandle}>
      Utilize o email: {emailSugestion}
      <Grid spacing={1} container>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            required
            // helperText={touched.email && errors.email}
            // error={touched.email && Boolean(errors.email)}
            onChange={(ev) => changeNameHandle(ev.target.value)}
            value={values.email}
            name="email"
            label="Email"
            inputProps={{ inputMode: "email" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            // helperText={touched.password && errors.password}
            // error={touched.password && Boolean(errors.password)}
            onChange={(ev) => changePasswordHandle(ev.target.value)}
            required
            value={values.password}
            name="password"
            label="Senha"
            type="password"
          />
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth size="large" variant="contained" color="primary" type="submit">
            {"ENTRAR"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button size="small" variant="text" color="primary" type="submit">
            Esqueci minha senha
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
// function setValues(arg0: (prev: any) => any) {
//   throw new Error("Function not implemented.");
// }
