import creditCardType, {
    getTypeInfo,
    types as CardType,
} from "credit-card-type";

export const GetCardType = (number: string) => {
    const type = creditCardType(number)
    
    let imgs: {
        white: any,
        black: any,
        color: any
    } | null = null;

    if (type.length > 0) {
        switch (type[0].type) {
            case 'mastercard':
                imgs = brands['master']
                break;
            case 'visa':
                imgs = brands['visa']
                break;
            case 'amex':
                imgs = brands['amex']
                break;
            case 'elo':
                imgs = brands['elo']
                break;
            case 'hiper':
                imgs = brands['hiper']
                break;
        }
    }
    
    return { ...type[0], imgs }
}

export const GetCardTypeImage = (type: string) => {

    switch (type.toLowerCase()) {
        case 'mastercard':
            return brands['master'].color
        case 'visa':
            return brands['visa'].color
        case 'amex':
            return brands['amex'].color
        case 'elo':
            return brands['elo'].color
        case 'hiper':
            return brands['hiper'].color
    }
    return
}

export const brands = {
    master: {
        white: require('./images/master/master_white.png'),
        black: require('./images/master/master_black.png'),
        color: require('./images/master/master_color.png'),
    },
    visa: {
        white: require('./images/visa/visa_white.png'),
        black: require('./images/visa/visa_black.png'),
        color: require('./images/visa/visa_color.png'),
    },
    amex: {
        white: require('./images/amex/amex_white.png'),
        black: require('./images/amex/amex_black.png'),
        color: require('./images/amex/amex_color.png'),
    },
    elo: {
        white: require('./images/elo/elo_white.png'),
        black: require('./images/elo/elo_black.png'),
        color: require('./images/elo/elo_color.png'),
    },
    hiper: {
        white: require('./images/hiper/hiper_white.png'),
        black: require('./images/hiper/hiper_black.png'),
        color: require('./images/hiper/hiper_color.png'),
    },
};
