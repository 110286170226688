import { Modal } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { Skeleton } from "@mui/material";
import { ReactComponent as Contract } from "assets/icons/contract.svg";
import { ReactComponent as Group } from "assets/icons/groups.svg";
import { ReactComponent as Money } from "assets/icons/money.svg";
import { ReactComponent as TagPlus } from "assets/icons/tag_plus.svg";
import { ReactComponent as TransferIcon } from "assets/icons/transfer.svg";
import { useCatalog } from "context/catalog/CatalogContext";
import { IUserWallet, TagStatus } from "models/wallet/IWallet";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { WalletApi } from "services/Api/wallet/WalletApi";
import NumberToMoneyString from "utils/mask/NumberToMoney";
import { PrivateHeader } from "../Components/privateHeader/PrivateHeader";
import Tag from "./pages/tag/Tag";
import Transfer from "./pages/transfer/Transfer";
import styles from "./Wallet.module.scss";
import { useUi } from "context/ui/UIContext";

const Wallet: FC = () => {
  const { storeId, url } = useParams<{ storeId: string; url: string }>();
  const history = useHistory();
  const { catalog, onLoading: onLoadingCatalog } = useCatalog();
  const { isMobile } = useUi();

  const [onLoading, setOnLoading] = useState(true);
  const [wallet, setWallet] = useState<IUserWallet | null>(null);
  const [showTagModal, setShowTagModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);

  const goToProfile = useCallback(() => {
    history.push(`/${url}/${storeId}/wallet/profile/${wallet?.Id}`);
  }, [history, storeId, url, wallet]);

  const goToDependents = useCallback(() => {
    history.push(`/${url}/${storeId}/wallet/dependents/${wallet?.Id}`);
  }, [history, storeId, url, wallet]);

  const goToExtract = useCallback(() => {
    history.push(`/${url}/${storeId}/wallet/extract/${wallet?.Id}`);
  }, [history, storeId, url, wallet]);

  const goToRecharge = useCallback(() => {
    history.push(`/${url}/${storeId}/wallet/recharge/${wallet?.Id}`);
  }, [history, storeId, url, wallet]);

  const getWallet = useCallback(async () => {
    const walletApi = WalletApi();
    setOnLoading(true);
    walletApi
      .getWallet(storeId)
      .then((response) => {
        const TAG = response.data?.TAG
          ? response.data.TAG.length !== 8
            ? ""
            : response.data.TAG
          : undefined;
        setWallet({
          ...response.data,
          TAG: response.data?.Status === TagStatus.Active ? TAG : undefined,
        });
      })
      .catch(() => {
        history.goBack();
      })
      .finally(() => {
        setOnLoading(false);
      });
  }, [history, storeId]);

  useEffect(() => {
    getWallet();
  }, [getWallet]);

  const disableButtons = useMemo(
    () => onLoading || !storeId || onLoadingCatalog,
    [onLoading, onLoadingCatalog, storeId]
  );

  return (
    <div id={styles.Wallet}>
      <PrivateHeader
        title={onLoadingCatalog ? "Wallet" : catalog?.name ?? "Wallet"}
      />
      <div
        className={styles.container}
        style={{ margin: isMobile ? "0px" : undefined }}
      >
        <div className={styles.header}>
          <div>
            <div style={{ fontSize: isMobile ? "12px" : undefined }}>
              Bem-vindo(a),&nbsp;{" "}
              <span
                style={{
                  fontSize: isMobile && !onLoading ? "12px" : undefined,
                }}
              >
                {onLoading ? (
                  <Skeleton width={200} height={35} variant="text" />
                ) : (
                  wallet?.UserName
                )}
              </span>
            </div>
            <div
              className={disableButtons ? styles.disabled : undefined}
              onClick={goToProfile}
              style={{ fontSize: isMobile ? "12px" : undefined }}
            >
              Meus dados <ChevronRight />
            </div>
          </div>
          <p style={{ fontSize: isMobile ? "12px" : undefined }}>
            Tag vinculada:&nbsp;{" "}
            <b>
              {onLoading ? (
                <Skeleton width={75} height={30} variant="text" />
              ) : wallet?.TAG ? (
                wallet.TAG.replace(/([A-Za-z0-9]{2})(?=[A-Za-z0-9])/g, `$1-`)
              ) : (
                "Sem Tag"
              )}
            </b>
          </p>
        </div>

        <div className={styles.actions}>
          <div>
            <p>Saldo atual</p>
            <span>
              {onLoading ? (
                <Skeleton width={150} height={45} variant="text" />
              ) : (
                NumberToMoneyString(wallet?.Balance ?? 0)
              )}
            </span>
          </div>
          <div
            style={{
              display: isMobile ? "grid" : undefined,
              gridTemplateColumns: isMobile ? "1fr 1fr" : undefined,
            }}
          >
            <div
              className={disableButtons ? styles.disabled : undefined}
              onClick={goToRecharge}
            >
              <Money />
              Recarga
            </div>
            <div
              className={disableButtons ? styles.disabled : undefined}
              onClick={() => setShowTransferModal(true)}
            >
              <TransferIcon />
              Transferência
            </div>
            <div
              className={disableButtons ? styles.disabled : undefined}
              onClick={() => setShowTagModal(true)}
            >
              <TagPlus />
              Vinculo de TAG
            </div>
            <div
              className={disableButtons ? styles.disabled : undefined}
              onClick={goToDependents}
            >
              <Group />
              Dependentes
            </div>
            <div
              className={disableButtons ? styles.disabled : undefined}
              onClick={goToExtract}
            >
              <Contract />
              Extrato
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={showTagModal}
        onClose={() => setShowTagModal(false)}
        className={styles.tagModal}
      >
        <Tag
          storeId={storeId}
          onCancel={() => setShowTagModal(false)}
          tag={wallet?.TAG}
          walletId={wallet?.Id ?? ""}
          cardName={wallet?.UserName ?? ""}
          refresh={getWallet}
        />
      </Modal>
      <Modal
        open={showTransferModal}
        onClose={() => setShowTransferModal(false)}
        className={styles.tagModal}
      >
        <Transfer
          onCancel={() => setShowTransferModal(false)}
          refresh={getWallet}
          wallet={wallet}
          localId={storeId}
        />
      </Modal>
    </div>
  );
};

export default Wallet;
