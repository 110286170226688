import { format } from 'date-fns'
import React, { FC } from 'react'
import { IOrderTracking } from 'models/order/aggregates/tracking/IOrderTracking'
import "./OrderTracking.css"
import { TimeLineStatus } from './timeLineStatus/TimeLineStatus'
import { Button, Icon } from '@material-ui/core'
import QRCode from "qrcode.react";


interface IOrderTrackingProps {
    orderTracking: IOrderTracking;
}

export const OrderTracking: FC<IOrderTrackingProps> = ({ orderTracking }) => {

    return (

        <div id="orderTracking">
            <div className="title">PEDIDO: #{orderTracking.Code}</div>
            <div id="content">
                <div id="info">

                    {/* <div>{orderTracking.Id}</div> */}
                    <div className="infoLabel">Criado em: {format(new Date(orderTracking.CriationDate), "HH:mm dd/MM/yyyy")}</div>
                    {
                        orderTracking.AcceptDate &&
                        <div className="infoLabel">Aceito em: {format(new Date(orderTracking.AcceptDate), "HH:mm dd/MM/yyyy")}</div>
                    }
                    <div className="infoLabel">Tipo de pagamento: {orderTracking.KindPay}</div>
                    <div className="infoLabel">Valor do Pedido: R${orderTracking.Amount.toFixed(2).replace(".", ",")}</div>
                    {!!orderTracking.Duration &&
                        <div className="infoLabel">Tempo estimado: {orderTracking.Duration}</div>
                    }


                    <div>
                        <div className="infoLabel">
                            Pedido:
                        </div>

                        <div className="pedidoItens">
                            {orderTracking.Items.map((item) => (
                                <div> {item.Quantidade} {item.NomeProduto} {item.Valor.toFixed(2).replace(".", ",")}</div>
                            ))}
                        </div>
                    </div>
                </div>

                <div>
                    <QRCode size={250} style={{ margin: 16 }} value={orderTracking.Id.replace(/[^0-9a-zA-Zs]/g, "")} />
                </div>
                <div className="timeline">
                    {<TimeLineStatus statusOrder={orderTracking?.CurrentState} />}
                    {
                        !!orderTracking.TrackingKey &&
                        <Button fullWidth className="trackinDeliveryButton" variant="outlined" color={"primary"} onClick={() => window.open(orderTracking.TrackingKey)}><Icon>room</Icon>Acompanhe a sua entrega</Button>
                    }
                </div>
            </div>
        </div>
    )
}
