import { Button, IconButton, Modal, Paper } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import { Skeleton } from "@mui/material";
import { ReactComponent as Copy } from "assets/icons/copy.svg";
import { ReactComponent as Alert } from "assets/icons/alert.svg";
import { useUi } from "context/ui/UIContext";
import {
  MeepPIXEnum,
  PixRechargeRequest,
  PixStatusResponse,
} from "models/wallet/IWallet";
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { WalletApi } from "services/Api/wallet/WalletApi";
import { createDefferedPromise } from "utils/DefferedPromise";
import NumberToMoneyString from "utils/mask/NumberToMoney";
import styles from "./PixRecharge.module.scss";

const walletApi = WalletApi();

const REQUEST_TIMER_DELAY = 5;
const PIX_EXPIRATION_TIMER = 360;

let requestDelayTimeout: NodeJS.Timeout;
let expirationTimeout: NodeJS.Timeout;

export interface IPixHandler {
  open(amount: number, walletId: string, localId: string): Promise<string>;
}

export interface IPix {}

const PixRecharge: ForwardRefRenderFunction<IPixHandler, IPix> = ({}, ref) => {
  const deffered = useRef(createDefferedPromise<string>());

  const { toast, isMobile } = useUi();

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [pixStatus, setPixStatus] = useState<PixStatusResponse | null>(null);
  const [isActiveRequestTimer, setIsActiveRequestTimer] = useState(false);
  const [expirationCounter, setExpirationCounter] =
    useState(PIX_EXPIRATION_TIMER);
  const [isActiveExpirationTimer, setIsActiveExpirationTimer] = useState(true);
  const [requestTimerDelayCounter, setRequestTimerDelayCounter] =
    useState<number>(REQUEST_TIMER_DELAY);

  const pixPaymentStatus = useRef<MeepPIXEnum | null>(null);
  const paymentId = useRef<string | null>(null);

  const startRequestTimer = useCallback(() => {
    setIsActiveExpirationTimer(true);
    setExpirationCounter(PIX_EXPIRATION_TIMER);
    setRequestTimerDelayCounter(REQUEST_TIMER_DELAY);
    setIsActiveRequestTimer(true);
  }, []);

  const resetExpirationTimer = useCallback(() => {
    setPixStatus(null);
    clearTimeout(expirationTimeout);
    setIsActiveExpirationTimer(false);
  }, []);

  const resetRequestTimer = useCallback(() => {
    clearTimeout(requestDelayTimeout);
    setIsActiveRequestTimer(false);
    setRequestTimerDelayCounter(REQUEST_TIMER_DELAY);
  }, []);

  useEffect(() => {
    if (isActiveRequestTimer && requestTimerDelayCounter > 0 && visible) {
      requestDelayTimeout = setTimeout(() => {
        setRequestTimerDelayCounter((prev) => prev - 1);
      }, 1000);
    } else if (isActiveRequestTimer && requestTimerDelayCounter === 0) {
      setRequestTimerDelayCounter(REQUEST_TIMER_DELAY);
    }

    return () => {
      clearTimeout(requestDelayTimeout);
    };
  }, [requestTimerDelayCounter, isActiveRequestTimer, visible]);

  const successPix = useCallback(() => {
    setVisible(false);
    resetExpirationTimer();
    setPixStatus(null);
    paymentId.current = null;
    pixPaymentStatus.current = null;
    deffered.current.resolver("Recarga aprovada");
  }, [resetExpirationTimer]);

  const cancelPixPayment = useCallback(
    async (message: string) => {
      paymentId.current = null;
      pixPaymentStatus.current = null;
      setVisible(false);
      resetExpirationTimer();
      setShowCancelModal(false);
      setPixStatus(null);
      deffered.current?.rejecter(message);
    },
    [resetExpirationTimer]
  );

  const getPixStatus = useCallback(
    async (_paymentId: string) => {
      try {
        const response = await walletApi.getPixStatus(_paymentId);

        if (response.data.StatusDescription === "pending") {
          setRequestTimerDelayCounter(REQUEST_TIMER_DELAY);
        } else if (response.data.StatusDescription === "cancelled") {
          resetRequestTimer();

          cancelPixPayment("QRCode cancelado");
        } else if (response.data.StatusDescription === "approved") {
          resetRequestTimer();

          successPix();
        }
      } catch (error) {
        pixPaymentStatus.current = "pending";
      }
    },
    [cancelPixPayment, resetRequestTimer, successPix]
  );

  useEffect(() => {
    if (isActiveExpirationTimer && expirationCounter > 0 && visible) {
      expirationTimeout = setTimeout(() => {
        setExpirationCounter((prev) => prev - 1);
      }, 1000);
    } else if (isActiveExpirationTimer && expirationCounter === 0) {
      cancelPixPayment("Tempo de pagamento esgotado");
    }

    return () => {
      clearTimeout(expirationTimeout);
    };
  }, [
    cancelPixPayment,
    expirationCounter,
    isActiveExpirationTimer,
    resetExpirationTimer,
    visible,
  ]);

  useEffect(() => {
    if (!isActiveExpirationTimer && visible) {
      cancelPixPayment("Tempo de pagamento esgotado");
    }
  }, [cancelPixPayment, isActiveExpirationTimer, visible]);

  useEffect(() => {
    if (
      isActiveRequestTimer &&
      requestTimerDelayCounter === 0 &&
      paymentId.current &&
      visible
    ) {
      getPixStatus(paymentId.current);
    }
  }, [
    requestTimerDelayCounter,
    isActiveRequestTimer,
    pixStatus,
    visible,
    getPixStatus,
  ]);

  const getPixPayment = useCallback(
    async (amount: number, walletId: string, localId: string) => {
      try {
        setLoading(true);
        setPixStatus(null);
        pixPaymentStatus.current = null;
        const payload: PixRechargeRequest = {
          Amount: amount,
          LocalClientId: localId,
          WalletId: walletId,
        };

        const result = await walletApi.createPixRecharge(payload);

        if (result.status !== 200) {
          cancelPixPayment("Falha ao obter os dados do pix");
        }

        paymentId.current = result.data.OrderId;

        pixPaymentStatus.current = "pending";
        // pixPaymentStatus.current = result.data.OrderStatusDescription;

        const parseResponse: PixStatusResponse = {
          OrderId: result.data.OrderId,
          Amount: amount,
          StautsId: result.data.OrderStatusId,
          StatusDescription: result.data.OrderStatusDescription,
          TransactionId: "",
          IsRechargeMade: false,
          WalletBalance: 0,
          QrCodeLink: result.data.QrCodeLink,
          QrCodeBase64: result.data.QrCodeBase64,
          PixPaymentOrderStatus: result.data.OrderStatusDescription,
        };

        setPixStatus(parseResponse);
      } catch (error) {
        cancelPixPayment(
          (error as any)?.response?.data?.message ??
            "Falha ao obter os dados do pix"
        );
      } finally {
        setLoading(false);
      }
    },
    [cancelPixPayment]
  );

  const open = useCallback(
    (amount: number, walletId: string, localId: string) => {
      pixPaymentStatus.current = null;
      setPixStatus(null);
      getPixPayment(amount, walletId, localId);

      startRequestTimer();
      setVisible(true);

      deffered.current = createDefferedPromise<string>();
      return deffered.current.promise;
    },
    [getPixPayment, startRequestTimer]
  );

  useImperativeHandle(ref, () => ({ open }));

  const copyPixToClipboard = useCallback(() => {
    if (pixStatus?.QrCodeLink) {
      navigator.clipboard.writeText(pixStatus.QrCodeLink);
      toast("Código copiado para área de transferência", "success");
    }
  }, [pixStatus, toast]);

  const closeModal = useCallback(() => {
    setShowCancelModal(false);
  }, []);

  const onClickCancel = useCallback(() => {
    setShowCancelModal(true);
  }, []);

  const [minuteLeft, minuteRight] = useMemo(
    () =>
      String(Math.floor(expirationCounter / 60))
        .padStart(2, "0")
        .split(""),
    [expirationCounter]
  );
  const [secondLeft, secondRight] = useMemo(
    () =>
      String(expirationCounter % 60)
        .padStart(2, "0")
        .split(""),
    [expirationCounter]
  );

  return (
    <Modal open={visible} id={styles.Pix}>
      <div className={styles.container}>
        <div className={styles.title}>
          <span>Recarga via PIX</span>
          <IconButton onClick={onClickCancel}>
            <CloseOutlined />
          </IconButton>
        </div>
        <div className={styles.description}>
          Faça o pagamento pelo seu banco favorito e não se preocupe, seu
          pagamento será <b>validado automaticamente</b>
        </div>
        <div className={styles.pixContainer}>
          {loading || !pixStatus ? (
            <span className={styles.img}>
              <Skeleton width={163} height={163} variant="rectangular" />
            </span>
          ) : (
            <img
              src={`data:image/jpeg;base64,${pixStatus?.QrCodeBase64}`}
              alt="qr_code"
            />
          )}
          <div>
            <div>
              {loading || !pixStatus ? (
                <Skeleton width={50} height={26} variant="text" />
              ) : (
                NumberToMoneyString(Number(pixStatus?.Amount ?? "0"))
              )}
            </div>
            <div className={styles.divisor} />
            <div className={styles.timer}>
              <span>Expira em:</span>
              <b>
                <div>
                  <span>{minuteLeft}</span>
                  <span>{minuteRight}</span>
                </div>
                <span>:</span>
                <div>
                  <span>{secondLeft}</span>
                  <span>{secondRight}</span>
                </div>
              </b>
            </div>
          </div>
        </div>
        <div
          className={styles.pixFooter}
          style={{ marginBottom: isMobile ? 16 : undefined }}
        >
          {loading || !pixStatus ? (
            <span className={styles.code}>
              <Skeleton
                width={"100%"}
                height={40}
                variant="rectangular"
                style={{ borderRadius: 8, margin: "14px 0" }}
              />
            </span>
          ) : (
            <p>{pixStatus?.QrCodeLink}</p>
          )}

          <Button
            fullWidth
            variant="contained"
            onClick={copyPixToClipboard}
            color="inherit"
            className={!pixStatus?.QrCodeLink ? styles.disabled : ""}
          >
            <Copy />
            Copiar código
          </Button>
          <Button
            color="inherit"
            fullWidth
            variant="outlined"
            onClick={onClickCancel}
          >
            Cancelar
          </Button>
        </div>
        <Modal
          open={showCancelModal}
          onClose={closeModal}
          className={styles.modal}
        >
          <Paper className={styles.paper}>
            <div>
              <span>Cancelar recarga</span>
              <IconButton onClick={closeModal}>
                <CloseOutlined />
              </IconButton>
            </div>
            <Alert />
            <p>Deseja realmente cancelar a recarga?</p>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => cancelPixPayment("Cancelado pelo usuário")}
            >
              Cancelar
            </Button>
            <Button variant="outlined" color="inherit" onClick={closeModal}>
              Voltar
            </Button>
          </Paper>
        </Modal>
      </div>
    </Modal>
  );
};

export default forwardRef(PixRecharge);
