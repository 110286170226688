import React, { FC } from 'react'
import styles from './Header.module.scss'
import { Store } from 'models/catalog/aggregates/Store';

export interface IHeaderProps {
    defaultBackgroundImage?: string;
    backgroundImage?: string;
    store?: Store;
}

const Header: FC<IHeaderProps> = ({ backgroundImage, defaultBackgroundImage, store }) => {
    return (
        <div className={styles.headerContainer}>
            <div style={{ backgroundImage: `url(${defaultBackgroundImage})` }} className={styles.imgContainer}>
                <div style={{ backgroundImage: `url(${backgroundImage})` }}  className={styles.imgContainer} />
            </div>

            <div className={styles.content}>
                <h1>{store?.name}</h1>
                <span>{store?.description}</span>
            </div>
        </div>
    )
}
export default Header