import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { IProductItem } from '../productList/interfaces/IProductItem';
import { ISeccionedProduct } from '../productList/interfaces/ISeccionedProduct';
import ProductList, { IProductListRef } from '../productList/_ProductList';
import TabMenu from '../tabMenu/_TabMenu';
import SimpleHeader from '../_simpleHeader/SimpleHeader';
import styles from './ProductListCategory.module.scss';

export interface IProductListCategoryProps {
    seccionedProduct: ISeccionedProduct[],
    title: string,
    onClickProduct: (product: IProductItem) => void,
    prefixPrice?: string
    onClickBack?: () => void
}

const ProductListCategory: FC<IProductListCategoryProps> = ({ seccionedProduct, onClickBack, title, onClickProduct, prefixPrice }) => {
    const [headerOpen, setHeaderOpen] = useState(true);
    const [indexTabMenu, setIndexTabMenu] = useState(0);

    const refProductList = useRef<IProductListRef>(null)

    const onScroll = useCallback(() => {
        const offset = window.pageYOffset
        if (offset > 230) {
            setHeaderOpen(true);
        } else {
            setHeaderOpen(false);
        }
    }, [])

    useEffect(() => {
        onScroll();
        window.addEventListener("scroll", onScroll)
        return () => {
            window.removeEventListener("scroll", onScroll)
        }
    }, [onScroll]);

    const onClickTabMenu = useCallback((index: number) => {
        setIndexTabMenu(index);
        if (index === 0) {
            window.scrollTo(0, 0);
        } else {
            refProductList.current?.goToCagetory(index);
        }
    }, []);

    const onClickProductItemHandle = useCallback((product: IProductItem) => {
        onClickProduct(product);
    }, [onClickProduct]);

    return (
        <div id={styles.ProductListCategory}>
            <div className={styles.container}>
                <SimpleHeader
                    open={headerOpen}
                    categories={seccionedProduct.filter(item => !!item.products.length)}
                    title={title}
                    position={indexTabMenu}
                    onClickTab={onClickTabMenu}
                    onClickBack={onClickBack}
                />
                {seccionedProduct.length > 1 && (
                    <div className={styles.tab}>
                        <TabMenu
                            position={indexTabMenu}
                            onClick={onClickTabMenu}
                            seccionedProducts={seccionedProduct}
                        />
                    </div>
                )}
                <ProductList
                    onClickProduct={onClickProductItemHandle}
                    offset={65}
                    ref={refProductList}
                    onChangeCategory={(index) => setIndexTabMenu(index)}
                    prefixPrice={prefixPrice}
                    sectionedProducts={seccionedProduct}
                />
            </div>
        </div>
    )
}
export default ProductListCategory