import { IAppointments } from "pages/public/schedule/interfaces/IScheduled";
import api from "../Api";
import { IDemandedPlaceScheduleResponse } from "../../../pages/public/schedule/interfaces/api/IDemandedPlaceScheduleResponse";

export const ScheduledApi = () => {
    
    const getDemandedSchedulesByUserService = async () => {
        return await api.get<{ items: IAppointments[] }>("/DemandSchedule/DemandedSchedulesByUserService")
    }

    const getDemandedPlaceScheduleWithClientsByUser = async (clientId: string): Promise<{ items: IDemandedPlaceScheduleResponse[] }> => {
        const response = await api.get<{ items: IDemandedPlaceScheduleResponse[] }>("/DemandSchedule/GetDemandedPlaceScheduleWithClientsByUser");
        return response.data
    }

    return {
        getDemandedSchedulesByUserService,
        getDemandedPlaceScheduleWithClientsByUser
    }
}

export default ScheduledApi


