import { IUserRegisterResponse, IUserDocumentResponse, IUserRegisterRequest, IUser } from "../../../models/user/IUser";
import api from "../Api";

const UserApi = () => {

    const register = async (data: IUserRegisterRequest) => {
        return await api.post<IUserRegisterResponse>('/Account/Register', { ...data, cpf: data.cpf.replace(/[^0-9]/g, "") });
    }
    const getEmailByCpf = async (cpf: string) => {

        return await api.post<IUserDocumentResponse>('/SuperApp/User/GetByCpf', { cpf });
    }
    const getUserData = async (): Promise<IUser> => {
        const response = await api.get('/Account/userbasicinfo');
        return response.data
    }
    return {
        register,
        getEmailByCpf,
        getUserData
    }
}
export default UserApi;