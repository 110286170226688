import React, { FC } from 'react'
import styles from './StoreItem.module.scss'
import Image from '../../../../../components/ui/_image/Image'
import { IStoreItem } from './IStoreItem'

export interface IStoreItemProps {
    //propertys
    store: IStoreItem
    onClick: (store: IStoreItem) => void
}

const StoreItem: FC<IStoreItemProps> = ({ store, onClick }) => {
    return (
        <div id={styles.StoreItem} >
            {/* <Link to={(location) => `/${url}/${store.id}`}> */}
            <div onClick={() => onClick(store)} className={styles.container} style={{ backgroundImage: `url(${store.imageUrl})` }} >
                <div className={styles.descriptionContainer}>
                    {store.logoUrl &&
                        <div className={styles.logo}>
                            {store.imageUrl && <Image src={store.imageUrl} width={100} height={100} className={styles.image}></Image>}
                        </div>
                    }
                    <div className={styles.description}>
                        <h2>{store.name}</h2>
                    </div>
                </div>
            </div>
            {/* </Link> */}
        </div>
    )
}
export default StoreItem