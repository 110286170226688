import Container from "components/ui/Container/Container";
import { PrivateHeader } from "pages/private/Components/privateHeader/PrivateHeader";
import React, { FC, useCallback, useEffect } from "react";
import { useCatalog } from "../../../../context/catalog/CatalogContext";
import Header from "../products/components/Header/Header";
import { useHistory, useParams } from "react-router-dom";
import { IStoreItem } from "./StoreItem/IStoreItem";
import StoreItem from "./StoreItem/StoreItem";
import styles from "./StoresPage.module.scss";
import { useTheme } from "context/theme/ThemeContext";
import { useParamsConfig } from "context/paramsConfig/ParamsConfigContext";
export interface IStoresPageProps {
  //propertys
}
const StoresPage: FC<IStoresPageProps> = () => {


  const { catalog, onLoading, storesLengthRedirect } = useCatalog();
  const { url } = useParams<{ url: string }>();
  const { push } = useHistory();
  const { topImage } = useTheme();
  const { setParamsLocalId } = useParamsConfig()



  useEffect(() => {
    storesLengthRedirect();
  }, [storesLengthRedirect])

  const onClickHandle = useCallback(
    (store: IStoreItem) => {
      setParamsLocalId(store.id);
      push(`/${url}/${store.id}`)
    },
    [push, setParamsLocalId, url],
  )

  return (
    <div id={styles.StoresPage}>
      <Container>
        {
          onLoading ? <div>Carregando catalogo</div>
            :
            (
              catalog &&
              <>  <PrivateHeader title={catalog.name}></PrivateHeader>
                <div className={styles.header} >
                  <Header backgroundImage={topImage ?? catalog.logoUrl}></Header>
                </div>
                <div className={styles.container}>
                  <div className={styles.storesList}>
                    {
                      catalog?.stores?.map((store) => <StoreItem onClick={onClickHandle} store={store}></StoreItem>)
                    }
                  </div>
                </div>
              </>
            )
        }
      </Container>
    </div>
  );
};
export default StoresPage;
