
import React, { FC, useEffect, useState } from 'react'
import styles from './TourPage.module.scss'
import Layout from 'components/layout/Layout'
import UseTourPage from './UseTourPage'
import { useLocation } from 'react-router-dom'
import CartTour from '../components/cartTour/CartTour'
import { Button, Dialog } from '@material-ui/core'
import TourItem from './tourItem/TourItem'

export interface ITourPageProps {
    //propertys
}
const TourPage: FC<ITourPageProps> = () => {

    const { storesTour, onClickStoreTourHandle, aboutProject, onClickAboutProject } = UseTourPage();

    const query = new URLSearchParams(useLocation().search)
    const [showModal, setShowModal] = useState(false);


    useEffect(() => {
        const operatorId = query.get("operatorId");

        if (operatorId) {
            setShowModal(true);
        }
        return () => {

        }
    }, [])

    return (
        storesTour ?
            <Layout>
                <div id={styles.TourPage} >
                    <div className={styles.storesTour}>

                        {storesTour.map((item) => (
                            !item.name.includes("**") &&
                            <TourItem values={item} onClick={() => onClickStoreTourHandle(item.id)}></TourItem>
                        ))}
                        <TourItem values={aboutProject} onClick={onClickAboutProject}></TourItem>
                    </div>

                    <CartTour></CartTour>

                </div>
                <Dialog open={showModal} onClose={() => setShowModal(false)}>
                    <div className={styles.modalMessage} onClick={() => setShowModal(false)}>

                        <b > Experimente a Serra Gaúcha do seu jeito!  </b>
                        <p >Tour Aproveite Gramado e Canela
                            De R$ 89,00 por R$ 20,00
                            Acesse a aba de passeios e garanta sua reserva!</p>
                        <Button variant="contained" fullWidth color={'primary'} onClick={() => setShowModal(false)}> Continuar</Button>
                    </div>
                </Dialog>
            </Layout>
            :
            <div></div>
    )
}
export default TourPage