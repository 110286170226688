import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, Icon, IconButton } from '@material-ui/core'
import BackButton from 'components/ui/backButton/BackButton'
import React, { FC, useCallback, useState } from 'react'
import { Header } from '../../../../components/header/Header'
import { useAuth } from '../../../../context/auth/AuthContext'
import { useUi } from '../../../../context/ui/UIContext'
import Menu from '../../privateLayout/menu/Menu'
import './PrivateHeader.css'

interface PrivateHeaderProps {
    // onClickMenu?: () => void
    menuDisabled?: boolean;
    confirmBack?: boolean;
    onClickBack?: () => void;
    title?: string
}
export const PrivateHeader: FC<PrivateHeaderProps> = ({ menuDisabled, onClickBack, confirmBack, title }) => {
    const { logout, isAuth, openModalLogin } = useAuth();
    const { isIframe } = useUi();
    const [openMenu, setopenMenu] = useState(false);

    const [dialogLogoutOpen, setdialogLogoutOpen] = useState(false);
    const [dialogSairOpen, setdialogSairOpen] = useState(false);

    const onClickLogout = () => {
        logout();
        setdialogLogoutOpen(false);
    }

    const onClickConfirmBack = useCallback(() => {
        if (isIframe) {
            window.parent.postMessage({ command: "CLOSE" }, "*")
        } else {
            onClickBack && onClickBack();
        }
    }, [isIframe, onClickBack])

    const onClickBackHandle = useCallback(
        () => {
            if (confirmBack) {
                setdialogSairOpen(true);
            } else {
                onClickConfirmBack();
            }
        },
        [confirmBack, onClickConfirmBack],
    )


    const onClickLogin = useCallback(() => {
        openModalLogin();
    }, [openModalLogin])

    const onClickMenu = useCallback(() => {
        setopenMenu(prev => !prev);
    }, [])

    return (
        <div id={"privateHeader"}>
            <Header
                start={
                    <div className="iconButtonContainer">
                        {
                            (isIframe || onClickBack) &&
                            <BackButton className={'backButton'} onClick={onClickBackHandle} />
                        }
                    </div>
                }
                center={
                    <h2>{title}</h2>
                }
                end={
                    <>
                        {
                            menuDisabled ?
                                <div className="iconButtonContainer">
                                    {/* <IconButton><Icon className="icon">account_circle</Icon></IconButton> */}
                                    <IconButton onClick={() => { setdialogLogoutOpen(true) }}><Icon className="icon">logout</Icon></IconButton>
                                </div>
                                :
                                <div className="iconButtonContainer">
                                    <IconButton onClick={onClickMenu}><Icon className="icon">menu</Icon></IconButton>
                                </div>
                        }
                    </>
                }
            />
            <Dialog open={dialogLogoutOpen} onClose={() => { setdialogLogoutOpen(false) }} >
                <DialogTitle>Sair?</DialogTitle>
                <DialogContent>
                    Você tem certeza que deseja sair?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setdialogLogoutOpen(false) }} variant="outlined" >Cancelar</Button>
                    <Button onClick={onClickLogout} variant="outlined" >Sair</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={dialogSairOpen} onClose={() => { setdialogSairOpen(false) }} >
                <DialogTitle>Pedido em andamento</DialogTitle>
                <DialogContent>
                    Seu pedido ainda não foi concluído tem certeza que deseja voltar?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setdialogSairOpen(false) }} variant="outlined" >Cancelar</Button>
                    <Button onClick={onClickConfirmBack} variant="outlined" >Voltar as Compras</Button>
                </DialogActions>
            </Dialog>

            <Drawer anchor="right" open={openMenu} style={{ minWidth: 250 }} onClose={() => setopenMenu(false)}>
                <Menu
                    onClickOut={isAuth ? () => setdialogLogoutOpen(true) : undefined}
                    onClickLogin={!isAuth ? () => onClickLogin() : undefined} />

            </Drawer>
        </div>
    )
}
