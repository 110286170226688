import { Button, Grid, TextField } from '@material-ui/core'
import { Formik, Form } from 'formik'
import React, { FC, useState } from 'react'
import { cpfMask } from '../../../../../cross-cutting/masks/cpf'

interface FormCpfProps {
    onSubmit: (cpf: string) => void
}
export const FormCpf: FC<FormCpfProps> = ({ onSubmit }) => {
    const [formObject, setformObject] = useState<{ cpf: string }>({} as { cpf: string })

    const onsubmit = () => {
        onSubmit(formObject.cpf);
    }


    const onChangeCpf = (cpf: string) => {
        setformObject(prev => ({ cpf: cpfMask(cpf) }))
    }


    return (
        <Formik
            initialValues={formObject}
            onSubmit={onsubmit}
        >{
                ({
                    handleSubmit,
                    handleChange,
                    values,
                    isValid,
                    errors,
                    touched
                }) =>
                    <Form onSubmit={handleSubmit}>
                        {
                            <Grid container spacing={1} >
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        fullWidth
                                        name="cpf"
                                        value={formObject.cpf}
                                        onChange={(e) => onChangeCpf(e.target.value)}
                                        label="CPF"
                                        inputProps={{
                                            type: "tel",
                                        }}
                                    >

                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button

                                        fullWidth size="large"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        Cadastrar
                                        </Button>
                                </Grid>
                            </Grid>
                        }
                    </Form>
            }
        </Formik>
    )
}
