import React, { FC, useCallback, useEffect, useState } from 'react'
import './ResumeView.css'
import { Dialog, DialogContent, DialogContentText, Icon } from '@material-ui/core'
import { IOrderDetail, IOrderDetailItem } from '../../../models/order/aggregates/datail/IOrderDetail'
import { moneyMaskNumber } from 'cross-cutting/masks/money'
import { IPaymentLinkInstallments } from 'models/paymentLink/IPaymentLinkResponse'
import { CoupomType, CouponValueType, ICoupon } from 'models/coupon/ICoupon'
import CalcCoupon from '../coupon/CalcCoupon'
import styles from './ResumeView.module.scss'

interface resumeViewProps {
    orderDetail: IOrderDetail | null,
    installment?: IPaymentLinkInstallments,
    deliveryFee?: number | null,
    serviceFee?: number | null,
    coupon?: ICoupon,
    setAcceptServiceFee?: (value: boolean) => void
}

interface Composition {
    Descricao: String;
    Id: string;
    Opcoes: Array<any>
}

interface CompositionOption {
    Descricao: string;
    Id: string;
    ProdutoId: string;
    ProdutoValor: number;
    Quantidade: number;
}

export const ResumeView: FC<resumeViewProps> = ({ orderDetail, deliveryFee, installment, coupon, serviceFee, setAcceptServiceFee }) => {
    const [openServiceFee, setOpenServiceFee] = useState(false);
    const [acceptServiceFee, setCheckAcceptServiceFee] = useState(true);
    const [orderDetailHandled, setOrderDetailHandled] = useState<IOrderDetail | null>();

    const totalCart = orderDetail?.itens.reduce((acc, item) => acc + item.unitValue * item.quantity, 0)

    const orderDetailHandler = useCallback(() => {
        if (orderDetail) {
            const newOrderDetail = orderDetail.itens.map((orderItem: IOrderDetailItem) => {
                let additionalValue = 0;

                if (orderItem.composition.length !== 0) {
                    const composition = JSON.parse(orderItem.composition);
                    composition.map((compositionItem: Composition) => {
                        additionalValue += compositionItem.Opcoes.reduce((acc: number, item: CompositionOption) => acc + (item.Quantidade * item.ProdutoValor), 0);
                    })
                }

                return ({
                    ...orderItem,
                    totalValue: additionalValue + (orderItem.quantity * orderItem.unitValue)
                })
            })

            setOrderDetailHandled(() => ({
                ...orderDetail,
                itens: newOrderDetail,
            }))
        }
    }, [orderDetail]);

    useEffect(() => {
        orderDetailHandler();
    }, [orderDetailHandler])
       

    return (
        <div className={styles.container}>
            <div className={styles.title}>Pagamento</div>
            <div className={styles.details}>
                {orderDetailHandled?.itens.map((resumeItem) => (
                    <div className={styles.itemResume} key={resumeItem.productId}>
                        <div className={styles.quantityContainer}>
                            <span className={styles.quantityText}>{resumeItem.quantity}</span>
                        </div>
                        <div className={styles.productName}>
                            {resumeItem.name}
                        </div>
                        <div className={styles.productPrice}>
                            {moneyMaskNumber(resumeItem.totalValue || 0)}
                        </div>
                    </div>
                ))}

                {(!!deliveryFee) &&
                    <div className={styles.itemResume}>
                        <div className={styles.quantityContainer}>
                            <Icon className={styles.deliveryFee}>place</Icon>
                        </div>

                        <div className={styles.productName}>
                            Taxa de entrega
                        </div>
                        <div className={styles.productPrice}>
                            {moneyMaskNumber(deliveryFee)}
                        </div>
                    </div>
                }

                {(!!serviceFee) &&
                    <div className={styles.itemResume}>
                        <div className={styles.quantityContainer}>
                            <Icon color="primary">percent</Icon>
                        </div>

                        <div className={styles.productName}>
                            Taxa de servico
                        </div>
                        <button className={styles.infoFeeButton} onClick={() => setOpenServiceFee(true)} >
                            <Icon className={styles.infoFeeIcon}>info</Icon>
                        </button>
                        <div className={styles.productPrice}>
                            {moneyMaskNumber(serviceFee)}
                        </div>
                    </div>
                }

                {(!!coupon && !!orderDetailHandled) &&
                    <div className={styles.itemResume}>
                        <div className={styles.quantityContainer}>
                            <Icon color="primary">local_activity</Icon>
                        </div>

                        <div className={styles.productName}>
                            {coupon.type === CoupomType.cashback ? `Cashback` : `Desconto`}
                            {coupon.valueType === CouponValueType.percentage && `(${coupon.value}%)`}
                        </div>
                        <div className={styles.productPrice}>
                            {moneyMaskNumber(CalcCoupon(totalCart || 0, coupon).value)}
                        </div>
                    </div>
                }

                <div className={styles.totalRow}>
                    <div className={styles.itemResume}>
                        <div className={styles.quantityContainer}>
                            <Icon color="primary">monetization_on</Icon>
                        </div>

                        <div className={styles.productName}>
                            Total{installment ? ` (${installment.description})` : ''}
                        </div>
                        <div className={styles.productPrice} style={{ fontWeight: 700 }}>
                            {
                                installment ?
                                    moneyMaskNumber((installment.price ?? 0) + (deliveryFee ?? 0)) + (serviceFee ?? 0) ?? "R$ 0,00" :
                                    moneyMaskNumber(CalcCoupon(orderDetailHandled?.amount ?? 0, coupon).total + (deliveryFee ?? 0) + (serviceFee ?? 0)) ?? "R$ 0,00"
                            }
                        </div>
                    </div>
                </div>
            </div>


            <Dialog open={openServiceFee} onClose={() => setOpenServiceFee(false)}>
                <DialogContent className={styles.modalContent}>
                    <DialogContentText className={styles.modalHeader}>
                        <span className={styles.modalFeeTitle}>Taxa de serviço</span>
                        <button className={styles.closeButton} onClick={() => setOpenServiceFee(false)}>
                            <Icon className={styles.closeIcon}>close</Icon>
                        </button>
                    </DialogContentText>
                    <DialogContentText>
                        <div className={styles.modalRow}>
                            <Icon color={`primary`} onClick={() => setCheckAcceptServiceFee(prev => !prev)}>
                                {acceptServiceFee ? 'check_box_outline' : 'check_box_outline_blank'}
                            </Icon>
                            <span className={styles.modalFeeDescription}>
                                Incluir taxa de serviço
                            </span>
                        </div>
                        <button onClick={() => { setOpenServiceFee(false); setAcceptServiceFee?.(acceptServiceFee) }} className={styles.modalConfirButton}>Confirmar</button>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    )
}
