import { IGetMainServicesCategoryByLocalIdResponse, IGetServicesCategoryByLocalIdResponse } from "../../_domain/dto/ReservePlaceSchedule/GetServicesByLocalIdResponseDTO";
import { IProductTourCategory } from "../../_domain/_models/IProductTourCategory";
import { IStoreTour } from "../../_domain/_models/IStoreTour";
import { IStoreTourListResponse } from "../../_domain/_models/IStoreTourListResponse";



export const CategoryServiceToStoreTourListResponseUseCase = (localId: string, responseServices: IGetServicesCategoryByLocalIdResponse): IStoreTourListResponse => {
    const data: IStoreTourListResponse = {
        items: [{
            id: localId,
            name: "",
            imageUrl: "",
            logoUrl: "",
            productTourCategory: responseServices.items.map<IProductTourCategory>((item) => ({
                id: item.id,
                title: item.name,
                imageUrl: "",
                products: item.services.map((serviceItem) => ({
                    id: serviceItem.id,
                    name: serviceItem.name,
                    description: "",
                    thumbnailUrl: serviceItem.imageUrl,
                    imageUrl: serviceItem.imageUrl,
                    price: !!serviceItem.prices.length ? Math.min(...serviceItem.prices.map((item) => item.price)) : 0
                })),
            }))
        }]
    };
    return data;
};


export const CategoryMainServiceToStoreTourListResponseUseCase = (responseMainServices: IGetMainServicesCategoryByLocalIdResponse): IStoreTourListResponse => {
    const data: IStoreTourListResponse = {
        items: responseMainServices.items.map<IStoreTour>((item) => ({
            id: item.id,
            name: item.name,
            imageUrl: item.imageUrl,
            logoUrl: "",
        }))
    };
    return data;
};




export const CategoryServiceToStoreTourResponseUseCase = (storeId: string, responseServices: IGetServicesCategoryByLocalIdResponse): IStoreTour => {
    const data: IStoreTour = {

        id: storeId,
        name: "",
        imageUrl: "",
        logoUrl: "",
        productTourCategory: responseServices.items.map<IProductTourCategory>((item) => ({
            id: item.id,
            title: item.name,
            imageUrl: "",
            products: item.services.map((serviceItem) => ({
                id: serviceItem.id,
                name: serviceItem.name,
                description: serviceItem.description,
                thumbnailUrl: serviceItem.imageUrl,
                imageUrl: serviceItem.imageUrl,
                price: !!serviceItem.prices.length ? Math.max(...serviceItem.prices.map((item) => item.price)) : 0
            })),
        }))
    }

    return data;
};
