import { Button, Icon } from '@material-ui/core'
import React, { FC } from 'react'
import { IProfile } from '../../profile/interfaces/IProfile'
import ProfilePicture from '../profilePicture/ProfilePicture'
import SocialMedias from '../socialMedias/SocialMedias'
import styles from './ProfileHeader.module.scss'
export interface IProfileHeaderProps {
    //propertys
    profile: IProfile;
}
const ProfileHeader: FC<IProfileHeaderProps> = ({ profile }) => {
    return (
        <div id={styles.ProfileHeader} >
            <div className={styles.container} >
                <div className={styles.backgroundContainer} >
                    <ProfilePicture photoUrl={profile.photoUrl} />
                </div>
                <div className={styles.name}>
                    <h2>{profile.name}</h2>
                </div>
                <div className={styles.info}>
                    {!!profile.age && <h3>{profile.age} anos</h3>}
                    {profile.from && <> <Icon className={styles.icon}>circle</Icon>
                        <h3>{profile.from}</h3></>}
                </div>
                <div className={styles.data}>
                    <div className={styles.item}>
                        <div className={styles.value}>
                            <h2>95</h2>
                        </div>
                        <div className={styles.label}>
                            Seguidores
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.value}>
                            <h2>12</h2>
                        </div>
                        <div className={styles.label}>
                            Lugares Visitados
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.value}>
                            <h2>6</h2>
                        </div>
                        <div className={styles.label}>
                            Eventos
                        </div>
                    </div>
                </div>
                <div className={styles.actions}>
                    <Button color={"primary"} fullWidth className={styles.actionButton} variant={"contained"}>Seguir</Button>
                    <Button color={"primary"} fullWidth className={styles.actionButton} variant={"outlined"}>Mensagem</Button>
                </div>
                <div className={styles.medias}>
                    <SocialMedias social={profile.social} />
                </div>

            </div>
        </div>
    )
}
export default ProfileHeader