import { CoupomType, CouponValueType, ICoupon } from "models/coupon/ICoupon"
import { PaymentType } from "models/payment/IPay";

const CalcCoupon = (totalWithoutDelivery: number, coupon?: ICoupon, PaymentType?: PaymentType) => {

    let value = 0;
    let error = "";
    let ableTo = true;
    if (coupon) {
        if (coupon.minOrderValue && totalWithoutDelivery < coupon.minOrderValue) {
            error = "Não atingiu o valor mínimo";
            ableTo = false;
        } else {
            if (CouponValueType.currency === coupon.valueType) {
                value = coupon.value;
                if (value > totalWithoutDelivery) {
                    value = totalWithoutDelivery
                }
            } else if (CouponValueType.percentage === coupon.valueType) {
                value = (totalWithoutDelivery * coupon.value) / 100;
                if (coupon.maxValue && value > coupon.maxValue) {
                    value = coupon.maxValue
                }
            }
        }
    }
    return ({
        total: totalWithoutDelivery - (coupon?.type === CoupomType.cashback ? 0 : value),
        value: value,
        id: coupon?.id ?? "",
        ableTo: ableTo,
        errorMessage: error
    })
}
export default CalcCoupon