import React, { useEffect, useCallback, useState, FC } from "react";
import { Grid, Icon, Modal, TextField } from "@material-ui/core";
import AddressApi from "../../../services/Api/address/AddressApi";
import { IAddress } from "../../../models/address/IAddress";
import { DeliveryAddressItem } from "./deliveryAddressItem/DeliveryAddressItem";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useUi } from "../../../context/ui/UIContext";
import { usePayment } from "../../../context/payment/PaymentContext";
import { DeliveryType } from "../../../models/delivery/deliveryFee";
import { NewAddress } from "../../newAddress/NewAddress";
import { IStoreConfig } from "models/local/ILocalConfig";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { IOrderPads } from "../UseLocalOrderPads";
import styles from './DeliverySelect.module.scss';

interface IPlace {
  number: string;
}
interface DeliverySelectProps {
  address: IAddress | null;
  setAddress: (address: IAddress | null) => void;
  storeConfig: IStoreConfig | null;
  deliveryType: DeliveryType;
  setDeliveryType?: (deliveryType: DeliveryType) => void;
  setCustomDeliveryDescription?: (value: string) => void;
  customDeliveryDescription?: string;
  title?: string;
  localOrderPads?: IOrderPads[];
  onChangeSelectedOrderPads?: (orderPad: IOrderPads) => void
  selectedOrderPad?: IOrderPads;
  fixedPlace?: boolean
}
export const DeliverySelect: FC<DeliverySelectProps> = ({
  setAddress,
  title,
  address,
  storeConfig,
  setDeliveryType,
  deliveryType,
  setCustomDeliveryDescription,
  customDeliveryDescription,
  localOrderPads,
  selectedOrderPad,
  onChangeSelectedOrderPads
}) => {
  const [addresses, setaddresses] = useState<IAddress[]>([]);
  const [selectAdressOpen, setselectAdresOpen] = useState(false);
  const [newAdressOpen, setNewAdresOpen] = useState(false);
  const [loadingAddresses, setloadingAddresses] = useState(false);
  const { toast, showLoading, hideLoading } = useUi();
  const [countDeliveryType, setCountDeliveryType] = useState(0);

  const { updateTableLocalStorage } = usePayment();

  const getAddresses = useCallback(async () => {
    try {
      setloadingAddresses(true);
      const response = await AddressApi.getUserAdresses();
      if (response.status === 200) {
        setaddresses(response.data);
        setAddress(response.data.find((address) => address.default) ?? null);
      }
    } catch (error) {
    } finally {
      setloadingAddresses(false);
    }
  }, [setAddress]);
  useEffect(() => {
    getAddresses();
  }, [getAddresses]);

  const onChangeType = (type: DeliveryType.delivery | DeliveryType.catch | DeliveryType.other) => {
    setDeliveryType && setDeliveryType(type);
  };

  const onClickAdd = useCallback(() => {
    // history.replace(`${url}/newAddress`);
    setNewAdresOpen(true);
  }, []);

  const onPressDeleteAddress = useCallback(
    async (address: IAddress) => {
      try {
        showLoading();
        const responseDelete = await AddressApi.deleteAddress(address.id);
        setaddresses((prev) =>
          prev.filter((_address) => _address.id !== address.id)
        );
      } catch (error: any) {
        if (error?.response)
          toast(error?.response?.data?.message ?? "Ops, não foi possivel excluir endereço", "error");
      } finally {
        hideLoading();
      }
    },
    [hideLoading, showLoading, toast]
  );

  const onSelect = useCallback(
    async (address: IAddress) => {
      try {
        showLoading();
        const responseDelete = await AddressApi.setDefaultAddress(address.id);
        setselectAdresOpen(false);
        getAddresses();
      } catch (error: any) {
        toast(
          error?.response?.data?.message ??
          "Ops, não foi possivel excluir endereço",
          "error"
        );
      } finally {
        hideLoading();
      }
    },
    [getAddresses, hideLoading, showLoading, toast]
  );

  useEffect(() => {
    let count = 0;

    if (setDeliveryType) {
      if (storeConfig?.allowCustomDelivery) {
        setDeliveryType(DeliveryType.other);
        count++;
      }
      if (storeConfig?.allowCatchOnShop) {
        setDeliveryType(DeliveryType.catch);
        count++;
      }
      if (storeConfig?.allowDeliveryHome) {
        setDeliveryType(DeliveryType.delivery);
        count++;
      }
    }

    setCountDeliveryType(count);

    return () => { };
  }, [setDeliveryType, storeConfig]);

  return (
    <Grid container>
      <Modal
        open={newAdressOpen}
        onClose={() => {
          setNewAdresOpen(false);
        }}
        className={styles.modalContainer}
      >
        <NewAddress
          onSuccess={() => {
            getAddresses();
            setNewAdresOpen(false);
          }}
          onCancel={() => setNewAdresOpen(false)}
        />
      </Modal>
      <Modal
        open={selectAdressOpen}
        onClose={() => {
          setselectAdresOpen(false);
        }}
        className={styles.modalContainer}
      >
        <div className={styles.addressModalContainer}>
          <h4>Selecione o endereço</h4>
          {addresses?.length > 0 && (
            <div
              style={{ padding: "16px", maxHeight: "70vh", overflowY: "auto" }}
            >
              {addresses.map((address, index) => (
                <DeliveryAddressItem
                  key={index}
                  address={address}
                  onClickRemove={() => onPressDeleteAddress(address)}
                  onClick={() => onSelect(address)}
                />
              ))}
            </div>
          )}
          <button className={styles.modalSubmitButton} onClick={onClickAdd}>
            <Icon className={styles.icon}>add</Icon>
            <span className={styles.text}>Adicionar Novo Endereço</span>
          </button>
        </div>
      </Modal>

      {(storeConfig?.allowDeliveryHome ||
        storeConfig?.allowCatchOnShop ||
        storeConfig?.allowCustomDelivery) && (
          <Grid container spacing={1}>
            <Grid xs={12} item>
              <div className={styles.title}>{title}</div>
            </Grid>
            {countDeliveryType > 1 && (
              <div className={styles.deliveryAddressContainer}>
                {storeConfig?.allowDeliveryHome && (
                  <button
                    className={`${styles.deliveryAddressButon} ${deliveryType === DeliveryType.delivery ? styles.selected : ''}`}
                    onClick={() => onChangeType(DeliveryType.delivery)}
                  >
                    <Icon className={styles.buttonIcon}>store</Icon>
                    <span className={styles.buttonText}>Na entrega</span>
                  </button>
                )}

                {storeConfig?.allowCatchOnShop && (
                  <button
                    className={`${styles.deliveryAddressButon} ${deliveryType === DeliveryType.catch ? styles.selected : ''}`}
                    onClick={() => onChangeType(DeliveryType.catch)}
                  >
                    <Icon className={styles.buttonIcon}>store</Icon>
                    <span className={styles.buttonText}>Na retirada</span>
                  </button>
                )}

                {storeConfig?.allowCustomDelivery && (
                  <button
                    className={`${styles.deliveryAddressButon} ${deliveryType === DeliveryType.other ? styles.selected : ''}`}
                    onClick={() => onChangeType(DeliveryType.other)}
                  >
                    <Icon className={styles.buttonIcon}>room_service</Icon>
                    <span className={styles.buttonText}>Outro</span>
                  </button>
                )}
              </div>
            )}
          </Grid>
        )}

      <Grid xs={12} item>
        {deliveryType === DeliveryType.delivery && (
          <DeliveryAddressItem
            onClickEdit={() => {
              setselectAdresOpen(true);
            }}
            loading={loadingAddresses}
            onClick={() => setselectAdresOpen(true)}
            address={address}
          />
        )}


        {
          deliveryType === DeliveryType.catch && (
            <div className={styles.deliveryCatchMessage}>Retirar no balcão</div>
          )
        }

        {!storeConfig?.allowTableDelivery && deliveryType === DeliveryType.other && (
          <DeliveryAddressItem onClick={() => { }}>
            {storeConfig?.customDeliveryDescription}
            <TextField
              autoFocus
              onChange={(e) =>
                setCustomDeliveryDescription &&
                setCustomDeliveryDescription(e.target.value)
              }
              value={customDeliveryDescription}
            ></TextField>
          </DeliveryAddressItem>
        )}

        {/* {!storeConfig?.allowTableDelivery && deliveryType === DeliveryType.other && (
         <div className={styles.contentDeliveryDescription}>
            <span>{storeConfig?.customDeliveryDescription}</span>
         </div>
        )} */}

        {
          storeConfig?.allowTableDelivery && !!localOrderPads?.length && deliveryType === DeliveryType.other && (
          <DeliveryAddressItem onClick={() => { }}>
            {storeConfig?.customDeliveryDescription}
            <Autocomplete
              autoSelect
              options={localOrderPads}
              value={selectedOrderPad}
              getOptionLabel={(option) => option.number}
              onChange={(e, _value) => {
                onChangeSelectedOrderPads &&
                  _value &&
                  onChangeSelectedOrderPads(_value)
                _value &&
                  updateTableLocalStorage(_value)
                setCustomDeliveryDescription &&
                  _value &&
                  setCustomDeliveryDescription(_value.number);
              }}
              renderInput={(inputParams) => <TextField {...inputParams} size="small" style={{ marginLeft: 8 }} />}
            />
          </DeliveryAddressItem>
        ) 
      }
      </Grid>
    </Grid>
  );
};
