import { IProductComposition } from 'models/catalog/aggregates/Composition'
import React, { FC, memo } from 'react'
import BoxProductComposition from '../boxProductComposition/BoxProductComposition'
import styles from './ProductCompositionList.module.scss'
export interface IProductCompositionListProps {
    productCompositions: IProductComposition[]
}

const ProductCompositionList: FC<IProductCompositionListProps> = memo(({ productCompositions }) => {
    return (
        <div id={styles.ProductCompositionList}>
            <div className={styles.container}>
                {
                    productCompositions.map((productComposition) => (
                        <BoxProductComposition key={productComposition.id} productComposition={productComposition} />
                    ))
                }
            </div>
        </div>
    )
})

export default ProductCompositionList