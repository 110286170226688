import React, { FC, useCallback, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { TextField, MenuItem, CircularProgress, Checkbox, FormControlLabel, Paper, IconButton } from '@material-ui/core';
import { Formik, Form } from 'formik';

import * as yup from 'yup'; // for everything 
import { useUi } from '../../context/ui/UIContext';
import { ICreditCardAddRequest, ICreditCardAddForm, ICreditCardOwnerForm } from '../../models/payment/ICard';
import CardApi from '../../services/Api/card/CardApi';
import { CardPreview } from './cardPreview/CardPreview';
import { useLocal } from '../../context/local/LocalContext';
import { DeliverySelect } from 'pages/payment/deliverySelect/DeliverySelect';
import { DeliveryType } from 'models/delivery/deliveryFee';
import { IAddress } from 'models/address/IAddress';
import { useAuth } from 'context/auth/AuthContext';
import styles from './NewCard.module.scss';
import { CloseOutlined } from '@material-ui/icons';

interface INewCardProps {
    onCancel?: () => void
    onConfirm?: () => void
    title?: string;
}

export const NewCard: FC<INewCardProps> = ({ onCancel, onConfirm, title }) => {

    const { hideLoading, toast } = useUi();
    const [card, setCard] = useState<ICreditCardAddForm>({ ...{} as ICreditCardAddForm, customer: {} as ICreditCardOwnerForm, isOwner: true })
    // const { userId } = useParams<{ userId: string }>();
    const { storeConfig } = useLocal();
    const { user } = useAuth();
    const [loadingSave, setLoadingSave] = useState(false);
    const [address, setAddress] = useState<IAddress | null>(null)

    const cardSchema = yup.object<ICreditCardAddForm>().shape({
        number: yup
            .string()
            .required()
            .max(16)
            .min(16, "Insira um cartão válido"),

        securityCode: yup
            .string()
            .min(3, "Código de Segurança Inválido")
            .max(4)
            .required(),
        ownerName: yup
            .string()
            .required()
            .min(5, "Insira um nome válido"),
        customer: yup.object<ICreditCardOwnerForm>().when("isOwner",
            {
                is: false,
                then: yup.object<ICreditCardOwnerForm>().shape({
                    name: yup.string().required(),
                    cpf: yup.string().required().length(11, "Insira um CPF valido"),
                    phoneNumber: yup.string().required(),
                    email: yup.string().email().required(),
                })
            }
        )
    });

    const onsubmit = useCallback(async (cardValue: ICreditCardAddForm) => {
        try {
            setLoadingSave(true)

            const creditCardAddRequest: ICreditCardAddRequest = {
                number: cardValue.number,
                securityCode: cardValue.securityCode,
                ownerName: cardValue.ownerName,
                month: cardValue.month,
                year: cardValue.year,
                customer:
                    cardValue.isOwner ?
                        {
                            name: cardValue.ownerName,
                            cpf: user.cpf,
                            email: user.email,
                            phoneNumber: user.ddd + user.phoneNumber,
                            addressId: address?.id ?? ""
                        }
                        :
                        {
                            ...cardValue.customer,
                            addressId: address?.id ?? ""
                        }
            }

            const response = await CardApi.saveCreditCard(creditCardAddRequest);
            if (response) {
                if (response.status === 201 || response.status === 200) {
                    toast("Cartao adicionado com sucesso", "success");
                    onConfirm && onConfirm();
                }

            }
        }
        catch (error: any) {
            hideLoading();
            if (error.response.status === 400) {
                if (error.response.data.message) {
                    // toast(error.response.data.message, "error");
                }
            }
        } finally {
            setLoadingSave(false)
        }

    }, [address, hideLoading, onConfirm, toast, user.cpf, user.ddd, user.email, user.phoneNumber])

    const numberMask = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newEvent: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> = e;
        newEvent.target.value = e.target.value.replace(/[^0-9]/g, '').substr(0, 16)
        return newEvent
    }
    const cvvMask = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newEvent: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> = e;
        newEvent.target.value = e.target.value.replace(/[^0-9]/g, '').substr(0, 4)
        return newEvent
    }

    return (
        <Container
            className={styles.addCard}
            maxWidth="xs"
            style={{
                display: "flex",
                flexDirection: "column",
                borderRadius: 8,
                color: "var(--text)",
                justifyContent: "start",
                alignItems: "center"
            }}>
            <Paper style={{ padding: 16 }}>
                {title && (
                    <h4>
                        {title}
                        <IconButton onClick={onCancel}>
                            <CloseOutlined />
                        </IconButton>
                    </h4>
                )}
                <Formik
                    initialValues={card}
                    onSubmit={onsubmit}
                    validationSchema={cardSchema}
                >{
                        ({ handleSubmit, handleChange, values, isValid, errors, touched }) =>
                            <Form onSubmit={handleSubmit}>
                                <CardPreview {...values} />
                                <Grid spacing={1} alignContent="center" alignItems="center" container>
                                </Grid>
                                <Grid spacing={2} alignContent="center" alignItems="center" container>
                                    <Grid style={{}} item xs={12}>
                                        <TextField
                                            required
                                            autoComplete={"off"}
                                            helperText={touched.number && errors.number}
                                            error={touched.number && Boolean(errors.number)}
                                            variant="outlined"
                                            fullWidth
                                            onChange={(e) => handleChange(numberMask(e))}
                                            value={values.number}
                                            name="number"
                                            label="Número"
                                            inputProps={{ inputMode: 'numeric' }}
                                            size='small'
                                        />
                                    </Grid>

                                    <Grid style={{ height: 56 }} item xs={3}>
                                        <TextField
                                            select
                                            required
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChange}
                                            name="month"
                                            label="Mês"
                                            placeholder="mm"
                                            size='small'
                                        >
                                            <MenuItem style={{ textAlign: "left" }} value="01" >01</MenuItem>
                                            <MenuItem value="02" >02</MenuItem>
                                            <MenuItem value="03" >03</MenuItem>
                                            <MenuItem value="04" >04</MenuItem>
                                            <MenuItem value="05" >05</MenuItem>
                                            <MenuItem value="06" >06</MenuItem>
                                            <MenuItem value="07" >07</MenuItem>
                                            <MenuItem value="08" >08</MenuItem>
                                            <MenuItem value="09" >09</MenuItem>
                                            <MenuItem value="10" >10</MenuItem>
                                            <MenuItem value="11" >11</MenuItem>
                                            <MenuItem value="12" >12</MenuItem>
                                        </TextField>
                                    </Grid>

                                    <Grid style={{ height: 56 }} item xs={3}>
                                        <TextField
                                            select
                                            required
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChange}
                                            name="year"
                                            label="Ano"
                                            placeholder="aa"
                                            size='small'
                                        >
                                            <MenuItem value="2024" >24</MenuItem>
                                            <MenuItem value="2025" >25</MenuItem>
                                            <MenuItem value="2026" >26</MenuItem>
                                            <MenuItem value="2027" >27</MenuItem>
                                            <MenuItem value="2028" >28</MenuItem>
                                            <MenuItem value="2029" >29</MenuItem>
                                            <MenuItem value="2030" >30</MenuItem>
                                            <MenuItem value="2031" >31</MenuItem>
                                            <MenuItem value="2032" >32</MenuItem>
                                            <MenuItem value="2033" >33</MenuItem>
                                            <MenuItem value="2034" >34</MenuItem>
                                            <MenuItem value="2035" >35</MenuItem>
                                            <MenuItem value="2036" >36</MenuItem>
                                            <MenuItem value="2036" >37</MenuItem>
                                            <MenuItem value="2036" >38</MenuItem>
                                        </TextField>
                                    </Grid>

                                    <Grid style={{ height: 56 }} item xs={6}>
                                        <TextField
                                            required
                                            variant="outlined"
                                            fullWidth
                                            onChange={(e) => handleChange(cvvMask(e))}
                                            value={values.securityCode}
                                            helperText={touched.securityCode && errors.securityCode}
                                            error={touched.securityCode && Boolean(errors.securityCode)}
                                            name="securityCode"
                                            label="CVV"
                                            inputProps={{ inputMode: 'numeric' }}
                                            size='small'
                                        />
                                    </Grid>
                                    <Grid style={{ height: 56 }} item xs={12}>
                                        <TextField
                                            required
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChange}
                                            value={values.ownerName}
                                            helperText={touched.ownerName && errors.ownerName}
                                            error={touched.ownerName && Boolean(errors.ownerName)}
                                            name="ownerName"
                                            label="Nome escrito no Cartão"
                                            size='small'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color='primary'
                                                    onChange={handleChange}
                                                    checked={values.isOwner}
                                                    name="isOwner"
                                                />
                                            }
                                            label="Sou o titular do cartão"
                                        />
                                    </Grid>

                                    {!values.isOwner &&
                                        <>
                                            <Grid style={{ height: 56 }} item xs={12}>
                                                <TextField
                                                    required={!values.isOwner}
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={handleChange}
                                                    value={values.customer?.name}
                                                    helperText={touched.customer?.name && errors.customer?.name}
                                                    error={touched.customer?.name && Boolean(errors.customer?.name)}
                                                    name="customer.name"
                                                    label="Nome do titular"
                                                    size='small'
                                                />
                                            </Grid>

                                            <Grid style={{ height: 56 }} item xs={12}>
                                                <TextField
                                                    required={!values.isOwner}
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={handleChange}
                                                    value={values.customer.cpf}
                                                    helperText={touched.customer?.cpf && errors.customer?.cpf}
                                                    error={touched.customer?.cpf && Boolean(errors.customer?.cpf)}
                                                    name="customer.cpf"
                                                    label="CPF do titular"
                                                    size='small'
                                                />
                                            </Grid>

                                            <Grid style={{ height: 56 }} item xs={12}>
                                                <TextField
                                                    required={!values.isOwner}
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={handleChange}
                                                    value={values.customer?.email}
                                                    helperText={touched.customer?.email && errors.customer?.email}
                                                    error={touched.customer?.email && Boolean(errors.customer?.email)}
                                                    name="customer.email"
                                                    label="E-mail do titular"
                                                    size='small'
                                                />
                                            </Grid>

                                            <Grid style={{ height: 56 }} item xs={12}>
                                                <TextField
                                                    required={!values.isOwner}
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={handleChange}
                                                    value={values.customer?.phoneNumber}
                                                    helperText={touched.customer?.phoneNumber && errors.customer?.phoneNumber}
                                                    error={touched.customer?.phoneNumber && Boolean(errors.customer?.phoneNumber)}
                                                    name="customer.phoneNumber"
                                                    label="Telefone do titular"
                                                    size='small'
                                                />
                                            </Grid>
                                        </>
                                    }
                                    <Grid style={{}} item xs={12}>

                                        {
                                            <DeliverySelect
                                                title={"Endereço do titular do cartão:"}
                                                address={address}
                                                setAddress={setAddress}
                                                storeConfig={
                                                    storeConfig && {
                                                        ...storeConfig,
                                                        allowCatchOnShop: false,
                                                        allowCustomDelivery: false
                                                    }}
                                                deliveryType={DeliveryType.delivery}
                                                setDeliveryType={() => { }}
                                            ></DeliverySelect>
                                        }
                                    </Grid>

                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <button
                                                onClick={() => onCancel && onCancel()}
                                                className={styles.cancelButton}
                                            >
                                                Cancelar
                                            </button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <button
                                                type="submit"
                                                disabled={loadingSave}
                                                className={styles.submitButton}
                                            >
                                                {
                                                    loadingSave ?
                                                        <><CircularProgress size={16} style={{ color: "#fff", marginRight: 8 }} />Salvando Cartão</>
                                                        :
                                                        "Salvar"
                                                }
                                            </button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                    }
                </Formik>
            </Paper>
        </Container >
    )
}
