import React, { useCallback } from "react";
import Layout from "components/layout/Layout";
import styles from "./FormTourPage.module.scss";
import UseFormTour from "./UseFormTour";
import { IconButton, MenuItem } from "@material-ui/core";
import Input from "pages/public/schedule/components/input/Input";
import { AddCircleOutline, Delete } from "@material-ui/icons";
import MeepMaskedInput from "pages/public/schedule/components/inputMasked/InputMasked";
import Button from "pages/public/schedule/components/Button/Button";
import { PaymentCartResume } from "../../components/paymentResume/PaymentResume";
import PickerDate from "pages/public/schedule/components/pickerDate/PickerDate";
import { differenceInYears, format } from "date-fns";
import TimeSelect from "../../components/timeSelect/TimeSelect";
import { Modal } from "components/modal/Modal";
import { Carousel } from "components/ui/carousel/Carousel";

export const FormTourPage = () => {
  const {
    date,
    setDate,
    customers,
    handleChangeCustomers,
    addQuantity,
    removeCustomer,
    handleScheduleTour,
    formScheduleTour,
    handleChangeCustomersValue,
    closeModalPayment,
    openModalPayment,
    service,
    placeSchedule,
    validate,
    onClickBackHandle,
    totalValue,
    handleCpf,
    dateInfo,
    time,
    setTime,
    backOpenModal,
    handleBackOpenModal,
    handleBackCloseModal,
    serviceDetails,
    localVacancies
  } = UseFormTour();

  const removePipe = (value: string) => {
    if (value.includes("|")) {
      const [newValue1, newValue2] = value.split("|");
      return newValue2
    } else {
      return value
    }
  }


  const calcuDisabledValueByAge = useCallback((birthday: string, minAge?: number, maxAge?: number) => {
    if (maxAge && maxAge !== 0 && differenceInYears(new Date(), new Date(birthday)) > maxAge) {
      return { disabled: true, message: `(Idade máxima é ${maxAge} anos)` }
    }
    if (minAge && minAge !== 0 && differenceInYears(new Date(), new Date(birthday)) < minAge) {
      return { disabled: true, message: `(Idade mínima é ${minAge} anos)` }
    }
    return { disabled: false }
  }, []);


  return (
    <Layout onClickBack={handleBackOpenModal} isTopLayout>
      <div className={styles.carousel}>
        {
          !!serviceDetails?.showcaseImages.length &&
          <Carousel values={serviceDetails.showcaseImages.map(item => item.url)} />

        }
      </div>
      <div id={styles.FormTourPage}>
        <div className={styles.container}>
          {/* {placeSchedulesList && placeSchedulesList?.length > 1 &&
            <>
              <h3>Selecione um local</h3>

              <Input
                select
                value={placeSchedule?.name}
                fullWidth
                name="placeSchedule"
                onChange={(ev) => handleChangePlaceSchedule(ev.target.value)}
                label="local"
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {placeSchedulesList?.map((item) => (<MenuItem value={item.name}>{item.name}</MenuItem>))}
              </Input>
            </>
          } */}

          <h2>{serviceDetails?.name}</h2>

          {placeSchedule &&
            <>
              <div className={styles.containerPickerDate}>
                <PickerDate
                  dateInfo={dateInfo}
                  value={date}
                  onChange={(date) => setDate(date)}
                  availableDays={placeSchedule?.availableDays}
                />
              </div>
              {
                date &&
                <TimeSelect
                  times={dateInfo.find(item => item.day === format(date, "dd/MM/yyyy"))?.times ?? []}
                  value={time}
                  onChange={setTime}
                  localVacancies={localVacancies}
                />
              }
              {
                date ?
                  <>
                    <div className={styles.inputs}>
                      {localVacancies > 0 && customers?.map((item, index) => (
                        <>
                          <div className={styles.inputsHeader}>
                            <h2>Cliente {index + 1}</h2>
                            {
                              index > 0 &&
                              <IconButton onClick={() => removeCustomer(item.id)}>
                                <Delete />
                              </IconButton>
                            }
                          </div>

                          <div className={styles.containerInfoCustomer}>
                            <MeepMaskedInput
                              label="CPF"
                              value={item.document}
                              variant="outlined"
                              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                                handleCpf(
                                  event.target.value,
                                  index
                                )
                              }
                              name="document"
                              mask="999.999.999-99"
                              placeholder="000.000.000-00"
                            />

                            <Input
                              value={item.name}
                              fullWidth
                              variant="outlined"
                              name="name"
                              disabled={!!item.document}
                              onChange={(event) =>
                                handleChangeCustomers(
                                  event.target.name,
                                  event.target.value,
                                  item.id
                                )
                              }
                              label="Nome"
                              placeholder="Nome completo"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <Input
                              value={item.birthDate}
                              variant="outlined"
                              disabled={!!item.document}
                              fullWidth
                              name="birthDate"
                              type="date"
                              inputProps={{ max: '2022-12-31' }}
                              onChange={(event) => {
                                handleChangeCustomers(
                                  event.target.name,
                                  event.target.value,
                                  item.id
                                )
                              }}
                              label="Data de nascimento"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />

                            <Input
                              label="RG ou Certidão de nascimento"
                              variant="outlined"
                              value={item.rg}
                              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                                handleChangeCustomers(
                                  event.target.name,
                                  event.target.value.replace(/([^\d\s/-])/g, ''),
                                  item.id
                                )
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              name="rg"
                            />

                          </div>
                          <MeepMaskedInput
                            label="Telefone"
                            variant="outlined"
                            value={item.phone}
                            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                              handleChangeCustomers(
                                event.target.name,
                                event.target.value,
                                item.id
                              )
                            }
                            name="phone"
                            mask="(99) 99999-9999"
                            placeholder="(00) 00000-0000"
                          />

                          <Input
                            label="Hotel"
                            variant="outlined"
                            value={item.note}
                            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                              handleChangeCustomers(
                                event.target.name,
                                event.target.value,
                                item.id
                              )
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="note"
                          />
                          <Input
                            fullWidth
                            name="value"
                            variant="outlined"
                            value={item.value?.description}

                            select
                            onChange={(event) =>
                              handleChangeCustomersValue(
                                event.target.value,
                                item.id
                              )}
                            label="Preço"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            <MenuItem
                              value={""}
                              disabled
                            >
                              Selecionar
                            </MenuItem>
                            {serviceDetails?.otherValues.map((_item) => (
                              <MenuItem
                                value={_item.description}
                                style={
                                  calcuDisabledValueByAge(item.birthDate, _item.minAge, _item.maxAge).disabled ?
                                    { color: '#BDBDBD' }
                                    :
                                    { color: '#333333' }
                                }
                                disabled={
                                  calcuDisabledValueByAge(item.birthDate, _item.minAge, _item.maxAge).disabled
                                }

                              >
                                {removePipe(_item.description)} - {new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL", }).format(_item.price)} {
                                  calcuDisabledValueByAge(item.birthDate, _item.minAge, _item.maxAge).message
                                }
                              </MenuItem>
                            ))}
                          </Input>
                        </>
                      ))

                      }

                      {localVacancies > 1 && <div className={styles.addNewPeople}>
                        <Button
                          variant="outlined"
                          onClick={() => addQuantity()}
                        >
                          Adicionar nova pessoa <AddCircleOutline />
                        </Button>
                      </div>}
                      <div className={styles.containerTotal}>


                        <div className={styles.containerButton}>
                          {
                            <Button disabled={!validate()} onClick={() => handleScheduleTour()}>Reservar</Button>
                          }
                        </div>
                        {
                          customers.length > 0 &&
                          <div className={styles.infoSchedule}>

                            <p>
                              Pessoas: <b>{customers.length}</b>
                            </p>
                            <p> Total: <b>{new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(totalValue)}</b>
                            </p>

                          </div>
                        }
                      </div>

                    </div>
                    <div>
                      {
                        service && formScheduleTour &&
                        <PaymentCartResume
                          open={openModalPayment}
                          onClose={closeModalPayment}
                          formScheduleTour={formScheduleTour}
                          service={service}
                          placeSchedule={placeSchedule}
                        />
                      }
                    </div>
                  </>
                  :
                  <div></div>
              }
            </>
          }

        </div>
        <Modal toggleModal={handleBackCloseModal} onConfirm={onClickBackHandle} open={backOpenModal} title="Sair" confirmTitle="Sim" cancelTitle="Não">
          <p>Deseja sair do cadastro de reserva?</p>
          <p>Os dados cadastrados serão perdidos.</p>
        </Modal>
      </div>
    </Layout>
  );
};
