import { IProductComposition } from 'models/catalog/aggregates/Composition'
import { IOrderComposition } from 'models/order/IOrderComposition'
import React, { FC } from 'react'
import ProductCompositionList from './components/productCompositionList/ProductCompositionList'
import { CompositionsProvider, ProductCompositionsConsumer } from './context/CompositionContext'
import { ITotalsComposition } from './interfaces/ITotalsComposition'
import styles from './ProductCompositions.module.scss'

export interface IProductCompositionsListProps {
    onChangeOrderCompositions: (orderComposition: IOrderComposition[], totals: ITotalsComposition, unvalidatedCompositions: IProductComposition[]) => void;
    productCompositions: IProductComposition[];
    disabled?: boolean
}

const ProductCompositions: FC<IProductCompositionsListProps> = ({ onChangeOrderCompositions, productCompositions, disabled }) => {
    return (
        <CompositionsProvider productCompositions={productCompositions} onChangeOrderCompositions={onChangeOrderCompositions} disabled={disabled}>
            <ProductCompositionsConsumer>
                {
                    ({ orderCompositions: orderComposition }) => (
                        <div id={styles.ProductCompositions}>
                            <div className={styles.container}>
                                <ProductCompositionList productCompositions={productCompositions} />
                            </div>
                        </div>
                    )
                }
            </ProductCompositionsConsumer>
        </CompositionsProvider>
    )
}
export default ProductCompositions