
import api from '../Api'
import { ILoginResponseString } from '../../../models/auth/ILogin';
import Axios from 'axios';
import querystring from "querystring";
import { IValidateCode, IValidateCodeResponse, ISendValidationCode } from '../../../models/auth/IValidationCode';

const AuthApi = () => {
    // const login = async (username: string, password: string) => {
    //     const loginRequest: ILoginRequest = { username, password }
    //     const responseLogin = await post<ILoginResponse>("/Login/", loginRequest);
    //     return responseLogin
    // }
    const login = async (username: string, password: string, smsValidationId?: string) => {
        const loginRequest = {
            username,
            password,
            smsValidationId,
            grant_type: "password"
        }

        // const responseLogin = await post<ILoginResponse>("/Login/", loginRequest);
        const responseLogin = await Axios.post<ILoginResponseString>(process.env.REACT_APP_API_BASE_URL_MEEP?.replace("/api", "") + "/token", querystring.stringify(loginRequest));
        return responseLogin
    }

    const requestValidationCode = (data: ISendValidationCode): Promise<void> => {
        return api.post('/SuperApp/User/SendMessageValidate', data);
    }


    const validateSmsCode = (data: IValidateCode) => {
        return api.post<IValidateCodeResponse>(
            '/SuperApp/User/ValidatePhoneNumberWithVerificationCode/',
            data,
        );
    }

    return {
        login,
        requestValidationCode,
        validateSmsCode
    }
}

export default AuthApi;
