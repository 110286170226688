import React, { ChangeEvent, FC, FormEvent, useCallback, useEffect, useState } from 'react'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { TextField, Button, MenuItem, CircularProgress, FormControlLabel, Drawer, FormLabel, Radio, RadioGroup, FormControl } from '@material-ui/core';
import { CardPreview } from '../cardPreview/CardPreview';
import styles from './PaymentGuestCardForm.module.scss'
import { IAddress } from 'models/address/IAddress';
import { IStoreConfig } from 'models/local/ILocalConfig';
import NewAddressForm from 'pages/newAddress/NewAddressForm';
import { DeliveryAddressItem } from 'pages/payment/deliverySelect/deliveryAddressItem/DeliveryAddressItem';
import { toast } from 'react-toastify';
import { ICardPaymentGuestRequest, IOwnerAddressPaymentGuest, IOwnerPaymentGuest } from 'models/paymentGuest/IPaymentGuestForm';
import { cpfMask } from 'cross-cutting/masks/cpf';
import { v4 } from 'uuid';
import { cnpjMask } from 'utils/mask/cnpj';

export interface ICardFormProps {
    //propertys
    onSubmit: (cardValues: ICardPaymentGuestRequest) => void,
    cardValues?: ICardPaymentGuestRequest,
    onClose?: () => void,
    loading?: boolean,
    storeConfig?: IStoreConfig
}

const PaymentGuestCardForm: FC<ICardFormProps> = ({ cardValues, onSubmit, onClose, loading, storeConfig }) => {
    const [pessoa, setPessoa] = useState<string>("fisica")
    const [card, setCard] = useState<ICardPaymentGuestRequest>({
        number: "",
        securityCode: "",
        ownerName: "",
        month: "",
        year: "",
        owner: {} as IOwnerPaymentGuest,
    });

    const [owner, setOwner] = useState<IOwnerPaymentGuest>({
        name: "",
        document: "",
        email: "",
        phoneNumber: "",
        birthDay: "",
        address: {} as IOwnerAddressPaymentGuest
    });
    const [address, setAddress] = useState<IOwnerAddressPaymentGuest>({
        CEP: "",
        description: "",
        street: "",
        number: "",
        complement: "",
        neighborhood: "",
        city: "",
        state: "",
    });
    const [openAddressForm, setOpenAddressForm] = useState(false);

    const [erros, setErros] = useState({
        number: "",
        securityCode: "",
        ownerName: "",
        month: "",
        year: "",
        owner: {
            name: "",
            document: "",
            email: "",
            phoneNumber: "",
            address: "",
        }
    });

    const validate = useCallback(
        (card: ICardPaymentGuestRequest) => {
            setErros({
                number: "",
                securityCode: "",
                ownerName: "",
                month: "",
                year: "",
                owner: {
                    name: "",
                    document: "",
                    email: "",
                    phoneNumber: "",
                    address: "",
                }
            })
            
            if (!card.number || card.number.length < 14) {
                setErros(prev => ({ ...prev, number: "Numero invalido" }));
                return false;
            }
            if (!card.month) {
                setErros(prev => ({ ...prev, month: "mes inválido" }));
                return false;
            }
            if (!card.year) {
                setErros(prev => ({ ...prev, year: "ano inválido" }));
                return false;
            }
            if (!card.ownerName || card.ownerName.length < 3) {
                setErros(prev => ({ ...prev, ownerName: "nome inválido" }));
                return false;
            }
            if (!card.securityCode || card.securityCode.length < 3) {
                setErros(prev => ({ ...prev, securityCode: "codigo inválido" }));
                return false;
            }
            if (!card.owner.name || card.owner.name.length < 3) {
                setErros(prev => ({ ...prev, owner: { ...prev.owner, name: "nome inválido" } }));
                return false;
            }
            if (!card.owner.document || card.owner.document.length < 11) {
                setErros(prev => ({ ...prev, owner: { ...prev.owner, document: "Documento inválido" } }));
                return false;
            }

            if (
                !card.owner.address.street ||
                !card.owner.address.number ||
                !card.owner.address.city ||
                !card.owner.address.state
            ) {
                setErros(prev => ({ ...prev, owner: { ...prev.owner, address: "Endereço não informado" } }));
                toast.error("Informe um endereço");
                return false;
            }

            return true;
        }, []
    )

    const onSubmitHandle = useCallback(
        (ev: FormEvent<HTMLFormElement>) => {   
            ev.preventDefault();

            const newCard: ICardPaymentGuestRequest = {
                ...card,
                owner: {
                    ...owner,
                    address: address
                }
            }

            if (validate(newCard)) {
                onSubmit(newCard);
            }

        },
        [card, address, owner],
    )

    const numberMask = (value: string) => {
        return value.replace(/[^0-9]/g, '').substr(0, 16);
    }

    const cvvMask = (value: string) => {
        return value.replace(/[^0-9]/g, '').substr(0, 4);
    }

    const onSubmitAddress = useCallback(
        (address: IAddress) => {
            setAddress(address);
            setOpenAddressForm(false);
        },
        [],
    )

    const onChangeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        ev.persist()

        if (ev.target.name === "number") {
            setCard((prev) => ({ ...prev, [ev.target.name]: numberMask(ev.target.value) }));
        } else if (ev.target.name === "securityCode") {
            setCard((prev) => ({ ...prev, [ev.target.name]: cvvMask(ev.target.value) }));
        } else {
            setCard((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
        }
    }, []);

    const changeOwnerHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        ev.persist()
        if (ev.target.name === "document") {
            if (pessoa === "fisica") {
                setOwner((prev) => ({ ...prev, [ev.target.name]: cpfMask(ev.target.value) }));
            } else {
                setOwner((prev) => ({ ...prev, [ev.target.name]: cnpjMask(ev.target.value) }));
            }
        } else {
            setOwner((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
        }
    }, [pessoa]);

    useEffect(() => {
        setOwner((prev) => ({ ...prev, document: "" }));

        return () => {

        }
    }, [pessoa])

    return (
        <div id={styles.CardForm} >
            <div className={styles.container} >

                <Container
                    maxWidth="xs"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 16,
                        /* background: "#31c",*/
                        justifyContent: "start",
                        alignItems: "center"
                    }}
                >

                    <form onSubmit={onSubmitHandle}>
                        {/* <Paper elevation={2} style={{ padding: 16 }}> */}
                        {/* <img width={135} height={50} className="" src={require("../../../../res/images/logos/logo-meep.png")}></img> */}
                        <CardPreview {...card} />
                        <Grid spacing={1} alignContent="center" alignItems="center" container>
                        </Grid>
                        <Grid spacing={2} alignContent="center" alignItems="center" container>
                            <Grid style={{ height: 80 }} item xs={12}>
                                <TextField
                                    required
                                    variant="outlined"
                                    fullWidth
                                    onChange={onChangeHandle}
                                    value={card.ownerName}
                                    helperText={erros.ownerName}
                                    error={!!erros.ownerName}
                                    name="ownerName"
                                    label="Nome Escrito no Cartão" />
                            </Grid>

                            <Grid style={{}} item xs={12}>
                                <TextField
                                    required
                                    autoComplete={"off"}
                                    helperText={erros.number}
                                    error={!!erros.number}
                                    variant="outlined"
                                    fullWidth
                                    onChange={onChangeHandle}
                                    value={card.number}
                                    inputProps={{
                                        inputMode: "tel",
                                    }}
                                    name="number"
                                    label="Número"
                                />
                            </Grid>

                            <Grid style={{ height: 80 }} item xs={3}>
                                <TextField
                                    select
                                    required
                                    variant="outlined"
                                    fullWidth
                                    value={card.month}
                                    onChange={onChangeHandle}
                                    name="month"
                                    label="Mês"
                                    placeholder="mm"
                                >
                                    <MenuItem style={{ textAlign: "left" }} value="01">01</MenuItem>
                                    <MenuItem value="02">02</MenuItem>
                                    <MenuItem value="03">03</MenuItem>
                                    <MenuItem value="04">04</MenuItem>
                                    <MenuItem value="05">05</MenuItem>
                                    <MenuItem value="06">06</MenuItem>
                                    <MenuItem value="07">07</MenuItem>
                                    <MenuItem value="08">08</MenuItem>
                                    <MenuItem value="09">09</MenuItem>
                                    <MenuItem value="10">10</MenuItem>
                                    <MenuItem value="11">11</MenuItem>
                                    <MenuItem value="12">12</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid style={{ height: 80 }} item xs={3}>
                                <TextField
                                    select
                                    required
                                    variant="outlined"
                                    fullWidth
                                    value={card.year}
                                    onChange={onChangeHandle}
                                    name="year"
                                    label="Ano"
                                    placeholder="aa"
                                >
                                    <MenuItem value="2020">20</MenuItem>
                                    <MenuItem value="2021">21</MenuItem>
                                    <MenuItem value="2022">22</MenuItem>
                                    <MenuItem value="2023">23</MenuItem>
                                    <MenuItem value="2024">24</MenuItem>
                                    <MenuItem value="2025">25</MenuItem>
                                    <MenuItem value="2026">26</MenuItem>
                                    <MenuItem value="2027">27</MenuItem>
                                    <MenuItem value="2028">28</MenuItem>
                                    <MenuItem value="2029">29</MenuItem>
                                    <MenuItem value="2030">30</MenuItem>
                                    <MenuItem value="2031">31</MenuItem>
                                    <MenuItem value="2032">32</MenuItem>
                                    <MenuItem value="2033">33</MenuItem>
                                    <MenuItem value="2034">34</MenuItem>
                                    <MenuItem value="2035">35</MenuItem>
                                    <MenuItem value="2036">36</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid style={{ height: 80 }} item xs={6}>
                                <TextField
                                    required
                                    variant="outlined"
                                    fullWidth
                                    onChange={onChangeHandle}
                                    value={card.securityCode}
                                    helperText={erros.securityCode}
                                    error={!!erros.securityCode}
                                    name="securityCode"
                                    label="CVV"
                                    inputProps={{ inputMode: 'numeric' }} />
                            </Grid>

                            <>
                                <Grid style={{ height: 80 }} item xs={12}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        fullWidth
                                        onChange={changeOwnerHandle}
                                        value={owner.name}
                                        helperText={erros.owner.name}
                                        error={!!erros.owner.name}
                                        name="name"
                                        label="Nome do titular" />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Documento</FormLabel>
                                        <RadioGroup aria-label="pessoa" name="gender1" value={pessoa} onChange={(ev, value) => setPessoa(value)}>
                                            <FormControlLabel value="fisica" control={<Radio />} label="Pessoa fisica" />
                                            <FormControlLabel value="juridica" control={<Radio />} label="Pessoa Juridica" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>


                                <Grid style={{ height: 80 }} item xs={12}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        fullWidth
                                        onChange={changeOwnerHandle}
                                        value={owner.document}
                                        inputProps={{
                                            inputMode: "tel",
                                        }}
                                        helperText={erros.owner.document}
                                        error={!!erros.owner.document}
                                        name="document"
                                        label={`${pessoa === "fisica" ? "CPF" : "CNPJ"} do titular`} />
                                </Grid>

                                <Grid style={{ height: 80 }} item xs={12}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        fullWidth
                                        onChange={changeOwnerHandle}
                                        value={owner.email}
                                        helperText={erros.owner.email}
                                        error={!!erros.owner.email}
                                        inputProps={{
                                            inputMode: "email",
                                        }}
                                        name="email"
                                        label="E-mail do titular" />
                                </Grid>

                                <Grid style={{ height: 80 }} item xs={12}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        fullWidth
                                        onChange={changeOwnerHandle}
                                        value={owner.phoneNumber}
                                        helperText={erros.owner.phoneNumber}
                                        error={!!erros.owner.phoneNumber}
                                        name="phoneNumber"
                                        label="Telefone do titular" />
                                </Grid>
                            </>

                            <Grid style={{}} item xs={12}>
                                <h3>Endereço do titular</h3>
                                <DeliveryAddressItem
                                    onClickEdit={() => { setOpenAddressForm(true); }}
                                    // loading={loadingAddresses}
                                    onClick={() => setOpenAddressForm(true)}
                                    address={{ ...address, id: v4() }} />
                            </Grid>



                            {onClose && <Grid item xs={4}>
                                <Button
                                    // disabled={!isValid}
                                    size="large"
                                    variant="contained"
                                    onClick={() => onClose && onClose()}
                                    fullWidth
                                >
                                    cancelar
                                </Button>
                            </Grid>}
                            <Grid item xs={8}>
                                <Button
                                    // disabled={!isValid}
                                    size="large"
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    fullWidth
                                    disabled={loading}

                                >
                                    {loading ?
                                        <><CircularProgress size={16} style={{ color: "#fff", marginRight: 8 }} />Salvando Cartão</>
                                        :
                                        "Salvar"}
                                </Button>
                            </Grid>

                        </Grid>
                        {/* </Paper> */}
                    </form>
                </Container >
            </div>
            <Drawer anchor={"bottom"} open={openAddressForm} onClose={() => setOpenAddressForm(false)}>
                <NewAddressForm onSubmit={onSubmitAddress} onClose={() => setOpenAddressForm(false)} />
            </Drawer>
        </div >
    )
}
export default PaymentGuestCardForm