import { IStoreConfig } from 'models/local/ILocalConfig'
import { IOrderDetail } from 'models/order/aggregates/datail/IOrderDetail'
import { useEffect, useState } from 'react'

const UseServiceFee = (orderDetail?: IOrderDetail | null, storeConfig?: IStoreConfig | null) => {
    const [serviceFee, setServiceFee] = useState(0);
    const [acceptServiceFee, setAcceptServiceFee] = useState(true);

    useEffect(() => {

        if (orderDetail && storeConfig?.serviceFee) {
            if(acceptServiceFee){

                const _serviceFee = (orderDetail.amount * storeConfig.serviceFee) / 100
                setServiceFee(_serviceFee);
            }else{
                setServiceFee(0);
            }
        }


    }, [acceptServiceFee, orderDetail, serviceFee, storeConfig])




    return ({ serviceFee, setAcceptServiceFee })
}

export default UseServiceFee