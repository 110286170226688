import { IAccountDetailWithStoreConfig } from "../../../models/order/aggregates/datail/IAccountDetailWithStoreConfig"
import { IOrderDetail } from "../../../models/order/aggregates/datail/IOrderDetail"
import { IOrderDetailWithStoreConfig } from "../../../models/order/aggregates/datail/IOrderDetailWithStoreConfig"
import api from "../Api"

export const AccountApi = () => {

    const getAccount = async (accountId: string) => {
        const response = await api.get<IAccountDetailWithStoreConfig>(`/SuperApp/Order/GetOrderAndConfigsByAccountId/${accountId}`)
        return { ...response, data: accountToOrder(response.data) }
    }

    const accountToOrder = (account: IAccountDetailWithStoreConfig): IOrderDetailWithStoreConfig => {
        const returnOrder: IOrderDetail = {
            id: account.id,
            amount: account.ammount * 1.1,
            itens: account.orders.flatMap((order) => (order.itens))
        }
        return {
            store: {
                ...account.store,
                deliveryFee: 0,
                allowPaymentOnDelivery: false,
                allowOnlinePayment: true,
                showDeliveryStatus: false,
                allowCatchOnShop: false,
                allowDeliveryHome: false,
                allowCustomDelivery: false,
                customDeliveryDescription: "",//Enum Adiquirente
                isMeepCard: false,
                acceptInstallment: false,
                useMeepDelivery: false,
                acceptSlipPayment: false,
            },
            order: returnOrder
        }
    }

    return {
        getAccount
    }
}
