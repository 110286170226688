/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, Grid, Icon, TextField } from '@material-ui/core'
import React, { FC, memo, useCallback, useState } from 'react'
import { useUi } from '../../../../context/ui/UIContext';
import { moneyMask, moneyToFloat } from '../../../../cross-cutting/masks/money'
import { CreditCardStatus, ICardItem } from '../../../../models/payment/ICard';
import CardApi from '../../../../services/Api/card/CardApi';

interface IVerifyModalProps {
    open: boolean;
    card: ICardItem;
    onValidate: () => void
}

export const VerifyModal: FC<IVerifyModalProps> = memo(({ open, card, onValidate }) => {
    const { toast, showLoading, hideLoading } = useUi()
    const [valorCobrado, setValorCobrado] = useState("");
    const [securityCode, setSecurityCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [initCheckEnviado, setInitCheckEnviado] = useState(false);

    const initCheck = useCallback(async () => {
        try {
            setLoading(true)
            showLoading();
            await CardApi.initCheckCreditCard(card.id, securityCode);
            setInitCheckEnviado(true);
        } catch (error: any) {
            if (error?.response?.data?.message) {
               // toast(error.response.data.message, "error")
            }
        } finally {
            setLoading(false);
            hideLoading();
        }

    }, [card, toast, securityCode])

    const onPressValidate = useCallback(async () => {
        try {
            setLoading(true)
            showLoading()
            await CardApi.checkCreditCard(card.id, moneyToFloat(valorCobrado));
            onValidate && onValidate()
            toast("Cartão validado com sucesso", "success")
        } catch (error) {
            // if (error?.response?.data?.message) {
            //     toast(error.response.data.message, "error")
            // }
        } finally {
            setLoading(false);
            hideLoading();
        }

    }, [card.id, onValidate, toast, valorCobrado])


    // useEffect(() => {
    //     if (securityCode.length === 3) {
    //         initCheck(securityCode);
    //     }
    //     return () => {

    //     }
    // }, [initCheck, securityCode])


    return (
        <div>
            <Grid style={{ textAlign: "center", justifyContent: "center", display: "flex", padding: 16 }} container>
                <Grid xs={12} item>
                    <div style={{ color: "#c00d1e", display: "flex", alignItems: "center" }}><Icon>verified_user</Icon> VERIFICAR CARTAO</div>
                </Grid>
                {
                    !initCheckEnviado && card.status === CreditCardStatus.PENDING ?
                        <>
                            <Grid xs={12} item>
                                <div style={{}}>Codigo de segurança</div>
                            </Grid>
                            <Grid xs={12} item>
                                <TextField
                                    placeholder="123"
                                    inputProps={{ style: { fontSize: 32, textAlign: "center" }, inputMode: 'numeric' }}
                                    value={securityCode}
                                    onChange={(e) => setSecurityCode(e.target.value.substr(0, 4).replace("-", "").replace(".", "").replace(/[^0-9]/g, ""))}
                                ></TextField>
                            </Grid>
                            <Grid xs={12} style={{ marginTop: 16 }} item>
                                <Button
                                    variant="contained"
                                    disabled={securityCode.length < 3}
                                    onClick={() => !loading && initCheck()}
                                    fullWidth color="primary"
                                    size="large"
                                >
                                    {
                                        loading ?
                                            <CircularProgress style={{ color: "#fff" }} size={16} />
                                            : "VALIDAR"
                                    }
                                </Button>

                            </Grid>
                        </>
                        :
                        <>
                            <Grid xs={12} item>
                                <div style={{}}>Informe o valor cobrado</div>
                                <div style={{ fontSize: "smaller", opacity: 0.8 }}>Foi efetuada uma cobrança de valor aleatório em seu cartão.</div>
                                <div style={{ fontSize: "smaller", opacity: 0.8 }}></div>
                            </Grid>
                            <Grid xs={12} item>
                                <TextField
                                    autoFocus
                                    variant="standard"
                                    inputMode="numeric"
                                    onChange={(e) => setValorCobrado(moneyMask(e.target.value))}
                                    value={valorCobrado}
                                    placeholder="R$0,00"
                                    inputProps={{ prefix: "R$", inputMode: "numeric", style: { textAlign: "center", fontSize: 32 } }}
                                ></TextField>
                            </Grid>
                            <div style={{ fontSize: "smaller", fontSizeAdjust: "inherit", opacity: 0.7 }}>*Não se preocupe esse valor será estornado</div>

                            <Grid xs={12} item>
                                <Button
                                    variant="contained"
                                    disabled={!valorCobrado}
                                    onClick={() => !loading && onPressValidate()}
                                    fullWidth color="primary"
                                    size="large"
                                >
                                    {
                                        loading ?
                                            <CircularProgress style={{ color: "#fff" }} size={16} />
                                            : "VALIDAR"

                                    }

                                </Button>
                            </Grid>
                        </>
                }

            </Grid>
        </div>
    )
})
