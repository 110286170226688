import React, { FC } from "react";
import styles from "./Header.module.scss";
import { useUi } from "context/ui/UIContext";

export interface IHeader {
  image?: string;
  name: string;
}

const Header: FC<IHeader> = ({ image, name }) => {
  const { isMobile } = useUi();
  return (
    <div id={styles.Header}>
      {image ? (
        <img
          className={isMobile ? styles.imgMobile : styles.img}
          src={image}
          alt="Foto_produto"
        />
      ) : (
        <div />
      )}
      <div className={isMobile ? styles.descriptionMobile : styles.description}>
        <b>{name}</b>
      </div>
      <div />
    </div>
  );
};

export default Header;
