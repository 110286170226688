
import api from "../Api";
import { ICardItem, ICreditCardAddRequest, INewCardPostObject } from "../../../models/payment/ICard";

const CardApi = () => {
  const { post, get } = api;

  // api.defaults.baseURL = "https://meepserver-dev.azurewebsites.net/api"

  // /api/superapp/CreditCard/Save


  const saverCard = async (card: ICreditCardAddRequest, userId: string) => {
    const postObject: INewCardPostObject = {
      ...card,
      userId: userId,
    };

    const response = await post("/Bot/CreditCard", postObject);
    return response
  };

  const getCards = async () => {
    const response = await get<ICardItem[]>("/superapp/creditcard/GetUserCreditCards");
    return response
  };

  const saveCreditCard = (creditCard: ICreditCardAddRequest) => {
    return api.post('/superapp/CreditCard/Save', creditCard);
  };

  const deleteCard = (creditCardId: string) => {
    return api.post('/superapp/CreditCard/Delete/' + creditCardId);
  };

  const checkCreditCard = (creditCardId: string, value: number) => {
    return api.post("/superapp/Creditcard/CheckCreditCard", { creditCardId, value })
  }
  const initCheckCreditCard = (creditCardId: string, cvv: string) => {
    return api.post("/superapp/Creditcard/InitCheckCreditCard", { creditCardId, cvv })
  }


  ///api/superapp/CreditCard/Delete/

  return {
    saverCard,
    getCards,
    saveCreditCard,
    deleteCard,
    checkCreditCard,
    initCheckCreditCard
  };
};


export default CardApi();