import { Button, Container, Grid, TextField } from '@material-ui/core'
import React from 'react'
import { useMeepCardPayment } from '../../context/meepCardPayment/_MeepCardPaymentContext'
import { DeliveryAddressItem } from '../payment/deliverySelect/deliveryAddressItem/DeliveryAddressItem'
import { ResumeView } from '../payment/resume/ResumeView'
import MeepCardResume from './meepCardResume/MeepCardResume'

const MeepCardPaymentPage = () => {

    const { orderDetail, storeConfig, meepCardData, setCustomDeliveryDescription, customDeliveryDescription, onClickEnviarPagamento } = useMeepCardPayment();

    return (
        // <div style={{ position: "fixed", top: 0, left: 0, bottom: 0, right: 0, backgroundColor: "#c00d1e", textAlign: "center" }}>

        <Container maxWidth="sm" style={{ display: "flex", flexDirection: "column", padding: 16 }}>

            <Grid style={{ textAlign: "center", padding: 16 }} item xs={12} >
                <img width={150} src={require('../../res/images/logos/meep-logo-nome.png')} alt="Logo Meep" />
            </Grid>
            <Grid item xs={12}>
                <ResumeView orderDetail={orderDetail} />
            </Grid>
            {

                <Grid item xs={12}>
                    <MeepCardResume cardData={meepCardData} />
                </Grid>
            }

            <Grid item xs={12}>
                {
                    <DeliveryAddressItem
                        onClick={() => { }}
                    >
                        {storeConfig?.customDeliveryDescription}
                        <TextField
                            autoFocus
                            onChange={(e) => setCustomDeliveryDescription(e.target.value)}
                            value={customDeliveryDescription}
                        ></TextField>
                    </DeliveryAddressItem>
                }
            </Grid>

            <Container maxWidth="sm" style={{ textAlign: "center", position: "fixed", bottom: 0, left: 0, right: 0, padding: 16, background: "#fff" }}  >
                <Button onClick={onClickEnviarPagamento} fullWidth variant="contained" color="primary">Finalizar Pagamento</Button>
            </Container>

        </Container>
        // </div>
    )
}

export default MeepCardPaymentPage
