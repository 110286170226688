import { IOrderPads } from "pages/payment/UseLocalOrderPads";
import api from "../Api";

const OrderPadApi = () => {
  const getOrderPads = async (localId: string): Promise<IOrderPads[]> => {
    const response = await api.get<
      {
        AbertoEm: string;
        Id: string;
        Numero: string;
        ProprietarioId: string;
        Status: number;
      }[]
    >("/comandaseletronicas?localClienteId=" + localId);
    return response.data.map((item) => ({
      number: item.Numero,
      id: item.Id,
      status: item.Status
    }));
  };
  return {
    getOrderPads,
  };
};
export default OrderPadApi;
