import { Button, Grid,TextField } from '@material-ui/core'
import React, { FormEvent, useCallback, useState } from 'react'
import { useMeepCardPayment } from '../../../../context/meepCardPayment/_MeepCardPaymentContext';

const LoginAlunoPage = () => {

    const [apelido, setApelido] = useState("");
    const [senha, setSenha] = useState("");
    const { login } = useMeepCardPayment()

    const onSubmit = useCallback(
        (event: FormEvent) => {
            event.preventDefault();
            if (apelido && senha) {
                login(apelido, senha)
            }
        },
        [apelido, login, senha],
    )


    return (
        <form style={{ width: "100%" }} onSubmit={onSubmit}>
            <Grid spacing={2} container>
                <Grid sm={12} xs={12} item>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Apelido"
                        name="usuario"
                        value={apelido}
                        onChange={(e) => setApelido(e.target.value)}
                    >
                    </TextField>
                </Grid>
                <Grid sm={12} xs={12} item>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Senha"
                        type="password"
                        name="senha"
                        value={senha}
                        onChange={(e) => setSenha(e.target.value)}
                    >

                    </TextField>
                </Grid>
                <Grid sm={12} xs={12} item>
                    <Button disabled={!(apelido && senha)} size="large" variant="contained" color={"primary"} fullWidth type="submit">Entrar</Button>
                </Grid>
            </Grid>
        </form >
    )
}

export default LoginAlunoPage
