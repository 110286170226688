import { UseMercadoPago } from "context/payment/UseMercadoPago";
import { useUi } from "context/ui/UIContext";
import { IPaymentGuestRequest } from "models/paymentGuest/IPaymentGestRequest";
import {
  ICardPaymentGuestRequest,
  IOwnerAddressPaymentGuest,
  IOwnerPaymentGuest,
} from "models/paymentGuest/IPaymentGuestForm";
import {
  IPaymentLinkInstallments,
  IPaymentLinkResponse,
} from "models/paymentLink/IPaymentLinkResponse";
import { useCallback, useEffect, useState } from "react";
import PaymentLinkApi from "services/Api/paymentLink/PaymentLinkApi";
import { validate } from "../creditForm/validate";
import Utils from "utils/utils";
import { CardBrandType } from "models/payment/ICard";

export const UseCredit = (paymentLink: IPaymentLinkResponse) => {
  const { toast } = useUi();
  const { deviceIdMercadoPago } = UseMercadoPago();
  const [installment, setInstallment] = useState<IPaymentLinkInstallments>();
  const [approved, setApproved] = useState('');
  const [loading, setLoading] = useState(false);
  const [cardBrand, setCardBrand] = useState<number>(CardBrandType.Unknown);
  const [card, setCard] = useState<ICardPaymentGuestRequest>({
    number: "",
    securityCode: "",
    ownerName: "",
    month: "",
    year: "",
    owner: {} as IOwnerPaymentGuest,
  });
  const [owner, setOwner] = useState<IOwnerPaymentGuest>({
    name: "",
    document: "",
    email: "",
    phoneNumber: "",
    birthDay: "",
    address: {} as IOwnerAddressPaymentGuest,
  });
  const [address, setAddress] = useState<IOwnerAddressPaymentGuest>({
    CEP: "",
    description: "",
    street: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
  });

  const [errors, setErrors] = useState({
    number: "",
    securityCode: "",
    ownerName: "",
    month: "",
    year: "",
    owner: {
      name: "",
      document: "",
      email: "",
      phoneNumber: "",
      address: "",
    },
  });

  useEffect(() => {
    const brand = Utils.toCardBrand(card.number);
    setCardBrand(Number.parseInt(brand.toString()));
  }, [card]);

  const onClickSend = useCallback(() => {
    const paymentLinkApi = PaymentLinkApi();
    const newCard: ICardPaymentGuestRequest = {
      ...card,
      owner: {
        ...owner,
        address: address,
      },
    };
    if (paymentLink && validate(newCard, setErrors)) {
      const newPaymentGuestPayRequest: IPaymentGuestRequest = {
        mePayLinkId: paymentLink?.id,
        isSinglePayment: paymentLink?.isSinglePayment === true,
        creditCard: {
          number: newCard.number,
          name: newCard.ownerName,
          securityCode: newCard.securityCode,
          fingerPrint: deviceIdMercadoPago,
          monthYearValid: newCard.month + "/" + newCard.year,
        },
        order: {
          value: installment?.price ?? paymentLink?.price,
          itens: [
            {
              name: paymentLink?.product.nome,
              productId: paymentLink?.product.id,
              value: paymentLink?.product.valor,
              quantity: 1,
            },
          ],
        },
        customer: {
          name: newCard?.owner.name,
          document: newCard?.owner.document,
          email: newCard?.owner.email,
          phoneNumber: newCard?.owner.phoneNumber,
          birthDay: newCard?.owner.birthDay,
          address: {
            postalCode: newCard.owner.address.CEP ?? "",
            street: newCard.owner.address.street,
            number: newCard.owner.address.number,
            complement: newCard.owner.address.complement,
            neighborhood: newCard.owner.address.neighborhood,
            city: newCard.owner.address.city,
            state: newCard.owner.address.state,
            country: "",
          },
        },
        installments: installment?.installment ?? 1,
        storeId: paymentLink.localId,
      };

      setLoading(true);
      paymentLinkApi
        .getPaymentGuestSendPay(newPaymentGuestPayRequest)
        .then((res) => {
          setApproved(res?.data?.orderId ?? "-");
        })
        .catch((error) => {
          toast(error?.response?.data?.Message, "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    address,
    card,
    deviceIdMercadoPago,
    installment,
    owner,
    paymentLink,
    toast,
  ]);

  return {
    card,
    setCard,
    owner,
    setOwner,
    address,
    setAddress,
    approved,
    onClickSend,
    installment,
    setInstallment,
    loading,
    errors,
    cardBrand,
  };
};
