import { useComposition } from 'components/productComposition/context/CompositionContext'
import { IProductComposition } from 'models/catalog/aggregates/Composition'
import React, { FC, useEffect, useState } from 'react'
import BoxProductCompositionItem from '../boxProductCompositionItem/BoxProductCompositionItem'
import styles from './BoxProductComposition.module.scss'

export interface IBoxProductCompositionProps {
    productComposition: IProductComposition
}

const BoxProductComposition: FC<IBoxProductCompositionProps> = ({ productComposition }) => {
    const { orderCompositions, getTotalsComposition } = useComposition();

    const [disabledAllAdd, setDisabledAllAdd] = useState(false);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        const totalComposition = getTotalsComposition(productComposition);
        const maxComposition = !!productComposition.max && (totalComposition.quantity >= productComposition.max);
        setTotal(totalComposition.quantity);
        if (maxComposition) {
            setDisabledAllAdd(true)
        } else {
            setDisabledAllAdd(false)
        }
    }, [getTotalsComposition, orderCompositions, productComposition])

    return (
        <div id={styles.BoxProductComposition}>
            <div className={styles.container}>
                <div className={styles.info}>
                    <span className={styles.description}>
                        <span className={styles.descriptionText}>{productComposition.description}</span>

                        {!!productComposition.min &&
                            <span className={productComposition.min - total > 0 ? styles.obrigatorio : styles.obrigatorioChecked}>(Obrigatório)</span>
                        }
                    </span>
                    
                </div>
                
                <div className={styles.compositionItems}>
                    {
                        productComposition.compositionItems.map((productCompositionItem) =>
                            <BoxProductCompositionItem
                                key={productCompositionItem.id}
                                disabledAllAdd={disabledAllAdd}
                                productCompositionItem={productCompositionItem}
                                productComposition={productComposition}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    )
}
export default BoxProductComposition