import Layout from 'components/layout/Layout'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styles from './AboutProject.module.scss'
import AboutprojectImg from '../../../../../../../../assets/image/aboutproject.png'


export const AboutProject = () => {


    const { url } = useParams<{ url: string }>();

    const { push } = useHistory();

    const onClickGoBack = () => {
        push(`/${url}/schedule/tour`)
    }

    return (

        <Layout onClickBack={onClickGoBack}>
            <div id={styles.AboutProject}>
                <div className={styles.container}>
                    <div className={styles.header}>
                        <div className={styles.aboutprojectImg}>
                            <img src={AboutprojectImg} alt="Imagem da ciadade de gramados" />
                        </div>
                        <div className={styles.text}>
                            <p>A Gramado Receptivo vem <b>realizando sonhos</b> desde 2016!
                                O prazer em receber e bem atender é visível no sorriso dos nossos vendedores, agentes de viagens, operadores, guias e motoristas.
                            </p>
                            <p>Nós, da Gramado Receptivo, trabalhamos com o objetivo de <b>encantar nossos clientes e turistas</b> que vêm para a Serra Gaúcha.</p>
                            <p>Inovando sempre e pensando em melhorar ainda mais a sua experiência, investimos pesado no mercado online através dos nosso <b>site, app e totens de auto atendimento</b> que possibilitam que você possa ter mais segurança e comodidade na hora de escolher a sua próxima experiência.
                            </p>
                        </div>
                    </div>
                    <div className={styles.bottom}>
                        <div className={styles.text}>
                            <p>Com operação própria, dispomos de serviços regulares e privativos como: locações de veículos, transfers, passeios, pacotes, hospedagem, elaboração de <b>roteiros personalizados</b> para particulares e/ou grupos, guias de turismo credenciados pela Embratur, guias bilíngues e muito mais! Tudo para poder <b>oferecer o melhor a você e sua família!</b>
                            </p>
                            <p>Os melhores passeios da Serra Gaúcha você encontra com a Gramado Receptivo.
                                <b> Experimente do seu jeito!</b>
                            </p>
                        </div>
                        <div className={styles.aboutprojectVideo}>
                            <iframe width="100%" height="352" src="https://www.youtube.com/embed/Sq-K1FsyogQ?controls=0" title="YouTube video Gramado" ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>

    )
}
