import { useState } from 'react'
import { IFaq } from './IFaq';

const mock: IFaq =
{
    categories: [
        {
            title: "Pergunta 1",
            questions: [
                {
                    description: "Fiz minha compra, e agora?",
                    answers: "Show!! Agora basta retirar seu comprovante no totem de autoatendimento e aguardar. Nossa equipe entrará em contato para encaminhar seu voucher de confirmação."
                },
                {
                    description: "O passeio é realizado por quem?",
                    answers: "O passeio é realizado pela Gramado Receptivo. A agência passou a operar os próprios serviços em 2017, um ano após a sua fundação. A experiência de 20 anos no mercado turístico do nosso diretor, aliado à dedicação de uma equipe competente, faz da Gramado Receptivo uma empresa de sucesso. O sorriso no rosto de cada vendedor, agentes de viagens, operadores, guias e motoristas é reflexo do prazer que temos em receber e atender nossos clientes."      },
                {
                    description: "Que horário vocês irão me buscar para o passeio?",
                    answers: "Relaxa … Você será contatado pela nossa equipe operacional no dia anterior ao seu passeio, que vai te passar todos os detalhes sobre os horários, ponto de encontro etc! "
                },
                {
                    description: "Que horário vocês irão me buscar para o passeio?",
                    answers: "Relaxa … Você será contatado pela nossa equipe operacional no dia anterior ao seu passeio, que vai te passar todos os detalhes sobre os horários, ponto de encontro etc! "
                },
                {
                    description: "Quais são as formas de pagamento?",
                    answers: "O pagamento é realizado através do cartão de crédito, com a parcela mínima de R$100,00."
                },
                {
                    description: "Onde será o ponto de encontro?",
                    answers: "Buscamos em todos os hotéis e pousadas de Gramado e Canela. Nosso motorista irá chamar por você e sua família na recepção."
                },
                {
                    description: "Onde retiro meu comprovante?",
                    answers: "Nos totens de autoatendimento. Nós também entraremos em contato para encaminhar o seu voucher."
                },
                {
                    description: "O que acontece em caso de chuva no dia do passeio?",
                    answers: "Fique tranquilo (a)! Todos os nossos passeios tem estrutura para os dias chuvosos!"
                },
                {
                    description: "Quero cancelar, o que eu faço?",
                    answers: "No caso de desistência dos serviços em até 48 horas de antecedência, serão cobradas taxas administrativas de 50% do valor total. A devolução dos outros 50% será feita de acordo com a forma de pagamento. Em caso de desistência antes do prazo(até 48h antes), não será possível realizar a devolução."
                },

            ]
        }
    ]
}


const UseFaqPage = () => {
    const [values, setValues] = useState<IFaq>(mock)

    return (
        {
            values
        }
    )
}

export default UseFaqPage
