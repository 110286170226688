import { IOrderComposition } from "models/order/IOrderComposition";

export const CalcTotalsOfCompositionItemByCompositionId = (prevOrderConposition: IOrderComposition[], productCompositionItemId: string): { quantity: number, price: number } => {

    const calcTotalsOfCompositionItemByCompositionId = (prevOrderComposition: IOrderComposition[], productCompositionItemId: string) => {

        const composition = prevOrderComposition.flatMap(_composition => _composition.compositionItems).find(_item => _item.id === productCompositionItemId)
        if (composition) {
            return { quantity: composition.quantity, price: composition.totalPrice }
        }
        return { quantity: 0, price: 0 }
    }

    return calcTotalsOfCompositionItemByCompositionId(prevOrderConposition, productCompositionItemId);
}