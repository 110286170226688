import React, { useCallback, useEffect, useRef, useState } from 'react'
import GoogleMapReact from 'google-map-react';
import { Marker } from './marker/Marker';
import { OrderMapTracking } from '../../../../services/Api/order/OrderMapTracking';
import { useUi } from '../../../../context/ui/UIContext';
import { useParams } from 'react-router-dom';
import { IOrderMapTracking } from '../../../../models/order/aggregates/map/IOrderMapTracking';

export const MapOrderTracking = () => {

    const orderMapTracking = OrderMapTracking();
    const { showLoading, hideLoading } = useUi()

    const { orderId }: { orderId: string } = useParams()
    const [cord, setCord] = useState<IOrderMapTracking>()

    const onGetPosition= useRef(false)
    const [notFound, setnotFound] = useState(false)


    const getPositions = useCallback(
        async () => {
            if (!onGetPosition?.current) {
                onGetPosition.current = true;
                try {
                    if (!cord) {
                        showLoading();
                    }
                    const response = await orderMapTracking.getCurrent(orderId)

                    hideLoading();

                    setCord(response.data)
                } catch (error) {
                    setnotFound(true)
                } finally {
                    onGetPosition.current =false;
                    hideLoading();
                }
            }
        },
        [onGetPosition, cord, orderMapTracking, orderId, hideLoading, showLoading],
    )




    useEffect(() => {
        if (cord) {

            const timer = setInterval(() => {
                getPositions();
            }, 20000);
            return () => {
                clearTimeout(timer)
            }
        }
    }, [cord])

    useEffect(() => {
        getPositions();

    }, [])


    return (
        <div style={{ height: '100vh', width: '100%' }}>
            {
                cord ?
                    <GoogleMapReact
                    
                        debounced
                        bootstrapURLKeys={{ key: "AIzaSyD0gEKkUWocJCTOYTucIsUKtdrWa8VGc3g" }}
                        defaultCenter={{
                            lat: cord.currentDrivePosition.latitude,
                            lng: cord.currentDrivePosition.longitude
                        }}
                        defaultZoom={15}
                    >
                        <Marker
                            lat={cord.currentDrivePosition.latitude}
                            lng={cord.currentDrivePosition.longitude}
                            text=""
                            type="current"
                            icon="directions_bike"
                        />
                        <Marker
                            lat={cord.client.coords.latitude}
                            lng={cord.client.coords.longitude}
                            text=""
                            type="client"
                            icon="home"
                        />
                        <Marker
                            lat={cord.local.coords.latitude}
                            lng={cord.local.coords.longitude}
                            text=""
                            type="local"
                            icon="place"
                        />
                    </GoogleMapReact> :

                    notFound &&
                    <div style={{ alignSelf: "center", display: 'flex', justifyContent: 'center', textAlign: "center", flex: 1 }}>Pedido não encontrado!</div>

            }
        </div>
    )
}


