import React, { FC, useState } from 'react'
import styles from './PreviewPhotosProfile.module.scss'
import Slider, { Settings } from "react-slick";
import { IPhoto } from '../../profile/interfaces/IPhotos';
import { Dialog } from '@material-ui/core';
export interface IPreviewPhotosProfileProps {
    //propertys
    photos: IPhoto[]
}
const PreviewPhotosProfile: FC<IPreviewPhotosProfileProps> = ({ photos }) => {

    var settings: Settings = {
        slidesToShow: photos.length < 5 ? photos.length : 5,
        initialSlide: 0,
        infinite: false,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                }
            }
        ]
    };

    const [selectedPhoto, setselectedPhoto] = useState<IPhoto | null>(null);


    return (
        <div id={styles.PreviewPhotosProfile} >
            <h2>Fotos</h2>
            <div className={styles.container} >
                <Slider {...settings}>
                    {photos.map((photo) => (
                        <div className={styles.thumbnail} onClick={() => { photo.photoUrl && setselectedPhoto(photo) }}>
                            <div className={styles.imgContainer}>
                                <img alt="" src={photo.thumbnailUrl}></img>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            <Dialog open={!!selectedPhoto} onClose={() => { setselectedPhoto(null) }}>
                <div className={styles.photo}>
                    <img alt=" " src={selectedPhoto?.photoUrl}></img>
                </div>
            </Dialog>
        </div>
    )
}
export default PreviewPhotosProfile