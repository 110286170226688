import { IOrderMapTracking } from "../../../models/order/aggregates/map/IOrderMapTracking"
import api from "../Api"

export const OrderMapTracking = () => {

    const getCurrent = async (orderId: string) => {
        const reponse = await api.get<IOrderMapTracking>("/driveapp/Ride/GetById?orderId=" + orderId)
        return reponse
    }

    return {
        getCurrent
    }
}