import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./NewTagForm.module.scss";
import { Button, IconButton, TextField, Tooltip } from "@material-ui/core";
import {
  CloseOutlined,
  InfoOutlined,
  CheckCircleOutline,
} from "@material-ui/icons";

const errorList = [
  "A senha deve ter exatamente 4 caracteres numéricos.",
  "A senha não deve conter pares do mesmo número repetidos.",
  "A senha não deve conter números sequenciais.",
  "Número da TAG inválido. Deve estar no formato A1-B2-C3-E4.",
  "Nome inválido",
];

export interface INewTagForm {
  onCancel: () => void;
  nameInput?: boolean;
  title: string;
  buttonTitle: string;
  handleSubmitNewTag: (
    password: string,
    tag: string,
    closeModal: () => void,
    name?: string
  ) => void;
}

const NewTagForm: FC<INewTagForm> = ({
  onCancel,
  handleSubmitNewTag,
  title,
  nameInput,
  buttonTitle
}) => {
  const [password, setPassword] = useState("");
  const [tag, setTag] = useState("");
  const [name, setName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState<string[]>([]);
  const [tagError, setTagError] = useState("");
  const [nameError, setNameError] = useState("");

  const validatePassword = useCallback((value: string) => {
    const newErrors: string[] = [];

    if (!/^\d{4}$/.test(value)) {
      newErrors.push(errorList[0]);
    }
    if (/(\d)\1/.test(value)) {
      newErrors.push(errorList[1]);
    }
    if (
      /(0123|1234|2345|3456|4567|5678|6789|7890|0987|9876|8765|7654|6543|5432|4321)/.test(
        value
      )
    ) {
      newErrors.push(errorList[2]);
    }
    setErrors(newErrors);
  }, []);

  const validateTag = useCallback((value: string) => {
    // Remover caracteres não alfanuméricos para manipular o valor
    const alphanumericValue = value.replace(/[^A-Za-z0-9]/g, "").toUpperCase();
    let formattedValue = "";

    // Adicionar hífens a cada 2 caracteres
    for (let i = 0; i < alphanumericValue.length; i++) {
      if (i > 0 && i % 2 === 0) {
        formattedValue += "-";
      }
      formattedValue += alphanumericValue[i];
    }

    // Validar se o formato é "A1-B2-C3-E4"
    if (
      /^[A-Z0-9][A-Z0-9]-[A-Z0-9][A-Z0-9]-[A-Z0-9][A-Z0-9]-[A-Z0-9][A-Z0-9]$/.test(
        formattedValue
      )
    ) {
      setTagError("");
    } else {
      setTagError(errorList[3]);
    }

    setTag(formattedValue);
  }, []);

  const handlePasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setPassword(value);
      validatePassword(value);
    },
    [validatePassword]
  );

  const handleTagChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      validateTag(value);
    },
    [validateTag]
  );

  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (nameInput) {
        if (value.length < 3) {
          setNameError(errorList[4]);
        } else {
          setNameError("");
        }
      }
      setName(value);
    },
    [nameInput]
  );

  const handleConfirmPasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setConfirmPassword(e.target.value);
    },
    []
  );

  const disableSubmit = useMemo(
    () =>
      !!errors.length ||
      confirmPassword !== password ||
      !!tagError.length ||
      !!nameError.length ||
      !password.length ||
      !tag?.length ||
      (nameInput && !name.length),
    [
      confirmPassword,
      errors.length,
      name.length,
      nameError.length,
      nameInput,
      password,
      tag,
      tagError.length,
    ]
  );

  const onSubmit = useCallback(() => {
    handleSubmitNewTag(password, tag, onCancel, name);
  }, [handleSubmitNewTag, name, onCancel, password, tag]);

  return (
    <div id={styles.NewTagForm}>
      <h4>
        {title}
        <IconButton onClick={onCancel}>
          <CloseOutlined />
        </IconButton>
      </h4>

      <div>
        {nameInput && (
          <div className={styles.input}>
            <p>
              Nome <span>*</span>
            </p>
            <TextField
              variant="outlined"
              value={name}
              onChange={handleNameChange}
              error={!!nameError.length}
              helperText={nameError}
            />
          </div>
        )}
        <div className={styles.input}>
          <p>
            Número da TAG <span>*</span>
            <Tooltip
              title="A tag está localizada no canto do seu cartão, Ex.: 7E-D6-D5-88"
              placement="right"
            >
              <InfoOutlined />
            </Tooltip>
          </p>
          <TextField
            variant="outlined"
            value={tag}
            onChange={handleTagChange}
            error={!!tagError.length}
            helperText={tagError}
            inputProps={{ maxLength: 11 }}
            placeholder="__-__-__-__"
          />
        </div>
        <div className={styles.rules}>
          <h5>Regras da senha:</h5>
          <p>
            {errors.find((it) => (it === errorList[0] ? true : false)) ? (
              <InfoOutlined style={{ color: "#FFB300" }} />
            ) : (
              <CheckCircleOutline style={{ color: "#399C54" }} />
            )}
            Ter 4 caracteres numéricos
          </p>
          <p>
            {errors.find((it) => (it === errorList[1] ? true : false)) ? (
              <InfoOutlined style={{ color: "#FFB300" }} />
            ) : (
              <CheckCircleOutline style={{ color: "#399C54" }} />
            )}
            Não repetir pares do mesmo número, Ex.: 1122
          </p>
          <p>
            {errors.find((it) => (it === errorList[2] ? true : false)) ? (
              <InfoOutlined style={{ color: "#FFB300" }} />
            ) : (
              <CheckCircleOutline style={{ color: "#399C54" }} />
            )}
            Não utilizar números sequenciais, Ex.: 1234 ou 4321
          </p>
        </div>
        <div className={styles.input}>
          <p>
            Senha (4 dígitos numéricos) <span>*</span>
          </p>
          <TextField
            variant="outlined"
            value={password}
            onChange={handlePasswordChange}
            error={!!errors.length}
            helperText={errors.join(" ")}
            type="password"
          />
        </div>
        <div className={styles.input}>
          <p>
            Confirmar senha <span>*</span>
          </p>
          <TextField
            variant="outlined"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            error={confirmPassword !== password}
            helperText={
              confirmPassword !== password ? "As senhas não correspondem" : ""
            }
            type="password"
          />
        </div>
      </div>
      <Button
        fullWidth
        variant="contained"
        disabled={disableSubmit}
        onClick={onSubmit}
      >
        {buttonTitle}
      </Button>
    </div>
  );
};

export default NewTagForm;
