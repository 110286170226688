import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import styles from "./TicketForm.module.scss";
import {
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { IPayerErrors } from "./validate";
import { cpfMask } from "cross-cutting/masks/cpf";
import {
  IPaymentLinkResponse,
  PaymentTypeEnum,
  PaymentTypeForm,
} from "models/paymentLink/IPaymentLinkResponse"; // Assumindo que já tem essa interface
import FindAddressPaymentLink from "pages/payment/deliverySelect/findAddressPaymentLink/FindAddressPaymentLink";
import { IAddress } from "models/address/IAddress";
import { IOwnerAddressPaymentGuest } from "models/paymentGuest/IPaymentGuestForm";
import { ArrowRight } from "@material-ui/icons";
import { cnpjMask } from "utils/mask/cnpj";

export interface ITicketFormValues {
  PayerFirstName: string;
  PayerLastName: string;
  PayerDocumentNumber: string;
  PayerEmail: string;
  PayerPhone?: string;
  PayerAddress: IOwnerAddressPaymentGuest;
}

export interface ITicketForm {
  values: ITicketFormValues;
  handleChangeValues: (name: string, value: string) => void;
  errors: IPayerErrors;
  paymentLink: IPaymentLinkResponse;
  address: IOwnerAddressPaymentGuest;
  formRef: React.RefObject<HTMLDivElement>;
  setHolder: React.Dispatch<React.SetStateAction<string>>
  holder: string
  setAddress: React.Dispatch<React.SetStateAction<IOwnerAddressPaymentGuest>>;
  setErrorsAddress: React.Dispatch<
    React.SetStateAction<{
      number: string;
      securityCode: string;
      ownerName: string;
      month: string;
      year: string;
      owner: {
        name: string;
        document: string;
        email: string;
        phoneNumber: string;
        address: string;
      };
    }>
  >;
  errorsAddress: {
    number: string;
    securityCode: string;
    ownerName: string;
    month: string;
    year: string;
    owner: {
      name: string;
      document: string;
      email: string;
      phoneNumber: string;
      address: string;
    };
  };
  setValues: React.Dispatch<React.SetStateAction<ITicketFormValues>>
}

const TicketForm: FC<ITicketForm> = ({
  values,
  handleChangeValues,
  errors,
  paymentLink,
  address,
  errorsAddress,
  setAddress,
  formRef,
  setErrorsAddress,
  setValues,
  holder,
  setHolder
}) => {
  const [openAddressForm, setOpenAddressForm] = useState(false);

  const isFieldVisible = (fieldType: PaymentTypeForm): boolean => {
    const ticketPaymentType = paymentLink.paymentTypes.find(
      (paymentType) => paymentType.type === PaymentTypeEnum.TICKET
    );
    return (
      ticketPaymentType?.fields?.some((field) => field.type === fieldType) ??
      false
    );
  };

  const onChangeHandle = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      ev.persist();
      handleChangeValues(
        ev.target.name,
        ev.target.name === "PayerDocumentNumber"
          ? holder === "fisica"
            ? cpfMask(ev.target.value)
            : cnpjMask(ev.target.value)
          : ev.target.value
      );
    },
    [handleChangeValues, holder]
  );

  useEffect(() => {
    setValues((prev) => ({ ...prev, PayerDocumentNumber: "" }));
  }, [holder, setValues]);

  const onSubmitAddress = useCallback(
    (address: IAddress) => {
      setAddress(address);
      setOpenAddressForm(false);
    },
    [setAddress]
  );

  const showAddress = useMemo(
    () =>
      address.street
        ? `${address.street}, ${address.number} - ${address.neighborhood}, ${address.city}/${address.state}`
        : null,
    [address]
  );

  const handleFocus = useCallback(() => {
    formRef?.current?.scroll({ top: 300, behavior: "smooth" });
  }, [formRef]);

  return (
    <div id={styles.TicketForm}>
      <Container maxWidth="xs" className={styles.container}>
        <Grid spacing={2} alignContent="center" alignItems="center" container>
          {isFieldVisible(PaymentTypeForm.DOCUMENT) && (
            <>
              <Grid item xs={12}>
                <FormControl defaultValue={"fisica"} component="fieldset">
                  <RadioGroup
                    aria-label="pessoa"
                    name="gender1"
                    value={holder}
                    onChange={(ev, value) => setHolder(value)}
                    row
                  >
                    <FormControlLabel
                      value="fisica"
                      control={<Radio color="primary" />}
                      label="CPF"
                    />
                    <FormControlLabel
                      value="juridica"
                      control={<Radio color="primary" />}
                      label="CNPJ"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  autoComplete={"off"}
                  helperText={errors.PayerDocumentNumber}
                  error={!!errors.PayerDocumentNumber}
                  variant="outlined"
                  fullWidth
                  onChange={onChangeHandle}
                  value={values.PayerDocumentNumber}
                  name="PayerDocumentNumber"
                  onFocus={handleFocus}
                  label={`${holder === "fisica" ? "CPF" : "CNPJ"}`}
                  placeholder={`${holder === "fisica" ? "CPF" : "CNPJ"}`}
                />
              </Grid>
            </>
          )}

          {isFieldVisible(PaymentTypeForm.NAME) && (
            <Grid item xs={12}>
              <TextField
                required
                autoComplete={"off"}
                helperText={errors.PayerFirstName}
                error={!!errors.PayerFirstName}
                variant="outlined"
                fullWidth
                onChange={onChangeHandle}
                value={values.PayerFirstName}
                name="PayerFirstName"
                label={`${holder === "fisica" ? "Nome" : "Razão social"}`}
                placeholder={`${holder === "fisica" ? "Nome" : "Razão social"}`}
              />
            </Grid>
          )}
          {isFieldVisible(PaymentTypeForm.EMAIL) && (
            <Grid item xs={12}>
              <TextField
                required
                autoComplete={"off"}
                helperText={errors.PayerEmail}
                error={!!errors.PayerEmail}
                variant="outlined"
                fullWidth
                onChange={onChangeHandle}
                value={values.PayerEmail}
                name="PayerEmail"
                label="E-mail"
                placeholder="E-mail"
              />
            </Grid>
          )}
          {isFieldVisible(PaymentTypeForm.PHONE) && (
            <Grid item xs={12}>
              <TextField
                required
                autoComplete={"off"}
                helperText={errors.PayerPhone}
                error={!!errors.PayerPhone}
                variant="outlined"
                fullWidth
                onChange={onChangeHandle}
                value={values.PayerPhone}
                name="PayerPhone"
                label="Telefone"
                placeholder="Telefone"
                inputProps={{ maxLength: 11 }}
              />
            </Grid>
          )}
          {isFieldVisible(PaymentTypeForm.ADDRESS) && (
            <Grid style={{}} item xs={12} className={styles.address}>
              <div
                onClick={() => {
                  setOpenAddressForm(true);
                }}
              >
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Endereço"
                  className={styles.textField}
                  value={showAddress}
                  helperText={errorsAddress.owner.address}
                  error={!!errorsAddress.owner.address}
                  disabled
                  InputLabelProps={{
                    shrink: !!address.street,
                    style: { color: "#5f5f5f" }, 
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <ArrowRight />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>
          )}
        </Grid>
      </Container>
      <Modal
        open={openAddressForm}
        onClose={() => setOpenAddressForm(false)}
        className={styles.addressContainer}
      >
        <Paper className={styles.paper}>
          <FindAddressPaymentLink
            onSubmitAddress={onSubmitAddress}
            address={address}
            setAddress={setAddress}
            handleChangeValues={handleChangeValues}
          />
        </Paper>
      </Modal>
    </div>
  );
};

export default TicketForm;
