import React, { useCallback, useEffect, useState, FC, useMemo, useRef } from "react";
import { Grid, Drawer, Button, Icon, ButtonGroup, TextField, MenuItem, Modal } from "@material-ui/core";
import { CreditCardStatus, ICardItem } from "../../../models/payment/ICard";
import CardApi from "../../../services/Api/card/CardApi";
import { PaymentType } from "../../../models/payment/IPay";
import { usePayment } from "../../../context/payment/PaymentContext";
import { moneyMask, moneyToFloat } from "../../../cross-cutting/masks/money";
import { useUi } from "../../../context/ui/UIContext";
import { CardItem } from "../../../components/CardItem/CardItem";
import { GetCardTypeImage } from "../../../components/getTypeCard/GetTypeCard";
import { NewCard } from "../../newCard/NewCard";
import { VerifyModal } from "./verifyModal/VerifyModal";
import { IOrderDetail } from "models/order/aggregates/datail/IOrderDetail";
import { IStoreConfig } from "models/local/ILocalConfig";
import styles from './PaymentSelect.module.scss';
import { DadosWallet } from "models/payment/IPaymentApi";
import { IUserWallet } from "models/wallet/IWallet";
import { WalletApi } from "services/Api/wallet/WalletApi";
import NumberToMoneyString from "utils/mask/NumberToMoney";

export interface PaymentSelectProps {
  orderDetail?: IOrderDetail | null;
  setPaymentType: (paymentType: PaymentType) => void;
  paymentType: PaymentType;
  setChange?: (change: number) => void;
  change?: number | null;
  setInstallment?: (installment: number) => void;
  installment?: number | null;
  card?: ICardItem | null;
  setCard?: (card: ICardItem | null) => void;
  setWallet?: (wallet: DadosWallet) => void;
  storeConfig?: IStoreConfig | null;
  disabledTitle?: boolean
}


export const PaymentSelect: FC<PaymentSelectProps> = ({
  orderDetail,
  setPaymentType,
  paymentType,
  setChange,
  setInstallment,
  installment,
  card,
  setCard,
  storeConfig,
  disabledTitle,
  setWallet: setWalletToPay
}) => {
  const [cards, setcard] = useState<ICardItem[]>([]);
  const [selectCardOpen, setselectCardOpen] = useState(false);
  const [openAddCard, setopenAddCard] = useState(false);
  const [openVerifyCard, setOpenVerifyCard] = useState(false);
  const [troco, settroco] = useState<string>("");
  const [wallet, setWallet] = useState<IUserWallet | null>(null);

  const { showLoading, hideLoading, toast } = useUi();
  const { updatePaymentTypeLocalStorage } = usePayment();

  const updateWallet = useRef(true);

  const getWallet = useCallback(() => {
    const walletApi = WalletApi();
    walletApi.getWallet(storeConfig?.storeId ?? "").then((response) => {
      if (response.status === 200) {
        setWallet(response.data);
      }
    });
  }, [storeConfig]);

  useEffect(() => {
    if (updateWallet.current && paymentType === PaymentType.WALLET && wallet?.Id) {
      updateWallet.current = false;
      setWalletToPay && setWalletToPay({WalletId: wallet?.Id ?? "", Senha: ""})
    }
  }, [paymentType, setWalletToPay, wallet])
  

  const getCards = useCallback(() => {
    const response = CardApi.getCards().then((response) => {
      if (response.status === 200) {
        setcard(response.data);
      }
    });
  }, []);

  const onPressDeleteCard = useCallback(
    async (_deletedCard: ICardItem) => {
      try {
        showLoading();
        const responseDelete = await CardApi.deleteCard(_deletedCard.id);
        if (_deletedCard.id === card?.id) {
          setCard && setCard(null);
          setPaymentType(PaymentType.NAO_INFORMADO);
        }
        getCards();
      } catch (error: any) {
        toast(error?.response?.data?.message ?? "Ops, não foi possivel excluir cartao", "error");
      } finally {
        hideLoading();
      }
    },
    [showLoading, card, getCards, setCard, setPaymentType, toast, hideLoading]
  );

  useEffect(() => {
    getCards();
    getWallet();
  }, [getCards, getWallet]);

  const onChangeType = (type: PaymentType) => {
    setPaymentType(type);
    if (type === PaymentType.DEBITO || type === PaymentType.CREDITO) {
      settroco("R$0,00");
    } else if (type === PaymentType.DINHEIRO) {
      setCard && setCard(null);
    }
  };

  const onClickAddNewCard = useCallback(() => {
    setopenAddCard(true);
    // history.push(url + "/newCard")
  }, []);
  const onCloseAddNewCard = useCallback(() => {
    setopenAddCard(false);
    getCards();
    // history.push(url + "/newCard")
  }, [getCards]);
  const onCloseVerifyCard = useCallback(() => {
    setOpenVerifyCard(false);
    getCards();
    if (card) {
      setCard && setCard({ ...card, status: CreditCardStatus.VALIDATED });
    }
    // history.push(url + "/newCard")
  }, [getCards, card, setCard]);

  useEffect(() => {
    const newChange = moneyToFloat(troco);
    setChange && setChange(moneyToFloat(troco));
  }, [setChange, troco]);

  const onClickSelectCard = useCallback(
    (card: ICardItem | null) => {
      setCard && setCard(card);
      setselectCardOpen(false);
      settroco("R$0,00");
    },
    [setCard]
  );

  const onClickPix = useCallback(() => {
    updatePaymentTypeLocalStorage(PaymentType.PIX)
    setPaymentType(PaymentType.PIX);
    setCard && setCard(null);
    setselectCardOpen(false);
  }, [updatePaymentTypeLocalStorage, setPaymentType, setCard]);

  const onClickDinheiro = useCallback(() => {
    updatePaymentTypeLocalStorage(PaymentType.DINHEIRO)
    setPaymentType(PaymentType.DINHEIRO);
    setCard && setCard(null);
    setselectCardOpen(false);
  }, [updatePaymentTypeLocalStorage, setPaymentType, setCard]);
  
  const onClickCartao = useCallback((card: ICardItem) => {
    updatePaymentTypeLocalStorage(PaymentType.CREDITO)
    onClickSelectCard(card);
    setPaymentType(PaymentType.CREDITO);
    setChange && setChange(0);
  },
    [updatePaymentTypeLocalStorage, onClickSelectCard, setPaymentType, setChange]
  );

  const onClickWallet = useCallback((wallet: DadosWallet) => {
    updateWallet.current = false;
    updatePaymentTypeLocalStorage(PaymentType.WALLET)
    settroco("R$0,00");
    setPaymentType(PaymentType.WALLET);
    setChange && setChange(0);
    setWalletToPay && setWalletToPay(wallet);
    setselectCardOpen(false);
  },
    [updatePaymentTypeLocalStorage, setPaymentType, setChange, setWalletToPay]
  );

  const onClickCartaoNaEntrega = useCallback(() => {
    updatePaymentTypeLocalStorage(PaymentType.CREDITO)
    onClickSelectCard(null);
    setCard && setCard(null);
    setPaymentType(PaymentType.CREDITO);
  }, [onClickSelectCard, setCard, setPaymentType, updatePaymentTypeLocalStorage]);

  const onChangeInstallment = useCallback((installment: string) => {
    setInstallment && setInstallment(parseInt(installment));
  }, [setInstallment]);


  const isPixEnabled = useMemo(() =>
    storeConfig?.storeId.toLowerCase() === `1f3d32dd-9f1e-042a-dbb4-09ecb64a1427` ||
    storeConfig?.storeId.toLowerCase() === "03dcdd76-1450-c832-0913-518cef45cdab" ||
    storeConfig?.storeId.toLowerCase() === "455b3e22-d335-4336-7884-d97522f97ed3" ||
    storeConfig?.storeId.toLowerCase() === "53e5e585-6f4d-eab7-f739-516988cbdaf3" ||
    storeConfig?.storeId.toLowerCase() === "189c358b-b76c-7336-f922-192722626df9" ||
    storeConfig?.storeId.toLowerCase() === "2325cad0-bda1-c2bd-0e78-cd8f1707f2e5" ||
    storeConfig?.storeId.toLowerCase() === "682b8ff7-55ed-1939-69e4-e4421f70698b" ||
    storeConfig?.storeId.toLowerCase() === "d643e88f-5457-6024-483c-d25b048ee927" ||
    storeConfig?.storeId.toLowerCase() === "34899fab-ed6c-0697-897a-69bd4525741a" ||
    storeConfig?.storeId.toLowerCase() === "80ecc4fb-a318-d3b9-a337-88af75b8a459" ||
    storeConfig?.storeId.toLowerCase() === "511d6f49-b48b-5649-eb53-6debe54ac8d6" ||
    storeConfig?.storeId.toLowerCase() === "9b79e304-6f10-21e7-5300-360b1d43e218" ||
    storeConfig?.storeId.toLowerCase() === "fe77413a-30a5-785f-2406-d285cd393d24" ||
    storeConfig?.storeId.toLowerCase() === "bdb366cd-8775-240c-5522-ebb01a66fb65" ||
    storeConfig?.storeId.toLowerCase() === "ff200210-b47e-f2b3-6e36-12cfe745cce6" ||
    storeConfig?.storeId.toLowerCase() === "cda62d23-34ff-56d2-c229-0d959a06ebb5" ||
    storeConfig?.storeId.toLowerCase() === "ad25be8d-2d8c-35b5-566d-5610037ab454" ||
    storeConfig?.storeId.toLowerCase() === "8c78cf78-e8f2-9884-4151-6f2fb12e05e5" ||
    storeConfig?.storeId.toLowerCase() === "14431512-0a23-7903-4c9b-646e0bdaceb1" ||
    storeConfig?.storeId.toLowerCase() === "e3d4376a-7c48-d7bc-9531-e16e81c2229d" ||
    storeConfig?.storeId.toLowerCase() === "67a233c4-a71e-542a-bab1-ce874f6ef388" ||
    storeConfig?.storeId.toLowerCase() === "1e4d5c49-3823-e623-d702-89ab1b9cfc1f" ||
    storeConfig?.storeId.toLowerCase() === "9f2728a7-8e30-b60c-df8c-1773656f699a" ||
    storeConfig?.storeId.toLowerCase() === "82518b03-0a0c-407c-b569-f7ac7bc54b47" ||
    storeConfig?.storeId.toLowerCase() === "c00cf7e8-ad63-1203-610e-7f10b9dc0f00" ||
    storeConfig?.storeId.toLowerCase() === "e2f82bd9-a314-cbc2-7bc1-e4b185f95329" ||
    storeConfig?.storeId.toLowerCase() === "2a765cd0-e0ee-4dad-932c-337d3edbdb70" ||
    storeConfig?.storeId.toLowerCase() === "33ba2661-6511-eaa7-4db4-6a35376de628" ||
    storeConfig?.storeId.toLowerCase() === "2da74e9f-c1de-95bb-5922-fbe9e47c20ed"||
    storeConfig?.storeId.toLowerCase() === "0e1f8a20-ac41-3d28-2186-7d52ac56e5d1", [storeConfig])

  return (
    <Grid container>
      <Modal
        open={selectCardOpen}
        onClose={() => {
          setselectCardOpen(false);
        }}
        className={styles.modalContainer}
      >
        <div className={styles.modalContent}>
          <div style={{ position: "relative", paddingTop: 16, paddingBottom: 16, margin: "auto", maxHeight: "70vh" }}>
            {(storeConfig?.allowOnlinePayment) && (
              <>
                <h4 className={styles.paymentFormTitle}>Escolha uma forma de pagamento</h4>
                <span className={styles.whereToPayLabel}>Pagar online</span>
                {storeConfig?.allowWalletPayment && (
                  <>
                    <CardItem 
                      iconName="wallet" 
                      textTop={`Wallet - ${NumberToMoneyString(wallet?.Balance ?? 0)}`} 
                      onClick={() => onClickWallet({WalletId: wallet?.Id ?? "", Senha: ""})} 
                    />
                  </>
                )}
                {cards?.length > 0 &&
                  cards.map((card) => (
                    <CardItem
                      textTop={`xxxx.xxxx.xxxx.${card.finalNumber}`}
                      textBottom={card.brandDescription}
                      imageSrc={GetCardTypeImage(card.brandDescription)}
                      onClick={() => onClickCartao(card)}
                      autenticated={card.status === CreditCardStatus.VALIDATED ? true : false}
                      autenticateIcon={!storeConfig?.disableAutenticateCard}
                      key={card.id}
                      onClickRemove={() => {
                        onPressDeleteCard(card);
                      }}
                      iconName="credit_card"
                    />
                  ))}
                <CardItem textTop={"Cartão de Crédito"} onClickAdd={onClickAddNewCard} onClick={onClickAddNewCard} iconName="credit_card" />
              </>
            )}
            {isPixEnabled && (
              <>
                <CardItem iconName="qr_code_2" textTop={"PIX"} onClick={onClickPix}></CardItem>
              </>
            )}
            {storeConfig?.acceptSlipPayment && (
              <>
                <CardItem disabled textTop={"Boleto"}></CardItem>
              </>
            )}

            {storeConfig?.allowPaymentOnDelivery && (
              <>
                <span className={styles.whereToPayLabel}>Pagar na entrega</span>
                <CardItem textTop={"Dinheiro"} iconName="local_atm" onClick={onClickDinheiro}></CardItem>
                <CardItem textTop={"Cartao Crédito/Débito"} iconName="credit_card" onClick={onClickCartaoNaEntrega}></CardItem>
              </>
            )}

            {!storeConfig?.allowOnlinePayment && !storeConfig?.acceptSlipPayment && !storeConfig?.allowPaymentOnDelivery && !isPixEnabled && (
              <div>
                <div className={styles.emptyPaymentModalMessage}>Não existe nenhuma forma de pagamento disponível no momento!</div>
                <Button className={styles.closePaymentModalButton} color="primary" variant="contained" onClick={() => setselectCardOpen(false)}>Voltar</Button>
              </div>
            )}
          </div>
        </div>

        {/* <div style={{ padding: "4px 0" }}>
                        <Button variant="outlined" fullWidth color="primary"><Icon>add</Icon>Adicionar Novo Endereço</Button>
                    </div> */}
      </Modal>
      <Modal open={openAddCard} onClose={() => setopenAddCard(false)} className={styles.modalContainer}>
        <NewCard onCancel={() => onCloseAddNewCard()} onConfirm={() => onCloseAddNewCard()}></NewCard>
      </Modal>
      {card && (
        <Drawer anchor="bottom" open={openVerifyCard} onBackdropClick={() => setOpenVerifyCard(false)}>
          <VerifyModal card={card} open={openVerifyCard} onValidate={() => onCloseVerifyCard()} />
        </Drawer>
      )}

      <Grid xs={12} spacing={1} item>
        <Grid container spacing={1} style={{ marginBottom: 16 }}>
          {!disabledTitle &&
            <Grid xs={12} item>
              <div className={styles.title}>Forma de pagamento</div>
            </Grid>
          }
          {(paymentType === PaymentType.DEBITO || paymentType === PaymentType.CREDITO) &&
            (card ? (
              <Grid xs={12} item>
                <CardItem
                  textTop={`xxxx.xxxx.xxxx.${card.finalNumber}`}
                  textBottom={card.brandDescription}
                  iconName="credit_card"
                  imageSrc={GetCardTypeImage(card.brandDescription)}
                  autenticated={card.status === CreditCardStatus.VALIDATED ? true : false}
                  onClickEdit={() => {
                    setselectCardOpen(true);
                    setopenAddCard(false);
                  }}
                  onClick={() => {
                    setselectCardOpen(true);
                    setopenAddCard(false);
                  }}
                />
                {!storeConfig?.disableAutenticateCard && card.status !== CreditCardStatus.VALIDATED && (
                  <Grid xs={12} style={{ textAlign: "center", color: "#f55", borderRadius: 6 }} item>
                    <div>Cartão não Verificado</div>
                    <Button onClick={() => setOpenVerifyCard(true)} fullWidth style={{ backgroundColor: "#f55", color: "#fff" }} variant="contained">
                      <Icon>verified_user</Icon> Verificar agora
                    </Button>
                  </Grid>
                )}

                {storeConfig?.acceptInstallment && orderDetail && orderDetail?.amount > 100 && (
                  <Grid xs={12} style={{ textAlign: "center", borderRadius: 6 }} item>
                    <div>Parcelas</div>
                    <TextField fullWidth value={installment} onChange={(ev) => onChangeInstallment(ev.target.value)} select>
                      <MenuItem value={1}>1x A vista (R${orderDetail?.amount.toFixed(2)}) </MenuItem>
                      <MenuItem value={2}>2x R${(orderDetail?.amount / 2).toFixed(2)}</MenuItem>
                      <MenuItem value={3}>3x R${(orderDetail?.amount / 3).toFixed(2)}</MenuItem>
                    </TextField>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Grid xs={12} item>
                <CardItem
                  textTop={`Cartão de Crédito/Débito `}
                  textBottom={"NA ENTREGA"}
                  iconName="credit_card"
                  onClickEdit={() => {
                    setselectCardOpen(true);
                    setopenAddCard(false);
                  }}
                  onClick={() => {
                    setselectCardOpen(true);
                    setopenAddCard(false);
                  }}
                />
              </Grid>
            ))}
          {paymentType === PaymentType.DINHEIRO && (
            <Grid xs={12} item>
              <CardItem
                textTop={"DINHEIRO"}
                textBottom={"Na entrega"}
                iconName="local_atm"
                onClickEdit={() => {
                  setselectCardOpen(true);
                  setopenAddCard(false);
                }}
                onClick={() => {
                  setselectCardOpen(true);
                  setopenAddCard(false);
                }}
              />
            </Grid>
          )}

          {paymentType === PaymentType.PIX && (
            <Grid xs={12} item>
              <CardItem
                textTop={"PIX"}
                iconName={"qr_code_2"}
                onClickEdit={() => {
                  setselectCardOpen(true);
                  setopenAddCard(false);
                }}
                onClick={() => {
                  setselectCardOpen(true);
                  setopenAddCard(false);
                }}
              />
            </Grid>)}

          {paymentType === PaymentType.NAO_INFORMADO && (
            <Grid xs={12} item>
              <CardItem
                textTop={"Nenhuma forma de pagamento selecionado"}
                onClickEdit={() => {
                  setselectCardOpen(true);
                  setopenAddCard(false);
                }}
                onClick={() => {
                  setselectCardOpen(true);
                  setopenAddCard(false);
                }}
              />
            </Grid>
          )}

          {paymentType === PaymentType.WALLET && (
            <Grid xs={12} item>
              <CardItem
                textTop={`Wallet - ${NumberToMoneyString(wallet?.Balance ?? 0)}`} 
                iconName="wallet"
                onClickEdit={() => {
                  setselectCardOpen(true);
                  setopenAddCard(false);
                }}
                onClick={() => {
                  setselectCardOpen(true);
                  setopenAddCard(false);
                }}
              />
            </Grid>
          )}


          {paymentType === PaymentType.DINHEIRO && (
            <Grid xs={12} style={{ textAlign: "center" }} item>
              <Grid xs={12} item>
                <div className={styles.changeTitle}>Troco</div>
              </Grid>
              <Grid xs={12} item>
                <div className={styles.changeDescription}>Pra qual valor deseja troco?</div>
              </Grid>
              <TextField
                variant="outlined"
                inputMode="numeric"
                placeholder="R$ 0,00"
                onChange={(e) => settroco(moneyMask(e.target.value))}
                value={troco}
                size="small"
                inputProps={{ prefix: "R$", inputMode: "numeric", style: { textAlign: "center", fontSize: "larger" } }}
                style={{ width: "100%", maxWidth: 400 }}
              ></TextField>
            </Grid>
          )}
          {
            false && (
              <Grid xs={12} style={{ textAlign: "center" }} item>
                <ButtonGroup variant="outlined" aria-label="contained primary button group">
                  <Button
                    onClick={() => onChangeType(PaymentType.CREDITO)}
                    color="primary"
                    variant={paymentType === PaymentType.CREDITO ? "contained" : "outlined"}
                    size="small"
                  >
                    <div>
                      <div>
                        <Icon>credit_card</Icon>
                      </div>
                      <div>Credito</div>
                    </div>
                  </Button>
                  <Button
                    onClick={() => onChangeType(PaymentType.DEBITO)}
                    color="primary"
                    variant={paymentType === PaymentType.DEBITO ? "contained" : "outlined"}
                    size="small"
                  >
                    <div>
                      <div>
                        <Icon>credit_card</Icon>
                      </div>
                      <div>Debito</div>
                    </div>
                  </Button>
                </ButtonGroup>
              </Grid>
            )
          }
        </Grid>
      </Grid>
    </Grid>
  );
};
