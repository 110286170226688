import { StatusOrder } from 'models/order/aggregates/tracking/StatusOrder'
import React, { FC, useEffect, useState } from 'react'
import { StatusItem, StatusState } from './statusItem/StatusItem'


export interface IStatusMode {
    pendente: StatusState
    recusado: StatusState
    aceito: StatusState
    preparo: StatusState
    transporte: StatusState
    retirar: StatusState
    entregue: StatusState
    Devolvido: StatusState
}


interface ITimeLineStatusProps {
    statusOrder: StatusOrder
}


export const TimeLineStatus: FC<ITimeLineStatusProps> = ({ statusOrder }) => {

    const [statusMode, setstatusMode] = useState<IStatusMode>(

        {
            pendente: StatusState.disabled,
            recusado: StatusState.disabled,
            aceito: StatusState.disabled,
            preparo: StatusState.disabled,
            transporte: StatusState.disabled,
            retirar: StatusState.hidden,
            entregue: StatusState.hidden,
            Devolvido: StatusState.hidden
        }

    )

    useEffect(() => {


        switch (statusOrder) {
            case StatusOrder.pendente:

                setstatusMode(
                    {
                        pendente: StatusState.enabled,
                        aceito: StatusState.disabled,
                        preparo: StatusState.disabled,
                        transporte: StatusState.disabled,
                        retirar: StatusState.hidden,
                        entregue: StatusState.hidden,
                        recusado: StatusState.hidden,
                        Devolvido: StatusState.hidden
                    }
                )

                break;
            case StatusOrder.recusado:
                setstatusMode(
                    {
                        pendente: StatusState.enabled,
                        aceito: StatusState.hidden,
                        preparo: StatusState.hidden,
                        transporte: StatusState.hidden,
                        retirar: StatusState.hidden,
                        entregue: StatusState.hidden,
                        recusado: StatusState.hidden,
                        Devolvido: StatusState.canceled
                    }
                )
                break;
            case StatusOrder.aceito:
                setstatusMode(
                    {
                        pendente: StatusState.concluded,
                        aceito: StatusState.enabled,
                        preparo: StatusState.disabled,
                        transporte: StatusState.disabled,
                        retirar: StatusState.hidden,
                        entregue: StatusState.hidden,
                        recusado: StatusState.hidden,
                        Devolvido: StatusState.hidden
                    }
                )
                break;
            case StatusOrder.preparo:
                setstatusMode(
                    {
                        pendente: StatusState.concluded,
                        aceito: StatusState.concluded,
                        preparo: StatusState.enabled,
                        transporte: StatusState.disabled,
                        retirar: StatusState.hidden,
                        entregue: StatusState.hidden,
                        recusado: StatusState.hidden,
                        Devolvido: StatusState.hidden
                    }
                )
                break;
            case StatusOrder.transporte:
                setstatusMode(
                    {
                        pendente: StatusState.concluded,
                        aceito: StatusState.concluded,
                        preparo: StatusState.concluded,
                        transporte: StatusState.enabled,
                        retirar: StatusState.hidden,
                        entregue: StatusState.hidden,
                        recusado: StatusState.hidden,
                        Devolvido: StatusState.hidden
                    }
                )
                break;
            case StatusOrder.retirar:
                setstatusMode(
                    {
                        pendente: StatusState.concluded,
                        aceito: StatusState.concluded,
                        preparo: StatusState.concluded,
                        transporte: StatusState.concluded,
                        retirar: StatusState.enabled,
                        entregue: StatusState.hidden,
                        recusado: StatusState.hidden,
                        Devolvido: StatusState.hidden
                    }
                )
                break;
            case StatusOrder.entregue:
                setstatusMode(
                    {
                        pendente: StatusState.concluded,
                        aceito: StatusState.concluded,
                        preparo: StatusState.concluded,
                        transporte: StatusState.concluded,
                        retirar: StatusState.hidden,
                        entregue: StatusState.enabled,
                        recusado: StatusState.hidden,
                        Devolvido: StatusState.hidden
                    }
                )
                break;
            case StatusOrder.devolvido:
                setstatusMode(
                    {
                        pendente: StatusState.concluded,
                        aceito: StatusState.concluded,
                        preparo: StatusState.concluded,
                        transporte: StatusState.concluded,
                        retirar: StatusState.hidden,
                        entregue: StatusState.hidden,
                        recusado: StatusState.hidden,
                        Devolvido: StatusState.enabled
                    }
                )
                break;
        }

        return () => {

        }
    }, [statusOrder])


    return (
        <div id="TimeLineStatus">
            <StatusItem state={statusMode.pendente} index={1} >{StatusOrder.pendente}</StatusItem>
            <StatusItem state={statusMode.aceito} index={2} >Pronto! Seu pedido foi aceito</StatusItem>
            <StatusItem state={statusMode.preparo} index={3} >Estamos preparando seu pedido.</StatusItem>
            <StatusItem state={statusMode.transporte} index={4} >Oba! seu pedido já saiu para entrega.</StatusItem>
            <StatusItem state={statusMode.retirar} index={4} >Oba! Seu pedido já está pronto.</StatusItem>
            <StatusItem state={statusMode.entregue} index={5} >Entregue</StatusItem>
            <StatusItem state={statusMode.recusado} index={0} >Pedido cancelado.</StatusItem>
        </div>
    )
}


