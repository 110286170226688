import React, { FC, useState, useEffect } from 'react';
import { ISeccionedProduct } from '../productList/interfaces/ISeccionedProduct';
import './TabMenu.css';
import { Tab, Tabs } from '@material-ui/core';

interface TabMenuProps {
    seccionedProducts: ISeccionedProduct[],
    onClick: (index: number) => void
    position?: number
}

export const TabMenu: FC<TabMenuProps> = ({ position, seccionedProducts, onClick }) => {
    const [categoriaIndex, setcategoriaIndex] = useState(position);

    useEffect(() => {
        setcategoriaIndex(position)
    }, [position])

    function truncateText(text: string, maxLength: number) {
        if (text.length <= maxLength) {
            return text;
        }

        var index = text.substr(0, maxLength).lastIndexOf(" ");
        return text.substr(0, index) + "...";
    }

    return (
        <Tabs
            value={categoriaIndex}
            indicatorColor={"primary"}
            id={"tabCategoria"}
            onChange={(e, newValue) => { onClick(newValue); setcategoriaIndex(newValue) }}
            aria-label="simple tabs example"
            variant="scrollable"
            className="tabContainer"
        >
            {
                seccionedProducts.length > 1 &&
                seccionedProducts.map((section, index) =>
                    <Tab
                        id={`simple-tab-${index}`}
                        aria-controls={`simple-tabpanel-${index}`}
                        value={index}
                        label={truncateText(section.title.toLowerCase(), 30)}
                        className="tab"
                        key={section.id}
                        onClick={() => {
                            if (window.fbq) {
                                window.fbq("trackCustom", "CategoryClicked", {
                                    content_ids: section.id,
                                    content_name: section.title,
                                });
                            }
                        }}
                    />
                )
            }
        </Tabs>
    )
}

export default TabMenu
