import { IMeepCardData } from "../../../models/meepCard/ILoginMeepCard";
import api from "../Api";

export const MeepCardApi = () => {

    const login = async (apelido: string, senha: string, localClienteId: string) => {
        const loginRequest = {
            apelido,
            senha,
            localClienteId
        }
        const responseLogin = await api.post<IMeepCardData>("/dispositivo/cartaonominal/autenticar", loginRequest);
        return responseLogin
    }

    return {
        login
    }
}