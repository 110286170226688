import api from "../Api";

const DeliveryApi = () => {
    const getDeliveryFee = (localId: string, addressId:string)=> {
        return api.get(
            `/SimulateDeliveryCost?StoreId=${localId}&AddressId=${addressId}`,
        );
    };
    return { getDeliveryFee }
}

export default DeliveryApi()
