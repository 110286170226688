import React, { FC, useState } from 'react'
import styles from "./Image.module.scss"
interface ImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    fade?: boolean
}
const Image: FC<ImageProps> = (props) => {
    const [onLoadImage, setonLoadImage] = useState(false)
    return (
        <img onLoad={() => { setonLoadImage(true) }} {...props} className={`${props.className} ${onLoadImage ? styles.showImage : styles.hideImage}`} alt="" />
    )
}

export default Image
