import axios from "axios";
import { API_BASE_URL, API_EXTERNAL_URL } from "../../cross-cutting/constants";

// const BASE_URL = "https://meepserver.azurewebsites.net/api";
// const BASE_URL = "https://meepserver-dev.azurewebsites.net/api";
let useExternal = false;
if (window.location.href.includes("externalAddNewCard") || window.location.href.toLowerCase().includes("paymentlink")){
  useExternal = true;
}
const BASE_URL = useExternal ? API_EXTERNAL_URL : API_BASE_URL;

// export interface IResponse<T> {
//   data: T;
//   status: number;
//   statusText: string;
// }
// export interface IError<T> extends AxiosResponse<T> {
//   code?: string;
//   response?: IResponse<T>;
// }

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: 'bearer ' + localStorage.getItem(`@Meep:token`)
  }
});

export const Api = () => {
  return api;
};

export default api;
