import { ScheduleTourApi } from 'services/Api/schedule/ScheduleTourApi';
import { IValueServiceSchedule } from '../../presentation/pages/formTour/interface/IValuesServiceSchedule';

export const GetValuesServiceScheduleUseCase = async (serviceId: string): Promise<IValueServiceSchedule[]> => {

    const scheduleTourApi = ScheduleTourApi();
    const response = await scheduleTourApi.getServicesValuesByServiceId(serviceId);

    return (response.values.map((item) => ({
        listId: item.listId,
        description: item.description,
        value: item.value,
    })))
}
