import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Checkbox, Icon, Radio } from '@material-ui/core'

import { useComposition } from 'components/productComposition/context/CompositionContext'
import { ITotalsComposition } from 'components/productComposition/interfaces/ITotalsComposition'
import { IProductComposition, IProductCompositionItem } from 'models/catalog/aggregates/Composition'
import NumberToMoneyString from 'utils/mask/NumberToMoney'
import styles from './BoxProductCompositionItem.module.scss'

export interface IProductCompositionItemProps {
    productCompositionItem: IProductCompositionItem;
    productComposition: IProductComposition;
    disabledAllAdd?: boolean
}

const BoxProductCompositionItem: FC<IProductCompositionItemProps> = ({ productCompositionItem, productComposition, disabledAllAdd }) => {
    const {
        getTotalsCompositionItem,
        orderCompositions,
        addCompositionItem,
        removerCompositionItem,
        disabled
    } = useComposition();

    const [totalsItem, setTotalsItems] = useState<ITotalsComposition>({ price: 0, quantity: 0 });
    const [disabledAdd, setDisabledAdd] = useState(false)

    useEffect(() => {
        const newTotals = getTotalsCompositionItem(productCompositionItem.id);
        setTotalsItems(newTotals);
    }, [getTotalsCompositionItem, orderCompositions, productCompositionItem]);

    useEffect(() => {
        const totalCompositionItems = getTotalsCompositionItem(productCompositionItem.id);
        const maxCompositionItem = !!productCompositionItem.max && (totalCompositionItems.quantity >= productCompositionItem.max)
        if (disabledAllAdd || maxCompositionItem) {
            setDisabledAdd(true);
        } else {
            setDisabledAdd(false)
        }

    }, [disabledAllAdd, productCompositionItem, orderCompositions, getTotalsCompositionItem])


    const removeQuantityHandle = useCallback(
        () => {
            removerCompositionItem(productComposition.id, productCompositionItem, 1);
        },
        [removerCompositionItem, productComposition.id, productCompositionItem],
    )

    const addQuantityHandle = useCallback(
        () => {
            addCompositionItem(productComposition, productCompositionItem, 1);
        },
        [addCompositionItem, productComposition, productCompositionItem],
    )

    const onClickOption = useCallback(() => {
        if (!!totalsItem.quantity) {
            removeQuantityHandle()
        } else {
            addQuantityHandle()
        }
    }, [addQuantityHandle, removeQuantityHandle, totalsItem.quantity])

    const isCheckbox = useMemo(() => {
        return productComposition.compositionItems.every((item) => item.max === 1);
    }, [productComposition.compositionItems]);

    return (
        <div id={styles.ProductCompositionItem} >
            <div className={styles.container} >
                <div className={styles.start}>
                    {
                        !disabled && (
                            (productComposition.min === 1 && productComposition.max === 1) ?
                                <div className={styles.selector}>
                                    <Radio style={{ padding: 0 }} size='small' checked={!!totalsItem.quantity} onClick={onClickOption} />
                                </div>
                                :
                                    isCheckbox ?
                                        <div className={styles.selector}>
                                            <Checkbox style={{ padding: 0 }} size='small' checked={!!totalsItem.quantity} onClick={onClickOption} />
                                        </div>
                                    :
                                        <div className={styles.quantitiy}>
                                            <button
                                                type="button"
                                                style={{ all: 'unset', cursor: 'pointer' }}
                                                disabled={!totalsItem.quantity}
                                                className={!totalsItem.quantity ? styles.disabledIcon : styles.buttonIcons}
                                                onClick={removeQuantityHandle}
                                            >
                                                <Icon className={styles.icon}>remove</Icon>
                                            </button>
                                            {totalsItem.quantity}
                                            <button
                                                type="button"
                                                style={{ all: 'unset', cursor: 'pointer' }}
                                                disabled={disabledAdd}
                                                className={disabledAdd ? styles.disabledIcon : styles.buttonIcons}
                                                onClick={addQuantityHandle}
                                            >
                                                <Icon className={styles.icon}>add</Icon>
                                            </button>
                                        </div>
                        )
                    }

                    <div className={styles.description}>
                        {productCompositionItem.description}
                        {!!productCompositionItem.price && <b>({NumberToMoneyString(productCompositionItem.price)})</b>}
                    </div>
                </div>
                <div className={styles.end}>

                    <div className={styles.totalPriceContainer}>
                        {!!totalsItem.price && <div className={styles.totalPrice}>+{NumberToMoneyString(totalsItem.price)}</div>}
                    </div>

                </div>
            </div>
        </div >
    )
}
export default BoxProductCompositionItem