import React, { FC, useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { scriptTrack } from './TrackScript';
import SurveyApi from 'services/Api/survey/SurveyApi';
export interface ITrackWidgetProps {
    //propertys
}

declare global {
    interface Window {
        ReactNativeWebView: { postMessage: (value: string, targe?: string) => void }
        he: () => void
    }
    interface he { }
}

export interface TrackCustomer {
    name?: string,
    email?: string,
    phone?: string,
    CPF?: string
}
const TrackWidget: FC<ITrackWidgetProps> = () => {

    const { ownerId } = useParams<{ ownerId: string }>();
    const [key, setKey] = useState<string>("B8spJ2OF");
    const { search } = useLocation();
    const query = React.useMemo(() => new URLSearchParams(search), [search]);
    const addScript = useCallback(() => {
        const user = {
            name: query.get("name") || "",
            email: query.get("email") || "",
            phone: query.get("phone") || "",
            CPF: query.get("document") || "",
        }
        const interaction = {
            ownerId: query.get("ownerId") || "",
        }
        if (key) {
            const script = document.createElement("script");
            script.innerHTML = scriptTrack(key, user, interaction);
            document.head.appendChild(script);
        }
    }, [query, key])
    // http://localhost:3001/survey/1f3d32dd-9f1e-042a-dbb4-09ecb64a1427/90a7e052-0f1f-40b2-8f57-25f44a85032d?name=Thiago&document=11136397680


    useEffect(() => {
        addScript();
        setTimeout(() => {
            setInterval(() => {
                const value = document.getElementById("track__widget");
                // const value = document.getElement("track__widget");
                if (!value) {
                    window.parent.postMessage("{action: 'CLOSE'}", "*");
                    window?.ReactNativeWebView?.postMessage("{action: 'CLOSE'}");

                }
            }, 500);
        }, 2000);

    }, [addScript])


    useEffect(() => {
        SurveyApi().getWdigetByLocalId(ownerId).then((response) => {
            setKey(response.widgetKey);
        })
    }, [ownerId])


    return (
        <>
            <div id={`trackElement`}>
            </div>
            <div style={{ paddingBottom: 16 }}>
            </div>
        </>
    )
}
export default TrackWidget