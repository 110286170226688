import Layout from 'components/layout/Layout'
import React from 'react'
import { ReserveListCard } from '../../components/reserveListCard/ReserveListCard'
import UseReservesPage from './UseReservesPage'

const ReservesPage = () => {
    const { values, onClickBackHandle } = UseReservesPage();

    return (
        <Layout enableDescriptionImage onClickBack={(onClickBackHandle)}>
            <h2>Reservas</h2>
            {values && values.details.length && values.details.map(item =>
                <ReserveListCard
                    key={item.id}
                    value={item}
                />)
            }
        </Layout>
    )
}

export default ReservesPage
