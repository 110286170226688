import FingerprintJS, { GetResult } from '@fingerprintjs/fingerprintjs'
import { useCallback, useEffect, useState } from 'react'


const UseFingerPrint = () => {
    
    const [fingerPrint, setFingerPrint] = useState<GetResult>()

    const getFingerPrint = useCallback(
        async () => {
            const fp = await FingerprintJS.load()
            const result = await fp.get();
            setFingerPrint(result);
        },
        [],
    )

    useEffect(() => {
        getFingerPrint();
        return () => {
            
        }
    }, [])

    return (
        {fingerPrint}
    )
}

export default UseFingerPrint
