import { Button, Container, TextField, Grid } from "@material-ui/core";
import React, { FC, FormEvent, useCallback, useState } from "react";
import { GoogleAutoComplete } from "../../components/googleAutocomplete/GoogleAutoComplete";
import { IAddress } from "../../models/address/IAddress";
import { DeliveryAddressItem } from "../payment/deliverySelect/deliveryAddressItem/DeliveryAddressItem";

export interface INewAddressFormProps {
    //propertys
    onSubmit: (address: IAddress) => void
    onClose: () => void
}
const NewAddressForm: FC<INewAddressFormProps> = ({ onSubmit, onClose }) => {
    const [selectedAdress, setselectedAdress] = useState<IAddress | null>(null);
    const [values, setValues] = useState({ number: "", complement: "" });

    const onSubmitHandle = useCallback(
        async (ev: FormEvent<HTMLFormElement>) => {
            ev.preventDefault();
            if (selectedAdress) {

                onSubmit({
                    ...selectedAdress,
                    number: selectedAdress.number ? selectedAdress.number : values.number,
                    complement: selectedAdress.complement ?? values.complement,
                });
            }
        },
        [selectedAdress, onSubmit, values]
    );

    const changeNumeroHandle = useCallback((values: string) => {
        setValues((prev) => ({ ...prev, number: values }));
    }, []);
    const changeComplementoHandle = useCallback((values: string) => {
        setValues((prev) => ({ ...prev, complement: values }));
    }, []);

    return (
        <Container
            maxWidth="xs"
            style={{ display: "flex", flexDirection: "column", padding: 16, /* background: "#31c",*/ justifyContent: "start", alignItems: "center", minHeight: "80vh" }}
        >
            <h2>Adicionar Endereço</h2>
            <Grid spacing={2} container>
                <Grid xs={12} item>
                    <GoogleAutoComplete
                        onSelect={(address) => {
                            setselectedAdress(address);
                        }}
                        onReset={() => {
                            setselectedAdress(null);
                            setValues({ number: "", complement: "" });
                        }}
                    />
                    {selectedAdress && <DeliveryAddressItem address={selectedAdress} />}
                </Grid>
                <Grid xs={12} item>
                    <form onSubmit={onSubmitHandle}>
                        <Grid spacing={2} container>
                            <Grid xs={12} item>
                                {selectedAdress && !selectedAdress.number && (
                                    <TextField
                                        fullWidth
                                        required
                                        variant="outlined"
                                        name={"number"}
                                        onChange={(ev) => changeNumeroHandle(ev.target.value)}
                                        value={values.number}
                                        label={"Numero"}
                                        inputProps={{ inputMode: "numeric" }}
                                    />
                                )}
                            </Grid>
                            <Grid xs={12} item>
                                {selectedAdress && (
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        name={"complement"}
                                        onChange={(ev) => changeComplementoHandle(ev.target.value)}
                                        value={values.complement}
                                        label={"Complemento"}
                                    />
                                )}
                            </Grid>

                            <Grid spacing={2} container>
                                <Grid sm={6} item>
                                    <Button
                                        onClick={() => {
                                            onClose && onClose();
                                        }}
                                        variant="outlined"
                                        color={"primary"}
                                        fullWidth
                                    >
                                        Voltar
                                    </Button>
                                </Grid>
                                <Grid sm={6} item>
                                    <Button onClick={() => { }} color={"primary"} variant="contained" fullWidth type="submit" disabled={!selectedAdress?.number && !values.number}>
                                        Salvar Endereço
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Container>
    );


}
export default NewAddressForm