

import { Product } from 'models/catalog/aggregates/Product';
import { IOrderComposition } from 'models/order/IOrderComposition';
import { useCallback, useEffect, useState } from 'react';
import { IUseCaseCart } from './interfaces/IUseCaseCart';
import { ICartItem } from './models/ICart';
import { AddCartItemByIdUseCase } from './useCases/AddCartItemByIdUseCase copy';
import { AddCartItemByProductUseCase } from './useCases/AddCartItemByProductUseCase';
import { CalcTotalsCartUseCase } from './useCases/CalcTotalsCartUseCase';
import { RemoveCartItemByIdUseCase } from './useCases/RemoveCartItemByIdUseCase';

const useCaseCart = (): IUseCaseCart => {

    const [cartItems, setCartItems] = useState<ICartItem[]>([]);
    const [totals, setTotals] = useState<{ totalQuantity: number, totalPrice: number }>({ totalQuantity: 0, totalPrice: 0 });

    /**
     * Adicionar produto no carrinho caso ja exista adiciona na quantidade
     */

    const updateCartLocalStorage = useCallback((cart: ICartItem[]) => {
        sessionStorage.setItem(`@Meep:cart`, JSON.stringify(cart));
        setCartItems(cart);
    }, []);

    useEffect(() => {
        const storageCart = sessionStorage.getItem(`@Meep:cart`);
        storageCart && updateCartLocalStorage(JSON.parse(storageCart) as ICartItem[]);
    }, [updateCartLocalStorage])

    const addProductCart = useCallback((product: Product, quantity: number, orderCompositions?: IOrderComposition[]) => {
        setCartItems(prev => AddCartItemByProductUseCase(prev, product, quantity, orderCompositions));
    }, []);

    /**
     * Adiciona  produto no carrinho caso ja exista adiciona na quantidade
     */
    const addCartItem = useCallback((cartItemOd: string, quantity: number) => {
        setCartItems(prev => AddCartItemByIdUseCase(prev, cartItemOd, quantity));
    }, []);

    /**
     * Remove quantidade de items do carrinho
     */
    const removeCarItem = useCallback((cartItemId: string, quantity: number) => {
        setCartItems(prev => RemoveCartItemByIdUseCase(prev, cartItemId, quantity));
    }, []);

    /**
     * Limpar carrinho
     */
    const clearCart = useCallback(() => {
        setCartItems([]);
    }, []);

    useEffect(() => {
        setTotals(CalcTotalsCartUseCase(cartItems));
    }, [cartItems]);

    return (
        {
            cartItems,
            totals,
            addProductCart,
            removeCarItem,
            addCartItem,
            clearCart,
            setCartItems,
            updateCartLocalStorage
        }
    );
};

export default useCaseCart;

