import React, { FC, useCallback, useMemo, useState } from "react";
import styles from "./NewPasswordForm.module.scss";
import { Button, IconButton, TextField } from "@material-ui/core";
import {
  CloseOutlined,
  InfoOutlined,
  CheckCircleOutline,
} from "@material-ui/icons";

const errorList = [
  "A senha deve ter exatamente 4 caracteres numéricos.",
  "A senha não deve conter pares do mesmo número repetidos.",
  "A senha não deve conter números sequenciais.",
];

export interface INewPasswordForm {
  onCancel: () => void;
  handleSubmitNewPassword: (
    password: string,
    closeModal: () => void
  ) => void;
}

const NewPasswordForm: FC<INewPasswordForm> = ({ onCancel, handleSubmitNewPassword }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState<string[]>([]);

  const validatePassword = useCallback((value: string) => {
    const newErrors: string[] = [];

    if (!/^\d{4}$/.test(value)) {
      newErrors.push(errorList[0]);
    }
    if (/(\d)\1/.test(value)) {
      newErrors.push(errorList[1]);
    }
    if (
      /(0123|1234|2345|3456|4567|5678|6789|7890|0987|9876|8765|7654|6543|5432|4321)/.test(
        value
      )
    ) {
      newErrors.push(errorList[2]);
    }
    setErrors(newErrors);
  }, []);

  const handlePasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setPassword(value);
      validatePassword(value);
    },
    [validatePassword]
  );

  const handleConfirmPasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setConfirmPassword(e.target.value);
    },
    []
  );

  const disableSubmit = useMemo(
    () => !!errors.length || !password.length  || (confirmPassword !== password),
    [confirmPassword, errors.length, password]
  );

  const onSubmit = useCallback(() => {
    handleSubmitNewPassword(password, onCancel);
  }, [handleSubmitNewPassword, onCancel, password]);

  return (
    <div id={styles.NewPasswordForm}>
      <h4>
        Alterar senha da TAG
        <IconButton onClick={onCancel}>
          <CloseOutlined />
        </IconButton>
      </h4>

      <div>
        <div className={styles.rules}>
          <h5>Regras da senha:</h5>
          <p>
            {errors.find((it) => (it === errorList[0] ? true : false)) ? (
              <InfoOutlined style={{ color: "#FFB300" }} />
            ) : (
              <CheckCircleOutline style={{ color: "#399C54" }} />
            )}
            Ter 4 caracteres numéricos
          </p>
          <p>
            {errors.find((it) => (it === errorList[1] ? true : false)) ? (
              <InfoOutlined style={{ color: "#FFB300" }} />
            ) : (
              <CheckCircleOutline style={{ color: "#399C54" }} />
            )}
            Não repetir pares do mesmo número, Ex.: 1122
          </p>
          <p>
            {errors.find((it) => (it === errorList[2] ? true : false)) ? (
              <InfoOutlined style={{ color: "#FFB300" }} />
            ) : (
              <CheckCircleOutline style={{ color: "#399C54" }} />
            )}
            Não utilizar números sequenciais, Ex.: 1234 ou 4321
          </p>
        </div>
        <div className={styles.input}>
          <p>
            Senha (4 dígitos numéricos) <span>*</span>
          </p>
          <TextField
            variant="outlined"
            value={password}
            onChange={handlePasswordChange}
            error={!!errors.length}
            helperText={errors.join(" ")}
            type="password"
          />
        </div>
        <div className={styles.input}>
          <p>
            Confirmar senha <span>*</span>
          </p>
          <TextField
            variant="outlined"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            error={confirmPassword !== password}
            helperText={
              confirmPassword !== password ? "As senhas não correspondem" : ""
            }
            type="password"
          />
        </div>
      </div>
      <Button
        fullWidth
        variant="contained"
        disabled={disableSubmit}
        onClick={onSubmit}
      >
        Salvar
      </Button>
      <Button
        fullWidth
        variant="outlined"
        onClick={onCancel}
      >
        Cancelar
      </Button>
    </div>
  );
};

export default NewPasswordForm;
