import Cart from 'components/genericCart/Cart'
import { useCart } from 'components/genericCart/CartContext'
import { format } from 'date-fns'
import React, { FC, useState } from 'react'
import { ICartItemTour, ICartTour } from '../../interfaces/ICartTour'
import { PaymentCartTourResume } from '../paymentCartResume/PaymentCartTourResume'
import styles from './CartTour.module.scss'

export interface ICartTourProps {
    //propertys
}
const CartTour: FC<ICartTourProps> = () => {
    const [cartTour, setCartTour] = useState<ICartTour>()
    const { clearCart } = useCart();
    const renderDescription = (cartItem: ICartItemTour) => {

        return (
            <div className={styles.description}>
                <div className={styles.description}>
                    <div>
                        Reservas: {cartItem.customers.length} pessoas
                    </div>
                    <div>
                        Data: {format(new Date(cartItem.startDate), "dd/MM/yyyy")}
                    </div>
                </div>
            </div>
        )
    }
    return (

        <>
            <Cart
                onClickConfirm={setCartTour}

                renderDescription={renderDescription}
            />
            {
                cartTour &&
                <PaymentCartTourResume
                    open={!!cartTour}
                    onClose={() => setCartTour(undefined)}
                    onSucess={clearCart}
                    cartTour={cartTour}
                />
            }

        </>
    )
}
export default CartTour