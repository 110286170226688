import { useUi } from 'context/ui/UIContext';
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { GetServiceDetailsByServiceIdUseCase } from '../../../application/useCases/GetServiceDetailsByServiceIdUseCase';
import { IMoreDetails } from '../../../application/interfaces/IMoreDetails';


export const UseMoreDetails = () => {
  const [values, setValues] = useState<IMoreDetails>()
  const { url, storeId, serviceId } = useParams<{ url: string, storeId: string, serviceId: string; }>();

  const { push } = useHistory();
  const { showLoading, hideLoading } = useUi();

  const onClickProductHandle = useCallback(
    () => {
      push(`/${url}/schedule/tour/${storeId}/form/${serviceId}`)
      push({
        pathname: `/${url}/schedule/tour/${storeId}/form/${serviceId}`,
        state: {
          values
        },
      });
    },
    [url, storeId, push, serviceId],
  )

  const onClickGoBack = () => {
    push(`/${url}/schedule/tour/${storeId}`)
  }

  useEffect(() => {
    if (serviceId) {
      showLoading();
      GetServiceDetailsByServiceIdUseCase(serviceId)
        .then((response) => {
          setValues(response);
        })
        .finally(() => {
          hideLoading();
        })
    }
  }, [serviceId])

  return (
    {
      onClickProductHandle,
      onClickGoBack,
      values,
      serviceId
    }
  )
}
