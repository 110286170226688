import React, { FC } from 'react'
import { GetCardType } from '../../../components/getTypeCard/GetTypeCard'
import "./CardPreview.css"

interface CardPreviewProps {
    number: string,
    securityCode: string,
    ownerName: string,
    brand?: number
    month: string;
    year: string;
}

export const CardPreview: FC<CardPreviewProps> = ({
    number,
    securityCode,
    ownerName,
    brand,
    month,
    year,

}) => {
    return (
        <div className="card-container">
            <img
                style={
                    { objectFit: "contain", objectPosition: "center" }
                }
                className="logo-card"
                src={GetCardType(number?.length > 10 ? number : "0").imgs?.white ?? require("../../../res/images/logos/logo-meep-white.png")}
                alt="card imagem"
            />

            <div className="content-card">
                <div className="number-cvv-card">
                    <div className="number-card">{number ?? "0000 0000 0000 0000"}</div>
                    <div className="cvv-card">{securityCode ?? "CVV"}</div>
                </div>
                <div className="date-name-card">
                    <div className="date-card">
                        {month ?? "mm"}/{year ?? "aa"}
                    </div>
                    <div className="name-card">
                        {ownerName ?? "NOME NO CARTAO"}
                    </div>
                </div>
            </div>
        </div>
    )
}
