import React, { useState, useEffect, useCallback } from "react";
import { TextField, Button, CircularProgress, Grid } from "@material-ui/core";
import { IAddress } from "models/address/IAddress";
import { v4 } from "uuid";
import styles from "./FindAddressPaymentLink.module.scss";
import { IOwnerAddressPaymentGuest } from "models/paymentGuest/IPaymentGuestForm";
import { PaymentApi } from "services/Api/payment/PaymentApi";

export interface IFindAddressPaymentLinkProps {
  onSubmitAddress: (address: IAddress) => void;
  address: IOwnerAddressPaymentGuest;
  setAddress: React.Dispatch<React.SetStateAction<IOwnerAddressPaymentGuest>>;
  handleChangeValues?: (name: string, value: string) => void;
}

const FindAddressPaymentLink: React.FC<IFindAddressPaymentLinkProps> = ({
  onSubmitAddress,
  address,
  setAddress,
  handleChangeValues
}) => {
  const [loading, setLoading] = useState(false);
  const [enableField, setEnableField] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeValues?.(e.target.name, e.target.value);
    setAddress({
      ...address,
      [e.target.name]: e.target.value,
    });
  };

  const getAddressByCEP = useCallback(async (cep: string) => {
    const paymentApi = PaymentApi();

    if (cep.length !== 8) return;

    try {
      setLoading(true);
      const response = await paymentApi.getAddress(cep);

      if (!response.street) {
        setEnableField(true);
        setError("CEP não encontrado");
        setAddress((prevAddress) => ({
          ...prevAddress,
          street: "",
          neighborhood: "",
          city: "",
          state: "",
        }));
      } else {
        setEnableField(false);
        setAddress((prevAddress) => ({
          ...prevAddress,
          street: response.street,
          neighborhood: response.neighborhood,
          city: response.city,
          state: response.state,          
        }));
        setError(null);
      }
    } catch (err) {
      setEnableField(true);
      setError("Erro ao buscar o CEP");
    } finally {
      setLoading(false);
    }
  }, [setAddress]);

  useEffect(() => {
    if (address.CEP && address.CEP.length === 8) {
      getAddressByCEP(address.CEP);
    }
  }, [address.CEP, getAddressByCEP]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmitAddress({ ...address, id: v4() });
  };

  return (
    <Grid spacing={2} container>
      <form onSubmit={handleSubmit} id={styles.FindAddressPaymentLink}>
        <Grid xs={12} item>
          <label>
            CEP <span style={{ color: "#ED0029" }}>*</span>
          </label>
          <TextField
            name="CEP"
            value={address.CEP}
            onChange={handleChange}
            required
            error={!!error}
            helperText={error || ""}
            fullWidth
            margin="normal"
            variant="outlined"
          />
        </Grid>

        {loading && <CircularProgress size={24} />}
        <Grid spacing={2} container>
          <Grid xs={8} item>
            <label>Logradouro</label>
            <TextField
              name="street"
              value={address.street}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid xs={4} item>
            <label>
              Número <span style={{ color: "#ED0029" }}>*</span>
            </label>
            <TextField
              name="number"
              value={address.number}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              required
            />
          </Grid>
        </Grid>
        <Grid spacing={2} container>
          <Grid xs={8} item>
            <label>Bairro</label>
            <TextField
              name="neighborhood"
              value={address.neighborhood}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              disabled={!enableField}
            />
          </Grid>
          <Grid xs={4} item>
            <label>Complemento</label>
            <TextField
              name="complement"
              value={address.complement}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid spacing={2} container>
          <Grid xs={8} item>
            <label>Cidade</label>
            <TextField
              name="city"
              value={address.city}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              disabled={!enableField}
            />
          </Grid>
          <Grid xs={4} item>
            <label>UF</label>
            <TextField
              name="state"
              value={address.state}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              disabled={!enableField}
            />
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <div className={styles.item}>
            <label>Descrição</label>
            <TextField
              name="description"
              value={address.description}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </div>
        </Grid>
        <Grid xs={12} item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
            className={styles.button}
          >
            {loading ? "Salvando..." : "Salvar endereço"}
          </Button>
        </Grid>
      </form>
    </Grid>
  );
};

export default FindAddressPaymentLink;
