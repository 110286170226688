import { ButtonBase, CircularProgress, Icon, IconButton } from '@material-ui/core'
import React, { FC } from 'react'
import './Box.css'

interface IBoxProps {
    icon?: string,
    onClickEdit?: () => void
    onClickRemove?: () => void
    onClick?: () => void
    select?: boolean,
    loading?: boolean
    children?: React.ReactNode;
}

export const Box: FC<IBoxProps> = ({ children, onClickRemove, loading, onClick, onClickEdit, icon }) => {
    return (
        <ButtonBase
            onClick={onClick}
            disabled={!onClick}
            className={"container-box"}  >
            {
                icon &&
                <div className="icon-grid-box" >
                    {

                        <Icon fontSize={"large"} className={"icon-box"}>{icon}</Icon>
                    }
                </div>
            }
            <div className={"content-box"}  >
                {
                    loading ?
                        <div className={"top-content-box"}  >
                            <CircularProgress size={18} color="primary"></CircularProgress>
                        </div> :
                        children
                }

            </div>
            <div className={"actions-box"} >
                {/* {onClickMain && <IconButton onClick={onClickMain}><Icon style={{ color: address.default ? "#fa0" : "#999" }}>start</Icon></IconButton>} */}
                {onClickRemove && <IconButton onClick={onClickRemove}><Icon>close</Icon></IconButton>}
                {onClickEdit && <IconButton onClick={onClickEdit}><Icon>edit</Icon></IconButton>}
            </div>
        </ButtonBase>
    )
}
