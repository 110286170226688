
import React, { FC } from 'react'
import { IOrderInProgress } from "../../../../models/order/aggregates/progress/IOrderInProgress"
import { OrderInProgressItem } from './orderInProgressItem/OrderInProgressItem'
import "./OrdersInProgressList.css"
interface IOrdersInProgressListProps {
    ordersInProgressList: IOrderInProgress[]
}

export const OrdersInProgressList: FC<IOrdersInProgressListProps> = ({ ordersInProgressList }) => {
    return (
        <div id="ordersInProgressList">
            <div className="container">
                {!!ordersInProgressList.length ?
                    <div className="list">
                        {ordersInProgressList.map((orderInProgress, index) => (<OrderInProgressItem key={index} orderInProgress={orderInProgress} />))}
                    </div>
                    :
                    <div className={"alertaVazio"}>
                        Nenhum Pedido em andamento
                </div>
                }
            </div>
        </div>
    )
}
