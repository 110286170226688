import { ScheduleTourApi } from 'services/Api/schedule/ScheduleTourApi';
import { IProductsTourResponse } from '../../_domain/_models/IProductsTourResponse';
import { CategoryServiceToStoreTourResponseUseCase } from './CategoryServiceToStoreTourListResponseUseCase';

export const GetServicesTourCategoryByIdUseCase = async (storeId: string, localId: string): Promise<IProductsTourResponse> => {
    const scheduleTourApi = ScheduleTourApi();
    const response = await scheduleTourApi.getServiceCategoryByCategoryId(storeId);
    const storeTour = CategoryServiceToStoreTourResponseUseCase(localId, response);

    return { storeName: storeTour.name, items: storeTour.productTourCategory ?? [] }
}