import { IAddress } from "models/address/IAddress";
import { ICardItem } from "models/payment/ICard";

export interface IUserWallet {
  Id: string;
  Balance: number;
  UserName: string;
  TAG?: string;
  Status?: TagStatus;
}

export interface IUserProfile {
  Name: string;
  Document: string;
  Email: string;
  Phone: string;
  CreditCards: ICardItem[];
  Addresses: IAddress[];
}

export enum TagStatus {
  Bloqued = 0,
  Active,
  Cancelled,
  Pending
}

export interface IWalletDependents {
  Id: string;
  TAG?: string;
  Name: string;
  Balance: number;
  Status: TagStatus;
}

export enum TransactionType {
  Pending = 0,
  Recharge,
  Consumption,
  Refund,
  Transfer,
}

export interface IExtractParams {
  pageSize: number;
  page: number;
  LocalClientId: string;
  WalletId: string;
  startDate?: string;
  endDate?: string;
  hidePendingOrders?: boolean;
}

export interface UserTransfer {
  IsOrigin: boolean;
  Name: string;
  TAG: string;
  IsDependent: boolean;
}

export interface IExtractItem {
  DataTransfer?: UserTransfer;
  Date: string,
  LocalId: string,
  LocalName: string,
  OrderId: string,
  OrderStatus: string,
  OrderStatusId: number,
  PaymentType: string,
  PaymentTypeId: PaymentTypeEnum,
  PixStatus: null,
  QrCodeBase64: null,
  QrCodeLink: null,
  Type: TransactionType,
  Value: number,
}

export interface IMeepUser {
  UserName: string;
  CPF: string;
  IsDependent?: boolean;
}

export interface IWalletExtract {
  Balance: number;
  Status?: TagStatus; //TODO: Remover nullable após atualização da api
  TotalRecords: number;
  TAG: string;
  IsDependent: boolean;
  MeepUser: IMeepUser;
  Records: IExtractItem[]
}

export interface LinkTagRequest {
  LocalClientId: string;
  WalletId: string;
  Tag: String;
  Password: String;
  CardName: String;
  IsCardReplacement: boolean;
  IsDependent?: boolean;
}

export interface EditUserRequest {
  TAG: string;
  NewPassword?: String;
  CardName: string;
  WalletId: string;
}
export interface LinkTagResponse {
  Balance: number;
}

export interface NewDependentRequest {
  LocalId: string;
  WalletId: string;
  TAG: string;
  Password: string;
  CardName: string;
}

export interface RemoveDependentRequest {
  LocalId: string;
  WalletId: string;
  UserId: string;
  TAG: string;
}

export interface NewDependentResponse {}

export interface CreditRechargeRequest {
  CreditCardId: string;
  Amount: number;
  WalletId: string;
  LocalClientId: string;
}

export interface CreditRechargeResponse {
  NewBalance: number;
  OrderId: string;
}

export interface PixRechargeRequest {
  Amount: number;
  LocalClientId: string;
  WalletId: string;
}

export interface PixRechargeResponse {
  OrderId: string;
  OrderStatusId: number;
  OrderStatusDescription: MeepPIXEnum;
  QrCodeLink: string;
  QrCodeBase64: string;
  QrCodeExpirationDate: null;
  QrCodeExpirationTimeInSeconds: number;
}

export interface PixStatusResponse {
  OrderId: string;
  Amount: number;
  StautsId: number;
  StatusDescription: MeepPIXEnum;
  TransactionId: string;
  IsRechargeMade: false;
  WalletBalance: number;
  QrCodeLink: string;
  QrCodeBase64: string;
  PixPaymentOrderStatus: string;
}

export interface TransferBalanceRequest {
  LocalId: string;
  OriginWalletId: string;
  DestinationWalletId: string;
  TransferBalance: number;
}

export interface IOrderWalletItem {
  ProductId: string,
  Quantity: number,
  Value: number,
  Composition: null
}

export interface WalletPaymentRequest {
  LocalClientId: string,
  WalletId: string,
  DeliveryFee: number,
  ServiceFee: number,
  Discount: number,
  Coupon: null,
  Items: IOrderWalletItem[];
}

export type MeepPIXEnum = "approved" | "cancelled" | "pending";

export enum PaymentTypeEnum {
  INDEFINIDO = -1,
  NAO_INFORMADO = 0,
  CREDITO = 1,
  DEBITO = 2,
  DINHEIRO = 3,
  CREDITOLOJA = 4,
  MEEPCOIN = 5,
  MISTO = 6,
  CHEQUE = 7,
  PENDENTE = 8,
  BOLETO = 9,
  REEMBOLSO = 10,
  CASHBACK = 11,
  PRODUCAO = 12,
  AJUSTE = 13,
  VOUCHER = 14,
  DELIVERY = 15,
  PERDA = 16,
  EM_ABERTO = 17,
  PIX = 18,
  FIDELITY_COIN = 19,
  PRE_RECARGA = 20,
  PERMUTA = 21,
  WALLET = 22,
  CUSTOMIZADO_1 = 101,
  CUSTOMIZADO_2 = 102,
  CUSTOMIZADO_3 = 103,
  CUSTOMIZADO_4 = 104,
  CUSTOMIZADO_5 = 105,
  CUSTOMIZADO_6 = 106,
  CUSTOMIZADO_7 = 107,
  CUSTOMIZADO_8 = 108,
  CUSTOMIZADO_9 = 109,
  CUSTOMIZADO_10 = 110,
  CUSTOMIZADO_11 = 111,
  CUSTOMIZADO_12 = 112,
  CUSTOMIZADO_13 = 113,
  CUSTOMIZADO_14 = 114,
  CUSTOMIZADO_15 = 115,
  CUSTOMIZADO_16 = 116,
  CUSTOMIZADO_17 = 117,
  CUSTOMIZADO_18 = 118,
  CUSTOMIZADO_19 = 119,
  CUSTOMIZADO_20 = 120,
  CUSTOMIZADO_21 = 121,
  CUSTOMIZADO_22 = 122,
  CUSTOMIZADO_23 = 123,
  CUSTOMIZADO_24 = 124,
  CUSTOMIZADO_25 = 125,
  CUSTOMIZADO_26 = 126,
  CUSTOMIZADO_27 = 127,
  CUSTOMIZADO_28 = 128,
  CUSTOMIZADO_29 = 129,
  CUSTOMIZADO_30 = 130
}
