import { IAddress } from "../../../address/IAddress";
import { CoupomType, CouponAppliedInType, CouponValueType } from "../../../coupon/ICoupon";
import { IComposition, PaymentType } from "../../../payment/IPay";

export interface IHistoryStore {
  id: string;
  name: string;
  imageUrl: string;
}

export interface IHistoryPaymentCreditCard {
  id: string;
  brand: string;
  finalNumber: string;
  value: number;
}

export interface IHistoryPaymentMeepCard {
  id: string;
  value: number;
}

export interface IHistoryOrderItems {
  id: string;
  name: string;
  quantity: number;
  value: number;
  composition?: IComposition[];
}


export interface IHistoryPayment {
  type: PaymentType;
  creditCard?: IHistoryPaymentCreditCard;
  meepCard?: IHistoryPaymentMeepCard;
}


export interface IHistoryOrderDiscount {
  percent: number;
  amount: number;
}


export interface IHistoryOrderCoupon {
  code: string;
  valueType: CouponValueType;
  type: CoupomType;
  appliedIn: CouponAppliedInType;
  value: number;
  amount: number;
}


export interface IHistoryOrder {
  id: string;
  historyStore: IHistoryStore;
  historyPayment: IHistoryPayment[];
  historyOrderItems: IHistoryOrderItems[];
  historyOrderCoupon?: IHistoryOrderCoupon;
  historyOrderDiscount: IHistoryOrderDiscount;
  deliveryFee: number;
  address: IAddress;
  createdAt: string;
  amount: number;
  status: HistoryOrderStatus;
  
}

export interface IHistorySectionOrder {
  date: string;
  orders: IHistoryOrder[];
}

export enum HistoryOrderStatus {
  PAGAMENTO_REALIZADO = 3,
  CANCELADO = 4,
}
export const HistoryOrderStatusString = (historyOrderStatus: HistoryOrderStatus) => {
  switch (historyOrderStatus) {
    case (3):
      return "Pagamento Realizado"
    case (4):
      return "Cancelado"
  }
}



// export interface PrinterStatus {
//   Id: string;
//   PedidoId: string;
//   Status: StatusDeImpressaoEnum;
//   LastUpdate: string;
//   CreatedAt: string;
//   Message?: string;
//   PrintedOrders?: string;
// }