import React, { FC } from 'react'
import styles from './TourItem.module.scss'
import { ITourItem } from './ITourtem'
import Image from '../../../../../../../../components/ui/_image/Image'

export interface ITourItemProps {
    //propertys
    values: ITourItem
    onClick: (store: ITourItem) => void
    onClickAboutProject?: () => void
}
const TourItem: FC<ITourItemProps> = ({ values, onClick }) => {
    return (
        <div id={styles.StoreItem} >
            {/* <Link to={(location) => `/${url}/${store.id}`}> */}
            <div onClick={() => onClick(values)} className={styles.container} style={{ backgroundImage: `url(${values.imageUrl})` }} >
                <div className={styles.descriptionContainer}>
                    {values.logoUrl &&
                        <div className={styles.logo}>
                            {values.imageUrl && <Image src={values.imageUrl} width={100} height={100} className={styles.image}></Image>}
                        </div>
                    }
                    <div className={styles.description}>
                        <h2>{values.name}</h2>
                    </div>
                </div>
            </div>
            {/* </Link> */}
        </div>
    )
}
export default TourItem