import { MenuItem, TextField } from '@material-ui/core'
import React, { FC, useCallback } from 'react'
import styles from './InstallmentSelect.module.scss'
export interface Parcela {
    label: string
    value: number
    parcela: number
}
export interface IInstallmentSelectProps {
    //propertys
    total: number,
    parcelaMinima: number
    max: number,
    min: number,
    description: string,
    onChange: (value: Parcela) => void
    onCancel?: () => void,
    value: Parcela
    variant?: "standard" | "outlined" | "filled"; 
}
const InstallmentSelect: FC<IInstallmentSelectProps> = ({
    total,
    onChange,
    parcelaMinima,
    max,
    min,
    value,
    variant
}) => {

    // const [value, setValue] = useState<Parcela>({ label: `A vista por R$ ${total.toFixed(2)}`, parcela: 1, value: total });

    const gerarParcelas = useCallback((total: number, parcelaMin: number, max: number, min: number,) => {
        var parcelas: Parcela[] = []
        for (var i = max; i >= min; i--) {
            const valorDaParcela = total / i;
            if (valorDaParcela >= parcelaMin) {
                parcelas.push({ label: `${i} vezes de R$ ${valorDaParcela.toFixed(2)}`, parcela: i, value: valorDaParcela })
            }
        }
        parcelas.push({ label: `A vista por R$ ${total.toFixed(2)}`, parcela: 1, value: total });
        return parcelas
    }, [])


        // useEffect(() => {
        //     setValue(defaultValue ?? { label: `A vista por R$ ${total.toFixed(2)}`, parcela: 1, value: total });
        // }, [defaultValue, total])

    return (
        <div id={styles.InstallmentSelect} >
            <div className={styles.container} >
                <TextField
                    select
                    variant={variant}
                    value={value.parcela}

                    onChange={(ev) => {
                        const newParcela = gerarParcelas(total, parcelaMinima, max, min).find(item => item.parcela.toString() === ev.target.value.toString()) ?? { label: `A vista por R$ ${total.toFixed(2)}`, parcela: 1, value: total }
                        onChange(newParcela);
                    }}
                >
                    {gerarParcelas(total, parcelaMinima, max, min).map((parcela) => (<MenuItem value={parcela.parcela}> {parcela.label} </MenuItem>))}
                </TextField>
            </div>
        </div>
    )
}
export default InstallmentSelect