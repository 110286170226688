import React, { FC, FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { useUi } from "../../../../../context/ui/UIContext";
import styles from './FormLoginValidationCode.module.scss'
import { ISendValidationCode } from "models/auth/IValidationCode";

interface ILoginPostObject {
  ddd: string;
  ddi?: string;
  phoneNumber: string;
  // codeVerification?: string;
}

interface FormLoginPhoneNumberProps {
  onSubmit: (valitationCode: string) => void
  onClickBack?: () => void
  onResend: (data: ISendValidationCode) => Promise<void>
  defaultValues: ILoginPostObject
}

export const FormLoginValitationCode: FC<FormLoginPhoneNumberProps> = ({
  onSubmit,
  onClickBack,
  onResend,
  defaultValues
}) => {

  const { toast } = useUi();
  const { isIframe } = useUi();
  const [value, setValue] = useState<string>("");
  const [buttonVisible, setButtonVisible] = useState(true);

  const onSubmitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      if (value.length !== 6) {
        toast("Insira um código valido", "warning");
        return;
      }
      onSubmit(value);
    },
    [onSubmit, toast, value]
  );

  const resendCode = useCallback(() => {
    onResend({ ddd: defaultValues.ddd, ddi: defaultValues.ddi, phoneNumber: defaultValues.phoneNumber, isSecondAttempt: true })
  }, [defaultValues.ddd, defaultValues.ddi, defaultValues.phoneNumber, onResend])

  useEffect(() => {
    const timer = setTimeout(() => {
      setButtonVisible(false);
    }, 15000);

    return () => clearTimeout(timer);
  }, []);

  const handleClickSendSms = () => {
    setButtonVisible(true);
    resendCode();

    setTimeout(() => {
      setButtonVisible(false);
    }, 15000);
  };


  return (
    <form onSubmit={onSubmitHandle}>
      <Grid spacing={2} container>
        <Grid item xs={12}>
          <div style={{ fontSize: 20, color: 'var(--text)', fontWeight: 700, textAlign: 'left' }}>
            Digite o código que foi enviado para seu telefone
          </div>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'left' }}>
          <label htmlFor="codeVerification" className={`${styles.label} ${styles.required}`}>Código</label>
          <TextField
            // variant="outlined"
            fullWidth
            // helperText={(touched.codeVerification && errors.codeVerification) || (touched.ddd && errors.ddd)}
            // error={(touched.codeVerification && Boolean(errors.codeVerification)) || (touched.ddd && Boolean(errors.ddd))}
            onChange={(e) => setValue(e.target.value)}
            value={value}
            name="codeVerification"
            type="number"
            variant="outlined"
            placeholder="XXXXXX"
            size="small"
          />
        </Grid>

        <Grid item xs={12}>
          {
            !buttonVisible &&
            <Button className={styles.sendSms} variant="text" color="primary" onClick={handleClickSendSms}><u>Reenviar código de confirmação</u></Button>
          }
        </Grid>

        <Grid item xs={isIframe ? 8 : 12}>
          <Button fullWidth size="large" variant="contained" color="primary" type="submit" style={{ height: 49, boxShadow: 'none', borderRadius: 8 }}>
            Confirmar
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Button
            onClick={
              onClickBack
            }
            size="small"
            variant="text"
            color="primary"
            type="submit"
          >
            Voltar
          </Button>
        </Grid>

      </Grid>
    </form>
  );
};
