import React, { FC } from 'react'
import { HistoryOrderStatusString, IHistorySectionOrder } from '../../../../models/order/aggregates/history/IOrderHistory'
import "./OldOrderList.css"
import { PaymentTypeString } from '../../../../models/payment/IPay'
import Utils from 'utils/utils'
interface OldOrderListProps {
    orders: IHistorySectionOrder
}
const OldOrderList: FC<OldOrderListProps> = ({ orders }) => {

    return (
        <div id="oldOrderList">
            <div className="container-oldOrderList">

                <div className="date-oldOrderList">
                    {Utils.toDate(orders.date, true)}
                </div>

                <div className="orders-oldOrderList">
                    {orders.orders.map((order) =>
                        (
                            <div className="order-oldOrderList">
                                <div className="top-order-oldOrderList">
                                    <div className="estabelecimento-order-oldOrderList">
                                        {order.historyStore.name}
                                    </div>
                                    <div className="date-order-oldOrderList">
                                        {Utils.toTime(order.createdAt, true)}
                                    </div>
                                </div>
                                <div className="status-order-oldOrderList">
                                    {HistoryOrderStatusString(order.status)}
                                </div>
                                <div className="content-order-oldOrderList">
                                    Pedido:
                                    <div className="orderItens-order-oldOrderList">
                                        {order.historyOrderItems.map((orderItem) => (
                                            <div className="orderItem-order-oldOrderList">
                                                <div className="text-orderItem-oldOrderList">
                                                    {orderItem.quantity}x {orderItem.name}
                                                </div>
                                                <div className="value-orderItem-oldOrderList">
                                                    R${orderItem.value.toFixed(2).replace(".", ",")}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="footer-order-oldOrderList">
                                    {
                                        !!order.historyPayment.length &&
                                        <div className="id-order-oldOrderList">
                                            Pagamento:
                                        {order.historyPayment.map((payment) => (PaymentTypeString(payment.type) + " "))}
                                        </div>
                                    }
                                    <div className="id-order-oldOrderList">
                                        Total:
                                        R${order.amount.toFixed(2).replace(".", ",")}
                                    </div>
                                </div>
                            </div>
                        )
                    )}

                </div>
            </div>
        </div>
    )
}

export default OldOrderList
