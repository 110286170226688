import { Button, Container, Skeleton } from "@mui/material";
import { ReactComponent as Print } from "assets/icons/print.svg";
import { useUi } from "context/ui/UIContext";
import {
  IPaymentLinkResponse,
  TicketGetResponse,
} from "models/paymentLink/IPaymentLinkResponse";
import React, { FC, useCallback, useRef, useState } from "react";
import PaymentLinkApi from "services/Api/paymentLink/PaymentLinkApi";
import styles from "./Ticket.module.scss";
import TicketForm, { ITicketFormValues } from "../ticketForm/TicketForm";
import { IPayerErrors, validate } from "../ticketForm/validate";
import { ReactComponent as Load } from "assets/icons/loading.svg";
import { IOwnerAddressPaymentGuest } from "models/paymentGuest/IPaymentGuestForm";

export interface ITicket {
  paymentLink: IPaymentLinkResponse;
}

const Ticket: FC<ITicket> = ({ paymentLink }) => {
  const { toast, isMobile } = useUi();

  const [ticket, setTicket] = useState<TicketGetResponse | null>(null);
  const formRef = useRef<HTMLDivElement>(null);
  const [holder, setHolder] = useState<string>("fisica");

  const [payerValues, setPayerValues] = useState<ITicketFormValues>({
    PayerDocumentNumber: "",
    PayerEmail: "",
    PayerFirstName: "",
    PayerLastName: "",
    PayerAddress: {} as IOwnerAddressPaymentGuest,
  });

  const [errors, setErrors] = useState<IPayerErrors>({
    PayerDocumentNumber: "",
    PayerEmail: "",
    PayerFirstName: "",
    PayerLastName: "",
  });

  const [address, setAddress] = useState<IOwnerAddressPaymentGuest>({
    CEP: "",
    description: "",
    street: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
  });

  const [errorsAddress, setErrorsAddress] = useState({
    number: "",
    securityCode: "",
    ownerName: "",
    month: "",
    year: "",
    owner: {
      name: "",
      document: "",
      email: "",
      phoneNumber: "",
      address: "",
    },
  });

  const [loading, setLoading] = useState(false);

  const [showBoleto, setShowBoleto] = useState(false);

  const handleChangePayerValues = useCallback((name: string, value: string) => {
    setPayerValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const generateTicket = useCallback(() => {
    if (paymentLink && validate(paymentLink, payerValues, holder, setErrors)) {
      setShowBoleto(true);
      const paymentLinkApi = PaymentLinkApi();
      setLoading(true);

      const customPayer = {...payerValues};
      let firstName = customPayer.PayerFirstName;
      let lastName = "";
      const fullName = customPayer.PayerFirstName.split(" ");
      if (fullName.length > 1) {
        firstName = fullName[0];
        lastName = fullName[1];
      } else {
        lastName = ".";        
      }

      customPayer.PayerFirstName = firstName;
      customPayer.PayerLastName = lastName;

      paymentLinkApi
        .ticketPayment({
          OwnerId: paymentLink.localId,
          Description: paymentLink.description,
          ProductId: paymentLink.productId,
          TransactionAmount: paymentLink.price,
          ...customPayer,
          PayerAddress: {
            postalCode: address.CEP ?? "",
            city: address.city,
            complement: address.complement,
            neighborhood: address.neighborhood,
            number: address.number,
            state: address.state,
            street: address.street,
            description: address.description,
          },
        })
        .then((response) => {
          paymentLinkApi
            .ticketPaymentStatus(response.BoletoId)
            .then((res) => {
              setTicket(res);
            })
            .catch(() => {
              paymentLinkApi
                .ticketPaymentStatus(response.BoletoId)
                .then((res) => {
                  setTicket(res);
                })
                .catch(() => {
                  toast("A emissão do boleto falhou, tente novamente", "error");
                });
            });
        })
        .catch(() => {
          setShowBoleto(false);
          toast("A emissão do boleto falhou, tente novamente", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    paymentLink,
    payerValues,
    holder,
    address,
    toast,
  ]);

  const copyBarCodeToClipboard = useCallback(() => {
    if (ticket?.BarCode) {
      navigator.clipboard.writeText(ticket?.BarCode);
      toast("Código copiado para área de transferência", "success");
    }
  }, [ticket, toast]);

  const openNewTab = useCallback(() => {
    if (ticket?.Url) {
      window.open(ticket.Url, "_blank");
    }
  }, [ticket]);

  return (
    <Container maxWidth="md">
      <div id={styles.Ticket}>
        <div className={styles.title}>
          Realize o pagamento através do boleto
        </div>
        {ticket ? (
          <>
            <div className={styles.description}>
              {/* Realize a leitura do código de barras abaixo ou imprima seu boleto. */}
              Realize o pagamento do boleto abaixo
            </div>
            {/* <div className={styles.ticketContainer}>
            {!ticket?.BarCode ? (
              <Skeleton
                width={isMobile ? 350 : 495}
                height={isMobile ? 70 : 90}
                variant="rectangular"
                style={{ marginTop: isMobile ? 35 : 20 }}
              />
            ) : (
              <div className={styles.barcode}>
                <Barcode value={ticket.BarCode} />
              </div>
            )}
          </div> */}
            <div
              className={styles.ticketFooter}
              style={{ marginBottom: isMobile ? 16 : undefined }}
            >
              <div>
                {/* <div
                onClick={copyBarCodeToClipboard}
                className={!ticket?.BarCode ? styles.disabled : ""}
              >
                <Copy />
                Copiar código
              </div>
              <p>ou</p> */}
                <div
                  onClick={openNewTab}
                  className={ styles.disabled}
                  // className={!ticket?.BarCode ? styles.disabled : ""}
                >
                  <Print />
                  Imprimir boleto
                </div>
              </div>
              <p style={{ whiteSpace: isMobile ? "normal" : undefined }}>
                O pagamento via boleto pode demorar até 2 dias uteis para
                confirmação
              </p>
            </div>
              </>
        ) : (
          <div>
            <TicketForm
              handleChangeValues={handleChangePayerValues}
              values={payerValues}
              errors={errors}
              paymentLink={paymentLink}
              address={address}
              setAddress={setAddress}
              setErrorsAddress={setErrorsAddress}
              errorsAddress={errorsAddress}
              formRef={formRef}
              setValues={setPayerValues}
              setHolder={setHolder}
              holder={holder}
              />
          </div>
        )}
        {!showBoleto && (
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              if (!loading) generateTicket();
            }}
            disabled={loading}
          >
            {loading ? <Load /> : "Gerar boleto"}
          </Button>
        )}
      </div>
    </Container>
  );
};

export default Ticket;
