import { UseMercadoPago } from 'context/payment/UseMercadoPago';
import { useUi } from 'context/ui/UIContext';
import { IOrderDetail } from 'models/order/aggregates/datail/IOrderDetail';
import { IPaymentGuestRequest } from 'models/paymentGuest/IPaymentGestRequest';
import { ICardPaymentGuestRequest } from 'models/paymentGuest/IPaymentGuestForm';
import { IPaymentLinkInstallments, IPaymentLinkResponse } from 'models/paymentLink/IPaymentLinkResponse';
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import PaymentLinkApi from 'services/Api/paymentLink/PaymentLinkApi';
import { v4 } from 'uuid';

export interface IUsePaymentGuestPageProps {
    //propertys
}
const UsePaymentGuestPage = () => {
    const [card, setCard] = useState<ICardPaymentGuestRequest>();
    const [orderDetail, setOrderDetail] = useState<IOrderDetail>();
    const { showLoading, hideLoading, toast } = useUi();
    const [paymentLink, setPaymentLink] = useState<IPaymentLinkResponse>()
    const location = useLocation();
    const { deviceIdMercadoPago } = UseMercadoPago();
    const { replace } = useHistory();
    const [installment, setInstallment] = useState<IPaymentLinkInstallments>();

    const paymentLinkToOrderDetail = useCallback(
        (responsePaymentLink: IPaymentLinkResponse) => {
            const newOrderDetail = {
                id: v4(),
                amount: responsePaymentLink.price,
                itens: [{
                    productId: responsePaymentLink.productId,
                    name: responsePaymentLink.product.nome,
                    quantity: 1,
                    unitValue: responsePaymentLink.price,
                    composition: "",
                }]
            }
            return newOrderDetail
        },
        [],
    )

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const paymentLinkId = query.get("paymentLinkId");

        if (paymentLinkId) {
            const paymentLinkApi = PaymentLinkApi();
            showLoading();
            paymentLinkApi.getPaymentProduct(paymentLinkId).then((response) => {
                setOrderDetail(paymentLinkToOrderDetail(response.data));
                setPaymentLink(response.data);
            }).finally(() => {
                hideLoading();
            })
        }
        return () => {

        }
    }, [location]);

    const onClickSend = useCallback(
        () => {
            const paymentLinkApi = PaymentLinkApi();
            if (paymentLink && card) {
                const newPaymentGuestPayRequest: IPaymentGuestRequest = {
                    mePayLinkId: paymentLink?.id,
                    isSinglePayment: paymentLink?.isSinglePayment === true,
                    creditCard: {
                        number: card.number,
                        name: card.ownerName,
                        securityCode: card.securityCode,
                        fingerPrint: deviceIdMercadoPago,
                        monthYearValid: card.month + "/" + card.year
                    },
                    order: {
                        value: installment?.price ?? paymentLink?.price,
                        itens: [{
                            name: paymentLink?.product.nome,
                            productId: paymentLink?.product.id,
                            value: paymentLink?.product.valor,
                            quantity: 1,
                        }]
                    },
                    customer: {
                        name: card?.owner.name,
                        document: card?.owner.document,
                        email: card?.owner.email,
                        phoneNumber: card?.owner.phoneNumber,
                        birthDay: card?.owner.birthDay,
                        address: {
                            postalCode: card.owner.address.CEP ?? "",
                            street: card.owner.address.street,
                            number: card.owner.address.number,
                            complement: card.owner.address.complement,
                            neighborhood: card.owner.address.neighborhood,
                            city: card.owner.address.city,
                            state: card.owner.address.state,
                            country: "",
                        }
                    },
                    installments: installment?.installment ?? 1,
                    storeId: paymentLink.localId,
                }

                showLoading()
                paymentLinkApi.getPaymentGuestSendPay(newPaymentGuestPayRequest).then((response) => {
                    replace("/PaymentGuest/onSucessPayment");
                }).catch((error) => {
                    toast(error?.response.data.Message, "error");
                }).finally(() => {
                    hideLoading();
                });
            }

        },
        [card, deviceIdMercadoPago, hideLoading, installment, paymentLink, replace, showLoading, toast],
    )

    return (
        {
            card,
            setCard,
            orderDetail,
            onClickSend,
            paymentLink,
            installment,
            setInstallment
        }
    )
}
export default UsePaymentGuestPage