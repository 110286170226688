import { useParamsConfig } from "context/paramsConfig/ParamsConfigContext";
import { useUi } from "context/ui/UIContext";
import { IStoreConfig } from "models/local/ILocalConfig";
import { useEffect, useState } from "react";
import OrderPadApi from "services/Api/orderPad/OrderPadApi";

export interface IOrderPads {
  number: string;
  id: string;
  status: number;
}
const UseLocalOrderPads = (storeConfig: IStoreConfig | null) => {
  const [orderPads, setOrderPads] = useState<IOrderPads[]>();
  const { showLoading, hideLoading } = useUi();
  const [selectedOrderPad, setSelectedOrderPad] = useState<IOrderPads>();
  const { queryParams } = useParamsConfig()

  useEffect(() => {
    if (storeConfig?.storeId && orderPads) {
      if (queryParams?.queryPlace) {
        const selectedFromUrl = orderPads?.find(orderPad => orderPad.number === queryParams.queryPlace);
        setSelectedOrderPad(selectedFromUrl);
        localStorage.setItem(`@storagePlace-${storeConfig?.storeId}`, queryParams?.queryPlace);
      } else {
        const _storagePlace = localStorage.getItem(`@storagePlace-${storeConfig?.storeId}`);
        if (_storagePlace) {
          const selected = orderPads?.find(orderPad => orderPad.number === _storagePlace);
          setSelectedOrderPad(selected);
        }
      }
    }
  }, [orderPads, queryParams, storeConfig])


  useEffect(() => {
    const getOrderPads = async () => {
      if (storeConfig?.storeId) {
        try {
          showLoading();
          const service = OrderPadApi();
          const response = await service.getOrderPads(storeConfig.storeId);
          setOrderPads(response.filter(item => item.status !== 0)); //Filtrando apenas comandas ativas
        } finally {
          hideLoading();
        }
      }
    };
    getOrderPads();
  }, [hideLoading, showLoading, storeConfig]);




  return {
    orderPads,
    selectedOrderPad,
    setSelectedOrderPad
  };
};

export default UseLocalOrderPads;
