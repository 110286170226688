
import { useAuth } from "context/auth/AuthContext";
import { useLocal } from "context/local/LocalContext";
import { useUi } from "context/ui/UIContext";
import { DateInfo } from "pages/public/schedule/components/pickerDate/interfaces/PickerDate.type";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { v4 } from "uuid";
import { IMoreDetails } from "../../../application/interfaces/IMoreDetails";
import { GetDataByDocumentUseCase } from "../../../application/useCases/GetDataByDocumentUseCase";
import GetDiponibilityAndVancanciesUseCase from "../../../application/useCases/GetDiponibilityAndVancanciesUseCase";
import { GetPlacesScheduleByServiceIdUseCase } from "../../../application/useCases/GetPlacesScheduleByServiceIdUseCase";
import { GetServiceDetailsByServiceIdUseCase } from "../../../application/useCases/GetServiceDetailsByServiceIdUseCase";
import { GetServiceScheduleByIdUseCase } from "../../../application/useCases/GetServiceScheduleByIdUseCase";
import { GetValuesServiceScheduleUseCase } from "../../../application/useCases/GetValuesServiceScheduleUseCase";
import { useCart } from "../../../../../../../../components/genericCart/CartContext";
import { UseNavigationTour } from "../../hooks/UseNavigationTour";
import { ICartItemTour } from "../../interfaces/ICartTour";
import { ICustomerForm, IValuesCustomerForm } from "./interface/ICustomersFormSchedule";
import { IFormScheduleTour } from "./interface/IFormScheduleTour";
import { IPlaceScheduleFormSchedule } from "./interface/IPlaceScheduleFormSchedule";
import { IServiceScheduleForm } from "./interface/IServiceScheduleForm";
import { IValueServiceSchedule } from "./interface/IValuesServiceSchedule";

const UseFormTour = () => {

  const [seller, setSeller] = useState<string>();
  const [isId, setIsId] = useState<string>();
  const [serviceDetails, setServiceDetails] = useState<IMoreDetails>()

  const [date, setDate] = useState<Date | null>(null);
  const { openModalLogin, isAuth } = useAuth();
  const [customers, setCustomers] = useState<ICustomerForm[]>([
    {
      id: v4(),
      name: "",
      document: "",
      rg: "",
      phone: "",
      note: "",
      birthDate: "",
      value: { description: "", listId: "" } as IValuesCustomerForm
    }
  ]);

  const [totalValue, setTotalValue] = useState(0)

  const [placeSchedule, setPlaceSchedule] = useState<IPlaceScheduleFormSchedule>();

  const [openModalPayment, setOpenModalPayment] = useState(false);


  const [formScheduleTour] = useState<IFormScheduleTour | null>(null);

  const [service, setService] = useState<IServiceScheduleForm>();
  const [valuesService, setValuesService] = useState<IValueServiceSchedule[]>();
  const [placeSchedulesList, setPlaceSchedulesList] = useState<IPlaceScheduleFormSchedule[]>();

  const { serviceId, cartId, storeId } = useParams<{ serviceId: string, cartId?: string, storeId: string }>();
  const { localId } = useLocal();

  const { showLoading, hideLoading } = useUi();

  const [modalLogin] = useState(false);

  const { url } = useParams<{ url: string }>();
  const { push } = useHistory();

  const [dateInfo, setDateInfo] = useState<DateInfo[]>([]);
  const [time, setTime] = useState<{ start: string, end: string, vacancies: number }>();
  const [backOpenModal, setBackOpenModal] = useState(false);


  const { addCart, cartTourItems, replaceCart } = useCart<ICartItemTour>();

  const { goToTourStores } = UseNavigationTour()
  const [localVacancies, setLocalVacancies] = useState(time?.vacancies ?? 0)
  const handleBackOpenModal = useCallback(() => {
    setBackOpenModal(true);
  }, []);

  const handleBackCloseModal = useCallback(() => {
    setBackOpenModal(false);
  }, []);


  useEffect(() => {
    if (cartId) {
      const cartItemToEdit = cartTourItems.find(item => item.id === cartId)
      cartItemToEdit?.serviceScheduledId && setPlaceSchedule(placeSchedulesList?.find(item => item.serviceScheduledId === cartItemToEdit?.serviceScheduledId))
      cartItemToEdit?.totalPrice && setTotalValue(cartItemToEdit?.totalPrice);
      cartItemToEdit?.id && setIsId(cartItemToEdit?.id);
      cartItemToEdit?.startDate && setDate(new Date(cartItemToEdit?.startDate));
      cartItemToEdit?.customers && setCustomers(cartItemToEdit?.customers);
    }

    return () => {

    }
  }, [cartId, cartTourItems, placeSchedulesList])


  //GETS
  useEffect(() => {
    if (placeSchedule) {
      showLoading();
      GetDiponibilityAndVancanciesUseCase(placeSchedule?.serviceScheduledId).then(response => {
        setDateInfo(response);
      }).finally(() => {
        hideLoading()
      })
    }
  }, [placeSchedule]);

  useEffect(() => {
    if (localId && serviceId) {
      const getAllInitialValues = async (localId: string, serviceId: string) => {
        showLoading();
        const responseService = GetServiceScheduleByIdUseCase(serviceId, localId);
        const responsePlace = GetPlacesScheduleByServiceIdUseCase(serviceId);
        const responseServiceDetail = GetServiceDetailsByServiceIdUseCase(serviceId);

        const [service, places, detail] = await Promise.all([responseService, responsePlace, responseServiceDetail])

        const _placesEnabled = places.filter((item) => item.isEnabled);

        setService(service);
        setPlaceSchedulesList(_placesEnabled);
        setServiceDetails(detail)
        if (places.length > 0) {
          setPlaceSchedule(places[0]);
        }
        hideLoading();
      }
      getAllInitialValues(localId, serviceId)
    }
  }, [hideLoading, localId, serviceId, showLoading])

  // useEffect(() => {
  //   if (localId && serviceId) {
  //     showLoading();
  //     const responseService = GetServiceScheduleByIdUseCase(serviceId, localId);
  //     const responsePlace = GetPlacesScheduleByServiceIdUseCase(serviceId);
  //     const responseServiceDetail = GetServiceDetailsByServiceIdUseCase(serviceId);

  //     Promise.all([responseService, responsePlace, responseServiceDetail]).then(([service, places, detail]) => {
  //       setService(service);
  //       setPlaceSchedulesList(places);
  //       setServiceDetails(detail)
  //       if (places.length === 1) {
  //         setPlaceSchedule(places[0])
  //       }
  //     }).finally(() => {
  //       hideLoading();
  //     });
  //   }
  // }, [serviceId, localId])



  //NO LoadingFeedback
  useEffect(() => {
    if (serviceId) {
      GetValuesServiceScheduleUseCase(serviceId).then(response => {
        setValuesService(response);
      })
    }
  }, [serviceId])


  const validate = useCallback(() => {

    let isValidate = true;

    if (!date) {
      isValidate = false
    }
    if (!customers.length) {
      isValidate = false
    }

    customers.map((customer) => {
      if (customer.name === "" || customer.name === null || customer.name === undefined) {
        isValidate = false;
      }
      if (customer.birthDate === "" || customer.birthDate === null || customer.birthDate === undefined) {
        isValidate = false;
      }

      if (customer.note === "" || customer.note === null || customer.note === undefined) {
        isValidate = false;
      }
      if (customer.document.length < 12) {
        isValidate = false;
      }
      if (customer.rg.length < 3) {
        isValidate = false;
      }
      if (!customer.value) {
        isValidate = false
      }
    })

    return isValidate;
  }, [date, customers]);

  const onClickBackHandle = useCallback(() => {
    push(`/${url}/schedule/tour/${storeId}/moreDetails/${serviceId}`)
  }, [])


  useEffect(() => {
    const total = customers.map(item => item.value?.value ?? 0).reduce((prev, current) => prev + current, 0)
    setTotalValue(total)
  }, [customers])

  const validateCPF = (document: string) => ((document.replace(/[^0-9]/g, "").length === 11))

  const autocompleteCPF = useCallback((document: string, index: number, localId: string) => {
    if (validateCPF(document)) {
      GetDataByDocumentUseCase(localId, document).then((res) => {
        setCustomers(prev => prev.map((_item: ICustomerForm, _index: number) => {
          if (_index === index) {
            return {
              ..._item,
              name: res.name,
              birthDate: res.birthday.substr(0, 10),
              value: null
            }
          } else {
            return _item
          }
        }))
      });
    }
  }, [])

  const handleCpf = useCallback((document: string, customerIndex: number) => {
    setCustomers(
      prev => prev.map((_item, _index) => {
        if (_index === customerIndex) {
          return {
            ..._item,
            document
          }
        } else {
          return _item
        }
      })

    );
    if (localId) {
      autocompleteCPF(document, customerIndex, localId);
    }
  }, [localId])

  const addQuantity = useCallback(() => {
    const newCustomer: ICustomerForm = {
      id: v4(),
      name: "",
      document: "",
      rg: "",
      phone: "",
      note: "",
      birthDate: "",
      value: null
    };
    setCustomers((prev) => [...prev, newCustomer]);
  }, []);

  const removeQuantity = useCallback(() => {
    const lastId = [...customers].pop()?.id;
    setCustomers(prev => prev.filter(item => item.id !== lastId));
  }, [customers]);


  const removeCustomer = (customerId: string) => {
    setCustomers(prev => prev.filter(item => item.id !== customerId));
  };

  const handleChangeCustomers = useCallback((name: string, value: string, customerId: string) => {
    const changeForm = (prevState: ICustomerForm[], name: string, value: string, customerId: string) =>
      prevState.map((item) => {
        if (item.id === customerId) {
          return { ...item, [name]: value, value: name === "birthDate" ? null : item.value };
        }
        return item;
      });
    setCustomers(prev => changeForm(prev, name, value, customerId))
  }, []);

  const handleChangePlaceSchedule = useCallback((placeScheduleName: string) => {
    setPlaceSchedule(placeSchedulesList?.find(item => item.name === placeScheduleName));
  }, [placeSchedulesList]);

  const handleChangeCustomersValue = useCallback((description: string, customerId: string) => {
    const changeForm = (prevState: ICustomerForm[], description: string, customerId: string) =>
      prevState.map((item) => {
        if (item.id === customerId) {
          const selectedPrice = valuesService?.find(item => item.description === description);
          if (selectedPrice) {
            return { ...item, value: selectedPrice };
          } else {
            return item;
          }
        }
        return item;
      });

    setCustomers(prev => changeForm(prev, description, customerId));
  }, [valuesService]);

  useEffect(() => {
    if (time) {
      const cartItem = cartTourItems.find(item => item.serviceScheduledId === placeSchedule?.serviceScheduledId)
      const vacancie = time.vacancies - (cartItem?.customers.length ?? 0)
      setLocalVacancies(vacancie);
    }
  }, [cartTourItems, placeSchedule, time])


  const handleScheduleTour = useCallback(() => {
    if (isAuth) {
      if (time && date && customers) {
        const [hour, minutes, seconds] = time.start.split(":")
        const startDateTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), Number(hour), Number(minutes), Number(seconds)).toISOString();
        const schedule: IFormScheduleTour = {
          seller: seller,
          date: startDateTime,
          customers: customers
        }
        // setOpenModalPayment(true);

        if (service && schedule && placeSchedule && schedule.date) {
          const createCartItemUseCase = (_service: IServiceScheduleForm, _formScheduleTour: IFormScheduleTour, _placeSchedule: IPlaceScheduleFormSchedule, _storeId: string) => {
            const _totalPrice = _formScheduleTour.customers.map(item => item.value?.value ?? 0).reduce((prev, atual) => prev + atual, 0)
            const _totalQuantity = _formScheduleTour.customers.length
            const _cartItem: ICartItemTour = {
              id: isId ?? v4(),
              imageUrl: _service.imageUrl,
              name: _service.name,
              totalPrice: _totalPrice,
              quantity: _totalQuantity,
              description: _service.description,
              customers: _formScheduleTour.customers,
              serviceScheduledId: _placeSchedule.serviceScheduledId,
              startDate: _formScheduleTour.date ?? "",
              storeId: _storeId,
              serviceId: _service.id
            }

            return _cartItem

          }
          const _cartItem = createCartItemUseCase(service, schedule, placeSchedule, storeId);
          if (isId) {
            replaceCart(_cartItem)
          } else {

            addCart(_cartItem);
          }
          goToTourStores();


        }
      }
    } else {
      openModalLogin();
    }
  }, [isAuth, time, date, customers, seller, service, placeSchedule, storeId, isId, goToTourStores, replaceCart, addCart, openModalLogin])

  const closeModalPayment = () => {
    setOpenModalPayment(false)
  }

  return {
    seller,
    setSeller,
    date,
    setDate,
    customers,
    setCustomers,
    handleChangeCustomers,
    addQuantity,
    removeQuantity,
    removeCustomer,
    handleScheduleTour,
    closeModalPayment,
    formScheduleTour,
    handleChangeCustomersValue,
    service,
    valuesService,
    placeSchedulesList,
    handleChangePlaceSchedule,
    placeSchedule,
    validate,
    modalLogin,
    openModalPayment,
    onClickBackHandle,
    totalValue,
    handleCpf,
    dateInfo,
    time,
    setTime,
    backOpenModal,
    handleBackOpenModal,
    handleBackCloseModal,
    serviceDetails,
    localVacancies
  };
};
export default UseFormTour;
