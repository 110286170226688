
import React, { FC } from 'react'
import styles from './InteressesProfile.module.scss'

import img1 from '../../../../../res/images/social/interesses/1.svg'
import img2 from '../../../../../res/images/social/interesses/2.svg'
import img3 from '../../../../../res/images/social/interesses/3.svg'
import img4 from '../../../../../res/images/social/interesses/4.svg'
export interface IInteressesProfileProps {
    //propertys
}
const InteressesProfile: FC<IInteressesProfileProps> = () => {
    return (
        <div id={styles.InteressesProfile} >
            <h2>Interesses</h2>
            <div className={styles.container} >
                <img src={img1} alt="imagem 1" />
                <img src={img2} alt="imagem 2" />
                <img src={img3} alt="imagem 3" />
                <img src={img4} alt="imagem 4" />
            </div>
        </div>
    )
}
export default InteressesProfile