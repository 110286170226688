
import React, { FC } from 'react'
import styles from './ConquistasProfile.module.scss'

import img1 from '../../../../../res/images/social/conquistas/1.svg'
import img2 from '../../../../../res/images/social/conquistas/2.svg'
import img3 from '../../../../../res/images/social/conquistas/3.svg'
import img4 from '../../../../../res/images/social/conquistas/4.svg'
export interface IConquistasProfileProps {
    //propertys
}
const ConquistasProfile: FC<IConquistasProfileProps> = () => {
    return (
        <div id={styles.ConquistasProfile} >
            <h2>Conquistas</h2>
            <div className={styles.container} >
                <img src={img1} alt="imagem 1" />
                <img src={img2} alt="imagem 2" />
                <img src={img3} alt="imagem 3" />
                <img src={img4} alt="imagem 4" />
            </div>
        </div>
    )
}
export default ConquistasProfile