/* eslint-disable react-hooks/exhaustive-deps */
import { useTheme } from 'context/theme/ThemeContext';
import { Catalog } from 'models/catalog/aggregates/Catalog';
import { useCallback, useEffect, useState } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { CatalogApi } from 'services/Api/catalog/CatalogApi';
import useQuery from 'utils/useQuery';
import { GetProductFromCatalogById } from './useCases/GetProductFromCatalogById';

const useCatalogContext = () => {
    const [catalog, setCatalog] = useState<Catalog>();
    const [onLoading, setOnLoading] = useState(false);

    const { url } = useParams<{ url: string }>();
    const { pathname } = useLocation();
    const { replace } = useHistory();
    const { detectThemeByUrl } = useTheme();
    const { query } = useQuery();

    const forcedChangeCatalog = useCallback(
        (catalog: Catalog) => {
            if (catalog.id === "615fec29-5c8c-8f99-d431-55ec25f1aa26") { //Casa Cor
                return { ...catalog, hideCompositions: true }
            } else {
                return catalog;
            }
        },
        [],
    )

    const goToFirtsStore = useCallback(
        (catalog: Catalog, listId?: string) => {
            if (pathname !== `${pathname}/${catalog.stores[0].id}}`) {
                // replace(`/${url}/${catalog.stores[0].id}`)
                replace(`/${url}/${catalog.stores[0].id}${listId ? "?listId=" + listId : ""}`)
            }
        },
        [pathname, replace, url],
    )

    useEffect(() => {
        const catalogApi = CatalogApi();
        setOnLoading(true);
        const listId = query?.get("listId");
        if (listId) {
            catalogApi.GetCatalogByListId(url, listId).then((response) => {
                setCatalog(forcedChangeCatalog(response.data));
            }).finally(() => {
                setOnLoading(false);
            });
        } else {
            catalogApi.getCatalogByUrl(url).then((response) => {
                setCatalog(forcedChangeCatalog(response.data));

            }).finally(() => {
                setOnLoading(false);
            });
        }

        return () => { };
    }, [url]);

    const storesLengthRedirect = useCallback(() => {
        if (catalog && catalog?.stores?.length === 1) {
            const listId = query?.get("listId");
            goToFirtsStore(catalog, listId ?? undefined)
        }
    }, [catalog, goToFirtsStore])

    const getProductFromCatalog = useCallback((productId: string) => {
        if (catalog) {
            return GetProductFromCatalogById(catalog, productId)
        }
    }, [catalog]);



    useEffect(() => {

        detectThemeByUrl(url)

    }, [detectThemeByUrl, url])


    return (
        {
            catalog,
            onLoading,
            storesLengthRedirect,
            getProductFromCatalog
        }
    )
}

export default useCatalogContext
