import { ScheduleTourApi } from 'services/Api/schedule/ScheduleTourApi';
import { IPlaceScheduleFormSchedule } from '../../presentation/pages/formTour/interface/IPlaceScheduleFormSchedule';

export const GetPlacesScheduleByServiceIdUseCase = async (serviceId: string): Promise<IPlaceScheduleFormSchedule[]> => {

    const scheduleTourApi = ScheduleTourApi();
    const response = await scheduleTourApi.getPlacesByServiceId(serviceId);
    return (
        response.items
    )
}
