import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import React from 'react'
import Slider from "react-slick";

import styles from './Carousel.module.scss'
interface ICarouselProps {
    values: string[];
}

function SampleNextArrow(props: any) {
    const { onClick } = props;
    return (
        <button
            className={styles.prevArrow}
            onClick={onClick}
        >
            <ArrowBackIos />
        </button>
    );
}

function SamplePrevArrow(props: any) {
    const { onClick } = props;
    return (
        <button
            className={styles.nextArrow}
            onClick={onClick}
        >
            <ArrowForwardIos />
        </button>
    );
}

var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,    
    dotLimit: 3,
    arrows: true,
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SampleNextArrow />,
    autoplay: true,
    autoplaySpeed: 6000,
    cssEase: "linear",
};
export const Carousel: React.FC<ICarouselProps> = ({ values }) => {
    return (
        <div id={styles.Carousel}>

            <Slider {...settings}>

                {
                    values.map(item => (

                        <img src={item} alt="Imagens slider" />

                    ))
                }
            </Slider>
        </div>
    )
}
