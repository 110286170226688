import React, { FC, ReactNode } from 'react'
import styles from './Container.module.scss'
import { Container as ContainerMaterial } from '@material-ui/core'
export interface IContainerProps {
    //propertys
    style?: React.CSSProperties | undefined;
    children: ReactNode
}
const Container: FC<IContainerProps> = ({ children, style }) => {
    return (
        <div id={styles.Container} >
            <ContainerMaterial maxWidth={"md"} style={{ padding: 0, ...style }} >
                <>
                    {children}
                </>
            </ContainerMaterial>
        </div>
    )
}
export default Container