import { Button, IconButton, Modal, Paper, TextField } from "@material-ui/core";
import { AddCircle, CloseOutlined, DeleteOutline } from "@material-ui/icons";
import { ReactComponent as Alert } from "assets/icons/alert.svg";
import { ReactComponent as Key } from "assets/icons/key.svg";
import { ReactComponent as Empty } from "assets/image/empty.svg";
import { useUi } from "context/ui/UIContext";
import {
  EditUserRequest,
  IWalletDependents,
  LinkTagRequest,
} from "models/wallet/IWallet";
import React, { FC, useCallback, useState } from "react";
import { WalletApi } from "services/Api/wallet/WalletApi";
import NewPasswordForm from "./components/newPasswordForm/NewPasswordForm";
import NewTagForm from "./components/newTagForm/NewTagForm";
import styles from "./Tag.module.scss";

export interface ITags {
  onCancel: () => void;
  tag?: string;
  walletId: string;
  storeId: string;
  dependent?: IWalletDependents | null;
  cardName: string;
  refresh?: () => Promise<void>;
}

const Tag: FC<ITags> = ({
  onCancel,
  tag,
  walletId,
  storeId,
  dependent,
  cardName,
  refresh,
}) => {
  const [showNewTagModal, setShowNewTagModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);
  const [name, setName] = useState<string>(dependent?.Name ?? "");
  const [nameError, setNameError] = useState("");

  const { toast, showLoading, hideLoading, isMobile } = useUi();

  const handleSubmitNewTag = useCallback(
    (password: string, _tag: string, closeModal: () => void) => {
      const walletApi = WalletApi();
      showLoading();
      const params: LinkTagRequest = {
        CardName: cardName,
        IsCardReplacement: true,
        LocalClientId: storeId,
        WalletId: walletId,
        Password: password,
        Tag: _tag.replace(/-/g, ""),
        IsDependent: dependent ? true : false,
      };
      walletApi
        .linkTag(params)
        .then((response) => {
          if (response.status === 200) {
            closeModal();
            onCancel();
            refresh && refresh();
            toast(
              dependent
                ? "Dependente editado com sucesso!"
                : "Dependente criado com sucesso!",
              "success"
            );
          }
        })
        .finally(() => {
          hideLoading();
        });
    },
    [
      cardName,
      hideLoading,
      onCancel,
      refresh,
      showLoading,
      storeId,
      toast,
      walletId,
      dependent,
    ]
  );

  const handleEditDependent = useCallback(() => {
    const walletApi = WalletApi();
    showLoading();
    const params: EditUserRequest = {
      WalletId: walletId,
      CardName: name,
      TAG: tag ?? "",
    };
    walletApi
      .editUser(params)
      .then((response) => {
        if (response.status === 200) {
          onCancel();
          toast("Dependente editado com sucesso!", "success");
          refresh && refresh();
        }
      })
      .finally(() => {
        hideLoading();
      });
  }, [hideLoading, name, onCancel, refresh, showLoading, tag, toast, walletId]);

  const handleSubmitNewPassword = useCallback(
    (password: string, closeModal: () => void) => {
      const walletApi = WalletApi();
      showLoading();
      const params: EditUserRequest = {
        WalletId: walletId,
        NewPassword: password,
        CardName: cardName,
        TAG: tag ?? "",
      };
      walletApi
        .editUser(params)
        .then((response) => {
          if (response.status === 200) {
            closeModal();
            onCancel();
            toast("Senha alterada com sucesso", "success");
          }
        })
        .finally(() => {
          hideLoading();
        });
    },
    [cardName, hideLoading, onCancel, showLoading, tag, toast, walletId]
  );

  const onConfirmCancel = useCallback(async () => {
    if (tag) {
      try {
        showLoading();
        const walletApi = WalletApi();
        const response = await walletApi.deactiveTAG(tag);
        if (response.status === 200) {
          toast("TAG cancelada com sucesso", "success");
          refresh && refresh();
          onCancel();
        }
      } finally {
        hideLoading();
      }
    }
  }, [hideLoading, onCancel, refresh, showLoading, tag, toast]);

  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (dependent) {
        if (value.length < 3) {
          setNameError("Nome inválido");
        } else {
          setNameError("");
        }
      }
      setName(value);
    },
    [dependent]
  );

  return (
    <div id={styles.Tag}>
      <h4>
        {dependent ? "Editar dependente" : "Vinculo de TAG"}
        <IconButton onClick={onCancel}>
          <CloseOutlined />
        </IconButton>
      </h4>

      {dependent && (
        <div className={styles.input}>
          <p>
            Nome <span>*</span>
          </p>
          <TextField
            variant="outlined"
            value={name}
            onChange={handleNameChange}
            error={!!nameError.length}
            helperText={nameError}
          />
        </div>
      )}

      <Button
        fullWidth
        className={!tag ? styles.enableAdd : styles.disableAdd}
        disabled={!!tag}
        onClick={() => setShowNewTagModal(true)}
      >
        Vincular TAG
        <AddCircle />
      </Button>

      {tag ? (
        <div className={styles.tagContainer}>
          <div>
            <p>
              Tag atual:{" "}
              <b>{tag.replace(/([A-Za-z0-9]{2})(?=[A-Za-z0-9])/g, `$1-`)}</b>
            </p>
            <span onClick={() => setShowNewPasswordModal(true)}>
              <Key />
              Redefinir senha
            </span>
          </div>
          <IconButton onClick={() => setShowDeleteModal(true)}>
            <DeleteOutline />
          </IconButton>
        </div>
      ) : (
        <div className={styles.emptyTag}>
          <Empty />
          <p>Nenhuma tag encontrada</p>
        </div>
      )}
      {dependent && (
        <div className={styles.buttonsContainer}>
          <Button
            fullWidth
            variant="contained"
            disabled={false}
            onClick={handleEditDependent}
          >
            Salvar
          </Button>
          <Button fullWidth variant="outlined" onClick={onCancel}>
            Cancelar
          </Button>
        </div>
      )}

      <Modal
        open={showNewTagModal}
        onClose={() => setShowNewTagModal(false)}
        className={styles.newTagModal}
      >
        <NewTagForm
          title="Vinculo de TAG"
          buttonTitle="Vincular"
          onCancel={() => setShowNewTagModal(false)}
          handleSubmitNewTag={handleSubmitNewTag}
        />
      </Modal>
      <Modal
        open={showNewPasswordModal}
        onClose={() => setShowNewPasswordModal(false)}
        className={styles.newTagModal}
      >
        <NewPasswordForm
          onCancel={() => setShowNewPasswordModal(false)}
          handleSubmitNewPassword={handleSubmitNewPassword}
        />
      </Modal>
      <Modal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        className={styles.modal}
        style={{ padding: isMobile ? "0 8px" : undefined }}
      >
        <Paper
          className={styles.paper}
          style={{ width: isMobile ? "100%" : undefined }}
        >
          <div>
            <span>Cancelar TAG</span>
            <IconButton onClick={() => setShowDeleteModal(false)}>
              <CloseOutlined />
            </IconButton>
          </div>
          <Alert />
          <p>Deseja realmente cancelar a TAG?</p>
          <p>
            Ao clicar em <u>Continuar</u> o cartão será cancelado e não poderá
            ser utilizado novamente, perdendo sua função.
          </p>
          <Button variant="contained" color="inherit" onClick={onConfirmCancel}>
            Continuar
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancelar
          </Button>
        </Paper>
      </Modal>
    </div>
  );
};

export default Tag;
