import React, { FC } from 'react'
import styles from './FaqPage.module.scss'
import Button from 'pages/public/schedule/components/Button/Button'
import { FaqCard } from './faqCard/FaqCard'
import UseFaqPage from './UseFaqPage'
import { Close } from '@material-ui/icons'

interface IFaqPageProps {
    onClickFaq: () => void;
}

const FaqPage: FC<IFaqPageProps> = ({ onClickFaq }) => {
    const { values } = UseFaqPage();

    return (
        <div id={styles.FaqPage}>

            <div className={styles.titleHeader}>
                <p>Ajuda</p>
                <Close onClick={onClickFaq} />
            </div>
            <div className={styles.mainFaq}>
                {
                    values.categories.map((item, index) => (
                        <div key={index}>
                            {/* <p>{item.title}</p> */}
                            {
                                item.questions.map((it, key) => (
                                    <FaqCard value={it} key={key} />
                                ))
                            }
                        </div>

                    ))
                }
            </div>
            <div className={styles.footerButtonContact}>
                <p>Não encontrou sua dúvida por aqui? </p>
                <a
                    href="http://api.whatsapp.com/send?1=pt_BR&phone=54991964108"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Whatsapp">
                    <Button variant="contained" fullWidth>Chamar no WhatsApp</Button>
                </a>
            </div>
        </div>

    )
}

export default FaqPage
