import { addDays } from 'date-fns'
import { GetVancaciesAndDiponibilityResponseDto } from '../../_domain/dto/ReservePlaceSchedule/GetVacanciesAndDisponibilityByServiceIdResponseDTO'
import { ScheduleTourApi } from 'services/Api/schedule/ScheduleTourApi';

// import { ScheduleTourApi } from 'services/Api/schedule/ScheduleTourApi';


const GetDiponibilityAndVancanciesUseCase = async ( scheduledId: string): Promise<GetVancaciesAndDiponibilityResponseDto[]> => {
    const response = await ScheduleTourApi().getVacanciesAndDisponibilityService(scheduledId, new Date().toISOString(), addDays(new Date(), 90).toISOString())
    return response
}

export default GetDiponibilityAndVancanciesUseCase
