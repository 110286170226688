
import { useTheme } from "context/theme/ThemeContext"
import { useUi } from "context/ui/UIContext"
import { IUser } from "models/user/IUser"
import React, { FC, useCallback, useEffect } from "react"
import { useParams } from "react-router-dom"
import AuthPage from "../AuthPage"

import styles from "./ExternalAuthPage.module.scss"

export interface IAuthPageProps {
    //propertys
    onLogin?: () => void,
}
const ExternalAuthPage: FC<IAuthPageProps> = () => {

    const { toastFullScreen } = useUi();
    const { detectThemeByUrl } = useTheme()
    const { url } = useParams<{ url: string }>()

    const onSucessLogin = useCallback((authValues?: { token?: string, user?: IUser }) => {
        window.parent.postMessage({ command: "LOGIN_SUCCESS", token: authValues?.token, userId: authValues?.user?.id }, "*");
        toastFullScreen("Login realizado com sucesso", "check", "success", 10000)
    }, [toastFullScreen])

    const onCancelHandle = useCallback(() => {
        window.parent.postMessage({ command: "LOGIN_CANCEL" }, "*");
    }, [])

    useEffect(() => {
        detectThemeByUrl(url)
    }, [detectThemeByUrl, url])


    return (
        <div id={styles.AuthPage}>
            <AuthPage enableDddi onLogin={onSucessLogin} onPressCancel={onCancelHandle} />
        </div>
    )
}
export default ExternalAuthPage