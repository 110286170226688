import { useLocation } from "react-router-dom";

function useQuery() {
    const query = new URLSearchParams(useLocation().search)

    return {
        query
    }
}

export default useQuery