export interface DeliveryFee {
  price: number;
}

export interface DeliveryFeeData {
  storeId: string;
  addressId: string;
}

export enum DeliveryType {
  undefined = 0,
  delivery = 1,
  catch,
  other,
  localPlace,
}
