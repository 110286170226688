import { IResumoPedido } from "models/order/aggregates/IResumoPedido/IResumoPedido"
import { IOrder } from "models/order/IOrder"
import { IOrderComposition } from "models/order/IOrderComposition"
import { IOrderCompositionItem } from "models/order/IOrderCompositionItem"
import { IOpcoes, IOpcoesDoConsumidor } from "models/resumoPedido/resumoPedido"
import api from "../Api"

const SendOrderApi = () => {

    const sendPreOrder = async (order: IOrder, localId: string) => {
        const response = await api.post<{ pedidoId: string }>("/vendas/criarPrePedido", {
            resumoPedido: orderToResume(order),
            localClienteId: localId,
        })
        return response;
    }

    const orderToResume = (order: IOrder): IResumoPedido => {

        const compositionToOpcoesDoConsumidor = (orderCompositions: IOrderComposition[]): IOpcoesDoConsumidor[] => {
            return orderCompositions.map((composition) => ({
                Id: composition.id,
                Descricao: composition.description,
                Opcoes: compositionItemToOpcoes(composition.compositionItems),
            }))
        }
        const compositionItemToOpcoes = (orderCompositionItem: IOrderCompositionItem[]): IOpcoes[] => {
            return orderCompositionItem.map((item) => ({
                Id: item.id,
                Descricao: item.description,
                ProdutoId: "",
                ProdutoValor: item.price,
                Quantidade: item.quantity,
            }))
        }

        const newResumo: IResumoPedido = {
            idReferencia: "",
            valorPedido: order.total,
            id: order.id,
            itens: order.items.map((item) => (
                {
                    produtoId: item.productId,
                    nome: item.name,
                    quantidade: item.quantity,
                    valorUnitario: item.price,
                    opcoesDoConsumidor: item.orderComposition ? JSON.stringify(compositionToOpcoesDoConsumidor(item.orderComposition)) : "",
                    // opcoesDoConsumidorObject: item.orderComposition ? compositionToOpcoesDoConsumidor(item.orderComposition) : [],
                }
            )),
        }

        return newResumo
    }


    return {
        sendPreOrder
    }

}

export default SendOrderApi
