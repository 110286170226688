
import React, { useContext, createContext, useCallback, useEffect, useState, PropsWithChildren, Context } from 'react'
import { v4 } from 'uuid'
import { ICartItem } from './ICartItem'

interface ICartContext<T extends ICartItem = any> {
    cartTourItems: T[]
    totals: { price: number, quantity: number }
    addCart: (cartItem: T) => void
    removeCart: (cartTourId: string) => void
    clearCart: () => void,
    replaceCart: (cartItem: T) => void
}

interface ICartProviderProps {
    isEqual?: (cartItem: ICartItem) => boolean
}

const CartContext: Context<ICartContext> = createContext<ICartContext>({} as ICartContext)

const CartProvider = <T,>({ children }: PropsWithChildren<ICartProviderProps>) => {

    const [cartTourItems, setCartTourItems] = useState<ICartItem[]>([])
    const [totals, setTotals] = useState({ price: 0, quantity: 0 })

    const addCart = useCallback((cartItem: { name: string, totalPrice: number, imageUrl: string, quantity: number, [key: string]: any; } & T) => {
        setCartTourItems(prev => (
            [
                ...prev,
                {
                    id: v4(),
                    ...cartItem
                }
            ]))
    },
        [],
    )
    const removeCart = useCallback(
        (cartTourId: string) => {
            setCartTourItems(prev => (prev.filter(item => item.id !== cartTourId)))
        },
        [],
    )

    const clearCart = useCallback(
        () => {
            setCartTourItems([])
        },
        [],
    )

    const replaceCart = useCallback((cartItem: { id: string, name: string, totalPrice: number, imageUrl: string, quantity: number, [key: string]: any; } & T) => {
       removeCart(cartItem.id);
       addCart(cartItem)

    },[addCart, removeCart]);


    useEffect(() => {
        const totalPrice = cartTourItems.flatMap(item => item.totalPrice).reduce((prev, current) => (prev + current), 0);
        const totalQuantity = cartTourItems.flatMap(item => item.quantity).reduce((prev, current) => (prev + current), 0);
        setTotals({ price: totalPrice, quantity: totalQuantity });
        return () => {

        }
    }, [cartTourItems])

    return (
        <CartContext.Provider
            value={
                {
                    cartTourItems,
                    totals,
                    addCart: addCart,
                    removeCart: removeCart,
                    clearCart: clearCart,
                    replaceCart
                }
            }
        >
            {children}
        </CartContext.Provider>
    )
}



export default CartProvider


export const useCart = <T extends ICartItem,>() => {
    const context = useContext<ICartContext<T>>(CartContext);
    return context;
};