
import { CalcTotalsAllCompositions } from 'components/productComposition/useCases/CalcTotalsAllCompositions';
import { Product } from 'models/catalog/aggregates/Product';
import { IOrderComposition } from 'models/order/IOrderComposition';
import { v4 } from 'uuid';
import { ICartItem } from '../models/ICart';

export const AddCartItemByProductUseCase = (prevCartItems: ICartItem[], product: Product, quantity: number, orderCompositions?: IOrderComposition[]): ICartItem[] => {

    const compareComposition = (composition?: IOrderComposition[], compositionToCopare?: IOrderComposition[]) => {
        try {
            const compositionString = JSON.stringify(composition);
            const compositionToCopareString = JSON.stringify(compositionToCopare);
            return compositionString === compositionToCopareString;
        } catch (error) {
            return false;
        }
    };

    const productToCartItem = (_product: Product, _quantity: number, _orderCompositions?: IOrderComposition[]) => {
        const newCartItem: ICartItem = {
            id: v4(),
            name: _product.name,
            category: _product.category,
            price: _product.price,
            totalPrice: _quantity * (_product.price + (_orderCompositions ? CalcTotalsAllCompositions(_orderCompositions).price : 0)),
            description: _product.description,
            productPrice: _product.type,
            productId: _product.id,
            productType: _product.type,
            quantity: _quantity,
            imageUrl: _product.imageUrl,
            barcode: _product.barcode,
            orderComposition: _orderCompositions,
            printerName: _product.printerName,
        };
        return newCartItem;
    };

    const addQuantityInExitCartItem = (_prevCartItems: ICartItem[], _id: string, _quantity: number) => {
        const newCartItems = _prevCartItems.map((cartItem) => {
            if (cartItem.id === _id) {
                return { ...cartItem, quantity: cartItem.quantity + _quantity, totalPrice: cartItem.price * (cartItem.quantity + _quantity) };
            } else {
                return cartItem;
            }
        });
        return newCartItems;
    };

    const addNewCartItem = (_prevCartItems: ICartItem[], _product: Product, _quantity: number, _orderComposition?: IOrderComposition[]) => {
        const newCartItems = [..._prevCartItems, productToCartItem(_product, _quantity, _orderComposition)];
        return newCartItems;
    };

    const existProductInCart = (_prevCartItems: ICartItem[], _product: Product, _quantity: number, _orderCompositions?: IOrderComposition[]) => {
        const existCartItem = _prevCartItems.find((cartItem) => cartItem.productId === _product.id && compareComposition(cartItem.orderComposition, _orderCompositions));
        return existCartItem;
    };

    const addCartItem = (_prevCartItems: ICartItem[], _product: Product, _quantity: number, _orderCompositions?: IOrderComposition[]) => {
        const existCartItem = existProductInCart(_prevCartItems, _product, _quantity, _orderCompositions);
        const dontHaveComposition = !_orderCompositions?.length;

        if (existCartItem && dontHaveComposition) {
            const newCartItems = addQuantityInExitCartItem(_prevCartItems, existCartItem.id, _quantity);
            return newCartItems;
        } else {
            const newCartItems = addNewCartItem(_prevCartItems, _product, _quantity, _orderCompositions);
            return newCartItems;
        }
    };

    const execute = () => {
        return addCartItem(prevCartItems, product, quantity, orderCompositions);
    };

    return execute();
};