import { TrackCustomer } from "./TrackWidget";

export const scriptTrack = (key: string, customer?: TrackCustomer, interaction?: object) => {

  const script = `(function (f, b, g) {

    var a = {
      key: '${key}',
      appendTo: 'trackElement',
       ${customer ? `customer: {
          'name': '${customer.name}',
          'phone': '${customer.phone}',
          'email': '${customer.email}',
          'CPF': '${customer.CPF}'
      }` : ``},
     ${interaction ? `interaction:` + JSON.stringify(interaction) : ``}
    };
    var e;
    var c = f.getElementsByTagName(b)[0];
    if (f.getElementById(g)) { return };
    e = f.createElement(b);
    e.id = g;
    e.src = 'https://app.track.co/widget.min.js';
    e.type = 'text/javascript';
    e.async = true;
    e.onload = e.onreadystatechange = function () {
      var h = this.readyState;
    if (h && h !== 'complete' && h !== 'loaded') { return };
      try {
        var c = new TrackWidget();
      c.createWidget(a);
    } catch (i) { }
  };
 c.parentNode.insertBefore(e, c);}(document, 'script', 'trackwidget-js'))`;
  return script
};
